import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { Modal } from "../../../common/modal";
import { email as emailRegex } from "../../../../constants/regex";
import { OverlayPreloader } from "../../../common/preloader";
import Input from "../../../common/input";
import ModalControl from "../../../modalControl";
import styles from "./contactInfo.module.css";

const ContactInfo = ({ closeModal, clearAllModals, contactInfoData, setContactInfoData, preloader, changeContactInfo }) => {
  const { t } = useTranslation();

  const validate = values => {
    const { email } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");
    const invalidUrlText = t("dashboard_form_validations_invalid_email");

    if (!email) {
      errors.email = requiredText;
    }

    if (email && !email.match(emailRegex)) {
      errors.email = invalidUrlText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: contactInfoData.firstName,
      lastName: contactInfoData.lastName,
      email: contactInfoData.email,
      phone: contactInfoData.phone
    },
    validate,
    onSubmit: values => {
      const { firstName, lastName, email, phone } = values;
      const data = { name_f: firstName, name_l: lastName, email, phone, lead_id: contactInfoData.leadId };
      changeContactInfo(data);
    }
  });

  const { setFieldValue, setFieldTouched, values, errors, touched } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    setFieldTouched(e.target.name, true);
  };

  const { firstName, lastName, email, phone } = values;

  useEffect(() => {
    return () => {
      setContactInfoData({});
    };
  }, []); // eslint-disable-line

  return (
    <Modal closeModal={closeModal} clearAllModals={clearAllModals}>
      {preloader && <OverlayPreloader />}

      <h2 className={styles.title}>Contact Information</h2>

      <div className={styles.flexWrap}>
        <Input
          classNameWrap={styles.inputWrap}
          error={errors.firstName}
          isInvalid={errors.firstName && touched.firstName}
          id="firstName"
          label="First Name"
          onBlur={handleBlur}
          onChange={handleChange}
          name="firstName"
          value={firstName}
        />

        <Input
          classNameWrap={styles.inputWrap}
          error={errors.lastName}
          isInvalid={errors.lastName && touched.lastName}
          id="lastName"
          label="Last Name"
          onBlur={handleBlur}
          onChange={handleChange}
          name="lastName"
          value={lastName}
        />
      </div>
      <div className={styles.flexWrap}>
        <Input
          type="email"
          classNameWrap={styles.inputWrap}
          error={errors.email}
          isInvalid={errors.email && touched.email}
          id="email"
          label={t("dashboard_common_field_email")}
          onBlur={handleBlur}
          onChange={handleChange}
          name="email"
          value={email}
        />
      </div>

      <div className={styles.flexWrap}>
        <Input
          classNameWrap={styles.inputWrap}
          error={errors.phone}
          isInvalid={errors.phone && touched.phone}
          id="phone"
          label={t("dashboard_common_phone")}
          onBlur={handleBlur}
          onChange={handleChange}
          name="phone"
          value={phone}
        />
      </div>

      <ModalControl onExit={closeModal} onConfirm={formik.handleSubmit} isClose>
        {t("dashboard_common_button_save")}
      </ModalControl>
    </Modal>
  );
};

export default ContactInfo;
