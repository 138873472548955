import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { subTabLeads, subTabReferrals } from "../../../constants/referralRequests";
import { actions as payPerLeadActions } from "../../../actions/payPerLead";
import styles from "./tabs.module.css";

const Tabs = ({ setListActiveNetwork, setListActivePractice, listActivePractice, listActiveNetwork, activeFilter, setActiveFilter }) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.payPerLead);
  const isEmptyFilters = listActivePractice.length || listActiveNetwork.length;

  const setActiveTab = useCallback(subTab => dispatch(payPerLeadActions.setActiveTab(subTab)), [dispatch]);

  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);

  const handleSetActive = subTab => {
    const params = qs.stringify({ ...searchParams, tab: subTab });

    setActiveTab(subTab);
    history.push(`/referrals?${params}`);

    if (isEmptyFilters) {
      setListActivePractice([]);
      setListActiveNetwork([]);
    }

    if (activeFilter) {
      setActiveFilter(false);
    }
  };

  useEffect(() => {
    if ((searchParams?.tab && searchParams?.tab !== activeTab) || (!searchParams?.tab && !activeTab)) {
      handleSetActive(subTabReferrals.all, subTabLeads.new);
    }
  }, [searchParams?.tab]);

  return (
    <div className={styles.content}>
      <button
        type="button"
        className={cn(styles.tab, activeTab === subTabReferrals.all ? styles.tabActive : "")}
        onClick={() => handleSetActive(subTabReferrals.all)}
      >
        All
      </button>

      <button
        type="button"
        className={cn(styles.tab, activeTab === subTabReferrals.referrals ? styles.tabActive : "")}
        onClick={() => handleSetActive(subTabReferrals.referrals)}
      >
        My Requests
      </button>
    </div>
  );
};

export default Tabs;
