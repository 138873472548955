import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import store from "./reducers";
import ErrorBoundary from "./containers/errorBoundary";
import "./styles/normalize.css";
import "./styles/index.css";
import "./styles/grid.css";
import "./styles/stripeInputs.css";
import "./i18n";
import Routes from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Router>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
