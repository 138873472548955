import { account } from "../constants/actionTypes";

const defaultState = {
  error: {
    password: null,
    notifications: null,
    changeEmail: {}
  },
  verificationError: {
    changeEmail: false,
    checkEmailCode: false,
    checkPhoneCode: false,
    changePhone: false
  },
  notificationSettings: { notifications: [], newsletters: [] },
  profile: {
    login: "",
    new_messages: "",
    unread_messages: "",
    unread_notifications: "",
    listings_count: "0",
    referral_requests_count: "0",
    referral_requests_pending_import: "0",
    notifications: []
  },
  preloaders: {
    profile: false,
    contacts: false,
    password: false,
    notificationSettings: false,
    notifications: false,
    newsletters: false,
    verifications: false,
    removeNotificationEmail: false,
    removeNotificationPhone: false
  },
  success: {
    contacts: null,
    password: null,
    notifications: null,
    newsletters: null
  },
  modals: {
    changeEmail: false,
    changeEmailVerification: false,
    changeEmailSuccess: false,
    verifyEmail: false,
    verifyEmailSuccess: false,
    addPhone: false,
    addPhoneSuccess: false,
    changePhone: false,
    changePhoneSuccess: false,
    removePhone: false,
    removePhoneSuccess: false,
    changeEmailNew: false,
    changePhoneNew: false,
    changePasswordSuccess: false,
    deleteEmail: false,
    deletePhone: false,
    deletePhoneSuccess: false,
    deleteEmailSuccess: false,
    questionForm: false,
    questionFormSuccess: false
  },
  profileLocationAutoComplete: [],
  newLoginData: {
    email: "",
    password: ""
  },
  changeType: ""
};

export default function(state = defaultState, action) {
  const { payload, type } = action;

  switch (type) {
    case account.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isSectionLoading },
        verificationError: {
          ...defaultState.verificationError
        }
      };

    case account.GET_PROFILE:
      return {
        ...state,
        profile: payload,
        preloaders: {
          ...state.preloaders,
          profile: false
        }
      };

    case account.GET_PROFILE_FAIL:
      return {
        ...state,
        profileFail: payload,
        preloaders: {
          ...state.preloaders,
          profile: false
        }
      };

    case account.UPDATE_CONTACTS_SUCCESS:
      return {
        ...state,
        success: { ...state.success, contacts: payload }
      };

    case account.UPDATE_CONTACTS_FAIL:
      return {
        ...state,
        updateContactsFail: payload
      };

    case account.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        success: { ...state.success, password: payload }
      };

    case account.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        error: { ...state.error, password: payload }
      };

    case account.GET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: payload,
        preloaders: {
          ...state.preloaders,
          notificationSettings: false
        }
      };

    case account.GET_NOTIFICATION_SETTINGS_FAIL:
      return {
        ...state,
        notificationsFail: payload,
        preloaders: {
          ...state.preloaders,
          notificationSettings: false
        }
      };

    case account.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        success: { ...state.success, notifications: payload },
        preloaders: {
          ...state.preloaders,
          notifications: false
        }
      };

    case account.UPDATE_NEWSLETTERS_SUCCESS:
      return {
        ...state,
        success: { ...state.success, newsletters: payload },
        preloaders: {
          ...state.preloaders,
          newsletters: false
        }
      };

    case account.TOGGLE_ACTIVITY_OF_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        },
        verificationError: {
          ...defaultState.verificationError
        }
      };

    case account.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultState.modals
        }
      };

    case account.EMAIL_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          changeEmailVerification: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.EMAIL_SEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.EMAIL_VERIFY_CODE_FAIL:
      return {
        ...state,
        modals: {
          ...defaultState.modals
        },
        preloaders: { ...state.preloaders, verifications: false },
        error: {
          ...state.error,
          changeEmail: payload
        }
      };

    case account.EMAIL_VERIFY_CODE_CLEAR_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          changeEmail: {}
        },
        newLoginData: {
          ...defaultState.newLoginData
        }
      };

    case account.CHECK_EMAIL_VERIFY_CODE_FAIL:
      return {
        ...state,
        verificationError: {
          ...state.verificationError,
          checkEmailCode: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };
    case account.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          changeEmailSuccess: true
        },
        verificationError: {
          ...defaultState.verificationError
        },
        newLoginData: {
          email: "",
          password: ""
        },
        preloaders: { ...state.preloaders, verifications: false },
        profile: {
          ...state.profile,
          email: payload
        }
      };

    case account.VERIFIED_EMAIL_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          verifyEmailSuccess: true
        },
        verificationError: {
          ...defaultState.verificationError
        },
        newLoginData: {
          email: "",
          password: ""
        },
        preloaders: { ...state.preloaders, verifications: false },
        profile: {
          ...state.profile,
          email: payload
        }
      };

    case account.UPDATE_EMAIL_FAIL:
      return {
        ...state,
        verificationError: {
          ...state.verificationError,
          changeEmail: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.EMAIL_VERIFICATION_CHANGE:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          changeEmailNew: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.PHONE_VERIFICATION_CHANGE:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          addPhone: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.PASSWORD_VERIFICATION_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          changePasswordSuccess: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.SAVE_NEW_EMAIL_PASSWORD:
      return {
        ...state,
        newLoginData: {
          email: payload.email,
          password: payload.password
        }
      };
    case account.CLEAN_NEW_EMAIL_PASSWORD:
      return {
        ...state,
        newLoginData: {
          email: "",
          password: ""
        },
        verificationError: {
          ...defaultState.verificationError
        }
      };
    case account.PHONE_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        preloaders: { ...state.preloaders, verifications: false },
        verificationError: {
          ...defaultState.verificationError
        }
      };
    case account.PHONE_VERIFY_CODE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, verifications: false }
      };
    case account.CHECK_PHONE_VERIFY_CODE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, verifications: false },
        verificationError: {
          ...state.verificationError,
          checkPhoneCode: true
        }
      };

    case account.PROFILE_LOCATION_AUTOCOMPLETE:
      return {
        ...state,
        profileLocationAutoComplete: payload || [],
        preloaders: { ...state.preloaders, profileLocationAutoComplete: false }
      };

    case account.UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          addPhoneSuccess: true
        },
        verificationError: {
          ...defaultState.verificationError
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.REMOVE_PHONE_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultState.modals,
          removePhoneSuccess: true
        },
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.REMOVE_PHONE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, verifications: false }
      };

    case account.NOTIFICATION_REMOVE_EMAIL_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const removeEmailNotification = state.notificationSettings.listingNotifications.listings.map(item => {
        if (String(item.listing_id) === String(payload.listingId)) {
          return {
            ...item,
            verifiedemails: item.verifiedemails.filter(emailItem => String(emailItem.prkey) !== String(payload.prKey))
          };
        }

        return item;
      });

      return {
        ...state,
        preloaders: { ...state.preloaders, removeNotificationEmail: false },
        modals: { ...defaultState.modals, deleteEmailSuccess: true },
        notificationSettings: {
          ...state.notificationSettings,
          listingNotifications: {
            ...state.notificationSettings.listingNotifications,
            listings: removeEmailNotification
          }
        }
      };

    case account.NOTIFICATION_REMOVE_EMAIL_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, removeNotificationEmail: false }
      };

    case account.NOTIFICATION_REMOVE_PHONE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const removePhoneNotification = state.notificationSettings.listingNotifications.listings.map(item => {
        if (String(item.listing_id) === String(payload.listingId)) {
          return {
            ...item,
            verifiedphones: item.verifiedphones.filter(emailItem => String(emailItem.prkey) !== String(payload.prKey))
          };
        }

        return item;
      });

      return {
        ...state,
        preloaders: { ...state.preloaders, removeNotificationPhone: false },
        modals: { ...defaultState.modals, deletePhoneSuccess: true },
        notificationSettings: {
          ...state.notificationSettings,
          listingNotifications: {
            ...state.notificationSettings.listingNotifications,
            listings: removePhoneNotification
          }
        }
      };

    case account.NOTIFICATION_REMOVE_PHONE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, removeNotificationPhone: false }
      };

    case account.MARK_READ_ALL_NOTIFICATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          notifications: state.profile.notifications.map(item => {
            return { ...item, is_viewed: "1" };
          })
        }
      };

    case account.MARK_READ_NOTIFICATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          notifications: state.profile.notifications.map(item => {
            return payload.includes(item.notification_id) ? { ...item, is_viewed: "1" } : item;
          })
        }
      };

    case account.MARK_UN_READ_NOTIFICATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          notifications: state.profile.notifications.map(item => {
            return payload.includes(item.notification_id) ? { ...item, is_viewed: 0 } : item;
          })
        }
      };

    default:
      return {
        ...state
      };
  }
}
