import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Icon } from "../../media/icons/404.svg";
import styles from "./notFound.module.css";

export const NotFoundComponent = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("dashboard_404_metatitle");
  }, []);

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.imgWrap}>
          <Icon className={styles.errorImg} />
        </div>
        <p className={styles.textError}>{t("dashboard_404_description")}</p>
        <a className={styles.button} href="/">
          Return to Home
        </a>
      </div>
    </div>
  );
};
