import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as EmptyIcon } from "../../media/icons/new-empty-listing.svg";
import { validationNames, statuses } from "../../constants/listings";

import { NotificationListing } from "../common/notification";
import { OverlayPreloader } from "../common/preloader";
import { ListingNotification } from "./listingNotification";
import { getValidationData, getFillingFields, normalizeMembershipInsurances } from "../../helpers";
import styles from "./listingDetail.module.css";

import Modals from "./modals";
import ProductControls from "./productControls";
import PageNames from "./pageNames";
import Breadcrumbs from "./breadcrumbs";
import ProductActions from "./productActions";
import ProfileActions from "./profileActions";
import PublishedDirectories from "./publishedDirectories";
import ProductDetails from "./productDetails";
import ProfileInfo from "./profileInfo";
import ListingInfo from "./listingInfo";
import ListingInfoCover from "./listingInfoCover";
import LanguagesNew from "./languages";
import InsuranceNew from "./insurances";
import AssociationsNew from "./associations";
import EducationsNew from "./educations";
import LicensesNew from "./licenses";
import LocationsNew from "./locations";
import JobInformation from "./jobInformation";
import Tags from "./tags";
import PracticesNew from "./practices";
import SocialsNew from "./socials";
import BioNew from "./bio";
import ParentOrganization from "./parentOrganization";
import CalendlyNew from "./calendly";
import Video from "./video";
import Consultation from "./consultation";
import EventsTickets from "./eventsTickets";
import EventsAudiences from "./eventsAudiences";
import EventsSchedules from "./eventsSchedules";
import SelectTeam from "../selectTeam";
import { useMembership, useMembershipDocumentTitle, useMembershipImageUpload, useMembershipModal } from "../../helpers/hooks";

const Membership = ({ match, utilityStates, utilityCountries }) => {
  const [memberInputData, setMemberData] = useState({});
  const [showRequiredText, setShowRequiredText] = useState(false);
  const location = useLocation();
  const { id } = match.params;
  const { professions: practicesList } = useSelector(state => state.search);
  const { t } = useTranslation();

  const {
    deletePrimaryImage,
    getMemberDataNew,
    deleteAdditionalImages,
    getValidations,
    setMembershipClear,
    publishListing,
    resetDraft,
    getDirectoriesProfessions,
    pauseMembership,
    unpauseMembership,
    addCard,
    updateCard,
    submitImages,
    submitChanges,
    autoSaveListing,
    clearSuccess,
    clearAllModals,
    setCurrentTeam,
    getOptions,
    removeListingItem
  } = useMembership();

  const {
    directoriesProfessions,
    directoryPractices,
    error,
    preloaders,
    success,
    memberData,
    modals,
    validations,
    insuranceGroups,
    languagesList,
    validationErrors
  } = useSelector(state => state.listing);

  const {
    attorney,
    description,
    education,
    lawfirms,
    listing_url: listingUrl,
    languages,
    product_name: productName,
    practices,
    socials,
    title,
    website,
    insurances,
    status,
    listing_id: listingId,
    is_premium: isPremiumValue,
    is_draft: isDraft,
    parent_id: parentId,
    paused,
    bookmeetings,
    intro_video: introVideo,
    consultation,
    jobs,
    parent_company: parentCompany,
    tags,
    _canceled_to_date: canceledDate,
    change_status: changeStatus,
    publish_status: publishStatus,
    cover_image_url: defaultImage,
    cover_image_id: coverImageId,
    _profession: listingType,
    directories_availability: directoriesAvailability,
    primary_image_url: socialPhoto,
    additional_images: images = [],
    updated,
    event_tickets: eventTickets,
    event_schedules: eventSchedules
  } = memberData;

  useMembershipDocumentTitle({ listingType, attorney });

  const {
    uploadImagesError,
    showUploadImageNotificationError,
    uploadImageNotificationErrorText,
    handleDeleteCoverImage
  } = useMembershipImageUpload(listingId);

  const { openModal, closeModal, openAddNotificationsModal, handleActiveNotificationModal } = useMembershipModal({
    setMemberData,
    memberData
  });

  // todo we will use it later
  // const isDirectoriesAvailability = useMemo(() => "directories_availability" in memberData, [memberData.directories_availability]);
  const isPremium = useMemo(() => isPremiumValue === "true" || isPremiumValue === true, [isPremiumValue]);

  const { isJobListing, isEvents, isProfiles } = useMemo(() => {
    return {
      isJobListing: listingType === "Jobs",
      isEvents: listingType === "Events",
      isProfiles: listingType === "Profiles" || listingType === "People"
    };
  }, [listingType]);

  useEffect(() => {
    getValidations(id);
    getDirectoriesProfessions();

    return () => {
      setMembershipClear();
    };
  }, [location.pathname]); // eslint-disable-line

  useEffect(() => {
    setMemberData(memberData);
  }, [memberData]); // eslint-disable-line

  const handleBlur = (formik, fieldName) => formik.setFieldTouched(fieldName, true);

  const handleSubmitListing = (newData, section) => {
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    return submitChanges(
      {
        ...res,
        ...newData
      },
      section
    );
  };

  const handleAutoSaveListing = (newData, section, command, cancelTokens) => {
    return autoSaveListing({ listing_id: memberInputData.listing_id, ...newData }, section, command, cancelTokens);
  };

  const handleSubmitImage = (newData, section) => {
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    return submitImages(newData, section, res);
  };

  const handleRemoveListingItem = (collection, identifier, currentId, isIndex) => {
    removeListingItem(collection, identifier, currentId, isIndex, memberInputData);
  };

  const handleChangeTeam = teamValue => {
    setCurrentTeam(teamValue);
    getMemberDataNew(listingId);
  };

  const normalizeInsurances = useMemo(() => normalizeMembershipInsurances(insurances, insuranceGroups), []);

  const isShowCoverImage = useMemo(() => validations.length && getValidationData(validations, validationNames.coverImage).enabled, [
    validations,
    validationNames.coverImage
  ]);

  const isShowSuccessNotes = useMemo(() => Object.values(success).some(value => !!value), [success]);

  const isNotFilledAllFields = getFillingFields({
    validations,
    memberData
  });

  return (
    <>
      {uploadImageNotificationErrorText && (
        <NotificationListing error={showUploadImageNotificationError} type={5000} onClose={() => uploadImagesError(false)}>
          {uploadImageNotificationErrorText || "Could you please try again"}
        </NotificationListing>
      )}

      {isShowSuccessNotes && (
        <NotificationListing success onClose={clearSuccess}>
          {t("dashboard_common_saved")}
        </NotificationListing>
      )}

      {error && (
        <NotificationListing error={error} onClose={clearSuccess}>
          {error || "Something went wrong"}
        </NotificationListing>
      )}

      {(preloaders.memberData || preloaders.deleteMembership || preloaders.downgrade || (isProfiles && preloaders.profile)) && (
        <OverlayPreloader />
      )}

      {preloaders.memberData !== true && preloaders.memberData !== null ? (
        <div className={cn("wrapper", styles.wrapper)}>
          <div className={styles.headingFlex}>
            <PageNames />

            {isProfiles ? <SelectTeam unselectText="My Profile" handleChange={handleChangeTeam} className={styles.team} /> : null}
          </div>

          {!isProfiles && <Breadcrumbs listingName={attorney} />}
          <div className={styles.flexContent}>
            <div className={styles.container}>
              <div className={styles.content}>
                {isProfiles ? (
                  <>
                    <PublishedDirectories
                      directoriesAvailability={directoriesAvailability}
                      handleAutoSaveListing={handleAutoSaveListing}
                      listingUrl={listingUrl}
                      publishStatus={publishStatus}
                      paused={paused}
                      pauseListing={pauseMembership}
                      unPauseListing={unpauseMembership}
                      listingId={listingId}
                    />
                    <ProfileInfo
                      openModal={openModal}
                      title={productName}
                      status={status}
                      isPremium={isPremium}
                      changeStatus={changeStatus}
                      publishStatus={publishStatus}
                      isDraft={isDraft}
                      canceledDate={canceledDate}
                      updated={updated}
                      isPendingPayment={statuses.pendingPayment === status}
                    >
                      <ProfileActions
                        setShowRequiredText={setShowRequiredText}
                        showRequiredText={showRequiredText}
                        publishListing={publishListing}
                        isNotFilledAllFields={isNotFilledAllFields}
                        listingId={listingId}
                        isDraft={isDraft}
                        parentId={parentId}
                        resetDraft={resetDraft}
                        validationErrors={validationErrors}
                        isProfilePage={isProfiles}
                      />
                    </ProfileInfo>
                  </>
                ) : (
                  <>
                    <ProductActions
                      setShowRequiredText={setShowRequiredText}
                      showRequiredText={showRequiredText}
                      publishListing={publishListing}
                      isNotFilledAllFields={isNotFilledAllFields}
                      listingId={listingId}
                      isDraft={isDraft}
                      parentId={parentId}
                      resetDraft={resetDraft}
                      isProfiles={isProfiles}
                    />
                    <ProductDetails
                      openModal={openModal}
                      title={productName}
                      url={listingUrl}
                      status={status}
                      paused={paused}
                      isPremium={isPremium}
                      changeStatus={changeStatus}
                      publishStatus={publishStatus}
                      isDraft={isDraft}
                    >
                      <ProductControls
                        listingId={listingId}
                        openModal={openModal}
                        isPendingPayment={statuses.pendingPayment === status}
                        isExpired={status === statuses.expired}
                        publishStatus={publishStatus}
                        isPremium={isPremium}
                        canceledDate={canceledDate}
                        paused={paused}
                        isProfilePage={isProfiles}
                      />
                    </ProductDetails>
                  </>
                )}

                {isShowCoverImage ? (
                  <ListingInfoCover
                    defaultImage={defaultImage}
                    showRequiredText={showRequiredText}
                    validationName={getValidationData(validations, validationNames.name)}
                    validationBusiness={getValidationData(validations, validationNames.business)}
                    validationWebsite={getValidationData(validations, validationNames.website)}
                    validationPhoto={getValidationData(validations, validationNames.pictures)}
                    attorney={attorney}
                    title={title}
                    website={website}
                    submitChanges={handleAutoSaveListing}
                    handleSubmitImage={handleSubmitImage}
                    isActiveModalPhoto={modals.socialPhoto}
                    socialPhoto={socialPhoto}
                    coverImageId={coverImageId}
                    validationCoverImage={getValidationData(validations, validationNames.coverImage)}
                    validationAdditionalImages={getValidationData(validations, validationNames.additionalImages)}
                    id={listingId}
                    socialImagePreloaders={preloaders.socialImage}
                    images={images}
                    preloaders={preloaders}
                    deleteImage={handleDeleteCoverImage}
                    deleteAdditionalImages={deleteAdditionalImages}
                    openModal={openModal}
                    deleteCoverImage={deletePrimaryImage}
                    setShowUploadImageNotificationError={uploadImagesError}
                  />
                ) : (
                  <ListingInfo
                    openModal={openModal}
                    defaultImage={defaultImage}
                    coverImageId={coverImageId}
                    showRequiredText={showRequiredText}
                    validationName={getValidationData(validations, validationNames.name)}
                    validationBusiness={getValidationData(validations, validationNames.business)}
                    validationWebsite={getValidationData(validations, validationNames.website)}
                    validationPhoto={getValidationData(validations, validationNames.pictures)}
                    attorney={attorney}
                    title={title}
                    website={website}
                    submitChanges={handleAutoSaveListing}
                    handleSubmitImage={handleSubmitImage}
                    isActiveModalPhoto={modals.photo}
                    isJobListing={isJobListing}
                    deleteImage={handleDeleteCoverImage}
                    id={listingId}
                    preloaders={preloaders}
                    setShowUploadImageNotificationError={uploadImagesError}
                  />
                )}
                <BioNew
                  description={description}
                  validation={getValidationData(validations, validationNames.bio)}
                  showRequiredText={showRequiredText}
                  submitChanges={handleAutoSaveListing}
                  isEvents={isEvents}
                  isJobListing={isJobListing}
                />
                <JobInformation
                  submitChanges={handleAutoSaveListing}
                  showRequiredText={showRequiredText}
                  states={utilityStates}
                  countries={utilityCountries}
                  jobs={jobs && jobs.length ? jobs[0] : {}}
                  validation={getValidationData(validations, validationNames.job)}
                  jobsLists={jobs}
                />
                <PracticesNew
                  submitChanges={handleAutoSaveListing}
                  validation={getValidationData(validations, validationNames.specialties)}
                  selectedPractices={practices}
                  directoryPractices={directoryPractices}
                  showRequiredText={showRequiredText}
                  professions={practicesList.find(item => item.title.includes(listingType)) || {}}
                />
                <EventsSchedules
                  submitChanges={handleAutoSaveListing}
                  handleRemove={handleRemoveListingItem}
                  validation={getValidationData(validations, validationNames.events)}
                  selectedEvents={eventSchedules || []}
                  showRequiredText={showRequiredText}
                  isHideRepeat={isEvents}
                />
                <EventsTickets
                  submitChanges={handleAutoSaveListing}
                  selectedEvents={eventTickets || []}
                  validation={getValidationData(validations, validationNames.events)}
                  showRequiredText={showRequiredText}
                />
                <LanguagesNew
                  submitChanges={handleAutoSaveListing}
                  selectedLanguages={languages}
                  languagesList={languagesList}
                  validation={getValidationData(validations, validationNames.language)}
                  showRequiredText={showRequiredText}
                  isEvents={isEvents}
                />
                <Tags
                  submitChanges={handleAutoSaveListing}
                  selectedTags={tags}
                  validation={getValidationData(validations, validationNames.tags)}
                  showRequiredText={showRequiredText}
                />
                <LicensesNew
                  submitChanges={handleAutoSaveListing}
                  selectedLicenses={memberData.licenses}
                  states={utilityStates}
                  countries={utilityCountries}
                  validation={getValidationData(validations, validationNames.licenses)}
                  showRequiredText={showRequiredText}
                />
                <InsuranceNew
                  submitChanges={handleAutoSaveListing}
                  insurances={normalizeInsurances}
                  insuranceGroups={insuranceGroups}
                  selectedInsurances={memberData.insurances}
                  validation={getValidationData(validations, validationNames.insurance)}
                  showRequiredText={showRequiredText}
                />
                <EducationsNew
                  submitChanges={handleAutoSaveListing}
                  selectedEducations={education}
                  validation={getValidationData(validations, validationNames.educations)}
                  showRequiredText={showRequiredText}
                />
                <AssociationsNew
                  submitChanges={handleAutoSaveListing}
                  selectedAssociations={memberData.associations}
                  validation={getValidationData(validations, validationNames.associations)}
                  showRequiredText={showRequiredText}
                />
                <EventsAudiences
                  submitChanges={handleAutoSaveListing}
                  selectedEvents={memberData.communities || []}
                  validation={getValidationData(validations, validationNames.communities)}
                  showRequiredText={showRequiredText}
                  directoriesProfessions={directoriesProfessions}
                />
                <LocationsNew
                  submitChanges={handleAutoSaveListing}
                  selectedLocations={lawfirms}
                  states={utilityStates}
                  countries={utilityCountries}
                  validation={getValidationData(validations, validationNames.locations)}
                  validationPhone={getValidationData(validations, validationNames.phone)}
                  showRequiredText={showRequiredText}
                  isEvents={isEvents}
                />
                <CalendlyNew
                  selectedCalendly={bookmeetings}
                  submitChanges={handleAutoSaveListing}
                  validation={getValidationData(validations, validationNames.calendar)}
                  showRequiredText={showRequiredText}
                />
                <Consultation
                  consultation={consultation}
                  submitChanges={handleAutoSaveListing}
                  validation={getValidationData(validations, validationNames.freeConsultation)}
                  showRequiredText={showRequiredText}
                />
                <Video
                  introVideo={introVideo}
                  submitChanges={handleAutoSaveListing}
                  validation={getValidationData(validations, validationNames.video)}
                  showRequiredText={showRequiredText}
                />
                <ParentOrganization
                  submitChanges={handleAutoSaveListing}
                  states={utilityStates}
                  countries={utilityCountries}
                  showRequiredText={showRequiredText}
                  parentCompany={parentCompany && parentCompany.length ? parentCompany[0] : {}}
                  validation={getValidationData(validations, validationNames.parentOrganization)}
                />
                <SocialsNew
                  submitChanges={handleAutoSaveListing}
                  handleRemove={handleRemoveListingItem}
                  selectedSocials={socials}
                  validation={getValidationData(validations, validationNames.socialMedia)}
                  validationYelp={getValidationData(validations, validationNames.yelp)}
                  validationLinkedinCompany={getValidationData(validations, validationNames.linkedInCompany)}
                  validationLinkedInProfile={getValidationData(validations, validationNames.linkedInProfile)}
                  showRequiredText={showRequiredText}
                />
                <ListingNotification
                  memberData={memberData}
                  openModal={openAddNotificationsModal}
                  handleActiveNotificationModal={handleActiveNotificationModal}
                />
                {isProfiles ? (
                  <ProfileActions
                    setShowRequiredText={setShowRequiredText}
                    showRequiredText={showRequiredText}
                    publishListing={publishListing}
                    isNotFilledAllFields={isNotFilledAllFields}
                    listingId={listingId}
                    isDraft={isDraft}
                    parentId={parentId}
                    resetDraft={resetDraft}
                    isBottomPosition
                    validationErrors={validationErrors}
                  />
                ) : (
                  <ProductActions
                    isBottomPosition
                    setShowRequiredText={setShowRequiredText}
                    publishListing={publishListing}
                    isNotFilledAllFields={isNotFilledAllFields}
                    listingId={listingId}
                    isDraft={isDraft}
                    parentId={parentId}
                    resetDraft={resetDraft}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.iconContainer}>
          <EmptyIcon className={styles.icon} />
        </div>
      )}

      <>
        {/* <Elements stripe={stripePromise}>
          <>
            {modals.addCard && (
              <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.addCard)} clearAllModals={clearAllModals}>
                <AddCard
                  errorAddCard={errorAddCard}
                  addCard={addCard}
                  clearTokenError={clearTokenError}
                  countries={utilityCountries}
                  closeModal={() => closeModal(modalsConstant.addCard)}
                  errorToken={errorToken}
                  isFirst={!paymentMethods?.length}
                  setErrorToken={setErrorToken}
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                />
              </Modal>
            )}

            {selectedCard && modals.editCard && (
              <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.editCard)} clearAllModals={clearAllModals}>
                <EditCard
                  closeModal={() => closeModal(modalsConstant.editCard)}
                  countries={utilityCountries}
                  currentCardDetails={selectedCard}
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                  updateCard={updateCard}
                />
              </Modal>
            )}
          </>
        </Elements>
*/}
      </>
      <Modals
        handleSubmitImage={handleSubmitImage}
        closeModal={closeModal}
        uploadImagesError={uploadImagesError}
        modals={modals}
        memberData={memberData}
        getUpgradeOptions={getOptions()}
        clearAllModals={clearAllModals}
        openModal={openModal}
        addCard={addCard}
        countries={utilityCountries}
        updateCard={updateCard}
        isPendingPayment={statuses.pendingPayment === status}
        isExpired={statuses.expired === status}
        setMembershipClear={setMembershipClear}
        images={images}
        socialPhoto={socialPhoto}
        handleSubmitListing={handleSubmitListing}
        handleBlur={handleBlur}
        memberInputData={memberInputData}
        submitChanges={submitChanges}
        unpauseMembership={unpauseMembership}
        pauseMembership={pauseMembership}
      />
    </>
  );
};

export default Membership;
