import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "../feed/components/tabs";
import Filter from "./filter";
import Profiles from "./profiles";
import Organizations from "./organizations";
import Events from "./events";
import Jobs from "./jobs";
import PageNames from "../pageNames";
import { actions as homeActions } from "../../actions/home";
import { ReactComponent as EmptyIcon } from "../../media/icons/new-empty-listing.svg";
import { OverlayPreloader } from "../common/preloader";
import styles from "./saved.module.css";

const Saved = () => {
  const [filter, setFilter] = useState({ value: "", label: "" });
  const wrapperRef = useRef(null);
  const { professionTitle } = useSelector(state => state.search);
  const activeOptions = professionTitle.map(item => ({
    value: item,
    label: item.toLowerCase() === "people" ? "profiles" : item.toLowerCase()
  }));
  const all = { value: "All", label: "latest" };

  const profiles = activeOptions.find(item => item.label.includes("profile"));
  const organizations = activeOptions.find(item => item.label.includes("organization"));
  const jobs = activeOptions.find(item => item.label.includes("job"));
  const events = activeOptions.find(item => item.label.includes("event"));

  const dispatch = useDispatch();
  const { savedData, preloaders } = useSelector(state => state.home);
  const getSaved = useCallback(command => dispatch(homeActions.getSaved(command)), [dispatch]);
  const addToFavorite = useCallback(listingId => dispatch(homeActions.addToFavorite(listingId)), [dispatch]);
  const removeFavorite = useCallback(listingId => dispatch(homeActions.removeFavorite(listingId)), [dispatch]);
  const filterOptions = [all, profiles, organizations, jobs, events];

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }
    getSaved();
  }, []);

  const handleChangeTab = (value, isScroll) => {
    if (value.label !== filter.label) {
      setFilter(value);

      getSaved(value.label);
    }

    if (isScroll && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const isShowContent = useMemo(() => {
    return (
      filter.value !== all.value ||
      savedData?.list?.profiles?.length ||
      savedData?.list?.organizations?.length ||
      savedData?.list?.events?.length ||
      savedData?.list?.jobs?.length
    );
  }, [
    savedData?.list?.profiles?.length,
    savedData?.list?.organizations?.length,
    savedData?.list?.events?.length,
    savedData?.list?.jobs?.length,
    filter.value
  ]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {preloaders.saved && <OverlayPreloader />}
      <PageNames />
      <Tabs />

      {isShowContent ? <Filter filter={filter} setFilter={handleChangeTab} filterOptions={filterOptions.filter(item => item)} /> : null}

      {isShowContent ? (
        <>
          {(filter.value === profiles?.value || filter.value === all.value) && (
            <>
              <Profiles
                onClick={() => handleChangeTab(profiles)}
                count={savedData?.count?.profiles}
                isActive={filter.value === profiles?.value}
                list={savedData?.list?.profiles}
                removeFavorite={removeFavorite}
                addToFavorite={addToFavorite}
              />

              {filter.value === profiles?.value && !savedData?.list?.profiles?.length ? (
                <div className={styles.emptyContent}>
                  <EmptyIcon className={styles.icon} />
                </div>
              ) : null}
            </>
          )}

          {(filter.value === organizations?.value || filter.value === all.value) && (
            <>
              <Organizations
                onClick={() => handleChangeTab(organizations)}
                isActive={filter.value === organizations?.value}
                count={savedData?.count?.organizations}
                list={savedData?.list?.organizations}
                removeFavorite={removeFavorite}
                addToFavorite={addToFavorite}
                isOrganizations
              />

              {filter.value === organizations?.value && !savedData?.list?.organizations?.length ? (
                <div className={styles.emptyContent}>
                  <EmptyIcon className={styles.icon} />
                </div>
              ) : null}
            </>
          )}

          {(filter.value === jobs?.value || filter.value === all.value) && (
            <>
              <Jobs
                onClick={() => handleChangeTab(jobs, true)}
                isActive={filter.value === jobs?.value}
                count={savedData?.count?.jobs}
                list={savedData?.list?.jobs}
                removeFavorite={removeFavorite}
                addToFavorite={addToFavorite}
              />

              {filter.value === jobs?.value && !savedData?.list?.jobs?.length ? (
                <div className={styles.emptyContent}>
                  <EmptyIcon className={styles.icon} />
                </div>
              ) : null}
            </>
          )}

          {(filter.value === events?.value || filter.value === all.value) && (
            <>
              <Events
                onClick={() => handleChangeTab(events, true)}
                isActive={filter.value === events?.value}
                list={savedData?.list?.events}
                count={savedData?.count?.events}
                removeFavorite={removeFavorite}
                addToFavorite={addToFavorite}
              />

              {filter.value === events?.value && !savedData?.list?.events?.length ? (
                <div className={styles.emptyContent}>
                  <EmptyIcon className={styles.icon} />
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        <div className={styles.emptyContent}>
          <EmptyIcon className={styles.icon} />
        </div>
      )}
    </div>
  );
};

export default Saved;
