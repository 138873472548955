import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import SavedContainer from "../saved";
import FeedContainer from "../feed";
import CallHistoryContainer from "../callHistory";
import NotificationsContainer from "../notifications";
import Payments from "../payments";
import ManageNotification from "../manageNotification";
import TeamsContainer from "../teams";
import AccessContainer from "../access";
import PayPerLeadContainer from "../payPerLead";
import ReferralsContainer from "../referrals";
import { PaymentError } from "../../components/common/paymentError";
import PayPerLeadDetailContainer from "../payPerLeadDetail";
import PerformanceContainer from "../performance";
import Account from "../account";
import ListingsContainer from "../listings";
import ListingDetailContainer from "../listingDetail";
import NotFoundContainer from "../404";
import QuestionsAnswersContainer from "../questionsAnswers";
import QuestionContainer from "../question";
import ReferralRequestDetailContainer from "../referralRequestDetail";
import Messages from "../messages";
import Layout from "../../components/layout";

/* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-2086
import CannyContainer from "../canny";
*/

import { ImportBanner } from "../../components/importBanner";
import { Importing } from "../../components/importing";
import { ImportingSuccess } from "../../components/importingSuccess";
import VerificationEmailFlow from "../../components/verificationEmailFlow";
import { AddNewListing } from "../../components/listings/components/modals/addFreeListing";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { actions as homeActions } from "../../actions/home";
import { getQueryParams } from "../../helpers";
import { actions as authActions } from "../../actions/auth";
import { actions as teamActions } from "../../actions/teams";
import { actions as profileActions } from "../../actions/account/profile";
import { actions as mainActions } from "../../actions/main";
import { actions } from "../../actions/search";

const PrivateRoutes = ({ keycloak }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { client, countries, states, isActiveNewListing, listingModalListingType, footerInfo, socialLinks } = useSelector(
    state => state.main
  );
  const {
    listings_count: listingsCount,
    referral_requests_pending_import: referralRequestsPendingImport,
    referral_requests_count: referralRequestsCount,
    new_messages: newMessages,
    unread_messages: unreadMessages,
    email
  } = useSelector(state => state.account.profile);

  const { importReady, modals: modalsReferralRequest } = useSelector(state => state.referralRequests);
  const { currentTeam } = useSelector(state => state.teams);

  const queryParams = useMemo(() => getQueryParams(window.location.search), [window.location.search]);
  const setLoggedIn = useCallback(isActive => dispatch(authActions.setLoggedIn(isActive)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const getProfileImage = useCallback(() => dispatch(mainActions.getProfileImage()), [dispatch]);
  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);
  const getCountriesAndStates = useCallback(() => dispatch(mainActions.getCountriesAndStates()), [dispatch]);
  const getFooterInfo = useCallback(() => dispatch(mainActions.getFooterInfo()), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamActions.setCurrentTeam(team)), [dispatch]);
  const getImportReady = useCallback(() => dispatch(referralRequestsActions.getImportReady()), [dispatch]);
  const getAddNewListingData = useCallback(isModal => dispatch(homeActions.getAddNewListingData(isModal)), [dispatch]);
  const getProfessionsTree = useCallback(() => dispatch(actions.getProfessionsTree()), [dispatch]);

  const isActivePath = useMemo(() => {
    const { pathname } = location;
    return {
      isMessages: pathname === "/inbox",
      isHome: pathname === "/",
      isQA: pathname.includes("question-answer"),
      isReferralRequests: pathname.includes("referral-requests"),
      isPaidLeads: pathname.includes("paid-leads")
    };
  }, [location.pathname]);

  const { isMessages, isHome, isQA, isReferralRequests, isPaidLeads } = isActivePath;

  const checkEmptyCounts = counts => Object.values(counts).every(value => String(value) === "0");

  const isShowOnlyListingBanner = useMemo(
    () =>
      checkEmptyCounts({
        unreadMessages,
        listingsCount,
        referralRequestsPendingImport,
        referralRequestsCount,
        newMessages
      }),
    [unreadMessages, listingsCount, referralRequestsPendingImport, referralRequestsCount, newMessages]
  );

  const isShowFooter = useMemo(() => {
    return (isHome && isShowOnlyListingBanner) || isMessages;
  }, [isHome, isShowOnlyListingBanner, isMessages]);

  const isShowBannerReferralRequests = useMemo(() => {
    return isMessages || isQA || isReferralRequests || isPaidLeads;
  }, [isMessages, isQA, isReferralRequests, isPaidLeads]);

  const closeNewListing = () => {
    toggleNewListing(false);
  };

  useEffect(() => {
    const responseInterceptors = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401 && !error.response.config.url.includes("/login")) {
          const currentUrl = window.location.href;
          const urlParams = new URLSearchParams(window.location.search);
          if (!urlParams.get("isAuthenticationError")) {
            urlParams.set("isAuthenticationError", "true");
          }
          const newUrl = `${currentUrl.split("?")[0]}?${urlParams.toString()}`;
          window.history.replaceState({ path: newUrl }, "", newUrl);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptors);
    };
  }, []);

  useEffect(() => {
    getFooterInfo();
    getImportReady();
    getAddNewListingData(!isHome);
    getProfileImage();
    getProfessionsTree();
  }, []);

  useEffect(() => {
    if (!countries?.length && !email) {
      getProfile();
    }

    if (!countries?.length) {
      getCountriesAndStates();
    }
  }, [getProfile, countries?.length]);

  useEffect(() => {
    if (queryParams?.isAuthenticationError) {
      setLoggedIn(false);
      keycloak.logout({ redirectUri: `${window.location.origin}/login?isAuthenticationError=true` }).then(() => {
        history.push("/login");
      });
    }
  }, [queryParams.isAuthenticationError, setLoggedIn, history, keycloak]);

  useEffect(() => {
    if (queryParams.user_id && !currentTeam.value) {
      setCurrentTeam({ value: queryParams.user_id });
    }
  }, [setCurrentTeam, queryParams.user_id, currentTeam.value]);

  return (
    <Layout keycloak={keycloak} isShowFooter={isShowFooter} footerInfo={footerInfo} socialLinks={socialLinks}>
      {isShowBannerReferralRequests ? <ImportBanner importReady={importReady} /> : null}

      <VerificationEmailFlow />

      <PaymentError />
      {modalsReferralRequest.importing ? <Importing /> : null}
      {modalsReferralRequest.importingSuccess ? <ImportingSuccess /> : null}
      <Switch>
        <Route exact path="/" render={() => <FeedContainer />} />
        <Route exact path="/saved" render={() => <SavedContainer />} />
        <Route exact path="/inbox" render={() => <Messages />} />
        <Route
          exact
          path={["/listings", "/my-events", "/my-jobs", "/my-organizations"]}
          render={() => <ListingsContainer countries={countries} />}
        />
        <Route exact path="/call-history" render={() => <CallHistoryContainer />} />
        <Route exact path="/paid-leads" render={() => <PayPerLeadContainer />} />
        <Route exact path="/referrals" render={() => <ReferralsContainer />} />
        <Route exact path="/paid-leads/:id" render={props => <PayPerLeadDetailContainer match={props.match} />} />
        <Route exact path="/notifications" render={() => <NotificationsContainer />} />
        <Route exact path="/manage-notification" render={() => <ManageNotification />} />
        <Route exact path="/account" render={() => <Account keycloak={keycloak} countries={countries} />} />
        <Route exact path="/payments" render={() => <Payments countries={countries} keycloak={keycloak} />} />
        <Route exact path="/teams" render={() => <TeamsContainer />} />
        <Route exact path="/access" render={() => <AccessContainer />} />
        <Route exact path="/question-answer" render={() => <QuestionsAnswersContainer />} />
        <Route exact path="/question-answer/:id" render={() => <QuestionContainer />} />
        <Route exact path="/analytics" render={props => <PerformanceContainer {...props} />} />
        <Route exact path="/referral-requests/:id" render={() => <ReferralRequestDetailContainer client={client} />} />
        <Route
          exact
          path={["/listings/:id", "/my-events/:id", "/my-jobs/:id", "/my-organizations/:id", "/profile"]}
          render={props => <ListingDetailContainer utilityCountries={countries} utilityStates={states} match={props.match} />}
        />
        {/* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-2086
              <Route exact path="/product-roadmap" render={() => <CannyContainer />} />
            */}
        <Route path="/404" component={NotFoundContainer} />
        <Route component={NotFoundContainer} />
      </Switch>
      {isActiveNewListing ? (
        <AddNewListing listingModalListingType={listingModalListingType} closeModal={closeNewListing} clearAllModals={closeNewListing} />
      ) : null}
    </Layout>
  );
};

export default PrivateRoutes;
