import React from "react";

import ListingContent from "../listingContent";
import EventsListing from "../eventsListing";

const Organizations = ({ isExpand, onClick, isActive, list, addToFavorite, removeFavorite, isOrganizations, count }) => {
  return (
    <>
      {list?.length ? (
        <>
          {isExpand ? (
            <>
              {list?.length ? (
                <EventsListing
                  isOrganizations={isOrganizations}
                  addToFavorite={addToFavorite}
                  removeFavorite={removeFavorite}
                  isExpand
                  list={list}
                />
              ) : null}
            </>
          ) : (
            <ListingContent title="Organizations" count={count || ""} onClick={onClick} isActive={isActive}>
              {list?.length ? (
                <EventsListing
                  isOrganizations={isOrganizations}
                  removeFavorite={removeFavorite}
                  addToFavorite={addToFavorite}
                  list={list}
                />
              ) : null}
            </ListingContent>
          )}
        </>
      ) : null}
    </>
  );
};

export default Organizations;
