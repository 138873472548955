import axios from "axios";
import qs from "qs";

import { getResetPasswordError } from "../helpers";
import { auth } from "../constants/actionTypes";
import { actions as mainActions } from "./main";

export const actions = {
  login(credentials) {
    return dispatch => {
      dispatch(this.setLoading("login", true));
      return axios({
        method: "post",
        url: "/members/json-api/login",
        data: qs.stringify(credentials),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          if (res.data.ok === true) {
            dispatch({ type: auth.LOGIN_SUCCESS, payload: res.data });
            dispatch(mainActions.getLoginStatus());
          } else if (res.data.error) {
            dispatch({ type: auth.LOGIN_FAIL, payload: res.data.error });
          }
        })
        .catch(err => {
          dispatch({ type: auth.LOGIN_FAIL, payload: err.response ? err.response.data.error : ["Server error"] });
        });
    };
  },

  resetPassword(email, history) {
    return async dispatch => {
      dispatch(this.setLoading("resetPassword", true));
      const formData = new FormData();
      formData.append("login", email);
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/login/sendpass",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: auth.RESET_PASSWORD
          });
          history.push("/login/verification-code");
        })
        .catch(err => {
          const isNotExist = err?.response?.data?.error.includes("does not exist in database");

          if (isNotExist) {
            dispatch({
              type: auth.RESET_PASSWORD_FAIL_NOT_EXIST,
              payload: err?.response?.data?.error || err.message
            });
            history.push("/login/verification-code");
          } else {
            dispatch({
              type: auth.RESET_PASSWORD_FAIL,
              payload: err?.response?.data?.error || err.message
            });
          }
        });
    };
  },

  verificationCode(code, history) {
    return dispatch => {
      dispatch(this.setLoading("verificationCode", true));
      axios({
        method: "get",
        url: `/members/json-api/login/check-code?code=${code}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: auth.VERIFICATION_CODE,
            payload: {
              code
            }
          });
          history.push("/login/set-new-password");
        })
        .catch(err => {
          dispatch({
            type: auth.VERIFICATION_CODE_FAIL,
            payload: err?.response?.data?.error || err.message
          });
        });
    };
  },

  setNewPassword(history, passwordData) {
    return async dispatch => {
      dispatch(this.setLoading("setNewPassword", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/login/reset-password",
        data: passwordData
      })
        .then(() => {
          dispatch({
            type: auth.SET_NEW_PASSWORD
          });
          history.push("/login/reset-password-success");
        })
        .catch(err => {
          dispatch({
            type: auth.SET_NEW_PASSWORD_FAIL,
            payload: {
              error: err.response,
              errorResetPassword: getResetPasswordError(err?.response?.status)
            }
          });
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: auth.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  setSuccess(action, isActionSuccessful) {
    return {
      type: auth.SET_SUCCESS,
      payload: { action, isActionSuccessful }
    };
  },

  setError(action, error) {
    return {
      type: auth.SET_ERRORS,
      payload: { action, error }
    };
  },

  setLoggedIn(isLoggedIn) {
    return {
      type: auth.SET_LOGGED_IN,
      payload: isLoggedIn
    };
  }
};
