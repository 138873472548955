import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";
// eslint-disable-next-line
import { useDropzone } from "react-dropzone";

import { enableScroll } from "../../../helpers";
import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
import PhotoModal from "../cropCoverImage";
import styles from "./coverImage.module.css";

const CoverImage = ({ id, onUpload, image, deleteImage, preloaders, coverImageId, setShowUploadImageNotificationError }) => {
  const input = useRef();
  const [showError, setShowError] = useState(false);
  const [file, setFile] = useState(null);
  const [modalActive, setModalActive] = useState(false);

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    if (files[0].size >= 5000000) {
      setShowError(true);
    } else {
      handleUploadImage(files[0]);

      if (showError) {
        setShowError(false);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();

      formData.append("image_body", files);
      formData.append("listing_id", id);
      formData.append("is_default", "1");
      formData.append("command", "primary_image");

      return onUpload(formData, "photo");
    }
  };

  const handleOpenFile = () => {
    setModalActive(false);

    setTimeout(() => {
      setModalActive(true);
    }, 200);
  };

  const onDrop = useCallback((acceptedFiles, errors) => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          if (img.width < 1800) {
            setShowUploadImageNotificationError(true, false, true);
          } else {
            setFile(reader.result);

            handleOpenFile();
          }
        };
      };
    } else {
      const error = errors ? errors[0] : "";
      const isErrorMessage = error ? error.errors.find(item => item.code === "file-too-large") : "";
      setShowUploadImageNotificationError(true, Boolean(isErrorMessage));
      enableScroll();
      console.error("Invalid file object Main Image ");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/webp": [],
      "image/gif": [],
      "image/png": []
    },
    maxSize: 5000000
  });

  return (
    <>
      <h5 className={styles.mainTitle}>Main Image</h5>

      {modalActive && (
        <PhotoModal
          handleSubmitImage={onUpload}
          closeModal={() => setModalActive(false)}
          clearAllModals={() => setModalActive(false)}
          listingId={id}
          loading={preloaders.photo}
          isOpen={!file}
          preloadFile={file || image}
          handleRemovePreloaderFile={() => setFile(null)}
          defaultImage={image}
          deleteImage={deleteImage}
          coverImageId={coverImageId}
          setShowUploadImageNotificationError={setShowUploadImageNotificationError}
        />
      )}

      <div tabIndex={-1} role="button" className={styles.container} {...getRootProps()}>
        {image ? <span className={styles.coverImage} style={{ backgroundImage: `url(${image})` }} /> : null}

        {!image ? (
          <div tabIndex={-1} role="button" className={styles.content} onClick={handleOpenFile}>
            <button type="button" className={styles.photoCamera}>
              <CameraIcon className={styles.cameraIcon} />

              <span className={cn(styles.edit, styles.editCover)}>
                <PencilIcon />
              </span>
            </button>

            <h6 className={styles.title}>Click or Drop Image</h6>
            <p className={styles.description}>Create image at 1800 x 300 px. Keep images under 3MB and JPEG if possible.</p>
          </div>
        ) : null}
      </div>

      {image && (
        <div className={cn(styles.content, styles.contentActive)}>
          <button type="button" className={styles.photoCamera} onClick={handleOpenFile}>
            <PencilIcon className={styles.cameraIcon} />
          </button>

          <h6 className={styles.title}>Edit main image</h6>
        </div>
      )}

      {showError && <span className={styles.error}>Maximum limit 5 MB.</span>}

      <input
        {...getInputProps()}
        ref={input}
        accept=".png, .jpg, .jpeg, .webp, .gif"
        onChange={e => handleFileInput(e)}
        id="cover_image"
        name="upload"
        className={styles.fileInput}
        type="file"
      />
    </>
  );
};

export default CoverImage;
