import React, { useEffect, useState } from "react";
import cn from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import styles from "./tabs.module.css";

export const TabNav = ({ content, nav, navClass, page, isHideNavigation, isHideContent, isLeadPage, isShowReferralsTab }) => {
  const history = useHistory();
  const { location } = history;
  const [activeTab, setActiveTab] = useState(0);
  const [subCategory, setSubCategory] = useState(false);
  const queryParamsObj = queryString.parse(location.search);

  const historyPush = tab => {
    history.push(`/${page}?${queryString.stringify({ ...queryParamsObj, page: 0, tab })}`);
  };

  const setQueryParamsOnActive = tabName => {
    const activeTabName = tabName.toLowerCase();

    return nav.findIndex(({ param }, index) => {
      if (param === activeTabName) {
        setActiveTab(index);
        historyPush(param);
      }
      return false;
    });
  };

  useEffect(() => {
    const initActiveTab = () => {
      const { tab } = queryParamsObj;
      let activeTabIndex = 0;

      if (isLeadPage && nav.findIndex(item => item.param === tab) === -1 && isShowReferralsTab === false) {
        setSubCategory(true);
        historyPush(nav[1].param);
        activeTabIndex = 1;
        return activeTabIndex;
      }

      return nav.findIndex(({ param, isCategory, isShow }, index) => {
        if (param === tab) {
          activeTabIndex = index;

          if (!subCategory && (isCategory || !isShow)) {
            setSubCategory(true);
          }
        }

        return activeTabIndex;
      });
    };

    const initActiveIndex = initActiveTab() !== -1 ? initActiveTab() : 0;
    setActiveTab(initActiveIndex);
  }, [location, isShowReferralsTab]); // eslint-disable-line

  const renderNavigations = () => {
    return nav.map((item, index) => {
      return (
        <button
          className={cn(styles.button, index === activeTab ? styles.buttonActive : "")}
          onClick={e => {
            e.preventDefault();
            setQueryParamsOnActive(item.param);
          }}
          type="button"
          // eslint-disable-next-line react/no-array-index-key
          key={index.toString()}
        >
          {item.text}
        </button>
      );
    });
  };

  return (
    <div className={styles.tab}>
      {!isHideNavigation && <nav className={cn(navClass || styles.tabsNav)}>{renderNavigations()}</nav>}
      {!isHideContent && <>{content[activeTab]}</>}
    </div>
  );
};
