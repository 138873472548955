import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import styles from "./jobsListing.module.css";

const JobsListing = ({ list, isExpand, addToFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return (
          <Job
            key={`${item.title}-${item.added}`}
            item={item}
            isExpand={isExpand}
            addToFavorite={addToFavorite}
            removeFavorite={removeFavorite}
          />
        );
      })}
    </div>
  );
};

const Job = ({ item, isExpand, removeFavorite, addToFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = listingId => {
    if (isFavorite) {
      removeFavorite(listingId).then(res => {
        if (res) {
          setIsFavorite(false);
        }
      });
    } else {
      addToFavorite(listingId).then(res => {
        if (res) {
          setIsFavorite(true);
        }
      });
    }
  };

  useEffect(() => {
    if (item.bookmarked === "true") {
      setIsFavorite(true);
    }
  }, []);

  const addedDate = useMemo(() => {
    if (item.added) {
      const date1 = new Date(item.added);
      const date2 = new Date();
      const diffYears = date2.getFullYear() - date1.getFullYear();
      const diffMonths = diffYears * 12 + date2.getMonth() - date1.getMonth();

      const output =
        diffYears > 1
          ? `${diffYears} years ago`
          : diffYears === 1
          ? "1 year ago"
          : diffMonths > 1
          ? `${diffMonths} months ago`
          : "1 month ago";

      return output;
    }

    return "";
  }, [item.added]);

  return (
    <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
      <div className={styles.favorites}>
        <Button
          className={cn(styles.favoritesButton, isFavorite ? styles.favoritesButtonActive : "")}
          onClick={() => toggleFavorite(item.listing_id)}
        >
          <SaveIcon />
        </Button>
      </div>

      <div className={cn(isExpand ? styles.expandContainer : "")}>
        <Link to={`/public-listing/${item.listing_id}`} className={styles.tileImage}>
          <img src={item.cover_image_url} alt="Job" />
        </Link>
        <div className={cn(isExpand ? styles.expandDetailContent : "")}>
          <div className={styles.titleFlex}>
            {item.parent_company?.length ? <span className={styles.titleDetail}>{item.parent_company[0]?.name}</span> : null}
            <span className={styles.titleDetail}>
              <PinIcon /> {addedDate}
            </span>
          </div>

          <Link to={`/public-listing/${item.listing_id}`} className={styles.tileTitle}>
            {item.attorney}
          </Link>
          <p className={styles.tileDescription}>{item.description}</p>
        </div>
      </div>
    </div>
  );
};

export default JobsListing;
