export const FILTERS = {
  PRACTICES: "practices",
  CATEGORIES: "categories",
  PROFESSIONS: "professions",
  CATEGORIES_MY_ANSWER: "categoriesMyAnswers"
};

export const QUESTION_TYPES = {
  ALL: "all",
  QUESTIONS: "questions",
  MY_ANSWER: "my-answers"
};

export const TABS = {
  MY_QUESTIONS: "my-questions",
  ALL: "all",
  MY_ANSWER: "my-answers"
};

export const ACTIVE_FILTERS_DEFAULT_VALUE = {
  practices: [],
  categories: [],
  categoriesMyAnswers: [],
  professions: [],
  isFirstRender: true
};
