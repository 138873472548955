import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { actions as payPerLeadDetailActions } from "../../../actions/payPerLeadDetail";
import { priceFormatter } from "../../../helpers";
import { Button } from "../../common/button";
import styles from "./purchase.module.css";
import { modals as modalsConstant } from "../../../constants/payPerLeadDetail";

export const Purchase = ({ loading, leadId, price, isShowDescription }) => {
  const dispatch = useDispatch();
  const hideLead = useCallback(leadIds => dispatch(payPerLeadDetailActions.hideLead(leadIds)), [dispatch]);
  const checkCouponClear = useCallback(() => dispatch(payPerLeadDetailActions.checkCouponClear()), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const { t } = useTranslation();

  const handleHide = () => {
    hideLead(leadId);
  };

  const openModal = () => {
    toggleModal(modalsConstant.payment, true);
    checkCouponClear();
  };

  return (
    <>
      <div className={styles.purchase}>
        <Button className={styles.button} onClick={openModal}>
          Purchase for {priceFormatter.format(price || 0)}
        </Button>
        <Button className={cn("button--clear", styles.buttonGrey)} disabled={loading} onClick={handleHide}>
          {t("dashboard_ppl_table_not_interested")}
        </Button>
      </div>
      {isShowDescription && (
        <p className={styles.description}>
          Once purchased you will have access to the lead’s contact information. Each lead is sold exclusively to one professional.
        </p>
      )}
    </>
  );
};
