import React from "react";
import cn from "classnames";

import { Button } from "../../common/button";
import { ReactComponent as IconCheck } from "../../../media/icons/sucess-check.svg";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import styles from "./headingProfile.module.css";

const HeadingProfile = ({
  coverImage,
  name,
  logo,
  consultation,
  phone,
  isShowFavorites,
  isFavorite,
  removeFavorite,
  addToFavorite,
  headline
}) => {
  return (
    <div className={styles.container}>
      {coverImage && (
        <div className={styles.banner}>
          <img src={coverImage} alt="cover" />
        </div>
      )}

      <div className={cn(styles.content, coverImage ? styles.contentWithoutBanner : "")}>
        <span className={styles.avatar}>
          <img src={logo} alt="avatar" />
        </span>
        <div className={styles.heading}>
          <h3 className={styles.name}>{name}</h3>
        </div>
        <h6 className={styles.headline}>{headline}</h6>

        <div className={styles.flex}>
          {consultation ? (
            <span className={styles.freeCancel}>
              <IconCheck />
              Free consultation
            </span>
          ) : null}
        </div>

        <div className={styles.actions}>
          <Button className={styles.message}>Message</Button>

          {phone && (
            <a href={`tel:${phone}`} className={styles.phone}>
              {phone}
            </a>
          )}
        </div>
      </div>
      {isShowFavorites && (
        <>
          {isFavorite ? (
            <Button className={styles.favoritesButton} onClick={removeFavorite}>
              <SaveIcon className={styles.iconActive} />
            </Button>
          ) : (
            <Button className={styles.favoritesButton} onClick={addToFavorite}>
              <SaveIcon />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default HeadingProfile;
