import React from "react";
import format from "date-fns/format";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactComponent as Details } from "../../../../media/icons/details.svg";
import { Preloader } from "../../../common/preloader";
import { ACTIVE_BLOCK_NAMES } from "../../../../constants/messages";
import MessageInput from "../messageInput";
import MessageThread from "../messageThread";
import Actions from "../actions";
import MessagePanel from "../messagePanel";
import styles from "./messagesContent.module.css";

const MessagesContent = ({
  messageDetail,
  messageDetailMessages,
  preloaders,
  sendMessage,
  isClient,
  setActiveBlocks,
  activityBlocks,
  handleUnread,
  handleArchive,
  isArchived,
  handleUnnArchive,
  isActiveFilterModal,
  messagesLength
}) => {
  const { t } = useTranslation();

  const { initials: userInitials } = useSelector(state => state.account.profile);

  const messagesContentActive = !activityBlocks.message ? styles.messagesContentActive : "";
  const messagesContentDisable = activityBlocks.detail ? styles.messagesContentDisable : "";

  const renderInitials = name => {
    return name
      .split(" ")
      .slice(0, 2)
      .map(n => n[0])
      .join("");
  };

  const handleSend = message => {
    sendMessage({
      listing_message_id: messageDetail.listing_message_id,
      message,
      subject: "subject",
      lawyer_image: messageDetailMessages[0].lawyer_image,
      sent: format(Date.now(), "yyyy-MM-dd h:mm:ss"),
      is_lawyer: isClient ? "0" : "1"
    });
  };

  const activeDetailButtonDesktop = !activityBlocks.detailDesktop ? styles.buttonSvg : "";
  const activeDetailButtonMobile = !activityBlocks.detail ? styles.buttonSvg : "";

  const activityArchive = () => {
    if (isArchived) {
      handleUnnArchive();
    } else {
      handleArchive();
    }
  };

  return (
    <div className={cn(styles.messagesContent, messagesContentActive, messagesContentDisable)}>
      {preloaders.messageDetail && !preloaders.messages && <Preloader className={styles.preloader} />}

      {messagesLength ? (
        <>
          {messageDetail.name_full && (
            <div className={styles.userContent}>
              {isClient ? (
                <>
                  {messageDetail.listings_image_name ? (
                    <img className={styles.image} src={messageDetail.listings_image_name} alt="Attorney name" />
                  ) : (
                    <div className={styles.initials}>{renderInitials(messageDetail.name_full)}</div>
                  )}
                  <div className={styles.name}>{messageDetail.name_full}</div>
                </>
              ) : (
                <>
                  <div className={styles.initials}>{renderInitials(messageDetail.name_full)}</div>
                  <div className={styles.name}>{messageDetail.name_full}</div>
                </>
              )}

              <button
                type="button"
                className={cn(styles.details, styles.detailsMobile)}
                onClick={() =>
                  setActiveBlocks({
                    [ACTIVE_BLOCK_NAMES.DETAIL]: !activityBlocks.detail
                  })
                }
              >
                <Details className={activeDetailButtonMobile} />
              </button>
              <button
                type="button"
                className={cn(styles.details, styles.detailsDesktop)}
                onClick={() =>
                  setActiveBlocks({
                    [ACTIVE_BLOCK_NAMES.DETAIL_DESKTOP]: !activityBlocks.detailDesktop,
                    [ACTIVE_BLOCK_NAMES.DETAIL]: !activityBlocks.detailDesktop
                  })
                }
              >
                <Details className={activeDetailButtonDesktop} />
              </button>
              <Actions activityArchive={activityArchive} handleUnread={handleUnread} isArchived={isArchived} />
            </div>
          )}

          {messageDetail.name_full && (
            <MessagePanel
              name={messageDetail.name_full}
              setActiveBlocks={setActiveBlocks}
              activityBlocks={activityBlocks}
              handleUnread={handleUnread}
              handleArchive={handleArchive}
              isArchived={isArchived}
              handleUnnArchive={handleUnnArchive}
            />
          )}
          <MessageThread messageDetail={messageDetail} messageDetailMessages={messageDetailMessages} userInitials={userInitials} />
          <MessageInput
            disabled={!messageDetail.email}
            preloaders={preloaders}
            handleSend={handleSend}
            isActiveFilterModal={isActiveFilterModal}
          />
        </>
      ) : (
        <>
          <div className={styles.emptyState}>
            <div className={styles.emptyIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                <path
                  d="M26.8032 27.2628C26.1955 25.5617 24.8563 24.0586 22.9934 22.9865C21.1306 21.9144 18.8481 21.3333 16.5 21.3333C14.1519 21.3333 11.8694 21.9144 10.0065 22.9865C8.14365 24.0586 6.8045 25.5617 6.19677 27.2628"
                  stroke="#ABAFB9"
                  strokeWidth="2.66667"
                  strokeLinecap="round"
                />
                <circle cx="16.5" cy="10.6667" r="5.33333" stroke="#ABAFB9" strokeWidth="2.66667" strokeLinecap="round" />
              </svg>
            </div>
            <h5 className={styles.emptyTitle}>{t("dashboard_inbox_nomessage_title")}</h5>
            <p className={styles.emptyText}>{t("dashboard_inbox_nomessage_desc")}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default MessagesContent;
