import React from "react";
import cn from "classnames";

import { ReactComponent as FlashIcon } from "../../../media/icons/flash.svg";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { Button } from "../../common/button";
import styles from "./heading.module.css";

const Heading = ({ isGalleryExist, name, website, logo, addToFavorite, isShowFavorites, isFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.content, isGalleryExist ? styles.contentGallery : "")}>
      <div className={styles.leftSide}>
        <span className={styles.logo}>
          <img src={logo} alt="" />
        </span>
        <div>
          <h6 className={styles.name}>{name}</h6>
          <p className={styles.time}>
            _Open now: <span>12AM - 22:00PM</span>
          </p>
        </div>
      </div>
      <div className={styles.rightSide}>
        <a className={styles.website} href={website} target="_blank" rel="noopener noreferrer">
          <FlashIcon />
          See website
        </a>
        <div className={styles.favorites}>
          {isShowFavorites && (
            <>
              {isFavorite ? (
                <Button className={styles.favoritesButton} onClick={removeFavorite}>
                  <span>Unsave</span>
                  <SaveIcon className={styles.iconActive} />
                </Button>
              ) : (
                <Button className={styles.favoritesButton} onClick={addToFavorite}>
                  <span>Save</span>
                  <SaveIcon />
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Heading;
