// hooks/usePayPerLeadState.js
import { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { actions as referralRequestDetailActions } from "../../actions/referralRequestDetail";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { actions as payPerLeadDetailActions } from "../../actions/payPerLeadDetail";

import { listingType } from "../../constants/listings";
import { actions as payPerLeadActions } from "../../actions/payPerLead";
import { actions as listingActions } from "../../actions/listing";
import { getQueryParams } from "../../helpers";

export const usePayPerLeadState = () => {
  const { t } = useTranslation();

  const [leadsList, setLeadsList] = useState([]);
  const [search, setSearch] = useState("");
  const [filtrationByDate, setFiltrationByDay] = useState("");
  const [perPageLead, setPerPageLead] = useState("");
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const [activeFilter, setActiveFilter] = useState(false);
  const [team, setTeam] = useState({ value: "", label: "Unselect " });

  const sortOptions = [
    { value: "desc", label: t("dashboard_ppl_sortdropdown_recent") },
    { value: "asc", label: t("dashboard_ppl_sortdropdown_oldest") }
  ];

  return {
    leadsList,
    setLeadsList,
    search,
    setSearch,
    filtrationByDate,
    setFiltrationByDay,
    perPageLead,
    setPerPageLead,
    notification,
    setNotification,
    error,
    setError,
    activeFilter,
    setActiveFilter,
    team,
    setTeam,
    sortOptions
  };
};

export const usePayPerLeadFilters = (practicesReferrals, practices, directoriesReferrals, directories) => {
  const [listActivePractice, setListActivePractice] = useState([]);
  const [listActiveNetwork, setListActiveNetwork] = useState([]);

  const currentPractices = useMemo(() => {
    return practices;
  }, [practices]);

  const currentDirectories = useMemo(() => {
    return directories;
  }, [directories]);

  return {
    listActivePractice,
    setListActivePractice,
    listActiveNetwork,
    setListActiveNetwork,
    currentPractices,
    currentDirectories
  };
};

export const usePayPerLeadActions = ({ dispatch, history, searchParams, setSearch, setFiltrationByDay }) => {
  const resetAllSuccess = useCallback(() => dispatch(payPerLeadDetailActions.resetAllSuccess()), [dispatch]);

  const handleRemoveReferrals = useCallback(
    leadId => dispatch(referralRequestDetailActions.handleRemove(leadId, history, true, searchParams)),
    [dispatch, history, searchParams]
  );

  const handleFiltrationSearchLeads = useCallback(
    searchValue => {
      setSearch(searchValue);
    },
    [setSearch]
  );

  const historyPush = useCallback(() => {
    history.push(`/paid-leads?${queryString.stringify({ ...searchParams, page: 0 })}`);
  }, [history, searchParams]);

  const onDateFiltrationLead = useCallback(
    item => {
      if (String(searchParams.page) !== "0") {
        historyPush();
      }
      setFiltrationByDay(item);
    },
    [historyPush, searchParams.page, setFiltrationByDay]
  );

  return {
    resetAllSuccess,
    handleRemoveReferrals,
    handleFiltrationSearchLeads,
    onDateFiltrationLead
  };
};

export const usePayPerLeadEffects = ({
  setListActiveNetwork,
  setListActivePractice,
  listActiveNetwork,
  setLeadsList,
  setNotification,
  setError,
  listActivePractice,
  filtrationByDate,
  searchValue,
  perPageLead,
  team,
  searchParams
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const getLeads = useCallback(
    ({ dir_id, page, practice_id, tab, sort, search, perPage, teamValue }) =>
      dispatch(payPerLeadActions.getLeads({ dir_id, page, practice_id, tab, sort, search, perPage, team: teamValue })),
    [dispatch]
  );

  const { isShowReferralsTab } = useSelector(state => state.referralRequests);

  const setLeadsClear = useCallback(() => dispatch(payPerLeadActions.setLeadsClear()), [dispatch]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);
  const getPPLListings = useCallback(() => dispatch(listingActions.getExistingMemberships(listingType.ppl)), [dispatch]);
  const { leads, pagination, loading } = useSelector(state => state.payPerLead);
  const { success: listingSuccess, error: listingError } = useSelector(state => state.listing);
  const setClearReferrals = useCallback(() => dispatch(referralRequestsActions.setClearReferrals()), [dispatch]);

  const queryParams = useMemo(() => getQueryParams(location.search), [location.search]);

  useEffect(() => {
    if (searchParams && !searchParams?.tab) {
      history.push(`/paid-leads?page=0${queryParams.user_id ? `&user_id=${queryParams.user_id}` : ""}&tab=notpurchased`);
    }
  }, [history, isShowReferralsTab, queryParams, searchParams, location]);

  useEffect(() => {
    if (pagination.currentPageNum !== null && !searchParams?.tab) {
      history.push(`/paid-leads?page=${pagination.currentPageNum}&tab=notpurchased`);
    }
  }, [history, pagination.currentPageNum, searchParams?.tab]);

  useEffect(() => {
    if (searchParams && searchParams.tab) {
      setListActiveNetwork([]);
      setListActivePractice([]);
    }
  }, [searchParams?.tab, setListActiveNetwork, setListActivePractice]);

  useEffect(() => {
    document.title = t("dashboard_ppl_metatitle");
    getPPLListings();

    return () => {
      // setLeadsClear();
      setClearReferrals();
      setMembershipClear();
    };
  }, [getPPLListings, setLeadsClear, setClearReferrals, setMembershipClear, t]);

  useEffect(() => {
    if (leads) {
      setLeadsList(leads);
    }
  }, [leads, setLeadsList]);

  useEffect(() => {
    if (searchParams?.tab && !loading) {
      getLeads({
        dir_id: listActiveNetwork,
        page: searchParams.page,
        practice_id: listActivePractice,
        tab: searchParams.tab,
        sort: filtrationByDate?.value,
        search: searchValue,
        perPage: perPageLead,
        teamValue: team
      });
    }
  }, [listActivePractice, listActiveNetwork, searchParams.page, searchParams.tab, filtrationByDate?.value, searchValue, perPageLead, team]); // eslint-disable-line

  useEffect(() => {
    if (listingSuccess?.deleteMembership) {
      setNotification("Pay Per Lead Subscription Paused");
      setTimeout(() => setNotification(null), 7000);
    }
  }, [listingSuccess, setNotification]);

  useEffect(() => {
    if (listingError?.deleteMembership) {
      setError("Error Deleting Item");
      setTimeout(() => setError(null), 7000);
    }
  }, [listingError, setError]);
};
