import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./followUpQuestions.module.css";

export const FollowUpQuestions = ({ followups }) => {
  const { t } = useTranslation();

  if (!followups?.length) {
    return null;
  }

  return (
    <div className={styles.content}>
      <div className={styles.topContent} />

      <div>
        {followups.map((item, index) => {
          return (
            <div key={item.lead_followup_id} className={styles.itemQuestionAnswers}>
              <p className={styles.question}>
                {index + 1}. {item.question}
              </p>

              <div className={styles.answerBox}>
                <span className={styles.myAnswer}>{t("dashboard_referrals_details_my_answer")}:</span>
                {item.answer.length === 1 && item.answer[0] === "" && <span className={styles.missing}>[Missing]</span>}

                {item.answer.map(elem => {
                  if (item.answer_type === "select") {
                    return (
                      <p key={elem} className={styles.text}>
                        {elem}
                      </p>
                    );
                  }

                  return (
                    <span key={elem} className={styles.answerTextSimple}>
                      {elem}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
