import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { OverlayPreloader } from "../common/preloader";
import { Notification } from "../common/notification";
import { Upgrade } from "./components/modals/upgrade";
import { Payment } from "./components/modals/payment";
import { PaymentSuccess } from "./components/modals/paymentSuccess";
import { DeleteSuccess } from "./components/modals/deleteSuccess";
import { DowngradeSuccess } from "./components/modals/downgradeSuccess";
import { DowngradePeriodSuccess } from "./components/modals/downgradePeriodSuccess";
import { Delete } from "./components/modals/delete";
import { Downgrade } from "./components/modals/downgrade";
import { DowngradePeriod } from "./components/modals/downgradePeriod";
import { Pause } from "./components/modals/pause";
import { PauseSuccess } from "./components/modals/pauseSuccess";
import Confirm from "./components/modals/confirm";
import Listing from "./components/listing";
import PageNames from "../listingDetail/pageNames";
import { EditCardComponent } from "./components/modals/editCard";
import { AddCardComponent } from "./components/modals/addCard";
import Tabs from "./components/tabs";
import { ListingHead } from "./components/listingsHead";
import { EmptyState } from "./components/emptyState";
import { Pagination } from "../common/pagination";
import { actions as paymentActions } from "../../actions/payment";
import { actions as listingActions } from "../../actions/listing";
import { actions as mainActions } from "../../actions/main";
import { modals as modalsConstant, listingType, statuses } from "../../constants/listings";
import { Unpause } from "./components/modals/unpause";
import { UnpauseSuccess } from "./components/modals/unpauseSuccess";
import styles from "./listings.module.css";

export const Listings = ({ countries }) => {
  const dispatch = useDispatch();
  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const clearPaymentState = useCallback(() => dispatch(paymentActions.clearState()), [dispatch]);
  const cleanUpgradeOptions = useCallback(() => dispatch(listingActions.cleanUpgradeOptions()), [dispatch]);
  const clearTokenError = useCallback(() => () => dispatch(paymentActions.clearTokenError()), [dispatch]);
  const chargeForMembership = useCallback(
    (paymentData, paymentType, isReload, professionType) =>
      dispatch(paymentActions.chargeForMembership(paymentData, paymentType, isReload, history, professionType)),
    [dispatch]
  );
  const deleteMembership = useCallback((memberData, history) => dispatch(listingActions.deleteMembership(memberData, false, history)), [
    dispatch
  ]);
  const getListingAssistant = useCallback(() => dispatch(listingActions.getListingAssistant()), [dispatch]);
  const getConfig = useCallback(() => dispatch(listingActions.getConfig()), [dispatch]);
  const downgradeMembership = useCallback((id, history) => dispatch(listingActions.downgradeMembership(id, false, false, history)), [
    dispatch
  ]);
  const downgradeMembershipHow = useCallback(id => dispatch(listingActions.downgradeMembershipHow(id, false, false, history)), [dispatch]);
  const unDowngradeMembership = useCallback(id => dispatch(listingActions.unDowngradeMembership(id, false, false, history)), [dispatch]);
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const getExistingMemberships = useCallback(
    (
      page,
      dirId,
      professionGroupId,
      freeSponsor,
      professionName,
      sort,
      perPage,
      listActiveChange,
      listActiveStatus,
      isDraft,
      pathname,
      config
    ) =>
      dispatch(
        listingActions.getAllListings(
          listingType.regular,
          false,
          page,
          dirId,
          professionGroupId,
          freeSponsor,
          professionName,
          sort,
          perPage,
          listActiveChange,
          listActiveStatus,
          isDraft,
          pathname,
          config
        )
      ),
    [dispatch]
  );

  const getExistingMembershipsNew = useCallback(
    (page, dirId, professionGroupId, freeSponsor, professionName, sort, perPage, listActiveChange, listActiveStatus, isDraft, pathname) =>
      dispatch(
        listingActions.getExistingMembershipsNew(
          listingType.regular,
          false,
          page,
          dirId,
          professionGroupId,
          freeSponsor,
          professionName,
          sort,
          perPage,
          listActiveChange,
          listActiveStatus,
          isDraft,
          pathname
        )
      ),
    [dispatch]
  );

  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const getUpgradeOptions = useCallback((id, coupon) => dispatch(listingActions.getUpgradeOptions(id, coupon)), [dispatch]);
  const getPendingPayment = useCallback(id => dispatch(listingActions.getPendingPayment(id)), [dispatch]);
  const getRenewOptions = useCallback((id, coupon) => dispatch(listingActions.getRenewOptions(id, coupon)), [dispatch]);
  const getPaymentMethods = useCallback(() => dispatch(paymentActions.getPaymentMethods()), [dispatch]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);
  const updateDefaultSource = useCallback(cardId => dispatch(paymentActions.updateDefaultSource(cardId)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));
  const updateActiveUpgradeOptions = useCallback(activeOption => dispatch(listingActions.updateActiveUpgradeOptions(activeOption)), [
    dispatch
  ]);

  const pauseMembership = useCallback((id, history) => dispatch(listingActions.pauseListing(id, false, history, false)), [dispatch]);
  const unpauseMembership = useCallback((id, history) => dispatch(listingActions.unPauseListing(id, false, false, history)), [dispatch]);
  const clearSavedPaymentData = useCallback(() => dispatch(listingActions.clearSavedPaymentData()), [dispatch]);
  const setSavedPaymentData = useCallback(() => dispatch(listingActions.setSavedPaymentData()), [dispatch]);
  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);

  const payment = useSelector(state => state.payment);
  const {
    memberships,
    count,
    preloaders,
    upgradeOptions,
    success,
    modals,
    activeUpgradeOptions,
    pagination,
    savedPaymentData,
    filters,
    listingAssistants,
    showUpgradeOptionsError,
    listingConfig
  } = useSelector(state => state.listing);
  const isEmpty = !memberships.length;
  const isEmptyAssistant = !listingAssistants.length;

  const {
    defaultSource,
    preloaders: paymentPreloaders,
    success: paymentSuccess,
    paymentMethods,
    selectedCard,
    error: { stripeTokenError: errorToken, addCard: errorAddCard }
  } = payment;
  const history = useHistory();
  const isEvents = ["/my-events"].includes(history.location.pathname);
  const isJobs = ["/my-jobs"].includes(history.location.pathname);
  const isOrganizations = ["/my-organizations"].includes(history.location.pathname);
  const isAllListings = ["/listings"].includes(history.location.pathname);
  const searchParams = queryString.parse(history.location.search);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [notes, setNotes] = useState({ deleteSuccess: null });
  const [professionName, setProfessionName] = useState("");
  const [listings, setListings] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [listActiveDirectories, setListActiveDirectories] = useState([]);
  const [listActiveListingType, setListActiveListingType] = useState([]);
  const [listActiveSponsorOptions, setListActiveSponsorOptions] = useState([]);
  const [listActiveChange, setListActiveChange] = useState([]);
  const [listActiveStatus, setListActiveStatus] = useState([]);

  const [sort, setSort] = useState({ value: "", label: "Unselect " });
  const [team, setTeam] = useState({ value: "", label: "Unselect " });
  const [perPage, setPerPage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const getDocumentTitleText = () => {
      if (isEvents) {
        return t("dashboard_listings_events_metatitle");
      }

      if (isJobs) {
        return t("dashboard_listings_jobs_metatitle");
      }

      if (isOrganizations) {
        return t("dashboard_listings_organizations_metatitle");
      }

      return t("dashboard_listings_all_metatitle");
    };

    document.title = getDocumentTitleText();
  }, [history.location.pathname]);

  useEffect(() => {
    if (!isEvents && !isOrganizations && !isJobs && listingConfig.jobsProfession) {
      getExistingMemberships(
        searchParams.page,
        listActiveDirectories,
        listActiveListingType,
        listActiveSponsorOptions,
        professionName,
        sort.value,
        perPage,
        listActiveChange,
        listActiveStatus,
        isDraft,
        history.location.pathname,
        listingConfig
      );
    }

    if ((isEvents || isOrganizations || isJobs) && !preloaders.memberships) {
      getExistingMembershipsNew(
        searchParams.page,
        listActiveDirectories,
        listActiveListingType,
        listActiveSponsorOptions,
        professionName,
        sort.value,
        perPage,
        listActiveChange,
        listActiveStatus,
        isDraft,
        history.location.pathname
      );
    }
  }, [
    getExistingMemberships,
    searchParams.page,
    listActiveDirectories,
    listActiveListingType,
    listActiveSponsorOptions,
    professionName,
    sort,
    perPage,
    listActiveChange,
    listActiveStatus,
    isDraft,
    history.location.pathname,
    listingConfig.jobsProfession,
    team
  ]);

  useEffect(() => {
    if (team.value) {
      setTeam({ value: "", label: "Unselect " });
    }
  }, [history.location.pathname]);

  useEffect(() => {
    getListingAssistant();
    getConfig();
    return () => {
      setMembershipClear();
    };
  }, []); // eslint-disable-line

  const handlePerPage = page => {
    setPerPage(page);
    const pathname = history.location.pathname || "/listings";
    history.push({ pathname, search: queryString.stringify({ page: 0 }), number: 0 });
  };

  const openModal = (modalName, index) => {
    if (Number.isInteger(index)) {
      setItemToEdit(memberships[index]);
    }

    toggleActivityOfModal(modalName, true);
  };

  const handleOpenModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const closeModal = modalName => {
    toggleActivityOfModal(modalName, false);
  };

  const handleDelete = (id, historyPath) => {
    const target = memberships.find(({ listing_id }) => listing_id === id);
    return deleteMembership(target, historyPath);
  };

  const renderAddListingText = () => {
    if (isJobs) {
      return t("dashboard_listings_all_addjoblisting");
    }

    if (isEvents) {
      return t("dashboard_listings_all_addeventlisting");
    }

    if (isOrganizations) {
      return t("dashboard_listings_all_addorganizationlisting");
    }

    return t("dashboard_listings_all_addlisting");
  };

  const handleOpenNewListing = () => {
    toggleNewListing(true);
  };

  const getOptions = () => {
    if (modals.payPending) {
      return getPendingPayment;
    }
    if (modals.upgrade) {
      return getUpgradeOptions;
    }
    return getRenewOptions;
  };

  useEffect(() => {
    if (success.deleteMembership) {
      window.location.reload();

      setNotes({ ...notes, deleteSuccess: true });
      setTimeout(() => setNotes({ ...notes, deleteSuccess: false }), 7000);
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    if (pagination.currentPageNum !== null && pagination.currentPageNum !== 1) {
      const pathname = history.location.pathname || "/listings";

      history.push(`${pathname}?page=${pagination.currentPageNum}`);
    }
  }, [pagination.currentPageNum]); // eslint-disable-line

  useEffect(() => {
    setListings(() => {
      return memberships?.length ? memberships : null;
    });
  }, [memberships]);

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = pagination.lastPageNum || 0;
    const pathname = history.location.pathname || "/listings";

    for (let i = 1; i <= numberOfPages; i++) {
      navLinks.push({ pathname, search: queryString.stringify({ page: i }), number: i });
    }

    return navLinks;
  };

  const isShowPagination =
    (pagination.lastPageNum !== 1 && !!listings?.length && listings?.length >= pagination.perPage) ||
    Number(searchParams.page) > 1 ||
    pagination.perPage > 10;

  const renderPagination = () => {
    if (isShowPagination) {
      return (
        <Pagination
          className={styles.paginationMargin}
          links={makePaginationLinks()}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
          perPage={pagination.perPage}
          handlePerPage={handlePerPage}
          isStartFromFirst
        />
      );
    }
    return null;
  };

  const isShowSideBar =
    !(filters.professiongroups.length || filters.directories.length || filters.directories.length) && isEmpty && isEmptyAssistant;
  const appliedCount =
    listActiveDirectories.length +
    listActiveStatus.length +
    listActiveChange.length +
    listActiveListingType.length +
    listActiveSponsorOptions.length +
    isDraft;

  return (
    <React.Fragment>
      {notes.deleteSuccess && <Notification success>Listing Deleted</Notification>}
      {success.downgradeSuccess && <Notification success>Downgrade Successful</Notification>}
      {success.paymentSuccess && <Notification success>listing Was Successful Upgraded</Notification>}
      {(preloaders.memberships ||
        preloaders.listingAssistant ||
        preloaders.deleteMembership ||
        preloaders.downgrade ||
        preloaders.listingConfig) && (
        <OverlayPreloader overlayClassName={styles.membershipListingOverlay} spinnerClassName={styles.membershipListingSpinner} />
      )}

      <div
        className={cn(
          styles.wrapper,
          modals.addCard || modals.confirm || modals.editCard || modals.payment || modals.renew || (modals.upgrade && styles.disableScroll)
        )}
      >
        <PageNames isListingsPage />

        <ListingHead
          setProfessionName={setProfessionName}
          isEmpty={isEmpty}
          loading={preloaders.memberships || preloaders.deleteMembership || preloaders.downgrade}
          openNewListing={handleOpenNewListing}
          filters={filters}
          setListActiveDirectories={setListActiveDirectories}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          listActiveDirectories={listActiveDirectories}
          setListActiveListingType={setListActiveListingType}
          listActiveListingType={listActiveListingType}
          setListActiveSponsorOptions={setListActiveSponsorOptions}
          listActiveSponsorOptions={listActiveSponsorOptions}
          listActiveChange={listActiveChange}
          setListActiveChange={setListActiveChange}
          listActiveStatus={listActiveStatus}
          setListActiveStatus={setListActiveStatus}
          sort={sort}
          setSort={setSort}
          listingsListLength={listings?.length}
          appliedCount={appliedCount}
          setTeam={setTeam}
          addListingText={renderAddListingText()}
        />

        {isShowSideBar ? (
          <div className={styles.emptyWrapper}>
            <EmptyState isAllListings={isAllListings} isEvents={isEvents} isJobs={isJobs} isOrganizations={isOrganizations} />
          </div>
        ) : (
          <>
            <Tabs className={styles.filterType} count={count} />

            <Listing
              loading={preloaders.memberships || preloaders.deleteMembership || preloaders.downgrade}
              listingsList={listings}
              openModal={openModal}
              filters={filters}
              setListActiveDirectories={setListActiveDirectories}
              listActiveDirectories={listActiveDirectories}
              setListActiveListingType={setListActiveListingType}
              listActiveListingType={listActiveListingType}
              setListActiveSponsorOptions={setListActiveSponsorOptions}
              listActiveSponsorOptions={listActiveSponsorOptions}
              isEmptyAssistant={isEmptyAssistant}
              listActiveChange={listActiveChange}
              setListActiveChange={setListActiveChange}
              listActiveStatus={listActiveStatus}
              setListActiveStatus={setListActiveStatus}
              listingsListLength={listings?.length}
              appliedCount={appliedCount}
              isDraft={isDraft}
              setIsDraft={setIsDraft}
              team={team}
              isEvents={isEvents}
              isJobs={isJobs}
              isOrganizations={isOrganizations}
            >
              {!listings?.length ? (
                <EmptyState isAllListings={isAllListings} isEvents={isEvents} isJobs={isJobs} isOrganizations={isOrganizations} />
              ) : null}

              <div className={styles.addFreeListingButton}>
                <button type="button" onClick={handleOpenNewListing} className={styles.buttonJoin}>
                  Add Free {renderAddListingText()}
                </button>
              </div>

              {renderPagination()}
            </Listing>
          </>
        )}
      </div>

      <EditCardComponent
        paymentPreloaders={paymentPreloaders}
        closeModal={closeModal}
        clearAllModals={clearAllModals}
        modals={modals}
        updateCard={updateCard}
        countries={countries}
        selectedCard={selectedCard}
        paymentSuccess={paymentSuccess}
      />

      <AddCardComponent
        setErrorToken={setErrorToken}
        errorToken={errorToken}
        errorAddCard={errorAddCard}
        clearTokenError={clearTokenError}
        countries={countries}
        paymentMethods={paymentMethods}
        modals={modals}
        paymentSuccess={paymentSuccess}
        paymentPreloaders={paymentPreloaders}
        addCard={addCard}
        closeModal={closeModal}
        clearAllModals={clearAllModals}
      />

      {!preloaders.memberships && memberships && (
        <React.Fragment>
          {(modals.upgrade || modals.payPending || modals.renew) && (
            <Upgrade
              cleanUpgradeOptions={cleanUpgradeOptions}
              itemToEdit={itemToEdit}
              modals={modals}
              preloaders={preloaders}
              getUpgradeOptions={getOptions()}
              getRenewOptions={getRenewOptions}
              activeUpgradeOptions={activeUpgradeOptions}
              updateActiveUpgradeOptions={updateActiveUpgradeOptions}
              showUpgradeOptionsError={showUpgradeOptionsError}
              setItemToEdit={setItemToEdit}
              upgradeOptions={upgradeOptions}
              clearAllModals={clearAllModals}
              openModal={handleOpenModal}
              savedPaymentData={savedPaymentData}
              clearSavedPaymentData={clearSavedPaymentData}
              setSavedPaymentData={setSavedPaymentData}
              closeModal={() => closeModal(modalsConstant.upgrade)}
            />
          )}

          {modals.payment && (
            <Payment
              deleteCard={deleteCard}
              defaultSource={defaultSource}
              getPaymentMethods={getPaymentMethods}
              paymentMethods={paymentMethods}
              paymentPreloaders={paymentPreloaders}
              selectedCard={selectedCard}
              selectCard={selectCard}
              updateDefaultSource={updateDefaultSource}
              clearAllModals={clearAllModals}
              closeModal={() => closeModal(modalsConstant.payment)}
              openModal={openModal}
              savedPaymentData={savedPaymentData}
              clearSavedPaymentData={clearSavedPaymentData}
              isPendingPayment={statuses.pendingPayment === itemToEdit.status}
              isExpired={statuses.expired === itemToEdit.status}
            />
          )}

          {modals.confirm && (
            <Confirm
              openModal={openModal}
              closeModal={() => closeModal(modalsConstant.confirm)}
              clearAllModals={clearAllModals}
              activeUpgradeOptions={activeUpgradeOptions}
              modals={modals}
              chargeForMembership={chargeForMembership}
              itemToEdit={itemToEdit}
              selectedCard={selectedCard}
              defaultSource={defaultSource}
              paymentPreloaders={paymentPreloaders}
              clearSavedPaymentData={clearSavedPaymentData}
              profession={itemToEdit._profession.toLowerCase()}
            />
          )}

          {modals.paymentSuccess && (
            <PaymentSuccess
              clearPaymentState={clearPaymentState}
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              clearSavedPaymentData={clearSavedPaymentData}
              isPendingPayment={statuses.pendingPayment === itemToEdit.status}
              isExpired={statuses.expired === itemToEdit.status}
            />
          )}

          {modals.deleteSuccess && <DeleteSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.pauseSuccess && <PauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.unpauseSuccess && <UnpauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.downgradeSuccess && <DowngradeSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.downgradePeriodSuccess && <DowngradePeriodSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

          {modals.delete && (
            <Delete
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handleDelete={handleDelete}
              preloadersDeleteMembership={preloaders.deleteMembership}
              handlePause={() => openModal(modalsConstant.pause)}
            />
          )}

          {modals.pause && (
            <Pause
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handlePause={pauseMembership}
              preloadersPauseMembership={preloaders.pause}
            />
          )}

          {modals.unpause && (
            <Unpause
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handleUnpause={unpauseMembership}
              preloadersUnpauseMembership={preloaders.unpause}
            />
          )}

          {modals.downgrade && (
            <Downgrade
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              preloadersDowngradeMembership={preloaders.downgrade}
              downgradeMembership={downgradeMembership}
            />
          )}

          {modals.downgradePeriod && (
            <DowngradePeriod
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              preloaders={preloaders.downgrade}
              unDowngradeMembership={unDowngradeMembership}
              downgradeMembershipHow={downgradeMembershipHow}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
