import React from "react";

import styles from "./listingContent.module.css";

const ListingContent = ({ title, count, onClick, children, isActive }) => {
  return (
    <div className={styles.content}>
      <div className={styles.heading}>
        <h4 className={styles.title}>
          {title}: <span>{count}</span>
        </h4>
        {!isActive ? (
          <button type="button" className={styles.viewAll} onClick={onClick}>
            View All
          </button>
        ) : null}
      </div>
      {children}
    </div>
  );
};

export default ListingContent;
