import React from "react";

import { Notifications } from "../notifications";
import { AddCardComponent } from "../../payPerLeadDetail/modals/addCard";
import { EditCardComponent } from "../../payPerLeadDetail/modals/editCard";
import { Payment } from "../../payPerLeadDetail/modals/payment";
import { Confirm } from "../../payPerLeadDetail/modals/confirm";
import { getPracticeAreas } from "../../../helpers/getPracticeAreas";
import { Success } from "../../payPerLeadDetail/modals/success";
import { priceFormatter } from "../../../helpers";
import PaymentNotification from "../paymentNotification";

const Modals = ({ error, notification, success, errorBuyLead, lead, coupon, modals, practices, resetAllSuccess }) => {
  const currentPrice = coupon.code ? coupon.price : lead.price;

  return (
    <>
      <Notifications error={error} notification={notification} close={resetAllSuccess} />
      {lead?.lead_id && <PaymentNotification success={success} error={errorBuyLead} leadId={lead.lead_id} isNotGetLeadDetail />}
      <AddCardComponent />
      <EditCardComponent />
      {modals.payment && <Payment price={priceFormatter.format(lead.price || 0)} leadId={lead.lead_id} />}
      {modals.confirm && (
        <Confirm
          price={priceFormatter.format(lead.price || 0)}
          leadId={lead.lead_id}
          description={lead.summary}
          currentPrice={priceFormatter.format(currentPrice || 0)}
          lead={{
            ...lead,
            price: priceFormatter.format(currentPrice || 0),
            practicesTitle: getPracticeAreas([lead.practice1, lead.practice2, lead.practice3], practices)
          }}
        />
      )}
      {modals.success && <Success price={priceFormatter.format(currentPrice || 0)} leadId={lead.lead_id} />}
    </>
  );
};

export default Modals;
