export const tabsQuery = {
  account: "account",
  notifications: "notifications",
  paymentMethod: "payment-method",
  paymentHistory: "payment-history",
  integrations: "integrations"
};

export const modalsName = {
  changeEmail: "changeEmail",
  changeEmailVerification: "changeEmailVerification",
  verifyEmail: "verifyEmail",
  verifiedEmailSuccess: "verifiedEmailSuccess",
  changePasswordSuccess: "changePasswordSuccess",
  changeEmailSuccess: "changeEmailSuccess",
  addPhone: "addPhone",
  addPhoneSuccess: "addPhoneSuccess",
  changePhone: "changePhone",
  changePhoneSuccess: "changePhoneSuccess",
  removePhone: "removePhone",
  removePhoneSuccess: "removePhoneSuccess",
  deletePhone: "deletePhone",
  deleteEmail: "deleteEmail",
  changeEmailNew: "changeEmailNew",
  questionForm: "questionForm",
  questionFormSuccess: "questionFormSuccess"
};

export const tabs = [
  {
    name: "Account Notifications"
  },
  {
    name: "Listing Notifications"
  }
];

export const tabsKeys = {
  accountNotifications: "Account Notifications",
  listingNotifications: "Listing Notifications"
};
