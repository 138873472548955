import React from "react";

import { Notification } from "../../common/notification";
import { useCallAllHooks } from "../../payPerLeadDetail/hooks";

const PaymentNotification = ({ leadId, match, error, success, isNotGetLeadDetail }) => {
  const { successNotification, errorNotification, resetAllSuccess } = useCallAllHooks({
    success,
    lead_id: leadId,
    error,
    match,
    isNotGetLeadDetail,
    isNotClearLead: true
  });

  return (
    <>
      {successNotification && (
        <Notification success close={resetAllSuccess}>
          {successNotification}
        </Notification>
      )}
      {errorNotification && (
        <Notification error close={resetAllSuccess}>
          {errorNotification}
        </Notification>
      )}
    </>
  );
};

export default PaymentNotification;
