import React from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import { ReactComponent as CalendarIcon } from "../../../../media/icons/calendar.svg";

import styles from "./comment.module.css";

export const Comment = ({ added_tm, photo, comment, name_f, name_l }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <div className={styles.heading}>
        {photo && (
          <div className={styles.photo}>
            <img src={photo} alt="" />
          </div>
        )}

        <p className={styles.name}>
          {name_f} {name_l}
        </p>
      </div>
      <div className={styles.infoBox}>
        <CalendarIcon className={styles.calendarIcon} />
        <p className={styles.date}>
          {t("dashboard_qa_single_answered")} {format(new Date(added_tm.split(" ").join("T")), "MMM do yyyy, h:mm a")}
        </p>
      </div>

      <p className={styles.text}>{comment}</p>
      <div className={styles.downLine} />
    </div>
  );
};
