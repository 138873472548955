import React, { useEffect, useState } from "react";
import cn from "classnames";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../../payments/paymentMethods/paymentMethods.module.css";
import inputStyles from "../input/input.module.css";
import Checkbox from "../checkbox";
import Input from "../input";
import SelectInput from "../selectInput";
import { OverlayPreloader } from "../preloader";
import { customStyles as selectStyles } from "../../../constants/select";
import { renderSelectCountries } from "../../../helpers/select";
import { Notification } from "../notification";
import ModalControl from "../../modalControl";

const defaultFormikStatus = {
  cardExpiryFake: "",
  name_f: "",
  name_l: "",
  cardNumberFake: ""
};

const AddCardNoStripe = ({
  addCard,
  closeModal,
  clearTokenError,
  countries,
  isFirst,
  errorAddCard,
  setError,
  setErrorToken,
  setStripeToken
}) => {
  const [isCardDefault, setIsCardDefault] = useState(true);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const stripe = useStripe();
  const { t } = useTranslation();
  const [stripeFieldErrors, setStripeFieldErrors] = useState({
    cardNumberFake: undefined,
    cardCvcFake: undefined,
    cardExpiryFake: undefined
  });
  const elements = useElements();

  const { paymentMethods, preloaders: paymentPreloaders, success: paymentSuccess } = useSelector(state => state.payment);

  useEffect(() => {
    formik.validateForm();
  }, [stripeFieldErrors]); //eslint-disable-line

  const validate = ({ address_zip, cardNumberFake, cardCvcFake, cardExpiryFake, name_f, name_l }) => {
    const errors = {};

    if (!address_zip) {
      errors.address_zip = "Required";
    }
    if (!name_f) {
      errors.name_f = "Required";
    }
    if (!name_l) {
      errors.name_l = "Required";
    }

    if (stripeFieldErrors.cardNumberFake) {
      errors.cardNumberFake = stripeFieldErrors.cardNumberFake;
    } else if (!cardNumberFake) {
      errors.cardNumberFake = "Required";
    }

    if (stripeFieldErrors.cardCvcFake) {
      errors.cardCvcFake = stripeFieldErrors.cardCvcFake;
    } else if (!cardCvcFake) {
      errors.cardCvcFake = "Required";
    }

    if (stripeFieldErrors.cardExpiryFake) {
      errors.cardExpiryFake = stripeFieldErrors.cardExpiryFake;
    } else if (!cardExpiryFake) {
      errors.cardExpiryFake = "Required";
    }

    return errors;
  };

  const detectDuplicates = currentCard => {
    const { exp_year, exp_month, last4, name } = currentCard;
    return paymentMethods?.some(card => {
      return card.exp_year === exp_year && card.exp_month === exp_month && card.last4 === last4 && card.name === name;
    });
  };

  const formik = useFormik({
    initialValues: {
      address_country: { value: "US", label: "Unites States" },
      address_zip: "",
      cardNumberFake: "",
      cardCvcFake: "",
      cardExpiryFake: "",
      name_f: "",
      name_l: ""
    },
    initialStatus: defaultFormikStatus,
    validate,
    onSubmit: () => {
      const cardElement = elements.getElement("cardNumber");

      if (clearTokenError !== undefined) {
        clearTokenError();
      }
      setIsStripeLoading(true);
      stripe
        .createToken(cardElement, {
          type: "card",
          name: `${name_f} ${name_l}`,
          address_country: address_country.value,
          address_zip
        })
        .then(res => {
          if (res.token) {
            // add card and use new card token in payment immediately (listing signup)
            if (setStripeToken) {
              setStripeToken(res.token);
            } else if (detectDuplicates(res.token.card)) {
              // simply add new card
              const dupeCardText = "Such item has been added already";
              formik.setStatus({
                cardExpiryFake: dupeCardText,
                cardNumberFake: dupeCardText,
                name_f: dupeCardText,
                name_l: dupeCardText
              });
            } else {
              addCard({ ...res.token, default_source: isCardDefault }, isFirst);
            }
          }

          if (res.error) {
            if (setErrorToken !== undefined) {
              setErrorToken(res.error);
            }
            if (setError) {
              setError();
            }
          }
          setIsStripeLoading(false);
        })
        .catch(err => {
          console.error(err);
          if (setError) {
            setError(err.error);
          }
          if (!setError && setErrorToken) {
            setErrorToken(err.error);
          }

          setIsStripeLoading(false);
        });
    }
  });
  const { errors, status, touched } = formik;
  const { address_country, address_zip, cardNumberFake, cardCvcFake, cardExpiryFake, name_f, name_l } = formik.values;

  const statusHasDuplicate = status.cardNumberFake || status.cardExpiryFake || status.name_f || status.name_l;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);

    if (statusHasDuplicate) {
      formik.setStatus(defaultFormikStatus);
    }
  };

  const handleChangeSelect = (newValue, actionMeta) => {
    formik.setFieldValue(actionMeta.name, newValue);
  };

  const handleChangeFake = element => {
    formik.setFieldTouched(`${element.elementType}Fake`, true);
    formik.setFieldValue(`${element.elementType}Fake`, element.empty ? "" : "fake value for validation");
    if (element.error) {
      setStripeFieldErrors({ ...stripeFieldErrors, [`${element.elementType}Fake`]: element.error.message });
    } else if (statusHasDuplicate) {
      formik.setStatus(defaultFormikStatus);
    } else {
      setStripeFieldErrors({ ...stripeFieldErrors, [`${element.elementType}Fake`]: undefined });
    }
  };
  const handleBlur = object => {
    formik.setFieldTouched(object?.target?.name || `${object.elementType}Fake`, true);
  };

  useEffect(() => {
    if (paymentSuccess.addCard) {
      closeModal();
    }
  }, [paymentSuccess.addCard]); // eslint-disable-line

  return (
    <form className={styles.modalForm} action="" onSubmit={formik.handleSubmit}>
      {errorAddCard && <Notification error>{errorAddCard}</Notification>}
      <fieldset disabled={paymentPreloaders.addCard}>
        {paymentPreloaders.addCard && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        <p className={styles.cardSubtitle}>{t("dashboard_payments_paymentmethod")}</p>
        <h1 className={styles.cardTitle}>+ {t("dashboard_payments_addcardbutton")}</h1>
        <hr className={styles.hr} />
        <div className={styles.flexWrap}>
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={status.name_f || errors.name_f}
            isInvalid={(status.name_f || errors.name_f) && touched.name_f}
            id="name_f"
            label={t("dashboard_payment_first_name")}
            onBlur={handleBlur}
            onChange={handleChange}
            name="name_f"
            required
            value={name_f}
          />

          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={status.name_l || errors.name_l}
            isInvalid={(status.name_l || errors.name_l) && touched.name_l}
            id="name_l"
            label={t("dashboard_payment_last_name")}
            onBlur={handleBlur}
            onChange={handleChange}
            name="name_l"
            required
            value={name_l}
          />
        </div>
        <label className={inputStyles.label} htmlFor="cardNumber">
          Card Number <span className={styles.textRed}>*</span>
        </label>
        <div
          className={cn(
            styles.inputWrap,
            styles.inputWrapSingle,
            errors.cardNumberFake && touched.cardNumberFake ? "stripeInputInvalid" : null
          )}
        >
          <input className={styles.hiddenInput} type="text" name="cardNumberFake" readOnly value={cardNumberFake} />
          <CardNumberElement id="cardNumber" onBlur={handleBlur} onChange={handleChangeFake} showIcon />
          {(errors.cardNumberFake || status.cardNumberFake) && touched.cardNumberFake && (
            <div className={cn(inputStyles.error, styles.errorText)}>{status.cardNumberFake || errors.cardNumberFake}</div>
          )}
        </div>
        <div className="d-md-flex">
          <div className={cn(styles.inputWrap, errors.cardExpiryFake && touched.cardExpiryFake ? "stripeInputInvalid" : null)}>
            <label htmlFor="expiry" className={inputStyles.label}>
              {t("dashboard_payment_expiration_date")} <span className={styles.textRed}>*</span>
            </label>
            <input className={styles.hiddenInput} type="text" name="cardExpiryFake" readOnly value={cardExpiryFake} />
            <CardExpiryElement id="expiry" onBlur={handleBlur} onChange={handleChangeFake} />
            {(status.cardExpiryFake || errors.cardExpiryFake) && touched.cardExpiryFake && (
              <div className={cn(inputStyles.error, styles.errorText)}>{status.cardExpiryFake || errors.cardExpiryFake}</div>
            )}
          </div>

          <div className={cn(styles.inputWrap, errors.cardCvcFake && touched.cardCvcFake ? "stripeInputInvalid" : null)}>
            <label className={inputStyles.label} htmlFor="cvc">
              CVC <span className={styles.textRed}>*</span>
            </label>
            <input className={styles.hiddenInput} type="text" name="cardCvcFake" readOnly value={cardCvcFake} />
            <CardCvcElement id="cvc" onBlur={handleBlur} onChange={handleChangeFake} />
            {errors.cardCvcFake && touched.cardCvcFake && (
              <div className={cn(inputStyles.error, styles.errorText)}>{errors.cardCvcFake}</div>
            )}
          </div>
        </div>
        <div className="d-md-flex">
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={errors.address_zip}
            isInvalid={errors.address_zip && touched.address_zip}
            id="address_zip"
            label={t("dashboard_payment_zip_code")}
            onBlur={handleBlur}
            onChange={handleChange}
            name="address_zip"
            required
            value={address_zip}
          />

          <SelectInput
            classNameWrap={styles.inputWrap}
            className={styles.selectBox}
            styles={selectStyles}
            label={t("dashboard_payment_country")}
            name="address_country"
            options={renderSelectCountries(countries)}
            onChange={handleChangeSelect}
            defaultValue={{ value: "", label: t("select") }}
            value={[address_country]}
          />
        </div>
        <Checkbox className={styles.checkLabel} htmlFor="default" checked={isCardDefault} onChange={() => setIsCardDefault(!isCardDefault)}>
          {t("dashboard_payment_set_default")}
        </Checkbox>
      </fieldset>

      <ModalControl
        confirmationType="submit"
        onExit={closeModal}
        isClose
        confirmDisabled={paymentPreloaders.addCard}
        exitDisabled={paymentPreloaders.addCard}
        confirmClassName={paymentPreloaders.addCard || isStripeLoading ? styles.disable : null}
      >
        + {t("dashboard_payments_addcardbutton")}
      </ModalControl>
    </form>
  );
};

export const AddCard = AddCardNoStripe;
