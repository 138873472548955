import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { actions as mainActions } from "../../actions/main";
import { ReactComponent as CloseIcon } from "../../media/icons/close-cross.svg";
import { ReactComponent as MainLogo } from "../../media/icons/mainLogo.svg";
import { Hamburger } from "../header/hamburger";
import styles from "./publicHeader.module.css";

const PublicHeader = () => {
  const dispatch = useDispatch();
  const { isActiveMobileMenu } = useSelector(state => state.main);

  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);

  const handleOpenNewListing = () => {
    toggleMobileMenu(!isActiveMobileMenu);
  };

  const renderNavigation = () => {
    return (
      <div className={styles.links}>
        <Link to="/login" className={styles.login}>
          Login
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.main}>
      <header className={styles.content}>
        <Link to="/" className={styles.logo}>
          <MainLogo className={styles.mainLogo} />
        </Link>

        {isActiveMobileMenu && <div className={styles.activeMenu}>{renderNavigation()}</div>}
        <div className={styles.activeMenuTablet}>{renderNavigation()}</div>

        <div className={styles.rightContent}>
          <div className={styles.hamburger}>
            {isActiveMobileMenu ? (
              <button type="button" className={styles.closeIcon} onClick={handleOpenNewListing}>
                <CloseIcon />
              </button>
            ) : (
              <Hamburger toggleDropdown={handleOpenNewListing} activeMenu={isActiveMobileMenu} />
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default PublicHeader;
