import React from "react";
import cn from "classnames";
import { formatDistanceToNow } from "date-fns";
import DOMPurify from "dompurify";

import { parseDate } from "../../../helpers/parseDate";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone-filled.svg";
import { ReactComponent as ArrowIcon } from "../../../media/icons/arrow-right.svg";
import defaultListingImage from "../../../media/images/defaultListingImage.png";
import styles from "./profession.module.css";

const Profession = ({ isFree, current }) => {
  const {
    attorney,
    state_title: state,
    city,
    description,
    listing_url: listingUrl,
    listings_image_name: image,
    phone,
    added,
    practice4,
    practice5
  } = current;

  return (
    <div className={cn(styles.profession, isFree ? styles.professionFree : "")}>
      <div className={styles.professionContent}>
        <div className={styles.professionInfo}>
          <div className={styles.professionImgContent}>
            <img src={image || defaultListingImage} alt="Professional pictures" />
          </div>
          <div className={styles.professionDetail}>
            <a href={listingUrl} className={styles.professionName}>
              {attorney}
            </a>
            <span className={styles.professionAddress}>
              {state ? `${state}, ` : ""}
              {city}
            </span>
            {!isFree && <span className={styles.professionStatus}>Sponsor</span>}

            <div className={styles.hideMobile}>
              <p className={styles.professionPractices}>{`${practice4 ? `${practice4}` : ""}${practice5 ? `, ${practice5}` : ""}`}</p>
              <div className={styles.professionContact}>
                <span className={styles.professionPeriod}>{formatDistanceToNow(new Date(parseDate(added)))}</span>
                {!isFree && phone && (
                  <a href={`tel:${phone}`} className={styles.professionPhone}>
                    <PhoneIcon />
                    {phone}
                  </a>
                )}

                {isFree && (
                  <a href={listingUrl} className={styles.viewListing}>
                    View Listing
                    <ArrowIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.hideTablet}>
          <p className={styles.professionPractices}>Immigration Law, Family Law, Labor and Employment Law</p>
          <div className={styles.professionContact}>
            <span className={styles.professionPeriod}>14 years licensed</span>
            {!isFree && phone && (
              <a href={`tel:${phone}`} className={styles.professionPhone}>
                <PhoneIcon />
                {phone}
              </a>
            )}
            {isFree && (
              <a href={listingUrl} className={styles.viewListing}>
                View Listing
                <ArrowIcon />
              </a>
            )}
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      {!isFree && <p className={styles.professionDescription} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />}
    </div>
  );
};

export default Profession;
