import React, { useMemo, useCallback } from "react";
import queryString from "query-string";

import { Pagination } from "../../../common/pagination";
import styles from "./pagination.module.css";

const PaginationContent = ({
  setPerPage,
  getCases,
  activeSort,
  listActiveNetwork,
  listActivePractice,
  searchParams,
  searchReferrals,
  history,
  pagination,
  referralsLength
}) => {
  const handlePerPageReferrals = useCallback(
    perPage => {
      setPerPage(perPage);
      history.push({ pathname: "/referrals", search: queryString.stringify({ ...searchParams, page: 0 }) });

      getCases({
        sort: activeSort?.value,
        dirId: listActiveNetwork,
        practiceId: listActivePractice,
        type: searchParams.tab,
        page: 0,
        search: searchReferrals,
        perPage,
        isSelectedFilter: true
      });
    },
    [setPerPage, history, getCases, activeSort, listActiveNetwork, listActivePractice, searchParams, searchReferrals]
  );

  const paginationLinks = useMemo(
    () =>
      Array.from({ length: pagination.lastPageNum + 1 || 0 }, (_, i) => ({
        pathname: "/referrals",
        search: queryString.stringify({ ...searchParams, page: i }),
        number: i
      })),
    [pagination.lastPageNum, searchParams]
  );

  const isShowPagination = useMemo(() => {
    const isFirstPage = pagination.currentPageNum === 0 && pagination.lastPageNum === 0;

    return (
      (!!referralsLength && referralsLength >= pagination.perPage && !isFirstPage) ||
      (Number(searchParams.page) >= 1 && !isFirstPage) ||
      pagination.perPage > 10
    );
  }, [referralsLength, pagination, searchParams.page]);

  return (
    <>
      {isShowPagination && (
        <Pagination
          currentPage={parseInt(searchParams.page, 10) || 0}
          links={paginationLinks}
          lastPage={pagination.lastPageNum}
          perPage={pagination.perPage}
          handlePerPage={handlePerPageReferrals}
          className={styles.pagination}
        />
      )}
    </>
  );
};

export default PaginationContent;
