import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Aside from "./aside";
import EventsAside from "./eventsAside";
import JobContent from "./jobContent";
import EventContent from "./eventContent";
import EmptyState from "./emptyState";
import styles from "./publicListing.module.css";
import { actions } from "../../actions/publicListings";
import { OverlayPreloader } from "../common/preloader";
import { actions as profileActions } from "../../actions/account/profile";
import Layout from "../layout";
import PublicLayout from "../publicLayout";

const PublicListing = ({ socialLinks, footerInfo, listingId, keycloak }) => {
  const dispatch = useDispatch();
  const getListing = useCallback(id => dispatch(actions.getListing(id)), [dispatch]);
  const addToFavorite = useCallback(id => dispatch(actions.addToFavorite(id)), [dispatch]);
  const removeFavorite = useCallback(id => dispatch(actions.removeFavorite(id)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const { preloaders, listing } = useSelector(state => state.publicListing);
  const { account } = useSelector(state => state);
  const { profile } = account;
  const isJobListing = listing._profession === "Jobs";

  useEffect(() => {
    if (!profile?.user_id) {
      getProfile();
    }
  }, [profile?.user_id]);

  useEffect(() => {
    if (listingId) {
      getListing(listingId);
    }
  }, [listingId]);

  const MainContainer = profile?.user_id ? Layout : PublicLayout;

  return (
    <>
      {preloaders.page && <OverlayPreloader />}

      <MainContainer socialLinks={socialLinks} footerInfo={footerInfo} keycloak={keycloak}>
        <div className={styles.main}>
          <div className={styles.container}>
            {!listing.listing_id ? (
              <EmptyState />
            ) : (
              <>
                <div className={styles.content}>
                  {isJobListing ? (
                    <JobContent
                      listing={listing}
                      addToFavorite={addToFavorite}
                      isShowFavorites={profile.user_id}
                      removeFavorite={removeFavorite}
                    />
                  ) : (
                    <EventContent
                      listing={listing}
                      addToFavorite={addToFavorite}
                      isShowFavorites={profile.user_id}
                      removeFavorite={removeFavorite}
                    />
                  )}
                </div>
                {isJobListing ? <Aside /> : <EventsAside />}
              </>
            )}
          </div>
        </div>
      </MainContainer>
    </>
  );
};

export default PublicListing;
