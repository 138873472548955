import React from "react";
import cn from "classnames";

import styles from "./filter.module.css";
import SelectInput from "../../common/selectInput";

const Filter = ({ filter, setFilter, filterOptions, className }) => {
  const handleChangeFilter = value => {
    setFilter(value);
  };

  return (
    <div className={cn(styles.filter, className)}>
      <div className={styles.filterMobile}>
        <span className={styles.label}>Filter by:</span>
        <SelectInput
          classNameWrap={styles.selectWrapper}
          className={styles.select}
          name="country"
          placeholder="Filter By"
          onChange={value => handleChangeFilter(value)}
          options={filterOptions}
          value={filter}
        />
      </div>

      <div className={styles.filterDesktop}>
        {filterOptions?.map(item => {
          return (
            <button
              type="button"
              key={item.value}
              className={cn(styles.filterButton, filter?.value === item?.value ? styles.filterButtonActive : "")}
              onClick={() => handleChangeFilter(item)}
            >
              {item.value}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Filter;
