import React, { useRef } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../../common/button";
import styles from "./coupon.module.css";
import { useKeyDownEnter } from "../../../helpers/hooks";
import { priceFormatter } from "../../../helpers";

export const Coupon = ({ checkCoupon, children, className, setCoupon, coupon, handleClickNext, code, updatedPrice, price }) => {
  const couponElement = useRef();
  const activePrice = code ? styles.priceActive : "";
  const { t } = useTranslation();

  const onKeyDownEnter = () => {
    if (document.activeElement === couponElement.current) {
      checkCoupon();
    } else {
      handleClickNext();
    }
  };

  useKeyDownEnter(onKeyDownEnter);

  return (
    <div className={cn(styles.coupon, className)}>
      <p className={styles.leadText}>Lead Price (Exclusive)</p>
      <span className={cn(styles.price, activePrice)}>{price}</span>
      {code && <span className={styles.updatedPrice}>{priceFormatter.format(updatedPrice)}</span>}
      <label className={styles.label} htmlFor="coupon">
        <p className={styles.labelText}>Coupon</p>
        <span>
          <input
            type="text"
            id="coupon"
            className={styles.couponInput}
            onChange={e => setCoupon(e.target.value)}
            value={coupon}
            ref={couponElement}
          />
          <Button disabled={!coupon} className={styles.couponBtn} onClick={checkCoupon}>
            {t("dashboard_common_button_apply")}
          </Button>
        </span>
      </label>
      {children && <>{children}</>}
    </div>
  );
};
