import React, { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../../common/input";
import CalendarWithInputMask from "../../../listingDetail/calendarWithInputMask";
import SelectInput from "../../../common/selectInput";
import { getCountryNameFromCode, getStateNameFromCode, renderSelectCountries, renderSelectStates } from "../../../../helpers/select";
import { Modal } from "../../../common/modal";
import { Button } from "../../../common/button";
import { actions as actionProfile } from "../../../../actions/account/profile";
import { OverlayPreloader } from "../../../common/preloader";
import styles from "./questionForm.module.css";

const QuestionForm = ({ closeModal, formValues = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countries, states } = useSelector(state => state.main);
  const { preloaders } = useSelector(state => state.account);
  const updateContacts = useCallback((data, callback) => dispatch(actionProfile.updateContacts(data, callback, true)), [dispatch]);

  const validate = values => {
    const { name } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!name) {
      errors.name = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: formValues.name || "",
      birth_date: formValues.birth_date || "",
      location: formValues.location || "",
      selectedLocation: formValues.selectedLocation,
      state: formValues.state || "",
      country: formValues.country || "",
      city: formValues.city || "",
      zip: formValues.zip || ""
    },
    onSubmit: values => {
      const { name, birth_date, state, country, city, zip } = values;
      const [name_f, name_l] = name.split(" ");

      updateContacts(
        {
          name_f,
          name_l,
          birth_date,
          country: country?.value,
          city,
          state: state?.value,
          zip
        },
        false
      );
    },
    validate
  });

  const { values, errors, setFieldValue, handleSubmit } = formik;
  const isCanadaOrUS = useMemo(() => ["United States", "Canada"].includes(values.country?.label), [values.country]);
  const isCountrySelected = useMemo(() => Boolean(values.country?.label), [values.country]);

  const handleChange = event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const handleChangeDate = date => {
    setFieldValue("birth_date", date);
  };

  const handleSelectDate = date => {
    setFieldValue("birth_date", date);
  };

  const handleSelectCountry = newValue => {
    setFieldValue("country", newValue);

    if (!isCanadaOrUS) {
      setFieldValue("state", "");
    }
  };

  const handleSelectState = newValue => {
    setFieldValue("state", newValue);
  };

  const getCountryValue = countryValue => {
    if (typeof countryValue === "string") {
      return [{ value: countryValue, label: getCountryNameFromCode(countries, countryValue) }];
    }

    return [countryValue];
  };

  const getStateValue = stateValue => {
    if (typeof stateValue === "string") {
      return [{ value: stateValue, label: getStateNameFromCode(states, stateValue) }];
    }

    return [stateValue];
  };

  return (
    <Modal
      classNameButton={styles.closeButton}
      classNameMain={styles.modal}
      closeModal={closeModal}
      clearAllModals={closeModal}
      isDisableCloseOutside
    >
      {(preloaders.profileLocationAutoComplete || preloaders.contacts) && <OverlayPreloader />}

      <h2 className={styles.title}>Please answer a few questions.</h2>
      <div className={styles.formContent}>
        <label className={styles.label}>
          What is your full name? <span>*</span>
        </label>
        <Input
          isInvalid={errors.name}
          error={errors.name}
          name="name"
          className={styles.input}
          placeholder=""
          onChange={handleChange}
          value={values.name}
        />
      </div>
      <div className={styles.formContent}>
        <label className={styles.label}>What is your birthdate?</label>

        <CalendarWithInputMask
          name="location"
          error={errors.birth_date}
          isInvalid={errors.birth_date}
          value={values.birth_date ? new Date(values.birth_date) : null}
          onChange={date => handleSelectDate(date)}
          handleChangeInput={handleChangeDate}
          placeholder="MM/DD/YYYY"
          defaultMaxDate={new Date()}
          inputClassName={styles.calendarInput}
          minDate={new Date("1899")}
        />
      </div>
      <div className={styles.formContent}>
        <label className={styles.label}>Where do you live?</label>

        <SelectInput
          classNameWrap={styles.select}
          name="country"
          placeholder="Select a country"
          onChange={handleSelectCountry}
          options={renderSelectCountries(countries)}
          value={values.country?.value ? getCountryValue(values.country?.value) : ""}
        />
      </div>

      {isCountrySelected ? (
        <>
          {isCanadaOrUS ? (
            <div className={styles.formContent}>
              <label className={styles.label}>Zip code</label>
              <Input
                isInvalid={errors.zip}
                error={errors.zip}
                name="zip"
                className={styles.input}
                placeholder="Enter zip code"
                onChange={handleChange}
                value={values.zip}
              />
            </div>
          ) : (
            <>
              <div className={styles.formContent}>
                <label className={styles.label}>Select a state</label>

                <SelectInput
                  classNameWrap={styles.select}
                  noOptionsMessage={() => t("dashboard_referrals_select_country_first")}
                  name="state"
                  placeholder="Select a state"
                  onChange={handleSelectState}
                  options={values.country?.value && states?.length && renderSelectStates(values.country.value, states)}
                  value={values.state.value ? getStateValue(values.state) : ""}
                />
              </div>
              <div className={styles.formContent}>
                <label className={styles.label}>Enter a City</label>

                <Input
                  isInvalid={errors.city}
                  error={errors.city}
                  name="city"
                  className={styles.input}
                  placeholder="Type your city"
                  onChange={handleChange}
                  value={values.city}
                />
              </div>
            </>
          )}
        </>
      ) : null}

      <Button className={styles.submit} onClick={handleSubmit}>
        Next
      </Button>
    </Modal>
  );
};

export default QuestionForm;
