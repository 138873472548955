import React, { useEffect, useMemo, useState, useCallback } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { ReactComponent as SearchIcon } from "../../../../media/icons/search.svg";
import { ReactComponent as ArrowIcon } from "../../../../media/icons/arrow-right.svg";
import { actions as homeActions } from "../../../../actions/home";
import { Modal } from "../../../common/modal";
import styles from "./followCommunitiesForm.module.css";

const FollowCommunitiesForm = ({ closeModal, communities, allCommunityValue }) => {
  const dispatch = useDispatch();
  const [activeCommunities, setActiveCommunities] = useState([]);
  const [activeCommunitiesType, setActiveCommunitiesType] = useState("");
  const [communitiesList, setCommunitiesList] = useState([]);
  const [search, setSearch] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [filterOption, setFilterOption] = useState("All");

  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const followCommunities = useCallback(data => dispatch(homeActions.followCommunities(data)), [dispatch]);

  const handleSelectCommunities = community => {
    setActiveCommunities(prev =>
      prev.some(c => c.heritage_id === community.heritage_id)
        ? prev.filter(c => c.heritage_id !== community.heritage_id)
        : [...prev, community]
    );
  };

  const handleSetCommunities = name => {
    setActiveCommunitiesType(name === activeCommunitiesType ? "" : name);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const handleFilterChange = event => {
    setFilterOption(event.target.value);
    if (event.target.value !== "All") {
      setActiveCommunitiesType(event.target.value.toLowerCase());
    } else {
      setActiveCommunitiesType("");
    }
  };

  const handleFollowSelected = () => {
    followCommunities(activeCommunities);
    closeModal();
  };

  const handleFollowAll = () => {
    followCommunities([allCommunityValue]);
    closeModal();
  };

  useEffect(() => {
    setCommunitiesList(communities);
  }, [communities]);

  const normalizeCommunitiesList = useMemo(() => {
    const updateCommunitiesList = communities
      .reduce((acc, community) => {
        const children = community.children.map(itemChildren => ({
          ...itemChildren,
          name: community.heritage_group_title
        }));

        return [...acc, ...children];
      }, [])
      .sort((a, b) => a.heritage_title.localeCompare(b.heritage_title));

    return activeCommunitiesType
      ? updateCommunitiesList.filter(item => item.name.toLowerCase() === activeCommunitiesType)
      : updateCommunitiesList;
  }, [communities.length, activeCommunitiesType]);

  useEffect(() => {
    if (search) {
      const searchCommunities = normalizeCommunitiesList.filter(communityItem => {
        return communityItem.heritage_title.toLowerCase().includes(search.toLowerCase());
      });

      setCommunitiesList(searchCommunities);
    } else {
      setCommunitiesList(normalizeCommunitiesList);
    }
  }, [search, normalizeCommunitiesList.length]);

  const filterOptions = useMemo(() => {
    const options = communities.map(item => item.heritage_group_title);
    return ["All", ...options];
  }, [communities]);

  return (
    <Modal closeModal={closeModal} clearAllModals={closeModal} classNameMain={styles.modal}>
      <div className={styles.heading}>
        <h4 className={styles.title}>Follow Communities in Your City</h4>
        <h6 className={styles.subtitle}>Select a community as much as you want</h6>
      </div>

      {isMobile && (
        <div className={styles.filterRow}>
          <label className={styles.filterByLabel}>Filter </label>
          <select className={styles.filterDropdown} value={filterOption} onChange={handleFilterChange}>
            {filterOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}

      {!isMobile && (
        <div className={styles.tagsContainer}>
          {communities.length ? (
            <div className={styles.tagsList}>
              {communities.map(item => {
                return (
                  <button
                    key={`${item.heritage_group_title}-${item.heritage_path}`}
                    type="button"
                    className={cn(
                      styles.tagItem,
                      item.heritage_group_title.toLowerCase() === activeCommunitiesType ? styles.tagItemActive : ""
                    )}
                    onClick={() => handleSetCommunities(item.heritage_group_title.toLowerCase())}
                  >
                    {item.heritage_group_title}
                  </button>
                );
              })}
            </div>
          ) : null}

          <button type="button" className={styles.viewAll} onClick={() => handleFollowAll()}>
            Follow all <ArrowIcon />
          </button>
        </div>
      )}

      <div className={styles.inputWrap}>
        <SearchIcon className={styles.searchIcon} />
        <input type="text" className={styles.input} placeholder="Search" onChange={handleSearch} value={search} />
      </div>

      <div className={styles.categories}>
        {communitiesList.map(item => (
          <button
            key={String(item.heritage_id) + item.heritage_title}
            type="button"
            className={cn(
              styles.categoryItem,
              activeCommunities.some(c => c.heritage_id === item.heritage_id) ? styles.categoryItemActive : ""
            )}
            onClick={() => handleSelectCommunities(item)}
          >
            {item.heritage_title}
          </button>
        ))}
      </div>

      <div className={isMobile ? styles.mobileButtonsContainer : styles.followButtonContainer}>
        <button
          type="button"
          className={isMobile ? cn(styles.followButton, styles.followButtonMobile) : styles.followButton}
          onClick={handleFollowSelected}
          disabled={!activeCommunities.length}
        >
          Follow <ArrowIcon />
        </button>
        {isMobile && (
          <button type="button" className={styles.followAllButton} onClick={handleFollowAll}>
            Follow All communities <ArrowIcon />
          </button>
        )}
      </div>
    </Modal>
  );
};

export default FollowCommunitiesForm;
