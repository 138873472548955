import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../../media/icons/logo.svg";
import { useDocTitle } from "../../../helpers/hooks";
import styles from "./resetPasswordSuccess.module.css";
import { ReactComponent as SuccessIcon } from "../../../media/icons/success.svg";

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();

  useDocTitle(t("dashboard_forgotpassword_metatitle"));

  return (
    <>
      <div className={styles.wrapper}>
        <img className="login-banner__logo" src={logo} alt="" />

        <h1 className={styles.title}>Reset Your Password</h1>
        <p className={styles.subtitle}>
          You have successfully reset your password. You can login to your Heritage Web dashboard using the updated password.
        </p>

        <div className={styles.check}>
          <SuccessIcon />
        </div>
      </div>
      <div className={styles.authForm}>
        <Link to="/login" className={styles.link}>
          Back to Login Page
        </Link>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;
