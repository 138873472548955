import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { modals as modalsConstant } from "../../../constants/listings";
import { OverlayPreloaderAbsolute } from "../../common/preloader";
import { Notifications } from "../notifications";
import styles from "./listingNotification.module.css";

export const ListingNotification = ({ memberData, openModal, handleActiveNotificationModal }) => {
  const { t } = useTranslation();

  const {
    preloaders: { notifications }
  } = useSelector(state => state.listing);

  const { is_premium, verifiedemails: verifiedEmails, verifiedphones: verifiedPhones } = memberData;
  const isPremium = is_premium === "true" || is_premium === true;
  const preloaderElement = notifications && <OverlayPreloaderAbsolute />;
  const verifiedEmailsList = verifiedEmails ? verifiedEmails.filter(item => !item.date_removed || item.date_removed === "0000-00-00") : [];
  const verifiedPhonesList = verifiedPhones ? verifiedPhones.filter(item => !item.date_removed || item.date_removed === "0000-00-00") : [];
  const isShowNotifications = verifiedEmailsList.length !== 0 || verifiedPhonesList.length !== 0;

  return (
    <>
      {isPremium && (
        <section className={styles.content}>
          {preloaderElement}
          <h3 className={styles.title}>{t("dashboard_listing_notifications_title")}</h3>
          <p className={styles.text}>{t("dashboard_listing_notifications_description")}</p>

          <div className={styles.actions}>
            <button type="button" className={styles.actionsButton} onClick={() => openModal(modalsConstant.addEmail)}>
              {t("dashboard_listing_notifications_addemail")}
            </button>
            <button type="button" className={styles.actionsButton} onClick={() => openModal(modalsConstant.addPhone)}>
              {t("dashboard_listing_notifications_addphone")}
            </button>
          </div>
          {isShowNotifications && (
            <Notifications emails={verifiedEmailsList} phones={verifiedPhonesList} openModal={handleActiveNotificationModal} />
          )}
        </section>
      )}
    </>
  );
};
