import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import cn from "classnames";

import { customStyles as selectStyles } from "../../constants/select";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { Button } from "../common/button";
import { OverlayPreloader } from "../common/preloader";
import { getIsStateSelectionValid } from "../../helpers";
import {
  getCountryNameFromCode,
  getStateNameFromCode,
  renderSelectCountries,
  renderSelectNewLetter,
  renderSelectStates,
  renderSelectSpecialty
} from "../../helpers/select";
import closeGrayIcon from "../../media/icons/close-grey.svg";
import Input from "../common/input";
import ModalControl from "../modalControl";
import SelectInput from "../common/selectInput";
import "../../styles/index.css";
import styles from "./modalReferralRequest.module.css";

const blankLocation = { description: "", country: "", state: "", city: "", zip: "", profession: "", specialty: [], directory: [] };

const ModalReferralRequest = ({
  closeModal,
  submitChanges,
  categoriesProfessions,
  caseData,
  practices = [],
  professions = {},
  newsletters
}) => {
  const { t } = useTranslation();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const main = useSelector(state => state.main);
  const { preloaders } = useSelector(state => state.referralRequests);
  const { states, countries } = main;
  const loading = preloaders.modal;

  const handleBlur = (formik, fieldName) => formik.setFieldTouched(fieldName, true);

  const getDefaultValue = () => {
    if (Object.keys(caseData || {})) {
      const { summary, zip, country, city, state } = caseData;
      return {
        description: summary,
        country,
        state,
        city,
        zip,
        profession: professions,
        specialty: practices,
        directory: renderSelectNewLetter(newsletters, caseData.newsletter)
      };
    }
    return blankLocation;
  };

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleSelectChange = (newValue, actionMeta) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }

    if (actionMeta.name === "directory" || actionMeta.name === "specialty") {
      formik.setFieldValue(actionMeta.name, [...formik.values[actionMeta.name], ...newValue]);
    } else {
      formik.setFieldValue(actionMeta.name, newValue);
    }
  };

  const onDeleteMultiSelect = (name, id) => {
    formik.setFieldValue(
      name,
      formik.values[name].filter(item => item.value !== id)
    );
  };

  const validate = values => {
    const { description, country, state, city, zip, profession, specialty, directory } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (description.length <= 50) {
      errors.description = requiredText;
    }
    if (!profession) {
      errors.profession = requiredText;
    }
    if (!specialty.length) {
      errors.specialty = requiredText;
    }
    if (!directory.length) {
      errors.directory = requiredText;
    }
    if (!city) {
      errors.city = requiredText;
    }
    if (!country) {
      errors.country = requiredText;
    }
    if (!zip) {
      errors.zip = requiredText;
    }
    if (!state) {
      errors.state = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: getDefaultValue(),
    onSubmit: () => {
      return submitChanges({
        country: formik.values.country,
        state: formik.values.state,
        city: formik.values.city,
        zip: formik.values.zip,
        summary: formik.values.description,
        practices: formik.values.specialty.map(item => Number(item.value)),
        newsletter: formik.values.directory.map(item => Number(item.value))
      });
    },
    validate
  });
  const { values, errors, touched, handleSubmit } = formik;

  useEffect(() => {
    if (values.state && !getIsStateSelectionValid(values, "country", "state")) {
      formik.setFieldValue("state", "");
    }
  }, [values.country]); // eslint-disable-line

  useEffect(() => {
    if (values.profession && !isFirstRender) {
      formik.setFieldValue("specialty", []);
      formik.setFieldValue("directory", []);
    }
  }, [values.profession]); // eslint-disable-line

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <div>
        <p className={styles.popupTitle}>{t("dashboard_referrals_editor_details_title")}</p>
        <hr className={styles.hr} />
        <div className={styles.mB20}>
          <label className={styles.labelText} htmlFor="description">
            {t("dashboard_referrals_editor_details_description")}
          </label>
          <textarea
            value={values.description}
            onChange={handleChange}
            name="description"
            id="create-referral-description"
            className={cn(styles.textarea, errors.description ? styles.invalid : null)}
          />
        </div>
        <div className={cn("row", styles.mB20)}>
          <div className="col-12 col-md-6">
            <SelectInput
              error={errors.country}
              isInvalid={errors.country && touched.country}
              getOptionLabel={option => getCountryNameFromCode(countries, option)}
              options={renderSelectCountries(countries, false)}
              onBlur={() => handleBlur(formik, "country")}
              onChange={handleSelectChange}
              getOptionValue={option => option}
              inputId="country"
              label={t("dashboard_common_field_country")}
              placeholder={t("dashboard_common_dropdown_select")}
              name="country"
              styles={selectStyles}
              value={[values.country]}
            />
          </div>
          <div className="col-12 col-md-6">
            <SelectInput
              classNameWrap={styles.select}
              error={errors.state}
              getOptionLabel={option => getStateNameFromCode(states, option)}
              getOptionValue={option => option}
              id="state"
              inputId="state"
              isInvalid={errors.state && touched?.state}
              name="state"
              noOptionsMessage={() => t("dashboard_referrals_select_country_first")}
              label={t("dashboard_common_field_state")}
              placeholder={t("dashboard_common_dropdown_select")}
              onBlur={() => handleBlur(formik, "state")}
              onChange={handleSelectChange}
              options={states && renderSelectStates(values.country, states, false)}
              value={[values.state]}
            />
          </div>
        </div>
        <div className={cn("row", styles.mB20)}>
          <div className="col-12 col-md-6">
            <Input
              error={errors.city}
              id="city"
              isInvalid={errors.city && touched.city}
              name="city"
              label={t("dashboard_common_field_city")}
              onBlur={e => handleBlur(formik, e.target.name)}
              onChange={handleChange}
              value={values.city}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              error={errors.zip}
              id="zip"
              isInvalid={errors.zip && touched.zip}
              name="zip"
              label={t("dashboard_common_field_zipcode")}
              onBlur={e => handleBlur(formik, e.target.name)}
              onChange={handleChange}
              value={values.zip}
            />
          </div>
        </div>
        <hr className={cn(styles.hr, styles.indent)} />
        <div className="row">
          <div className="col-12">
            <button type="button" className={styles.searchButton}>
              <SearchIcon />
            </button>
            <SelectInput
              classNamePrefix="selectSearch"
              classNameWrap={styles.select}
              error={errors.specialty}
              options={renderSelectSpecialty(categoriesProfessions.practices, values.profession.value, values.specialty)}
              onBlur={() => handleBlur(formik, "specialty")}
              onChange={handleSelectChange}
              getOptionValue={option => option}
              id="specialty"
              inputId="specialty"
              isInvalid={errors.specialty && touched?.specialty}
              isMulti
              name="specialty"
              noOptionsMessage={() => t("dashboard_referrals_select_country_first")}
              placeholder={t("dashboard_referrals_editor_details_specialty_search")}
              value={[]}
              disabled={values.specialty.length === 3}
              className={values.specialty.length === 3 ? styles.disableSelect : ""}
            />
          </div>
        </div>
        <div className={cn("row", styles.mB20)}>
          <div className="col-12">
            {values.specialty.map(item => {
              return (
                <div className={styles.itemOptionBox} key={item.label}>
                  <Button
                    className={cn("button--clear", styles.btnDelete)}
                    onClick={() => {
                      onDeleteMultiSelect("specialty", item.value);
                    }}
                  >
                    <img src={closeGrayIcon} alt="close" />
                  </Button>
                  <p className={styles.textOption}>{item.label}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ModalControl
        onExit={closeModal}
        confirmationType="submit"
        onConfirm={handleSubmit}
        confirmDisabled={loading}
        confirmClassName={loading ? "button--outline" : null}
      >
        {t("dashboard_common_button_submit")}
      </ModalControl>
    </form>
  );
};

export default ModalReferralRequest;
