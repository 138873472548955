import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import format from "date-fns/format";

import { Textarea } from "../textarea";
import { actions as questionsActions } from "../../../../actions/questions";
import { ReactComponent as CalendarIcon } from "../../../../media/icons/calendar.svg";
import { ReactComponent as CommentIcon } from "../../../../media/icons/comments.svg";

import Dropdown from "../../../common/dropdown";
import styles from "./answer.module.css";

export const Answer = ({
  answer_id,
  answer,
  added_tm: added,
  attorney,
  city,
  comments,
  commentsRender,
  listings_image_name,
  state,
  url: website
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEmptyImage = Boolean(listings_image_name);
  const [firstName, lastName] = attorney.split(" ");
  const initials = firstName?.[0] + lastName?.[0];

  const onSubmitForm = text => {
    dispatch(questionsActions.sendComment({ answer_id, comment: text }));
  };

  const onEnterPress = (e, callBack) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      callBack();
    }
  };

  return (
    <div className={styles.answer}>
      <div className={styles.heading}>
        <div className={styles.photo}>
          {isEmptyImage ? <img src={listings_image_name} alt="" /> : <p className={styles.initials}>{initials}</p>}
        </div>
        <div>
          <p className={styles.name}>{attorney}</p>
          <p className={styles.company}>
            {website && (
              <>
                {website
                  .replace("www.", "")
                  .replace("https://", "")
                  .match(/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}/)}
              </>
            )}
          </p>
          {}
          <p className={styles.city}>{city ? `${city}, ${state}` : `${state}`}</p>
        </div>
      </div>
      <div className={styles.infoBox}>
        <CalendarIcon className={styles.calendarIcon} />
        {added && (
          <p className={styles.date}>
            {t("dashboard_qa_single_answered")} {format(new Date(added.split(" ").join("T")), "MMM do yyyy, h:mm a")}
          </p>
        )}
      </div>

      <p className={styles.text}>{answer}</p>
      <div className={styles.downLine} />
      <div className={styles.comments}>
        <Dropdown
          title={
            <div className={styles.dropdownButton}>
              <CommentIcon />
              <p className={styles.counter}>
                {comments.length ? <span className={styles.grey}>({comments.length})</span> : null}
                {t("dashboard_qa_single_comments")}
              </p>
            </div>
          }
          mainClass={styles.main}
          className={styles.dropdownComments}
        >
          {commentsRender}
          <p className="reply__title">{t("dashboard_qa_single_yourcomment")}</p>
          <Textarea reply buttonText={t("dashboard_qa_single_submitcomment")} onSubmit={onSubmitForm} onKeyDown={onEnterPress} />
        </Dropdown>
      </div>
    </div>
  );
};
