import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import styles from "./notification.module.css";
import { Button } from "../../common/button";
import { ReactComponent as NotificationsIcon } from "../../../media/icons/notifications.svg";
import { PopupNotification } from "../popups/notification";

export const Notification = ({ onCloseHandler, popup }) => {
  const { unread_notifications: unreadNotifications } = useSelector(state => state.account.profile);

  return (
    <div className={cn(styles.notificationWrap, Number(unreadNotifications) > 0 ? styles.counterNotification : null)}>
      <Button className={cn("button--clear", styles.popup)} onClick={onCloseHandler}>
        <NotificationsIcon />
      </Button>
      {popup === "notification" && <PopupNotification onCloseHandler={onCloseHandler} />}
    </div>
  );
};
