import React from "react";

import styles from "./educations.module.css";

const Educations = ({ educations }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Education</h3>
      <div>
        {educations.map(item => {
          return (
            <div className={styles.tile} key={item.edu_name}>
              <div className={styles.flex}>
                <div>
                  <h6 className={styles.tileTitle}>{item.edu_name}</h6>

                  <p className={styles.tileDescription}>
                    {item.edu_degree}, {item.edu_major}
                  </p>
                </div>
              </div>

              <span className={styles.tileStatus}>{item.edu_gradyear}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Educations;
