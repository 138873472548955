import React from "react";
import { useTranslation } from "react-i18next";

import { modalsName } from "../../../../constants/teams";
import Invite from "../invite";
import Accept from "../accept";
import Success from "../success";
import UpdateInvite from "../updateInvite";
import Remove from "../remove";
import ViewAccess from "../viewAccess";

const Modals = ({
  modals,
  inviteAccess,
  preloaders,
  clearAllModals,
  handleCloseModal,
  acceptAccess,
  activeAccess,
  handleOpenModal,
  rejectAccess,
  removeAccess,
  updateAccess
}) => {
  const { t } = useTranslation();

  const name = activeAccess.name_f || activeAccess.name_l ? `${activeAccess.name_f} ${activeAccess.name_l}` : activeAccess.member_name;

  return (
    <>
      {modals.invite && (
        <Invite
          onSubmit={inviteAccess}
          loading={preloaders.invite}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.invite)}
        />
      )}

      {modals.accept && (
        <Accept
          onSubmit={acceptAccess}
          loading={preloaders.accept}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.accept)}
          active={activeAccess}
          rejectAccess={rejectAccess}
        />
      )}

      {modals.inviteSuccess && (
        <Success
          title={t("dashboard_teams_invite_title")}
          text={t("dashboard_teams_invite_sent_msg")}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.inviteSuccess)}
        />
      )}

      {modals.updateInvite && (
        <UpdateInvite
          active={activeAccess}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.updateInvite)}
          handleRemoveAccess={() => {
            handleCloseModal(modalsName.updateInvite);
            handleOpenModal(modalsName.remove);
          }}
          onSubmit={updateAccess}
          loading={preloaders.update}
        />
      )}

      {modals.updateInviteSuccess && (
        <Success
          title="Update a Member"
          text="You’ve successfully updated a Member"
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.updateInviteSuccess)}
        />
      )}

      {modals.remove && (
        <Remove
          onSubmit={removeAccess}
          loading={preloaders.remove}
          active={activeAccess}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.remove)}
        />
      )}

      {modals.reject && (
        <Remove
          onSubmit={rejectAccess}
          loading={preloaders.reject}
          active={activeAccess}
          title={t("dashboard_teams_remove_title")}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.reject)}
        />
      )}
      {modals.rejectSuccess && (
        <Success
          title={t("dashboard_teams_remove_title")}
          text="You’ve successfully removed your access"
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.rejectSuccess)}
        />
      )}

      {modals.viewAccess && (
        <ViewAccess active={activeAccess} clearAllModals={clearAllModals} onClose={() => handleCloseModal(modalsName.viewAccess)} />
      )}

      {modals.removeSuccess && (
        <Success
          text={`You’ve successfully removed your access from you ${name} team`}
          clearAllModals={clearAllModals}
          onClose={() => handleCloseModal(modalsName.removeSuccess)}
        />
      )}
    </>
  );
};

export default Modals;
