import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";

import logo from "../../../media/icons/logo.svg";
import { OverlayPreloader } from "../../common/preloader";
import { actions } from "../../../actions/auth";
import { Button } from "../../common/button";
import Input from "../../common/input";
import { Notification } from "../../common/notification";
import { useDocTitle } from "../../../helpers/hooks";
import styles from "./verificationCode.module.css";

const VerificationCode = ({ history }) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const resetPassword = useCallback(email => dispatch(actions.verificationCode(email, history)), [dispatch, history]);
  const {
    errors,
    preloaders: { verificationCode: loading }
  } = useSelector(state => state.auth);

  const { t } = useTranslation();

  useDocTitle(t("dashboard_forgotpassword_metatitle"));

  const validate = ({ code }) => {
    const validationErrors = {};
    if (!code) {
      validationErrors.code = t("dashboard_common_required");
    }

    return validationErrors;
  };

  const formik = useFormik({
    initialValues: { code: "" },
    onSubmit: () => {
      if (errors.isNotExistEmail) {
        setShowError(true);
      } else {
        resetPassword(formik.values.code, history);
      }
    },
    validate,
    validateOnBlur: true
  });

  const { touched, values, handleSubmit } = formik;

  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };
  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (errors) {
      formik.setFieldError("code", errors.resetPassword);
    }
  }, [errors]); //eslint-disable-line

  return (
    <div className={styles.wrapper}>
      {loading && <OverlayPreloader />}
      {showError && (
        <Notification error close={() => setShowError(false)} timeToShow={400}>
          This verification code is not valid!
        </Notification>
      )}
      <div className="login-banner">
        <img className="login-banner__logo" src={logo} alt="" />
        <h1 className={cn("login-banner__title", styles.titleIndent)}>Reset Your Password</h1>
        <p className="login-banner__content">
          If you have an account with us, we just sent you an email with a verification code. Enter that code here to reset your password.
        </p>
      </div>

      <form className={styles.authForm} onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.mb30}
          error={formik.errors.code}
          id="code"
          isInvalid={formik.errors.code && touched.code}
          name="code"
          onBlur={e => handleBlur(e.target.name)}
          onChange={handleChange}
          label="Validation Code"
          required
          value={values.code}
        />

        <Button className="button--blue" type="submit">
          {t("dashboard_forgotpassword_button")}
        </Button>
        <div className={styles.receive}>
          Didn’t recieve the code yet?
          <Link to="/login/forgot-password" className={styles.link}>
            Resend it now
          </Link>
        </div>
        <hr className={styles.hr} />
        <Link to="/login" className={styles.link}>
          {t("dashboard_forgotpassword_back")}
        </Link>
      </form>
    </div>
  );
};

export default VerificationCode;
