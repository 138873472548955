import axios from "axios";

import { performance } from "../constants/actionTypes";
import { preloaderNames } from "../constants/performance";

function actionCreator(preloaderName, url, actionTypeSuccess, actionTypeError) {
  return dispatch => {
    dispatch(actions.setLoading(preloaderName, true));
    axios
      .get(url)
      .then(res => {
        if (res.data.toString().substr(0, 7) === "<!DOCTY") {
          dispatch({
            type: performance.GET_ERROR_DATA_FROM_SERVER,
            payload: "Wrong data from Server"
          });
          throw Error("Wrong data from Server");
        }

        dispatch({
          type: actionTypeSuccess,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: actionTypeError,
          payload: err
        })
      );
  };
}

export const actions = {
  getLeadsByYear() {
    return actionCreator(
      preloaderNames.leadsByYear,
      "/members/json-api/performance/leads-by-year",
      performance.GET_LEADS_BY_YEAR_SUCCESS,
      performance.GET_LEADS_BY_YEAR_FAIL
    );
  },

  getLeadsByListing() {
    return actionCreator(
      preloaderNames.leadsByListing,
      "/members/json-api/performance/leads-by-listings",
      performance.GET_LEADS_BY_LISTING_SUCCESS,
      performance.GET_LEADS_BY_LISTING_FAIL
    );
  },
  getLeadsByPracticeArea() {
    return actionCreator(
      preloaderNames.leadsByListing,
      "/members/json-api/performance/leads-by-practice",
      performance.GET_LEADS_BY_PRACTICE_AREA_SUCCESS,
      performance.GET_LEADS_BY_PRACTICE_AREA_FAIL
    );
  },

  getLeadsByMonth(year = 2018) {
    return actionCreator(
      preloaderNames.leadsByMonth,
      `/members/json-api/performance/leads-by-month?year=${year}`,
      performance.GET_LEADS_BY_MONTH_SUCCESS,
      performance.GET_LEADS_BY_MONTH_FAIL
    );
  },
  getLeadsByWeek(year) {
    return actionCreator(
      preloaderNames.leadsByWeek,
      `/members/json-api/performance/leads-by-week?year=${year}`,
      performance.GET_LEADS_BY_WEEK_SUCCESS,
      performance.GET_LEADS_BY_WEEK_FAIL
    );
  },
  getLeadsWeekByYear(year) {
    return actionCreator(
      preloaderNames.leadsByYear,
      `/members/json-api/performance/leads-by-week?year=${year}`,
      performance.GET_LEADS_BY_YEAR_SUCCESS,
      performance.GET_LEADS_BY_YEAR_FAIL
    );
  },
  getAllLeads() {
    return actionCreator(
      preloaderNames.allLeads,
      "/members/json-api/performance/all-leads",
      performance.GET_ALL_LEADS_SUCCESS,
      performance.GET_ALL_LEADS_FAIL
    );
  },
  getTrafficHistory() {
    return actionCreator(
      preloaderNames.trafficHistory,
      "/members/json-api/performance/traffic-history",
      performance.GET_TRAFFIC_HISTORY_SUCCESS,
      performance.GET_TRAFFIC_HISTORY_FAIL
    );
  },
  getTrafficSource() {
    return actionCreator(
      preloaderNames.trafficSource,
      "/members/json-api/performance/traffic-source",
      performance.GET_TRAFFIC_SOURCE_SUCCESS,
      performance.GET_TRAFFIC_SOURCE_FAIL
    );
  },
  getGeoChart() {
    return actionCreator(
      preloaderNames.geoChart,
      "/members/json-api/performance/geo-chart",
      performance.GET_GEO_CHART_SUCCESS,
      performance.GET_GEO_CHART_FAIL
    );
  },
  setLoading(section, isSectionLoading) {
    return {
      type: performance.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  }
};
