import React, { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { ReactComponent as DropdownIcon } from "../../../../media/icons/dropdown-arrow.svg";
import { FILTERS } from "../../../../constants/questionsAnswers";
import Checkbox from "../../../common/checkbox";
import styles from "./categories.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.categoriesMyAnswers.length === prevProps.activeFilters.categoriesMyAnswers.length;
};

export const CategoriesMyAnswers = React.memo(({ activeFilters, handleFilter, className }) => {
  const { myAnswersFilter } = useSelector(state => state.questions);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  if (!myAnswersFilter?.directories?.length) {
    return null;
  }

  const toggleDropdown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  return (
    <div className={styles.content}>
      <button type="button" className={styles.dropdownButton} onClick={toggleDropdown}>
        Network
        <DropdownIcon className={cn(styles.icon, isActiveDropdown ? styles.iconActive : "")} />
      </button>

      {isActiveDropdown && (
        <div className={cn(styles.dropdownContent, className)}>
          {myAnswersFilter.directories.map(({ dir_id: id, title }) => (
            <span key={id}>
              <Checkbox
                checked={activeFilters.categoriesMyAnswers.includes(String(id)?.toLowerCase())}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.CATEGORIES_MY_ANSWER, String(id)?.toLowerCase())}
                isNewStyles
                big
              >
                {title}
              </Checkbox>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}, areEqual);
