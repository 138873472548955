import React from "react";

import Location from "../../publicListing/location";
import styles from "./location.module.css";

const LocationContent = ({ address, parentCompany }) => {
  return (
    <div className={styles.content}>
      <Location parentCompany={parentCompany} address={address || {}} />
    </div>
  );
};

export default LocationContent;
