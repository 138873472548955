import React, { useCallback } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SidebarTile } from "../sidebarTile";
import { actions as mainActions } from "../../../actions/main";
import sideImage from "../../../media/images/publicPeople.png";
import styles from "./sidebar.module.css";

export const Sidebar = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { referral_requests_pending_import: referralRequestsPendingImport, referral_requests_count: referralRequestsCount } = useSelector(
    state => state.account.profile
  );

  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);
  const handleOpenNewListing = () => {
    toggleNewListing(true);
  };
  const isShowReferralRequestItem = Number(referralRequestsPendingImport) > 0 || Number(referralRequestsCount) > 0;

  return (
    <aside className={cn(styles.aside, className)}>
      <SidebarTile
        title={t("dashboard_sidebar_block1_title")}
        text={t("dashboard_sidebar_block1_description")}
        linkName={t("dashboard_sidebar_block1_button")}
        img={sideImage}
        isButton
        onClick={handleOpenNewListing}
      />

      {isShowReferralRequestItem && (
        <SidebarTile
          title={t("dashboard_sidebar_block2_title")}
          text={t("dashboard_sidebar_block2_description")}
          linkName={t("dashboard_sidebar_block2_button")}
          linkUrl="https://help.heritageweb.com/en/collections/2597815-paid-leads-service"
          isLink
        />
      )}
    </aside>
  );
};
