import React, { useState } from "react";
import cn from "classnames";

import { Button } from "../../../common/button";
import replyIcon from "../../../../media/icons/reply.svg";
import styles from "./textarea.module.css";

export const Textarea = ({ id, buttonText, className, reply, onSubmit, onKeyDown }) => {
  const [replyText, setReplyText] = useState("");

  const onSendText = () => {
    if (replyText) {
      onSubmit(replyText, id);
      setReplyText("");
    }
  };

  const handleOnKeyDown = e => {
    onKeyDown(e, onSendText);
  };

  return (
    <>
      <div className={styles.reply}>
        <textarea
          value={replyText}
          className={cn(styles.textarea, className)}
          onChange={e => setReplyText(e.target.value)}
          onKeyDown={handleOnKeyDown}
        />
        {reply && <img className={styles.icon} src={replyIcon} alt="" />}
      </div>
      <Button disabled={!replyText} className={styles.reply} onClick={onSendText}>
        {buttonText}
      </Button>
    </>
  );
};
