import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";
import queryString from "query-string";

import styles from "./pageNames.module.css";

const PageNames = ({ isListingsPage }) => {
  const history = useHistory();

  const searchParams = queryString.parse(history.location.search);

  return (
    <div className={cn(styles.main, isListingsPage ? styles.mainListings : "")}>
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/" exact>
        Feed
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/saved" exact>
        Saved
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/inbox" exact>
        {searchParams.tab === "archived" ? "Archived messages" : "All messages"}
      </NavLink>
    </div>
  );
};

export default PageNames;
