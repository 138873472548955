import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import format from "date-fns/format";

import { getStateName } from "../../../../helpers";
import styles from "./content.module.css";

const Content = ({ caseData, categoriesProfessions, states, isFailedEmailVerification }) => {
  const { t } = useTranslation();

  const practices = useMemo(
    () =>
      categoriesProfessions.practices
        ? categoriesProfessions.practices.filter(
            item =>
              item.practice_id === caseData.practice1 || item.practice_id === caseData.practice2 || item.practice_id === caseData.practice3
          )
        : [],
    [caseData, categoriesProfessions]
  );

  return (
    <>
      <hr className={styles.hr} />

      <div className={cn(styles.flexbox, styles.flexboxIndentChild, styles.relative)}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>NAME</h3>
          <p className={styles.textContent}>{`${caseData.name_f} ${caseData.name_l || ""}`}</p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>RECEIVED ON</h3>
          {caseData.added ? <p className={styles.textContent}>{format(new Date(caseData.added), "MM/dd/yyyy - h:mm a")}</p> : null}
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_common_field_email")}</h3>
          <p className={styles.textContent}>{caseData.email}</p>
          {isFailedEmailVerification && <p className={styles.errorEmail}>This email address is not verified</p>}
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>REQUEST ID</h3>
          <p className={styles.textContent}>{caseData.lead_id}</p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_common_phone")}</h3>
          <p className={styles.textContent}>{caseData.phone}</p>
        </div>
      </div>

      <hr className={styles.hrFullWidth} />

      <div>
        <div className={styles.headerBox}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_description")}</h3>
        </div>
        <p className={styles.textContent}>{caseData.summary}</p>
      </div>

      <hr className={styles.hrFullWidth} />

      <div className={styles.flexbox}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_location")}</h3>
          <p className={styles.textContent}>
            {caseData.city}, {getStateName(states, caseData.state)}
          </p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_specialties")}</h3>
          {practices.map(item => {
            return (
              <p className={styles.textContent} key={item.practice_id}>
                {item.title}
              </p>
            );
          })}
        </div>
      </div>

      <hr className={cn(styles.hrFullWidth, styles.mt30)} />
    </>
  );
};

export default Content;
