import axios from "axios";
// import { isToday, isYesterday } from "date-fns";

// eslint-disable-next-line import/no-cycle
import { main, auth, account } from "../constants/actionTypes";
// eslint-disable-next-line import/no-cycle
import { actions as profileActions } from "./account/profile";

export const actions = {
  getCountriesAndStates() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/index/countries-states",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(countriesStates => {
          dispatch({
            type: main.GET_UTILITY_LISTING,
            payload: {
              countries: countriesStates.data.countries,
              states: countriesStates.data.states
            }
          });
        })
        .catch(error => {
          dispatch({
            type: main.GET_UTILITY_LISTING_FAIL,
            payload: error
          });
          console.error("getMainInfo", error);
        });
    };
  },
  getProfileImage() {
    return dispatch => {
      axios
        .get("/members/api-v2/my-account/get?command=picture")
        .then(res => {
          dispatch({
            type: main.GET_PROFILE_IMAGE,
            payload: res.data.data
          });
        })
        .catch(err => {
          dispatch({
            type: main.GET_PROFILE_IMAGE_FAIL,
            payload: err
          });
        });
    };
  },

  getFooterInfo() {
    return dispatch => {
      axios
        .get("/members/api-v2/directory/get?command=links")
        .then(res => {
          dispatch({
            type: main.GET_FOOTER_INFO,
            payload: {
              footerInfo: res.data.data.footer_links,
              socialLinks: res.data.data.social_links
            }
          });
        })
        .catch(err => {
          dispatch({
            type: main.GET_FOOTER_INFO_FAIL,
            payload: err
          });
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: main.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  getLoginStatus() {
    return dispatch => {
      axios("/members/json-api/login")
        .then(res => {
          dispatch({ type: main.GET_LOGIN, payload: res.data });
          dispatch({ type: auth.LOGIN_SUCCESS, payload: res.data });
        })
        .catch(error => {
          console.error("error", error.message);
          dispatch({ type: main.GET_LOGIN_FAIL, payload: false });
        });
    };
  },

  sendEmailCode(email) {
    return dispatch => {
      axios({
        method: "post",
        url: "members/json-api/verify/send-email-code",
        data: { email },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: main.SEND_EMAIL_VERIFY_CODE_SUCCESS });
        })
        .catch(() => dispatch({ type: main.SEND_EMAIL_VERIFY_CODE_FAIL }));
    };
  },

  checkEmailCode(code, setStatus) {
    return dispatch => {
      dispatch(this.setLoading("verifyEmailCode", true));
      axios({
        method: "post",
        url: "members/json-api/verify/check-email-code",
        data: { code },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: main.CHECK_EMAIL_VERIFY_CODE_SUCCESS });
          dispatch(profileActions.getProfile());
        })
        .catch(err => {
          if (err?.response?.status === 404) {
            setStatus({ code: "Wrong code" });
          } else {
            setStatus({ code: "Something went wrong" });
          }
          dispatch({ type: main.CHECK_EMAIL_VERIFY_CODE_FAIL });
        });
    };
  },

  logout() {
    return {
      type: auth.LOGOUT
    };
  },

  setViewNotification(ids) {
    return async dispatch => {
      // dispatch(this.setLoading("notifications", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/view",
        data: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: account.MARK_READ_NOTIFICATION,
            payload: ids
          });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  setUnViewNotification(ids) {
    return dispatch => {
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/unview",
        data: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: account.MARK_UN_READ_NOTIFICATION,
            payload: ids
          });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  getCommunities() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "/members/api-v2/communities?command=tree"
      })
        .then(res => {
          dispatch({
            type: main.GET_COMMUNITIES,
            payload: res.data.data
          });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  setViewAllNotification() {
    return dispatch => {
      // dispatch(this.setLoading("notifications", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/view-all",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: account.MARK_READ_ALL_NOTIFICATION
          });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  togglePaymentError(isActive, errorMessage) {
    return {
      type: main.TOGGLE_PAYMENT_ERROR,
      payload: {
        isActive,
        errorMessage
      }
    };
  },

  toggleNewListing(isActive, listingType = "") {
    return {
      type: main.TOGGLE_NEW_LISTING,
      payload: {
        isActive,
        listingType
      }
    };
  },

  toggleMobileMenu(isActive) {
    return {
      type: main.TOGGLE_MOBILE_MENU,
      payload: {
        isActive
      }
    };
  }
};
