import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { OverlayPreloader } from "../common/preloader";
import { Search } from "./search";
import SubTabs from "./subTabs";
import Filters from "./filters";
import List from "./list";
import Pagination from "./pagination";
import Modals from "./modals";
import SelectTeam from "../selectTeam";
import SelectInput from "../common/selectInput";
import MainTabs from "./mainTabs";
import styles from "./payPerLead.module.css";
import { usePayPerLeadState, usePayPerLeadFilters, usePayPerLeadEffects, usePayPerLeadActions } from "./hooks";

const PayPerLead = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const { filters, loading, leads, pagination, tabsCounter } = useSelector(state => state.payPerLead);
  const { directories, practices } = filters;
  const { filters: filtersReferrals, preloaders: preloadersReferrals } = useSelector(state => state.referralRequests);
  const { directories: directoriesReferrals, practices: practicesReferrals } = filtersReferrals;
  const { modals, lead, coupon, success, error: errorBuyLead } = useSelector(state => state.payPerLeadDetail);

  const {
    leadsList,
    setLeadsList,
    search,
    setSearch,
    filtrationByDate,
    setFiltrationByDay,
    perPageLead,
    setPerPageLead,
    notification,
    setNotification,
    error,
    setError,
    activeFilter,
    setActiveFilter,
    team,
    setTeam,
    sortOptions
  } = usePayPerLeadState();

  const {
    listActivePractice,
    setListActivePractice,
    listActiveNetwork,
    setListActiveNetwork,
    currentPractices,
    currentDirectories
  } = usePayPerLeadFilters(practicesReferrals, practices, directoriesReferrals, directories);

  const { resetAllSuccess, handleRemoveReferrals, handleFiltrationSearchLeads, onDateFiltrationLead } = usePayPerLeadActions({
    dispatch,
    history,
    searchParams,
    setSearch,
    setFiltrationByDay
  });

  usePayPerLeadEffects({
    setListActiveNetwork,
    setListActivePractice,
    listActiveNetwork,
    setLeadsList,
    setNotification,
    setError,
    listActivePractice,
    filtrationByDate,
    searchValue: search,
    perPageLead,
    team,
    searchParams
  });

  return (
    <div className={styles.wrapper}>
      {(loading || preloadersReferrals.preloaderCase) && <OverlayPreloader />}
      <h1 className={styles.mainTitle}>Business referrals</h1>

      <MainTabs />

      <Search handleFiltrationSearch={handleFiltrationSearchLeads} search={search}>
        <SelectTeam handleChange={setTeam} unselectText="My Referrals" className={styles.team} />

        <SelectInput
          classNameWrap={styles.selectWrap}
          className={styles.selectLatest}
          name="latest"
          placeholder="Most Recent"
          defaultValue={{ value: "", label: t("dashboard_ppl_sortdropdown_default") }}
          onChange={onDateFiltrationLead}
          options={sortOptions}
          value={filtrationByDate}
        />
      </Search>

      <div className={styles.container}>
        <div className={styles.tabNavContent}>
          <div>
            <div className={styles.flex}>
              <SubTabs tabsCounter={tabsCounter} />

              <Filters
                setActiveFilter={setActiveFilter}
                activeFilter={activeFilter}
                options={sortOptions}
                practices={currentPractices}
                listActivePractice={listActivePractice}
                setListActivePractice={setListActivePractice}
                filtersReferrals={filtersReferrals}
                filters={filters}
                filtrationByDate={filtrationByDate}
                setFiltrationByDay={setFiltrationByDay}
                searchParams={searchParams}
                directories={currentDirectories}
                listActiveNetwork={listActiveNetwork}
                setListActiveNetwork={setListActiveNetwork}
                activeSort=""
                leadsList={leadsList}
              />
            </div>

            <div>
              <List leadsList={leadsList} practices={practices} handleRemoveReferrals={handleRemoveReferrals} team={team} />

              <Pagination
                setPerPageLead={setPerPageLead}
                searchParams={searchParams}
                history={history}
                pagination={pagination}
                leadsLength={leads.length}
              />
            </div>
          </div>
          <Modals
            error={error}
            notification={notification}
            success={success}
            errorBuyLead={errorBuyLead}
            lead={lead}
            coupon={coupon}
            modals={modals}
            practices={practices}
            resetAllSuccess={resetAllSuccess}
          />
        </div>
      </div>
    </div>
  );
};

export default PayPerLead;
