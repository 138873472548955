import axios from "axios";
import * as qs from "qs";

import { payPerLeadDetail } from "../constants/actionTypes";
import { actions as mainActions } from "./main";
import { trackDataLayer } from "../helpers/track";
import { eventNameDataLayer } from "../constants/track";

export const actions = {
  checkCoupon({ leadId, couponCode }) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      dispatch(this.checkCouponLoading());
      axios({
        method: "post",
        responseType: "json",
        headers: { "Content-Type": "application/json" },
        url: `/members/json-api${teamUrl}/lead/check-coupon`,
        data: { lead_id: leadId, coupon: couponCode, ...userIdParams }
      })
        .then(res => {
          dispatch({
            type: payPerLeadDetail.CHECK_COUPON,
            payload: {
              code: couponCode,
              price: res.data.price,
              discountType: res.data.discount_type,
              discountUsd: res.data.discount_usd,
              discount: res.data.discount
            }
          });
        })
        .catch(err =>
          dispatch({
            type: payPerLeadDetail.CHECK_COUPON_FAIL,
            payload: err.response
          })
        );
    };
  },

  buyLead(leadId, token, isPurchased, trackingData) {
    return (dispatch, getState) => {
      const {
        payPerLeadDetail: { coupon },
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const { price, website_url: websiteUrl, name_f, name_l, city, state, country, practicesTitle, singular, _heritage } = trackingData;
      dispatch(this.setLoading());
      dispatch(mainActions.togglePaymentError(false, ""));

      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/purchase-with-card`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          // eslint-disable-next-line radix
          lead_id: parseInt(leadId),
          token,
          coupon: coupon.code || "",
          ...userIdParams
        }
      })
        .then(() => {
          dispatch({
            type: payPerLeadDetail.BUY_LEAD
          });
          dispatch(this.getLead(isPurchased, leadId));
          new Promise(resolve => {
            resolve();
          }).then(() => {
            dispatch({
              type: payPerLeadDetail.RESET_SUCCESS,
              payload: "buyLead"
            });
            dispatch({
              type: payPerLeadDetail.BUY_LEAD
            });
          });
        })
        .catch(error => {
          new Promise(resolve => {
            dispatch({
              type: payPerLeadDetail.BUY_LEAD_FAIL,
              payload: error?.response?.data?.error ? error.response.data.error : "Something went wrong!"
            });
            trackDataLayer(eventNameDataLayer.leadSale, {
              heritage: _heritage,
              profession: singular,
              directory: websiteUrl,
              specialty: practicesTitle.toString(),
              country,
              state,
              city,
              ecommerce: {
                currency: "USD",
                value: 25,
                transaction_id: "p115-20202000",
                coupon: "free_back_rub",
                items: [
                  {
                    item_name: `${name_f} ${name_l}`,
                    item_id: leadId,
                    price,
                    item_category: "listing_upgrade",
                    quantity: "1"
                  }
                ]
              }
            });
            resolve();
          }).then(() => {
            dispatch({
              type: payPerLeadDetail.RESET_ERROR
            });
          });
        });
    };
  },

  hideLead(leadId) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      dispatch(this.setLoading());
      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/hide`,
        headers: {
          "Content-Type": "application/json"
        },
        // eslint-disable-next-line radix
        data: { lead_id: parseInt(leadId), ...userIdParams }
      })
        .then(() => {
          dispatch({
            type: payPerLeadDetail.HIDE_LEAD
          });
          new Promise(resolve => {
            resolve();
          }).then(() => {
            dispatch({
              type: payPerLeadDetail.RESET_SUCCESS,
              payload: "hideLead"
            });
          });
        })
        .catch(err => {
          new Promise(resolve => {
            dispatch({
              type: payPerLeadDetail.HIDE_LEAD_FAIL,
              payload: err?.response?.data?.error ? err.response.data.error : "Something went wrong"
            });
            resolve();
          }).then(() => {
            dispatch({
              type: payPerLeadDetail.RESET_ERROR
            });
          });
        });
    };
  },

  getLead(type, leadId, userId) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || userId ? "/team" : "";
      const userIdParams = currentTeam?.value || userId ? { user_id: currentTeam.value || userId } : {};

      dispatch(this.setLoading());
      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/${type}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({ lead_id: leadId, ...userIdParams })
      })
        .then(res => {
          dispatch({
            type: payPerLeadDetail.GET_LEAD,
            payload: res.data
          });
        })
        .catch(err =>
          dispatch({
            type: payPerLeadDetail.GET_LEAD_FAIL,
            payload: err.response
          })
        );
    };
  },

  getPractices() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/lead"
      })
        .then(res => {
          dispatch({
            type: payPerLeadDetail.GET_PRACTICES,
            payload: res.data?.filters?.practices
          });
        })
        .catch(err =>
          dispatch({
            type: payPerLeadDetail.GET_PRACTICES_FAIL,
            payload: err.response
          })
        );
    };
  },

  setLeadClear() {
    return {
      type: payPerLeadDetail.CLEAR_LEAD
    };
  },

  setLead(lead) {
    return {
      type: payPerLeadDetail.GET_LEAD,
      payload: lead
    };
  },

  setLoading() {
    return {
      type: payPerLeadDetail.SET_LOADING
    };
  },

  toggleModal(modal, isActive) {
    return {
      type: payPerLeadDetail.TOGGLE_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  clearAllModals() {
    return {
      type: payPerLeadDetail.CLEAR_ALL_MODALS
    };
  },

  checkCouponClear() {
    return {
      type: payPerLeadDetail.CHECK_COUPON_CLEAR
    };
  },

  checkCouponLoading() {
    return {
      type: payPerLeadDetail.CHECK_LOADING_LOADING
    };
  },

  resetAllSuccess() {
    return {
      type: payPerLeadDetail.RESET_ALL_SUCCESS
    };
  }
};
