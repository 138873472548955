import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useDispatch } from "react-redux";

import { getQueryParams } from "../../../helpers";
import { modalsName } from "../../../constants/account";
import { actions as listingActions } from "../../../actions/listing";
import { modals as modalsConstant } from "../../../constants/listings";
import styles from "./verifications.module.css";

const Verifications = ({ emailVerified, phoneVerified, email, phone, keycloak, openModal }) => {
  const isEmailVerified = emailVerified === true || emailVerified === "true";
  const isPhoneVerified = phoneVerified === true || phoneVerified === "true";
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useMemo(() => getQueryParams(history.location.search), [history.location]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));

  const openModalListing = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const handleChangeEmail = () => {
    if (keycloak.tokenParsed.acr !== "pwdcnf") {
      keycloak.login({ redirectUri: `${window.location.origin}/account?type=${modalsName.changeEmail}`, acrValues: "pwdcnf" });
    } else {
      openModal(modalsName.changeEmail);
    }
  };

  const handleChangePhone = () => {
    if (keycloak.tokenParsed.acr !== "pwdcnf") {
      keycloak.login({ redirectUri: `${window.location.origin}/account?type=${modalsName.changePhone}`, acrValues: "pwdcnf" });
    } else {
      openModal(modalsName.changePhone);
    }
  };

  const handleAddPhone = () => {
    if (keycloak.tokenParsed.acr !== "pwdcnf") {
      keycloak.login({ redirectUri: `${window.location.origin}/account?type=${modalsName.addPhone}`, acrValues: "pwdcnf" });
    } else {
      openModal(modalsName.addPhone);
    }
  };
  const handleRemovePhone = () => {
    if (keycloak.tokenParsed.acr !== "pwdcnf") {
      keycloak.login({ redirectUri: `${window.location.origin}/account?type=${modalsName.removePhone}`, acrValues: "pwdcnf" });
    } else {
      openModal(modalsName.removePhone);
    }
  };

  useEffect(() => {
    if (keycloak.tokenParsed.acr === "pwdcnf") {
      if (queryParams.type === modalsName.changeEmail) {
        openModal(modalsName.changeEmail);
        history.replace({
          pathname: history.location.pathname,
          search: ""
        });
      } else if (queryParams.type === modalsName.changePhone) {
        openModal(modalsName.changePhone);
        history.replace({
          pathname: history.location.pathname,
          search: ""
        });
      } else if (queryParams.type === modalsName.addPhone) {
        openModal(modalsName.addPhone);
        history.replace({
          pathname: history.location.pathname,
          search: ""
        });
      } else if (queryParams.type === modalsName.removePhone) {
        openModal(modalsName.removePhone);
        history.replace({
          pathname: history.location.pathname,
          search: ""
        });
      }
    }

    if (queryParams.type === modalsName.changePasswordSuccess) {
      openModal(modalsName.changePasswordSuccess);
      history.replace({
        pathname: history.location.pathname,
        search: ""
      });
    }
  }, []);

  return (
    <div className={styles.verificationsContent}>
      <div className={styles.verificationItem}>
        <div>
          <div className={styles.inputFlex}>
            <div className={styles.inputHeading}>
              <p className={styles.inputLabel}>{t("dashboard_common_field_email")}</p>
              {isEmailVerified ? (
                <span className={styles.verified}>{t("dashboard_common_verified")}</span>
              ) : (
                <span className={styles.notVerified}>{t("dashboard_common_notverified")}</span>
              )}
            </div>

            {!isEmailVerified && (
              <button
                type="button"
                className={cn(styles.blueButton, styles.blueButtonPhone)}
                onClick={() => openModalListing(modalsConstant.verification)}
              >
                {t("dashboard_home_verifyemail_modal_verifybtn")}
              </button>
            )}

            {email && (
              <button type="button" className={styles.blueButton} onClick={handleChangeEmail}>
                {t("dashboard_account_changeemail")}
              </button>
            )}
          </div>

          <div className={styles.defaultValue}>{email}</div>
        </div>
      </div>
      <div className={styles.verificationItem}>
        <div>
          <div className={styles.inputFlex}>
            <div className={styles.inputHeading}>
              <p className={styles.inputLabel}>{t("dashboard_account_phonenumber")}</p>
              {phone && (
                <>
                  {isPhoneVerified ? (
                    <span className={styles.verified}>{t("dashboard_common_verified")}</span>
                  ) : (
                    <span className={styles.notVerified}>{t("dashboard_common_notverified")}</span>
                  )}
                </>
              )}
            </div>

            {phone && isPhoneVerified && (
              <>
                <button type="button" className={cn(styles.blueButton, styles.blueButtonPhone)} onClick={handleRemovePhone}>
                  {t("dashboard_account_phone_remove_button")}
                </button>
                <button type="button" className={styles.blueButton} onClick={handleChangePhone}>
                  {t("dashboard_account_phone_change_title")}
                </button>
              </>
            )}

            {!isPhoneVerified && phone && (
              <button type="button" className={styles.blueButton} onClick={handleAddPhone}>
                {t("dashbaprd_common_add")}
              </button>
            )}

            {!isPhoneVerified && !phone && (
              <button type="button" className={styles.blueButton} onClick={handleAddPhone}>
                {t("dashbaprd_common_add")}
              </button>
            )}
          </div>
          <div className={styles.defaultValue}>{phone}</div>
        </div>
      </div>
    </div>
  );
};

export default Verifications;
