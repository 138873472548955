import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as IconCheck } from "../../../../media/icons/check-green.svg";
import styles from "../../pricing.module.css";

const PricingPlanFree = () => {
  const [activeList, setActiveList] = useState(false);
  const { t } = useTranslation();

  const toggleList = () => {
    setActiveList(prev => !prev);
  };

  return (
    <div className={styles.pricingItem}>
      <h2 className={styles.pricingTitle}>{t("dashboard_pricing_free_title")}</h2>
      <span className={styles.pricingType}>{t("dashboard_pricing_listing")}</span>
      <span className={styles.pricingPrice}>$0</span>
      <span className={styles.pricingPeriod}>{t("dashboard_pricing_free_subtitle")}</span>

      <div className={cn(styles.pricingIncludes, activeList ? styles.pricingIncludesActive : "")}>
        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits1_title")}</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet4")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits2_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet4")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits3_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits3_bullet1")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits4_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet4")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet5")}
        </p>
      </div>
      <div className={styles.pricingButtonContent}>
        <button className={styles.pricingHide} type="button" onClick={toggleList}>
          {activeList ? "Hide features" : "See all features"}
        </button>
        <Link to="/login" className={styles.pricingButtonBlue}>
          Become a member
        </Link>
      </div>
    </div>
  );
};

export default PricingPlanFree;
