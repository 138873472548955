import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import Input from "../../../common/input";
import Checkbox from "../../../common/checkbox";
import ModalControl from "../../../modalControl";
import styles from "./updateInvite.module.css";

const UpdateInvite = ({ clearAllModals, onClose, handleRemoveAccess, active, onSubmit, loading }) => {
  const { name_f, name_l, member_name, email, member_email, listings, inbox, referrals, qa, payments } = active;
  const activeOptions = { listings, inbox, referrals, qa, payments };
  const activePermission = Object.keys(activeOptions).filter(item => Boolean(activeOptions[item]));
  const { t } = useTranslation();

  const options = [
    { value: "listings", text: t("dashboard_teams_invite_option_1") },
    { value: "inbox", text: t("dashboard_teams_invite_option_2") },
    { value: "referrals", text: t("dashboard_teams_invite_option_3") },
    { value: "qa", text: t("dashboard_teams_invite_option_4") },
    { value: "payments", text: t("dashboard_teams_invite_option_5") }
  ];

  const formik = useFormik({
    initialValues: {
      email: member_email || email,
      name: name_f || name_l ? `${name_f} ${name_l}` : member_name,
      permissions: activePermission
    },
    onSubmit: values => {
      const normalizeOptions = options.reduce((total, current) => {
        return { ...total, [current.value]: values.permissions.includes(current.value) ? 1 : 0 };
      }, {});

      onSubmit({ team_member_id: active.team_member_id, ...normalizeOptions });
    }
  });

  const { values, errors, touched, handleSubmit, setFieldTouched } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    setFieldTouched(e.target.name, true);
  };

  const handleChangeCheckbox = name => {
    const isIncludes = values.permissions.includes(name);
    const newPermissions = isIncludes ? values.permissions.filter(item => item !== name) : [...values.permissions, name];

    formik.setFieldValue("permissions", newPermissions);
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <span className={styles.label}>{t("dashboard_teams_myteam_title")}</span>
      <h3 className={styles.title}>Update Member</h3>

      <form onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.modalInput}
          error={errors.name}
          id="name"
          isInvalid={errors.name && touched.name}
          name="name"
          type="text"
          label={t("dashboard_teams_invite_fieldtitle_name")}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
          placeholder={t("dashboard_teams_invite_fieldinput_name")}
          disabled
        />
        <Input
          error={errors.email}
          id="email"
          isInvalid={errors.email && touched.email}
          name="email"
          label={t("dashboard_teams_invite_fieldtitle_email")}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          placeholder={t("dashboard_teams_invite_fieldtitle_email")}
          disabled
        />
      </form>

      <div className={styles.permission}>
        <h5 className={styles.permissionTitle}>Permissions</h5>

        <div>
          {options.map(item => {
            return (
              <Checkbox
                key={item.value}
                isNewStyles
                big
                checked={values.permissions.includes(item.value)}
                className={styles.checkbox}
                id={item.value}
                name={item.value}
                onChange={() => handleChangeCheckbox(item.value)}
              >
                <span className={styles.checkboxName}>{item.text}</span>
              </Checkbox>
            );
          })}
        </div>
      </div>

      <button type="button" className={styles.remove} onClick={handleRemoveAccess}>
        {t("dashboard_teams_remove_title")}
      </button>

      <ModalControl onExit={onClose} confirmationType="button" onConfirm={formik.submitForm}>
        Send Invite
      </ModalControl>
    </Modal>
  );
};

export default UpdateInvite;
