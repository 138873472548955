import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import queryString from "query-string";

import styles from "./filterMessage.module.css";
import { Radio } from "../../../common/radio";
import { TABS, SORT } from "../../../../constants/messages";

const FilterMessage = ({ onClose, history, team, getMessages, setIsFirstLoading, handleClearAll, sort, setSort }) => {
  const [activeFilterType, setActiveFilterType] = useState("");
  const searchParams = queryString.parse(history.location.search);

  const onFilter = () => {
    if (activeFilterType === TABS.ARCHIVED || activeFilterType === TABS.ALL) {
      const activeTab = activeFilterType === TABS.ALL || activeFilterType === TABS.ARCHIVED ? activeFilterType : "";

      history.push({
        pathname: "/inbox",
        search: queryString.stringify({
          ...searchParams,
          tab: activeTab,
          isRead: "0"
        }),
        state: {
          update: true
        }
      });

      getMessages(searchParams.messageId, activeFilterType === TABS.ARCHIVED, team, false, sort);
      setIsFirstLoading(true);
      onClose();
    }

    if (activeFilterType === TABS.UNREAD) {
      history.push({
        pathname: "/inbox",
        search: queryString.stringify({
          ...searchParams,
          tab: TABS.ALL,
          isRead: "1"
        }),
        state: {
          update: true
        }
      });
      getMessages(searchParams.messageId, false, team, true, sort);
      onClose();
    }

    if (sort) {
      history.push({
        pathname: "/inbox",
        search: queryString.stringify({
          ...searchParams,
          tab: TABS.ALL,
          isRead: "0"
        }),
        state: {
          update: true
        }
      });
      getMessages(searchParams.messageId, false, team, false, sort);
      onClose();
    }
  };

  useEffect(() => {
    if (searchParams.tab && !activeFilterType && (searchParams.tab === TABS.ALL || searchParams.tab === TABS.ARCHIVED) && !sort) {
      setActiveFilterType(searchParams.tab);
    }
    if (searchParams.isRead && String(searchParams.isRead) === "1" && !activeFilterType && !sort) {
      setActiveFilterType(TABS.UNREAD);
    }
  }, []);

  const linkActiveArchive = activeFilterType === TABS.ARCHIVED ? styles.linkActive : "";
  const linkActiveAll = activeFilterType === TABS.ALL ? styles.linkActive : "";
  const linkUnread = activeFilterType === TABS.UNREAD ? styles.linkActive : "";

  const handleSort = type => {
    setSort(type);
    setActiveFilterType("");
  };

  const handleSetActiveFilterType = type => {
    setSort("");
    setActiveFilterType(type);
  };

  const isDisableClearAllButton = useMemo(() => {
    return activeFilterType === TABS.ALL;
  }, [activeFilterType]);

  return (
    <div className={styles.content}>
      <h2 className={styles.subTitle}>Filter</h2>

      <Radio
        mainClass={styles.indent}
        className={cn("button--clear", styles.link, linkActiveAll)}
        checked={activeFilterType === TABS.ALL}
        onChange={() => handleSetActiveFilterType(TABS.ALL)}
        iconClass={styles.icon}
      >
        <span>All</span>
      </Radio>
      <Radio
        iconClass={styles.icon}
        mainClass={styles.indent}
        className={cn("button--clear", styles.link, linkActiveArchive)}
        checked={activeFilterType === TABS.ARCHIVED}
        onChange={() => handleSetActiveFilterType(TABS.ARCHIVED)}
      >
        <span>Archive</span>
      </Radio>

      <Radio
        iconClass={styles.icon}
        mainClass={styles.indent}
        className={cn("button--clear", styles.link, linkUnread)}
        checked={activeFilterType === TABS.UNREAD}
        onChange={() => handleSetActiveFilterType(TABS.UNREAD)}
      >
        <span className={styles.checkboxValue}>Unread</span>
      </Radio>

      <div>
        <h2 className={styles.subTitle}>Sort by</h2>

        <Radio
          mainClass={styles.indent}
          className={cn("button--clear", styles.link, sort === SORT.desc ? styles.linkActive : "")}
          checked={sort === SORT.desc}
          onChange={() => handleSort(SORT.desc)}
          iconClass={styles.icon}
        >
          <span>Latest</span>
        </Radio>
        <Radio
          iconClass={styles.icon}
          mainClass={styles.indent}
          className={cn("button--clear", styles.link, sort === SORT.asc ? styles.linkActive : "")}
          checked={sort === SORT.asc}
          onChange={() => handleSort(SORT.asc)}
        >
          <span>Oldest</span>
        </Radio>
      </div>

      <div className={styles.footer}>
        <button type="button" disabled={isDisableClearAllButton} className={styles.clear} onClick={handleClearAll}>
          Clear all
        </button>
        <button type="button" className={styles.apply} onClick={onFilter}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterMessage;
