import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";

import Input from "../../common/input";
import SelectInput from "../../common/selectInput";
import { renderSelectCountries, renderSelectStates, getCountryNameFromCode, getStateNameFromCode } from "../../../helpers/select";
import styles from "./location.module.css";

const Location = ({ handleChangeSelect, values, handleChange, className }) => {
  const { t } = useTranslation();
  const { countries, states } = useSelector(state => state.main);

  const getCountryValue = countryValue => {
    if (typeof countryValue === "string") {
      return [{ value: countryValue, label: getCountryNameFromCode(countries, countryValue) }];
    }

    return [countryValue];
  };

  const getStateValue = stateValue => {
    if (typeof stateValue === "string") {
      return [{ value: stateValue, label: getStateNameFromCode(states, stateValue) }];
    }

    return [stateValue];
  };

  return (
    <div className={cn(styles.location, className)}>
      <div className={styles.item}>
        <SelectInput
          classNameWrap={styles.select}
          label="Country"
          name="country"
          placeholder={t("dashboard_common_dropdown_select")}
          onChange={handleChangeSelect}
          options={renderSelectCountries(countries)}
          value={values.country?.value ? getCountryValue(values.country?.value) : ""}
        />
        <SelectInput
          classNameWrap={styles.select}
          name="state"
          noOptionsMessage={() => t("dashboard_referrals_select_country_first")}
          label="State"
          placeholder={t("dashboard_common_dropdown_select")}
          onChange={handleChangeSelect}
          options={values.country?.value && states?.length && renderSelectStates(values.country.value, states)}
          value={values.state.value ? getStateValue(values.state.value) : ""}
        />
      </div>
      <div className={styles.item}>
        <Input
          className={styles.inputField}
          classNameWrap={styles.input}
          label={t("dashboard_common_field_city")}
          onChange={handleChange}
          name="city"
          type="text"
          value={values.city}
          placeholder="Enter City"
        />
        <Input
          className={styles.inputField}
          classNameWrap={styles.input}
          label={t("dashboard_common_field_zipcode")}
          onChange={handleChange}
          name="zip"
          type="text"
          value={values.zip}
          placeholder="Enter Zip Code"
        />
      </div>
    </div>
  );
};

export default Location;
