import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { email as emailRegex } from "../../../../constants/regex";
import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import Input from "../../../common/input";
import Checkbox from "../../../common/checkbox";
import ModalControl from "../../../modalControl";
import styles from "./invite.module.css";

const Invite = ({ clearAllModals, onClose, loading, onSubmit }) => {
  const { t } = useTranslation();

  const options = [
    { value: "listings", text: t("dashboard_teams_invite_option_1") },
    { value: "inbox", text: t("dashboard_teams_invite_option_2") },
    { value: "referrals", text: t("dashboard_teams_invite_option_3") },
    { value: "qa", text: t("dashboard_teams_invite_option_4") },
    { value: "payments", text: t("dashboard_teams_invite_option_5") }
  ];

  const validate = values => {
    const { name, email } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");
    const invalidUrlText = t("dashboard_form_validations_invalid_email");

    if (!name) {
      errors.name = requiredText;
    }

    if (!email) {
      errors.email = requiredText;
    }

    if (email && !email.match(emailRegex)) {
      errors.email = invalidUrlText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      permissions: []
    },
    onSubmit: values => {
      const normalizeOptions = options.reduce((total, current) => {
        return { ...total, [current.value]: values.permissions.includes(current.value) ? 1 : 0 };
      }, {});

      onSubmit({ email: values.email, name: values.name, ...normalizeOptions });
    },
    validate
  });

  const { values, errors, touched, handleSubmit, setFieldTouched, setFieldValue } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    setFieldTouched(e.target.name, true);
  };

  const handleChangeCheckbox = name => {
    const isIncludes = values.permissions.includes(name);
    const newPermissions = isIncludes ? values.permissions.filter(item => item !== name) : [...values.permissions, name];

    formik.setFieldValue("permissions", newPermissions);
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}

      <span className={styles.label}>{t("dashboard_teams_myteam_title")}</span>
      <h3 className={styles.title}>{t("Invite a New Member")}</h3>

      <form onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.modalInput}
          error={errors.name}
          id="name"
          isInvalid={errors.name && touched.name}
          name="name"
          type="text"
          label={t("Name")}
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.name}
          placeholder={t("dashboard_teams_invite_fieldinput_name")}
        />
        <Input
          error={errors.email}
          id="email"
          isInvalid={errors.email && touched.email}
          name="email"
          label={t("dashboard_teams_invite_fieldtitle_email")}
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.email}
          placeholder={t("dashboard_teams_invite_fieldinput_email")}
        />
      </form>

      <div className={styles.permission}>
        <h5 className={styles.permissionTitle}>Permissions</h5>

        <div>
          {options.map(item => {
            return (
              <Checkbox
                key={item.value}
                isNewStyles
                big
                checked={values.permissions.includes(item.value)}
                className={styles.checkbox}
                id={item.value}
                name={item.value}
                onChange={() => handleChangeCheckbox(item.value)}
              >
                <span className={styles.checkboxName}>{item.text}</span>
              </Checkbox>
            );
          })}
        </div>
      </div>

      <ModalControl onExit={onClose} confirmationType="submit" onConfirm={formik.submitForm}>
        Send Invite
      </ModalControl>
    </Modal>
  );
};

export default Invite;
