import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { ReactComponent as PrevCalendar } from "../../../../media/icons/previous.svg";
import styles from "./prev.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.prevQuestionId === prevProps.prevQuestionId && nextProps.tab === prevProps.tab;
};

export const Prev = React.memo(({ prevQuestionId, tab }) => {
  if (!prevQuestionId) {
    return null;
  }

  return (
    <Link className={cn("button--clear", styles.previousIcon)} to={`/question-answer/${prevQuestionId}${tab ? `?tab=${tab}` : ""}`}>
      <PrevCalendar />
    </Link>
  );
}, areEqual);
