import React from "react";

import ModalControl from "../../../modalControl";
import { ReactComponent as SuccessIcon } from "../../../../media/icons/successIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../../media/icons/arrow-right.svg";
import { Modal } from "../../../common/modal";
import styles from "./questionFormSuccess.module.css";

const QuestionFormSuccess = ({ clearAllModals, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      closeModal={clearAllModals}
      clearAllModals={clearAllModals}
      classNameMain={styles.modal}
      classNameButton={styles.closeIcon}
      isDisableCloseOutside
    >
      <section className={styles.content}>
        <SuccessIcon className={styles.icon} />
        <h3 className={styles.title}>Setup complete — Now let’s customize your feed!</h3>
        <p className={styles.text}>
          You`re all set with your basic info. To make sure you see the content you care about, follow the communities that matter to you.
        </p>
        <ModalControl confirmClassName={styles.submit} onConfirm={handleConfirm} onExit={clearAllModals} isHideExit>
          Follow communities
          <ArrowIcon />
        </ModalControl>
      </section>
    </Modal>
  );
};

export default QuestionFormSuccess;
