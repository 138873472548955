import React from "react";

import styles from "./professionItem.module.css";

const ProfessionItem = ({ name, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={styles.item}>
      <span className={styles.color} />
      <span className={styles.text}>{name}</span>
    </a>
  );
};

export default ProfessionItem;
