import React from "react";

import { ReactComponent as SuccessIcon } from "../../../../../media/icons/success.svg";
import { Modal } from "../../../../common/modal";
import ModalControl from "../../../../modalControl";
import modalStyles from "../../../../common/modal/modal.module.css";
import styles from "./success.module.css";

export const Success = ({ closeModal, clearAllModals, subTitle, title, message }) => {
  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      <section>
        <div className={modalStyles.pointer}>{subTitle}</div>
        <div className={styles.popupHeading}>{title}</div>
      </section>

      <div className={styles.paymentOkWrp}>
        <div className={styles.check}>
          <SuccessIcon />
        </div>
        {message}
        <br />
        <br />
      </div>
      <ModalControl isClose isHideExitConfirm onExit={closeModal} />
    </Modal>
  );
};
