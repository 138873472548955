import React from "react";
import { useTranslation } from "react-i18next";

import { Label } from "../label";
import { Value } from "../value";
import { Hr } from "../hr";
import { getNameWebsiteFromUrl } from "../../../helpers";
import { Practices } from "../practices";
import { ReactComponent as GoToIcon } from "../../../media/icons/goTo.svg";
import styles from "./info.module.css";

export const Info = React.memo(({ city, state, practicesTitle, websiteUrl, openToOtherProfessionals = {} }) => {
  const { t } = useTranslation();

  const normalizeWebSite = getNameWebsiteFromUrl(websiteUrl);
  const webSiteFullName = `${normalizeWebSite[0]?.toUpperCase()}${normalizeWebSite?.slice(1)}`;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.item}>
          <Label>{t("dashboard_single_lead_location")}</Label>
          {(city || state) && (
            <Value className={styles.address}>
              {city ? `${city.toLowerCase()}, ` : ""}
              {state}
            </Value>
          )}
        </div>

        <div className={styles.item}>
          <Practices practicesTitle={practicesTitle} />
        </div>

        <div className={styles.item}>
          <Label>SOURCE</Label>
          {websiteUrl && (
            <Value isLink href={websiteUrl} className={styles.valueWebsite}>
              {webSiteFullName}
              <GoToIcon className={styles.icon} />
            </Value>
          )}
        </div>

        <div className={styles.item}>
          <Label>{openToOtherProfessionals.question}</Label>
          {openToOtherProfessionals.answer && <Value>{openToOtherProfessionals.answer}</Value>}
        </div>
      </div>
      <Hr />
    </>
  );
});
