import React, { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as LogoutIcon } from "../../../../media/icons/logout-main.svg";
import { enableScroll, disableScroll } from "../../../../helpers";
import styles from "./detailNavigation.module.css";

export const DetailNavigation = ({ onCloseHandler, keycloak }) => {
  const { t } = useTranslation();

  const wrapperRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const handleUrlChange = () => {
      onCloseHandler();
    };

    const unListen = history.listen(handleUrlChange);

    return () => {
      unListen();
    };
  }, [history]);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <>
      <nav ref={wrapperRef} className={styles.nav}>
        <Link className={styles.link} to="/">
          {t("dashboard_nav_feed")}
        </Link>

        <Link className={styles.link} to="/profile">
          {t("dashboard_nav_profile")}
        </Link>

        <Link className={styles.link} to="/inbox">
          {t("dashboard_nav_inbox")}
        </Link>

        <Link className={styles.link} to="/account">
          {t("dashboard_nav_account")}
        </Link>

        <Link className={styles.link} to="/referrals">
          {t("dashboard_nav_referralrequests")} & {t("dashboard_nav_questions")}
        </Link>

        <Link className={styles.link} to="/listings">
          {t("dashboard_nav_businessservices")}
        </Link>

        <span className={styles.indent} />

        <button
          type="button"
          className={cn(styles.logout, "button--clear")}
          onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}/login` })}
        >
          <LogoutIcon />
          {t("dashboard_nav_logout")}
        </button>
      </nav>
      <div className={styles.background} onClick={onCloseHandler} role="button" tabIndex={-1} />
    </>
  );
};
