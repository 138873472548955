import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { getActiveStatuses } from "../../../../helpers";
import { statusesConstant } from "../../../../constants/referralRequestDetail";
import checkIcon from "../../../../media/icons/verify-green.svg";
import styles from "./statuses.module.css";

export const Statuses = ({ status }) => {
  const { t } = useTranslation();

  const currentStatus = getActiveStatuses(status);

  const renderDraftStatus = () => {
    return (
      <div className={cn(styles.bar, styles.barCorner, styles.requestReceived)}>
        <img className={styles.checkIcon} src={checkIcon} alt="check icon" />
        <p>{t("dashboard_referrals_details_status_received")}</p>
      </div>
    );
  };

  const renderReviewStatus = () => {
    if (statusesConstant.pendingReview === currentStatus) {
      return <div className={cn(styles.bar, styles.barCorner, styles.barPending)}>{t("dashboard_referrals_details_status_pending")}</div>;
    }

    if (statusesConstant.missingInformation === currentStatus) {
      return (
        <div className={cn(styles.bar, styles.barCorner, styles.barMissingInformation)}>
          {t("dashboard_referrals_details_status_missinginformation")}
        </div>
      );
    }

    return (
      <div className={cn(styles.bar, styles.barCorner, styles.barReviewed)}>
        <img className={styles.checkIcon} src={checkIcon} alt="check icon" />
        {t("dashboard_referrals_details_status_reviewed")}
      </div>
    );
  };

  const renderProfileLink = () => {
    if (statusesConstant.rejected === currentStatus) {
      return <div className={cn(styles.bar, styles.barRejected)}>{t("dashboard_referrals_details_status_rejected")}</div>;
    }

    if (statusesConstant.match === currentStatus) {
      return <div className={cn(styles.bar, styles.barMatch)}>(1) {t("dashboard_referrals_details_status_1match")}</div>;
    }

    if (statusesConstant.notMatch === currentStatus) {
      return <div className={cn(styles.bar, styles.barMatch)}>(0) {t("dashboard_referrals_details_status_morematches")}</div>;
    }

    if (statusesConstant.matches === currentStatus) {
      return (
        <div className={cn(styles.bar, styles.barMatches)}>
          <img className={styles.checkIcon} src={checkIcon} alt="check icon" />
          {t("dashboard_referrals_details_status_morematches")}
        </div>
      );
    }

    return <div className={cn(styles.bar, styles.barNotMatch)}>{t("dashboard_referrals_details_status_notmatch")}</div>;
  };

  return (
    <div className={styles.statuses}>
      {renderDraftStatus()}
      {renderReviewStatus()}
      {renderProfileLink()}
    </div>
  );
};
