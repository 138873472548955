import queryString from "query-string";
import { referralRequests } from "../constants/actionTypes";

const defaultPreloaders = {
  preloaderCase: false,
  preloaderCaseImport: false,
  modal: false
};

const defaultModals = {
  modalReferralRequest: true,
  importing: false,
  importingSuccess: false
};

const defaultState = {
  cases: [],
  importReady: {},
  error: null,
  preloaders: {
    ...defaultPreloaders
  },
  modals: {
    ...defaultModals
  },
  isShowReferralsTab: true,
  newReferralRequest: {},
  followups: [],
  pagination: {},
  updateReferralRequest: false,
  tabsCounter: { purchased: 0, notPurchased: 0 },
  filters: {
    directories: [],
    practices: []
  }
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case referralRequests.GET_CASES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const lastPageNum = parseInt(queryString.parse(queryString.extract(payload.pagination.last_page)).page, 10);
      // eslint-disable-next-line no-case-declarations
      const currentPageNum = parseInt(queryString.parse(queryString.extract(payload.pagination.current_page)).page, 10);

      return {
        ...state,
        preloaders: { ...defaultPreloaders },
        cases: payload.cases,
        pagination: { currentPageNum, lastPageNum, perPage: payload.pagination.per_page },
        tabsCounter: {
          purchased: payload.tabsCounter.purchased,
          notPurchased: payload.tabsCounter.notpurchased
        },
        isShowReferralsTab: payload.isSelectedFilter || Boolean(payload.tabsCounter.notpurchased),
        filters: payload.filters
      };

    case referralRequests.GET_NEW_REFERRAL_REQUEST:
      return {
        ...state,
        ...payload
      };

    case referralRequests.CLEAR_NEW_REFERRAL_REQUEST:
      return {
        ...state,
        newReferralRequest: {},
        followups: []
      };

    case referralRequests.GET_IMPORT_READY:
      return {
        ...state,
        importReady: payload
      };

    case referralRequests.GET_IMPORT:
      return {
        ...state,
        modals: {
          ...defaultModals,
          importingSuccess: true
        },
        importReady: {},
        preloaders: {
          ...state.preloaders,
          preloaderCaseImport: false
        }
      };

    case referralRequests.GET_CASES_FAIL:
      return {
        ...state,
        preloaders: { ...defaultPreloaders },
        error: payload
      };

    case referralRequests.SET_CASES_PRELOADER:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          preloaderCase: payload
        }
      };

    case referralRequests.SET_CASES_PRELOADER_IMPORT:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          preloaderCaseImport: payload
        }
      };

    case referralRequests.UPDATE_REFERRAL_REQUEST:
      return {
        ...state,
        updateReferralRequest: payload
      };

    case referralRequests.TOGGLE_ACTIVITY_OF_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        }
      };

    case referralRequests.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultModals
        }
      };

    case referralRequests.SET_CLEAR_REFERRALS:
      return {
        ...state,
        cases: [],
        tabsCounter: { purchased: 0, notPurchased: 0, new: 0 }
      };

    default:
      return {
        ...state
      };
  }
}
