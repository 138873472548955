import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Preloader } from "../../../common/preloader";
import styles from "./performancePracticeArea.module.css";

const PerformancePracticeArea = ({ divId, divIdTooltip, isLoading, leads }) => {
  const { t } = useTranslation();
  const normalizeData = (data = []) => {
    let totalCount = 0;

    data.forEach(item => {
      totalCount += parseInt(item.leads, 10);
    });

    return data.map(item => {
      return {
        name: item.title,
        leads: parseInt(item.leads, 10),
        value: parseFloat((100 / totalCount) * parseInt(item.leads, 10)).toFixed(2)
      };
    });
  };

  const data_raw = normalizeData(leads);

  useEffect(() => {
    const { d3 } = window;
    // set the dimensions and margins of the graph
    const svg_width = 500;
    const svg_height = 500;
    const svg_margin = 50;

    // clean up
    d3.select(`#${divId}`)
      .selectAll("svg")
      .remove();

    // radius
    const pie_radius = Math.min(svg_width, svg_height) / 2 - svg_margin;

    const svg = d3
      .select(`#${divId}`)
      .append("svg")
      .attr("width", svg_width)
      .attr("height", svg_height);

    const divConteiner = d3
      .select(`#${divId}`)
      .append("div")
      .attr("class", styles.infoContent);

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 20)
      .attr("class", "svg_diagram_title")
      .text(t("dashboard_performance_leadsbyspecialty_chart_title"));

    // canvas
    const canvas = svg.append("g").attr("transform", `translate(${svg_width / 1.3 - svg_width / 4},${svg_height / 2})`);

    // sort data to have respective color for 1..n
    const data = data_raw.length >= 2 ? data_raw.sort((a, b) => d3.descending(a.value, b.value)) : data_raw;
    // pie colors
    const color = d3
      .scaleOrdinal()
      .domain(data.map(d => d.name))
      .range(["#5BAFD7", "#AC4B77", "#91B848", "#E16D4F", "#F7BF44", "#DA4655"]);

    // build pie
    const pie = d3.pie().value(d => d.value);

    // map to data
    const pie_data = canvas.selectAll("path").data(pie(data));

    // Modal window over selected element on Pie
    const tooltip = d3.select(`#${divIdTooltip}`);

    // build pie
    pie_data
      .enter()
      .append("path")
      .merge(pie_data)
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(0)
          .outerRadius(pie_radius)
      )
      .on("mouseover", data_item => {
        const selected_element_value = data_item.data.value;
        const selected_element_leads = data_item.data.leads;
        const selected_element_name = data_item.data.name;
        const positionX = d3.event.clientX - 70;
        const positionY = d3.event.clientY - svg_height / 2;

        tooltip
          .html(
            `
            <p class="name nameValue">${selected_element_value}%</p>
            <p class="sub_name subNameValue">${selected_element_leads} leads</p>
            <div class="linkPanelD3 linkPanelD3Big" id="linkPanelD3Big">
              <p class="tooltipTextD3 tooltipTextD3Name">${selected_element_name}</p>
            </div>                      
              `
          )
          .style("left", `${positionX}px`)
          .style("top", `${positionY}px`)
          .style("display", "block")
          .style("position", "fixed");
        const linkPanelD3Big = d3.select("#linkPanelD3Big");

        linkPanelD3Big
          .append("span")
          .attr("class", "dotColor")
          .style("background", color(data_item.data.name));
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      })

      .attr("fill", d => color(d.data.name))
      .attr("stroke", "white")
      .style("stroke-width", "4px") // line - width between elements
      .style("opacity", 1);
    // remove elements that out of "data"
    pie_data.exit().remove();

    const canvasLegend = divConteiner.append("div").attr("class", styles.infoContainer);

    canvasLegend
      .selectAll("p")
      .data(data)
      .enter()
      .append("p")
      .attr("class", styles.infoItem)
      .text(d => d.name)
      .append("span")
      .style("background-color", d => color(d.name))
      .attr("color", d => color(d.name));
  }, [leads.length]); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      {isLoading && <Preloader />}
      <div id={divId} className={styles.svgContent} />
      <div id={divIdTooltip} className="tooltipD3Listing tooltipD3ListingBig" />
    </div>
  );
};

export default PerformancePracticeArea;
