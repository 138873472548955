import {
  questionsNamespace,
  referralRequestDetailNameSpace,
  referralRequestsNamespace,
  performanceNamespace,
  accountNamespace,
  callHistoryNamespace,
  payPerLeadDetailNamespace,
  payPerLeadNamespace,
  listingNamespace,
  paymentNamespace,
  inboxNamespace,
  homeNamespace,
  signUpNamespace,
  authNamespace,
  mainNamespace,
  messagesNamespace,
  searchResultNameSpace,
  teamsNameSpace,
  landingNamespace,
  publicListingsNameSpace,
  searchNameSpace
} from "./actionsNamespaces";

export const main = {
  GET_UTILITY_LISTING: `${mainNamespace}GET_UTILITY_LISTING`,
  MARK_READ: `${mainNamespace}MARK_READ`,
  GET_UTILITY_LISTING_FAIL: `${mainNamespace}GET_UTILITY_LISTING_FAIL`,
  GET_PROFILE_IMAGE: `${mainNamespace}GET_PROFILE_IMAGE`,
  GET_FOOTER_INFO: `${mainNamespace}GET_FOOTER_INFO`,
  GET_FOOTER_INFO_FAIL: `${mainNamespace}GET_FOOTER_INFO_FAIL`,
  GET_PROFILE_IMAGE_FAIL: `${mainNamespace}GET_PROFILE_IMAGE_FAIL`,
  GET_GLOBAL_INFO_SUCCESS: `${mainNamespace}GET_GLOBAL_INFO_SUCCESS`,
  GET_GLOBAL_INFO_FAIL: `${mainNamespace}GET_GLOBAL_INFO_FAIL`,
  SET_LOADING: `${mainNamespace}SET_LOADING`,
  GET_LOGIN: `${mainNamespace}GET_LOGIN`,
  GET_LOGIN_FAIL: `${mainNamespace}GET_LOGIN_FAIL`,
  SEND_EMAIL_VERIFY_CODE_SUCCESS: `${mainNamespace}SEND_EMAIL_VERIFY_CODE_SUCCESS`,
  SEND_EMAIL_VERIFY_CODE_FAIL: `${mainNamespace}SEND_EMAIL_VERIFY_CODE_FAIL`,
  CHECK_EMAIL_VERIFY_CODE_SUCCESS: `${mainNamespace}CHECK_EMAIL_VERIFY_CODE_SUCCESS`,
  CHECK_EMAIL_VERIFY_CODE_FAIL: `${mainNamespace}CHECK_EMAIL_VERIFY_CODE_FAIL`,
  TOGGLE_PAYMENT_ERROR: `${mainNamespace}TOGGLE_PAYMENT_ERROR`,
  TOGGLE_NEW_LISTING: `${mainNamespace}TOGGLE_NEW_LISTING`,
  TOGGLE_MOBILE_MENU: `${mainNamespace}TOGGLE_MOBILE_MENU`,
  GET_COMMUNITIES: `${mainNamespace}GET_COMMUNITIES`
};

export const landing = {
  SET_LOADING: `${landingNamespace}SET_LOADING`,
  GET_COUNTRIES: `${landingNamespace}GET_COUNTRIES`,
  GET_PRACTICES: `${landingNamespace}GET_PRACTICES`,
  GET_PRACTICES_FAIL: `${landingNamespace}GET_PRACTICES_FAIL`,
  GET_PROFESSIONS: `${landingNamespace}GET_PROFESSIONS`,
  GET_PROFESSIONS_FAIL: `${landingNamespace}GET_PROFESSIONS_FAIL`,
  GET_CITY_STATE: `${landingNamespace}GET_CITY_STATE`,
  GET_CITY_STATE_FAIL: `${landingNamespace}GET_CITY_STATE_FAIL`,
  GET_COUNTRIES_FAIL: `${landingNamespace}GET_COUNTRIES_FAIL`
};

export const search = {
  SET_LOADING: `${searchNameSpace}SET_LOADING`,
  GET_PRACTICES: `${searchNameSpace}GET_PRACTICES`,
  GET_PRACTICES_FAIL: `${searchNameSpace}GET_PRACTICES_FAIL`,
  GET_PROFESSIONS: `${searchNameSpace}GET_PROFESSIONS`,
  GET_PROFESSIONS_FAIL: `${searchNameSpace}GET_PROFESSIONS_FAIL`
};

export const auth = {
  SET_LOADING: `${authNamespace}SET_LOADING`,
  SET_SUCCESS: `${authNamespace}SET_SUCCESS`,
  SET_ERRORS: `${authNamespace}SET_ERRORS`,
  SET_LOGGED_IN: `${authNamespace}SET_LOGGED_IN`,
  RESET_PASSWORD: `${authNamespace}RESET_PASSWORD`,
  RESET_PASSWORD_FAIL: `${authNamespace}RESET_PASSWORD_FAIL`,
  RESET_PASSWORD_FAIL_NOT_EXIST: `${authNamespace}RESET_PASSWORD_FAIL_NOT_EXIST`,
  SET_NEW_PASSWORD: `${authNamespace}SET_NEW_PASSWORD`,
  SET_NEW_PASSWORD_FAIL: `${authNamespace}SET_NEW_PASSWORD_FAIL`,
  LOGIN_SUCCESS: `${authNamespace}LOGIN_SUCCESS`,
  LOGIN_FAIL: `${authNamespace}LOGIN_FAIL`,
  LOGOUT: `${authNamespace}LOGOUT`,
  SET_LOGIN_METHODS: `${authNamespace}SET_LOGIN_METHODS`,
  SET_GOOGLE_AUTH_ID: `${authNamespace}SET_GOOGLE_AUTH_ID`,
  SET_GOOGLE_AUTH_RESPONSE: `${authNamespace}SET_GOOGLE_AUTH_RESPONSE`,
  VERIFICATION_CODE: `${authNamespace}VERIFICATION_CODE`,
  VERIFICATION_CODE_FAIL: `${authNamespace}VERIFICATION_CODE_FAIL`,
  KEYCLOACK_FAIL: `${authNamespace}KEYCLOACK_FAIL`
};

export const signUp = {
  SET_LOADING: `${signUpNamespace}SET_LOADING`,
  SET_SUCCESS: `${signUpNamespace}SET_SUCCESS`,
  GET_PROFESSIONS: `${signUpNamespace}GET_PROFESSIONS`,
  CLEAN_PROFESSIONAL: `${signUpNamespace}CLEAN_PROFESSIONAL`,
  GET_PROFESSIONS_FAIL: `${signUpNamespace}GET_PROFESSIONS_FAIL`,
  GET_PROFESSIONS_LOADING: `${signUpNamespace}GET_PROFESSIONS_LOADING`,
  GET_PROFESSIONS_LIST: `${signUpNamespace}GET_PROFESSIONS_LIST`,
  GET_COUNTRIES_LIST: `${signUpNamespace}GET_COUNTRIES_LIST`,
  GET_COUNTRIES_LIST_FAIL: `${signUpNamespace}GET_COUNTRIES_LIST_FAIL`,
  SET_ERRORS: `${signUpNamespace}SET_ERRORS`,
  SIGNUP: `${signUpNamespace}SIGNUP`,
  SIGNUP_FAIL: `${signUpNamespace}FAIL`
};

export const home = {
  GET_UTILITY_LISTING: `${homeNamespace}GET_UTILITY_LISTING`,
  REMOVE_ALL_ERROR: `${homeNamespace}REMOVE_ALL_ERROR`,
  SET_LOADING: `${homeNamespace}SET_LOADING`,
  GET_DASHBOARD_DATA: `${homeNamespace}GET_DASHBOARD_DATA`,
  GET_DASHBOARD_DATA_SUCCESS: `${homeNamespace}GET_DASHBOARD_DATA_SUCCESS`,
  GET_DASHBOARD_DATA_FAIL: `${homeNamespace}GET_DASHBOARD_DATA_FAIL`,
  MARK_READ: `${homeNamespace}MARK_READ`,
  MARK_UNREAD: `${homeNamespace}MARK_UNREAD`,
  GET_ADD_NEW_LISTING_DATA: `${homeNamespace}GET_ADD_NEW_LISTING_DATA`,
  GET_ADD_NEW_LISTING_DATA_FAIL: `${homeNamespace}GET_ADD_NEW_LISTING_DATA_FAIL`,
  ADD_NEW_LISTING: `${homeNamespace}ADD_NEW_LISTING`,
  ADD_NEW_LISTING_FAIL: `${homeNamespace}ADD_NEW_LISTING_FAIL`,
  GET_SAVED: `${homeNamespace}GET_SAVED`,
  GET_MY_FEED: `${homeNamespace}GET_MY_FEED`,
  REMOVE_FOLLOWING: `${homeNamespace}REMOVE_FOLLOWING`,
  REMOVE_FOLLOWING_FAIL: `${homeNamespace}REMOVE_FOLLOWING_FAIL`,
  GET_FOLLOWING: `${homeNamespace}GET_FOLLOWING`,
  POST_FOLLOW_COMMUNITIES: `${homeNamespace}POST_FOLLOW_COMMUNITIES`,
  POST_FOLLOW_COMMUNITIES_FAIL: `${homeNamespace}POST_FOLLOW_COMMUNITIES_FAIL`,
  GET_FOLLOWING_FAIL: `${homeNamespace}GET_FOLLOWING_FAIL`,
  GET_MY_FEED_FAIL: `${homeNamespace}GET_MY_FEED_FAIL`,
  GET_SAVED_FAIL: `${homeNamespace}GET_SAVED_FAIL`,
  ADD_FAVORITES: `${homeNamespace}ADD_FAVORITES`,
  ADD_FAVORITES_FAIL: `${homeNamespace}ADD_FAVORITES_FAIL`,
  REMOVE_FAVORITES: `${homeNamespace}REMOVE_FAVORITES`,
  REMOVE_FAVORITES_FAIL: `${homeNamespace}REMOVE_FAVORITES_FAIL`
};

export const inbox = {
  SET_LOADING: `${inboxNamespace}SET_LOADING`,
  GET_MESSAGES: `${inboxNamespace}GET_MESSAGES`,
  GET_MESSAGES_FOR_FILTER: `${inboxNamespace}GET_MESSAGES_FOR_FILTER`,
  ARCHIVE: `${inboxNamespace}ARCHIVE`,
  MARK_AS_READ: `${inboxNamespace}MARK_AS_READ`,
  MARK_AS_UNREAD: `${inboxNamespace}MARK_AS_UNREAD`
};

export const payment = {
  SET_LOADING: `${paymentNamespace}SET_LOADING`,
  GET_PAYMENT_METHODS: `${paymentNamespace}GET_PAYMENT_METHODS`,
  SET_SELECTED_CARD: `${paymentNamespace}SET_SELECTED_CARD`,
  GET_PAYMENT_METHODS_FAIL: `${paymentNamespace}GET_PAYMENT_METHODS_FAIL`,
  GET_PAYMENT_HISTORY: `${paymentNamespace}GET_PAYMENT_HISTORY`,
  UPDATE_PAYMENT_METHOD: `${paymentNamespace}UPDATE_PAYMENT_METHOD`,
  UPDATE_PAYMENT_METHOD_FAIL: `${paymentNamespace}UPDATE_PAYMENT_METHOD_FAIL`,
  GET_PAYMENT_HISTORY_FAIL: `${paymentNamespace}GET_PAYMENT_HISTORY_FAIL`,
  ADD_CARD: `${paymentNamespace}ADD_CARD`,
  ADD_CARD_SUCCESS: `${paymentNamespace}ADD_CARD_SUCCESS`,
  ADD_CARD_LOCALLY: `${paymentNamespace}ADD_CARD_LOCALLY`,
  ADD_CARD_FAIL: `${paymentNamespace}ADD_CARD_FAIL`,
  UPDATE_CARD_SUCCESS: `${paymentNamespace}UPDATE_CARD_SUCCESS`,
  UPDATE_CARD_LOCALLY: `${paymentNamespace}UPDATE_CARD_LOCALLY`,
  UPDATE_CARD_FAIL: `${paymentNamespace}UPDATE_CARD_FAIL`,
  DELETE_CARD_SUCCESS: `${paymentNamespace}DELETE_CARD_SUCCESS`,
  DELETE_CARD_LOCALLY: `${paymentNamespace}DELETE_CARD_LOCALLY`,
  SET_DEFAULT_SOURCE: `${paymentNamespace}SET_DEFAULT_SOURCE`,
  CHARGE_FOR_MEMBERSHIP: `${paymentNamespace}CHARGE_FOR_MEMBERSHIP`,
  CHARGE_FOR_MEMBERSHIP_SUCCESS: `${paymentNamespace}CHARGE_FOR_MEMBERSHIP_SUCCESS`,
  CHARGE_FOR_MEMBERSHIP_FAIL: `${paymentNamespace}CHARGE_FOR_MEMBERSHIP_FAIL`,
  SET_STRIPE_TOKEN: `${paymentNamespace}SET_STRIPE_TOKEN:`,
  GET_ERROR_TOKEN: `${paymentNamespace}GET_ERROR_TOKEN`,
  CLEAR_ERROR_TOKEN: `${paymentNamespace}CLEAR_ERROR_TOKEN`,
  CLEAR_STATE: `${paymentNamespace}CLEAR_STATE`,
  CLEAR_SUCCESS: `${paymentNamespace}CLEAR_SUCCESS`
};

export const account = {
  SET_LOADING: `${accountNamespace}SET_LOADING`,
  TOGGLE_ACTIVITY_OF_MODAL: `${accountNamespace}TOGGLE_ACTIVITY_OF_MODAL`,
  CLEAR_ALL_MODALS: `${accountNamespace}CLEAR_ALL_MODALS`,
  GET_PROFILE: `${accountNamespace}GET_PROFILE`,
  MARK_READ_NOTIFICATION: `${accountNamespace}MARK_READ_NOTIFICATION`,
  MARK_UN_READ_NOTIFICATION: `${accountNamespace}MARK_UN_READ_NOTIFICATION`,
  MARK_READ_ALL_NOTIFICATION: `${accountNamespace}MARK_READ_ALL_NOTIFICATION`,
  PROFILE_LOCATION_AUTOCOMPLETE: `${accountNamespace}PROFILE_LOCATION_AUTOCOMPLETE`,
  PROFILE_LOCATION_AUTOCOMPLETE_FAIL: `${accountNamespace}PROFILE_LOCATION_AUTOCOMPLETE_FAIL`,
  GET_PROFILE_FAIL: `${accountNamespace}GET_PROFILE_FAIL`,
  UPDATE_CONTACTS: `${accountNamespace}UPDATE_CONTACTS`,
  UPDATE_CONTACTS_SUCCESS: `${accountNamespace}UPDATE_CONTACTS_SUCCESS`,
  UPDATE_CONTACTS_FAIL: `${accountNamespace}UPDATE_CONTACTS_FAIL`,
  UPDATE_PASSWORD: `${accountNamespace}UPDATE_PASSWORD`,
  UPDATE_PASSWORD_SUCCESS: `${accountNamespace}UPDATE_PASSWORD_SUCCESS`,
  UPDATE_PASSWORD_FAIL: `${accountNamespace}UPDATE_PASSWORD_FAIL`,
  GET_NOTIFICATION_SETTINGS: `${accountNamespace}GET_NOTIFICATION_SETTINGS`,
  GET_NOTIFICATION_SETTINGS_FAIL: `${accountNamespace}GET_NOTIFICATION_SETTINGS_FAIL`,
  UPDATE_NOTIFICATIONS_SUCCESS: `${accountNamespace}UPDATE_NOTIFICATIONS_SUCCESS`,
  UPDATE_NOTIFICATIONS_FAIL: `${accountNamespace}UPDATE_NOTIFICATIONS_FAIL`,
  UPDATE_NEWSLETTERS_SUCCESS: `${accountNamespace}UPDATE_NEWSLETTERS_SUCCESS`,
  UPDATE_NEWSLETTERS_FAIL: `${accountNamespace}UPDATE_NEWSLETTERS_FAIL`,
  EMAIL_SEND_VERIFY_CODE_SUCCESS: `${accountNamespace}EMAIL_SEND_VERIFY_CODE_SUCCESS`,
  EMAIL_VERIFY_CODE_SUCCESS: `${accountNamespace}EMAIL_VERIFY_CODE_SUCCESS`,
  EMAIL_VERIFY_CODE_FAIL: `${accountNamespace}EMAIL_VERIFY_CODE_FAIL`,
  EMAIL_VERIFY_CODE_CLEAR_ERROR: `${accountNamespace}EMAIL_VERIFY_CODE_CLEAR_ERROR`,
  CHECK_EMAIL_VERIFY_CODE_FAIL: `${accountNamespace}CHECK_EMAIL_VERIFY_CODE_FAIL`,
  UPDATE_EMAIL_SUCCESS: `${accountNamespace}UPDATE_EMAIL_SUCCESS`,
  VERIFIED_EMAIL_SUCCESS: `${accountNamespace}VERIFIED_EMAIL_SUCCESS`,
  UPDATE_EMAIL_FAIL: `${accountNamespace}UPDATE_EMAIL_FAIL`,
  SAVE_NEW_EMAIL_PASSWORD: `${accountNamespace}SAVE_NEW_EMAIL_PASSWORD`,
  CLEAN_NEW_EMAIL_PASSWORD: `${accountNamespace}CLEAN_NEW_EMAIL_PASSWORD`,
  PHONE_VERIFY_CODE_SUCCESS: `${accountNamespace}PHONE_VERIFY_CODE_SUCCESS`,
  PHONE_VERIFY_CODE_FAIL: `${accountNamespace}PHONE_VERIFY_CODE_FAIL`,
  CHECK_PHONE_VERIFY_CODE_FAIL: `${accountNamespace}CHECK_PHONE_VERIFY_CODE_FAIL`,
  UPDATE_PHONE_SUCCESS: `${accountNamespace}UPDATE_PHONE_SUCCESS`,
  REMOVE_PHONE_SUCCESS: `${accountNamespace}REMOVE_PHONE_SUCCESS`,
  REMOVE_PHONE_FAIL: `${accountNamespace}REMOVE_PHONE_FAIL`,
  EMAIL_VERIFICATION_CHANGE: `${accountNamespace}EMAIL_VERIFICATION_CHANGE`,
  PHONE_VERIFICATION_CHANGE: `${accountNamespace}PHONE_VERIFICATION_CHANGE`,
  PASSWORD_VERIFICATION_SUCCESS: `${accountNamespace}PASSWORD_VERIFICATION_SUCCESS`,
  NOTIFICATION_REMOVE_EMAIL_SUCCESS: `${accountNamespace}NOTIFICATION_REMOVE_EMAIL_SUCCESS`,
  NOTIFICATION_REMOVE_EMAIL_FAIL: `${accountNamespace}NOTIFICATION_REMOVE_EMAIL_FAIL`,
  NOTIFICATION_REMOVE_PHONE_SUCCESS: `${accountNamespace}NOTIFICATION_REMOVE_PHONE_SUCCESS`,
  NOTIFICATION_REMOVE_PHONE_FAIL: `${accountNamespace}NOTIFICATION_REMOVE_PHONE_FAIL`
};

export const listing = {
  UPLOAD_IMAGE_FAIL: `${listingNamespace}UPLOAD_IMAGE_FAIL`,
  UPLOAD_IMAGE: `${listingNamespace}UPLOAD_IMAGE`,
  UPLOAD_SOCIAL_IMAGE: `${listingNamespace}UPLOAD_SOCIAL_IMAGE`,
  UPLOAD_SOCIAL_IMAGE_FAIL: `${listingNamespace}UPLOAD_SOCIAL_IMAGE_FAIL`,
  CLEAR_CHANGES_FIELD: `${listingNamespace}CLEAR_CHANGES_FIELD`,
  CLEAR_ALL_MODALS: `${listingNamespace}CLEAR_ALL_MODALS`,
  CHANGE_MEMBER_DATA_YELP_AVVO_SUCCESS: `${listingNamespace}CHANGE_MEMBER_DATA_YELP_AVVO_SUCCESS`,
  DELETE: `${listingNamespace}DELETE_MEMBERSHIP`,
  DELETE_SUCCESS: `${listingNamespace}DELETE_SUCCESS`,
  DELETE_FAIL: `${listingNamespace}DELETE_FAIL`,
  PAUSE_FAIL: `${listingNamespace}PAUSE_FAIL`,
  PAUSE_SUCCESS: `${listingNamespace}PAUSE_SUCCESS`,
  UNPAUSE_SUCCESS: `${listingNamespace}UNPAUSE_SUCCESS`,
  UNPAUSE_FAIL: `${listingNamespace}UNPAUSE_FAIL`,
  DOWNGRADE: `${listingNamespace}DOWNGRADE`,
  DOWNGRADE_FAIL: `${listingNamespace}DOWNGRADE_FAIL`,
  UN_DOWNGRADE_FAIL: `${listingNamespace}UN_DOWNGRADE_FAIL`,
  DOWNGRADE_SUCCESS: `${listingNamespace}DOWNGRADE_SUCCESS`,
  UN_DOWNGRADE_SUCCESS: `${listingNamespace}UN_DOWNGRADE_SUCCESS`,
  DOWNGRADE_LOCALLY: `${listingNamespace}DOWNGRADE_LOCALLY`,
  GET_MEMBERSHIPS: `${listingNamespace}GET_MEMBERSHIPS`,
  SET_MEMBERSHIPS_CLEAR: `${listingNamespace}SET_MEMBERSHIPS_CLEAR`,
  GET_MEMBERSHIPS_FAIL: `${listingNamespace}GET_MEMBERSHIPS_FAIL`,
  GET_NEW_MEMBERSHIPS: `${listingNamespace}GET_NEW_MEMBERSHIPS`,
  GET_PRODUCT_NAME: `${listingNamespace}GET_PRODUCT_NAME`,
  GET_PRODUCT_NAME_SUCCESS: `${listingNamespace}GET_PRODUCT_NAME_SUCCESS`,
  GET_PRODUCT_NAME_FAIL: `${listingNamespace}GET_PRODUCT_NAME_FAIL`,
  SET_MEMBERSHIP_DATA_CLEAR: `${listingNamespace}SET_MEMBERSHIP_DATA_CLEAR`,
  GET_UPGRADE_OPTIONS: `${listingNamespace}GET_UPGRADE_OPTIONS`,
  GET_UPGRADE_OPTIONS_FAIL: `${listingNamespace}GET_UPGRADE_OPTIONS_FAIL`,
  GET_UPGRADE_OPTIONS_SHOW_ERROR: `${listingNamespace}GET_UPGRADE_OPTIONS_SHOW_ERROR`,
  GET_UPGRADE_OPTIONS_HIDE_ERROR: `${listingNamespace}GET_UPGRADE_OPTIONS_HIDE_ERROR`,
  SET_LOADING: `${listingNamespace}SET_LOADING`,
  GET_MEMBER_DATA: `${listingNamespace}GET_MEMBER_DATA`,
  SUBMIT_PHONE_EMAIL_SUCCESS: `${listingNamespace}SUBMIT_PHONE_EMAIL_SUCCESS`,
  GET_MEMBER_DATA_SUCCESS: `${listingNamespace}GET_MEMBER_DATA_SUCCESS`,
  GET_MEMBER_DATA_FAIL: `${listingNamespace}GET_MEMBER_DATA_FAIL`,
  SET_DEFAULT_MEMBER_DATA: `${listingNamespace}SET_DEFAULT_MEMBER_DATA`,
  GET_INSURANCE_GROUPS: `${listingNamespace}GET_INSURANCE_GROUPS`,
  GET_INSURANCE_GROUPS_FAIL: `${listingNamespace}GET_INSURANCE_GROUPS_FAIL`,
  UPDATE_ACTIVE_UPGRADE_OPTIONS: `${listingNamespace}UPDATE_ACTIVE_UPGRADE_OPTIONS`,
  PUBLISH_LISTING: `${listingNamespace}PUBLISH_LISTING`,
  PUBLISH_LISTING_FAIL: `${listingNamespace}PUBLISH_LISTING_FAIL`,
  DELETE_IMAGE: `${listingNamespace}REMOVE_AVATAR`,
  DELETE_ADDITIONAL_IMAGE: `${listingNamespace}DELETE_ADDITIONAL_IMAGE`,
  DELETE_COVER_IMAGE: `${listingNamespace}DELETE_COVER_IMAGE`,
  GET_VALIDATIONS: `${listingNamespace}GET_VALIDATIONS`,
  GET_VALIDATIONS_FAIL: `${listingNamespace}GET_VALIDATIONS_FAIL`,
  DELETE_IMAGE_FAIL: `${listingNamespace}DELETE_IMAGE_FAIL`,
  DELETE_COVER_IMAGE_FAIL: `${listingNamespace}DELETE_COVER_IMAGE_FAIL`,
  SET_DEFAULT_IMAGE: `${listingNamespace}SET_DEFAULT_IMAGE`,
  SET_SUCCESS: `${listingNamespace}SET_SUCCESS`,
  SUBMIT_CHANGES_FAIL: `${listingNamespace}SUBMIT_CHANGES_FAIL`,
  SUBMIT_CHANGES_SUCCESS: `${listingNamespace}SUBMIT_CHANGES_SUCCESS`,
  SUBMIT_CHANGES_CLOSE_MODALS: `${listingNamespace}SUBMIT_CHANGES_CLOSE_MODALS`,
  UPGRADE: `${listingNamespace}UPGRADE`,
  UPGRADE_SUCCESS: `${listingNamespace}UPGRADE_SUCCESS`,
  UPGRADE_LOCALLY: `${listingNamespace}UPGRADE_LOCALLY`,
  UPGRADE_FAIL: `${listingNamespace}UPGRADE_FAIL`,
  UPGRADE_CLEAN: `${listingNamespace}UPGRADE_CLEAN`,
  TOGGLE_ACTIVITY_OF_MODAL: `${listingNamespace}TOGGLE_ACTIVITY_OF_MODAL`,
  ACTIVE_NOTIFICATION_MODAL: `${listingNamespace}ACTIVE_NOTIFICATION_MODAL`,
  NOTIFICATIONS_LOADING: `${listingNamespace}NOTIFICATIONS_LOADING`,
  NOTIFICATION_EMAIL: `${listingNamespace}NOTIFICATION_EMAIL`,
  NOTIFICATION_EMAIL_FAIL: `${listingNamespace}NOTIFICATION_EMAIL_FAIL`,
  NOTIFICATION_VERIFICATION_EMAIL_FAIL: `${listingNamespace}NOTIFICATION_VERIFICATION_EMAIL_FAIL`,
  NOTIFICATION_VERIFICATION_EMAIL_SUCCESS: `${listingNamespace}NOTIFICATION_VERIFICATION_EMAIL_SUCCESS`,
  NOTIFICATION_VERIFICATION_PHONE_FAIL: `${listingNamespace}NOTIFICATION_VERIFICATION_PHONE_FAIL`,
  NOTIFICATION_VERIFICATION_PHONE_SUCCESS: `${listingNamespace}NOTIFICATION_VERIFICATION_PHONE_SUCCESS`,
  NOTIFICATION_PHONE: `${listingNamespace}NOTIFICATION_PHONE`,
  NOTIFICATIONS_VERIFY_EMAIL: `${listingNamespace}NOTIFICATIONS_VERIFY_EMAIL`,
  NOTIFICATION_PHONE_FAIL: `${listingNamespace}NOTIFICATION_PHONE_FAIL`,
  SET_SAVED_PAYMENT_DATA: `${listingNamespace}SET_SAVED_PAYMENT_DATA`,
  CLEAR_SAVED_PAYMENT_DATA: `${listingNamespace}CLEAR_SAVED_PAYMENT_DATA`,
  GET_LISTING_ASSISTANT: `${listingNamespace}GET_LISTING_ASSISTANT`,
  GET_DIRECTORIES_PROFESSIONS: `${listingNamespace}GET_DIRECTORIES_PROFESSIONS`,
  GET_DIRECTORIES_PROFESSIONS_FAIL: `${listingNamespace}GET_DIRECTORIES_PROFESSIONS_FAIL`,
  GET_LISTINGS_CONFIG: `${listingNamespace}GET_LISTINGS_CONFIG`,
  GET_LISTINGS_CONFIG_FAIL: `${listingNamespace}GET_LISTINGS_CONFIG_FAIL`,
  GET_MY_PROFILE: `${listingNamespace}GET_MY_PROFILE`,
  SET_VALIDATION_ERROR: `${listingNamespace}SET_VALIDATION_ERROR`,
  CLEAR_VALIDATION_ERROR: `${listingNamespace}CLEAR_VALIDATION_ERROR`,
  DELETE_PRIMARY_IMAGE: `${listingNamespace}DELETE_PRIMARY_IMAGE`,
  DELETE_PRIMARY_IMAGE_FAIL: `${listingNamespace}DELETE_PRIMARY_IMAGE_FAIL`
};

export const payPerLead = {
  SET_LOADING: `${payPerLeadNamespace}SET_LOADING`,
  SET_TAB: `${payPerLeadNamespace}SET_TAB`,
  SET_SUB_TAB: `${payPerLeadNamespace}SET_SUB_TAB`,
  GET_LEADS: `${payPerLeadNamespace}GET_LEADS`,
  GET_LEADS_FAIL: `${payPerLeadNamespace}GET_LEADS_FAIL`,
  SET_LEADS_CLEAR: `${payPerLeadNamespace}SET_LEADS_CLEAR`,
  SET_LEAD_NO_INTEREST: `${payPerLeadNamespace}SET_LEAD_NO_INTEREST`,
  SET_LEAD_INTEREST: `${payPerLeadNamespace}SET_LEAD_INTEREST`
};

export const payPerLeadDetail = {
  SET_LOADING: `${payPerLeadDetailNamespace}SET_LOADING`,
  RESET_SUCCESS: `${payPerLeadDetailNamespace}RESET_SUCCESS`,
  RESET_ALL_SUCCESS: `${payPerLeadDetailNamespace}RESET_ALL_SUCCESS`,
  RESET_ERROR: `${payPerLeadDetailNamespace}RESET_ERROR`,
  GET_LEAD: `${payPerLeadDetailNamespace}GET_LEAD`,
  GET_PRACTICES: `${payPerLeadDetailNamespace}GET_PRACTICES`,
  GET_PRACTICES_FAIL: `${payPerLeadDetailNamespace}GET_PRACTICES_FAIL`,
  GET_LEAD_FAIL: `${payPerLeadDetailNamespace}GET_LEAD_FAIL`,
  BUY_LEAD: `${payPerLeadDetailNamespace}BUY_LEAD`,
  BUY_LEAD_FAIL: `${payPerLeadDetailNamespace}BUY_LEAD_FAIL`,
  HIDE_LEAD: `${payPerLeadDetailNamespace}HIDE_LEAD`,
  HIDE_LEAD_FAIL: `${payPerLeadDetailNamespace}HIDE_LEAD_FAIL`,
  CLEAR_LEAD: `${payPerLeadDetailNamespace}CLEAR_LEAD`,
  TOGGLE_MODAL: `${payPerLeadDetailNamespace}TOGGLE_MODAL`,
  CLEAR_ALL_MODALS: `${payPerLeadDetailNamespace}CLEAR_ALL_MODALS`,
  CHECK_COUPON: `${payPerLeadDetailNamespace}CHECK_COUPON`,
  CHECK_COUPON_FAIL: `${payPerLeadDetailNamespace}CHECK_COUPON_FAIL`,
  CHECK_COUPON_CLEAR: `${payPerLeadDetailNamespace}CHECK_COUPON_CLEAR`,
  CHECK_LOADING_LOADING: `${payPerLeadDetailNamespace}CHECK_LOADING_LOADING`
};

export const callHistory = {
  GET_CALL_DATA_SUCCESS: `${callHistoryNamespace}GET_CALL_DATA_SUCCESS`,
  GET_CALL_DATA_FAIL: `${callHistoryNamespace}GET_CALL_DATA_FAIL`,
  SET_CALL_DATA_CLEAN: `${callHistoryNamespace}SET_CALL_DATA_CLEAN`
};

export const searchResult = {
  SET_LOADING: `${searchResultNameSpace}SET_LOADING`,
  GET_SEARCH_RESULT: `${searchResultNameSpace}GET_CALL_DATA_SUCCESS`,
  GET_SEARCH_RESULT_FAIL: `${searchResultNameSpace}GET_SEARCH_RESULT_FAIL`
};

export const performance = {
  SET_LOADING: `${performanceNamespace}SET_LOADING`,

  GET_LEADS_BY_MONTH: `${performanceNamespace}GET_LEADS_BY_MONTH`,
  GET_LEADS_BY_YEAR: `${performanceNamespace}GET_LEADS_BY_YEAR`,
  GET_LEADS_BY_WEEK: `${performanceNamespace}GET_LEADS_BY_WEEK`,
  GET_LEADS_BY_WEEK_SUCCESS: `${performanceNamespace}GET_LEADS_BY_WEEK_SUCCESS`,
  GET_LEADS_BY_YEAR_SUCCESS: `${performanceNamespace}GET_LEADS_BY_YEAR_SUCCESS`,
  GET_LEADS_BY_MONTH_SUCCESS: `${performanceNamespace}GET_LEADS_BY_MONTH_SUCCESS`,
  GET_LEADS_BY_LISTING_SUCCESS: `${performanceNamespace}GET_LEADS_BY_LISTING_SUCCESS`,
  GET_LEADS_BY_PRACTICE_AREA_SUCCESS: `${performanceNamespace}GET_LEADS_BY_PRACTICE_AREA_SUCCESS`,
  GET_ALL_LEADS_SUCCESS: `${performanceNamespace}GET_ALL_LEADS_SUCCESS`,
  GET_TRAFFIC_HISTORY_SUCCESS: `${performanceNamespace}GET_TRAFFIC_HISTORY_SUCCESS`,
  GET_TRAFFIC_SOURCE_SUCCESS: `${performanceNamespace}GET_TRAFFIC_SOURCE_SUCCESS`,
  GET_GEO_CHART_SUCCESS: `${performanceNamespace}GET_GEO_CHART_SUCCESS`,
  GET_LEADS_BY_WEEK_FAIL: `${performanceNamespace}GET_LEADS_BY_WEEK_FAIL`,
  GET_LEADS_BY_YEAR_FAIL: `${performanceNamespace}GET_LEADS_BY_YEAR_FAIL`,
  GET_LEADS_BY_MONTH_FAIL: `${performanceNamespace}GET_LEADS_BY_MONTH_FAIL`,
  GET_LEADS_BY_LISTING_FAIL: `${performanceNamespace}GET_LEADS_BY_LISTING_FAIL`,
  GET_LEADS_BY_PRACTICE_AREA_FAIL: `${performanceNamespace}GET_LEADS_BY_PRACTICE_AREA_FAIL`,
  GET_ALL_LEADS_FAIL: `${performanceNamespace}GET_ALL_LEADS_FAIL`,
  GET_TRAFFIC_HISTORY_FAIL: `${performanceNamespace}GET_TRAFFIC_HISTORY_FAIL`,
  GET_TRAFFIC_SOURCE_FAIL: `${performanceNamespace}GET_TRAFFIC_SOURCE_FAIL`,
  GET_GEO_CHART_FAIL: `${performanceNamespace}GET_GEO_CHART_FAIL`,
  GET_ERROR_DATA_FROM_SERVER: `${performanceNamespace}GET_ERROR_DATA_FROM_SERVER`
};

export const referralRequests = {
  GET_CASES_SUCCESS: `${referralRequestsNamespace}GET_CASES_SUCCESS`,
  GET_CASES_FAIL: `${referralRequestsNamespace}GET_CASES_FAIL`,
  SET_CASES_PRELOADER: `${referralRequestsNamespace}SET_CASES_PRELOADER`,
  SET_CASES_PRELOADER_IMPORT: `${referralRequestsNamespace}SET_CASES_PRELOADER_IMPORT`,
  TOGGLE_ACTIVITY_OF_MODAL: `${referralRequestsNamespace}TOGGLE_ACTIVITY_OF_MODAL`,
  CLEAR_ALL_MODALS: `${referralRequestsNamespace}CLEAR_ALL_MODALS`,
  SET_CLEAR_REFERRALS: `${referralRequestsNamespace}SET_CLEAR_REFERRALS`,
  GET_IMPORT_READY: `${referralRequestsNamespace}GET_IMPORT_READY`,
  GET_IMPORT: `${referralRequestsNamespace}GET_IMPORT`,
  GET_NEW_REFERRAL_REQUEST: `${referralRequestsNamespace}GET_NEW_REFERRAL_REQUEST`,
  CLEAR_NEW_REFERRAL_REQUEST: `${referralRequestsNamespace}CLEAR_NEW_REFERRAL_REQUEST`,
  UPDATE_REFERRAL_REQUEST: `${referralRequestsNamespace}UPDATE_REFERRAL_REQUEST`
};

export const referralRequestDetail = {
  GET_CASE_SUCCESS: `${referralRequestDetailNameSpace}GET_CASE_SUCCESS`,
  GET_CASE_FAIL: `${referralRequestDetailNameSpace}GET_CASE_FAIL`,
  SET_CASE_PRELOADER: `${referralRequestDetailNameSpace}SET_CASE_PRELOADER`,
  CLEAR_ALL_MODALS: `${referralRequestDetailNameSpace}CLEAR_ALL_MODALS`,
  SET_CONTACT_INFO_DATA: `${referralRequestDetailNameSpace}SET_CONTACT_INFO_DATA`,
  TOGGLE_ACTIVITY_OF_MODAL: `${referralRequestDetailNameSpace}TOGGLE_ACTIVITY_OF_MODAL`,
  TOGGLE_ACTIVITY_MESSAGE_MODAL: `${referralRequestDetailNameSpace}TOGGLE_ACTIVITY_MESSAGE_MODAL`,
  GET_CASE_PRACTICES: `${referralRequestDetailNameSpace}GET_CASE_PRACTICES`,
  SUBMIT_CASE_DATA_SUCCESS: `${referralRequestDetailNameSpace}SUBMIT_CASE_DATA_SUCCESS`,
  CHANGE_CONTACT_INFO: `${referralRequestDetailNameSpace}CHANGE_CONTACT_INFO`,
  UPDATED_FOLLOWUPS: `${referralRequestDetailNameSpace}UPDATED_FOLLOWUPS`,
  CHANGE_CONTACT_INFO_FAIL: `${referralRequestDetailNameSpace}CHANGE_CONTACT_INFO_FAIL`,
  SEND_MATCH_MESSAGE: `${referralRequestDetailNameSpace}SEND_MATCH_MESSAGE`,
  SUBMIT_CASE_DATA_FAIL: `${referralRequestDetailNameSpace}SUBMIT_CASE_DATA_FAIL`,
  GET_PRACTICES: `${referralRequestDetailNameSpace}GET_PRACTICES`,
  GET_PRACTICES_FAIL: `${referralRequestDetailNameSpace}GET_PRACTICES_FAIL`
};

export const questions = {
  GET_QA_SUCCESS: `${questionsNamespace}QA_GET_SUCCESS`,
  GET_MY_ANSWER: `${questionsNamespace}GET_MY_ANSWER`,
  GET_ALL_MY_ANSWER: `${questionsNamespace}GET_ALL_MY_ANSWER`,
  GET_MY_ANSWER_FAIL: `${questionsNamespace}GET_MY_ANSWER_FAIL`,
  GET_MY_QUESTIONS: `${questionsNamespace}GET_MY_QUESTIONS`,
  GET_MY_QUESTIONS_FAIL: `${questionsNamespace}GET_MY_QUESTIONS_FAIL`,
  GET_QA_FAIL: `${questionsNamespace}QA_GET_FAIL`,
  GET_CURRENT_QUESTION_ANSWER: `${questionsNamespace}GET_CURRENT_QUESTION_ANSWER`,
  SET_QA_PRELOADER: `${questionsNamespace}SET_QA_PRELOADER`,
  QA_MARK_SAVED: `${questionsNamespace}QA_MARK_SAVED`,
  QA_MARK_SAVED_SINGLE: `${questionsNamespace}QA_MARK_SAVED_SINGLE`,
  QA_MARK_UNSAVED_SINGLE: `${questionsNamespace}QA_MARK_UNSAVED_SINGLE`,
  QA_MARK_UNSAVED: `${questionsNamespace}QA_MARK_UNSAVED`,
  QA_UPDATE_ANSWERS: `${questionsNamespace}QA_UPDATE_ANSWERS`,
  CLEAN_QUESTIONS: `${questionsNamespace}CLEAN_QUESTIONS`,
  CLEAN_CURRENT_QUESTIONS: `${questionsNamespace}CLEAN_CURRENT_QUESTIONS`
};

export const messages = {
  GET_MESSAGES: `${messagesNamespace}GET_MESSAGES`,
  GET_MESSAGES_FAIL: `${messagesNamespace}GET_MESSAGES_FAIL`,
  TOGGLE_LOADING: `${messagesNamespace}TOGGLE_LOADING`,
  GET_MESSAGE_DETAIL: `${messagesNamespace}GET_MESSAGE_DETAIL`,
  GET_MESSAGE_DETAIL_LOADING: `${messagesNamespace}GET_MESSAGE_DETAIL_LOADING`,
  READ_MESSAGE: `${messagesNamespace}READ_MESSAGE`,
  UNREAD_MESSAGE: `${messagesNamespace}UNREAD_MESSAGE`,
  ARCHIVE_MESSAGE: `${messagesNamespace}ARCHIVE_MESSAGE`,
  UN_ARCHIVE_MESSAGE: `${messagesNamespace}UN_ARCHIVE_MESSAGE`,
  SEND_MESSAGE: `${messagesNamespace}SEND_MESSAGE`,
  SEND_MESSAGE_FAIL: `${messagesNamespace}SEND_MESSAGE_FAIL`,
  DISABLE_SUCCESS: `${messagesNamespace}DISABLE_SUCCESS`,
  SET_ACTIVE_BLOCKS: `${messagesNamespace}SET_ACTIVE_BLOCKS`,
  SET_ACTIVITY_MENU: `${messagesNamespace}SET_ACTIVITY_MENU`,
  SET_FILTER: `${messagesNamespace}SET_FILTER`,
  SET_ACTIVITY_FILTER: `${messagesNamespace}SET_ACTIVITY_FILTER`
};

export const teams = {
  SET_LOADING: `${teamsNameSpace}SET_LOADING`,
  TOGGLE_MODAL: `${teamsNameSpace}TOGGLE_MODAL`,
  ACTIVE_ACCESS: `${teamsNameSpace}ACTIVE_ACCESS`,
  GET_TEAMS: `${teamsNameSpace}GET_TEAMS`,
  GET_TEAMS_FAIL: `${teamsNameSpace}GET_TEAMS_FAIL`,
  REMOVE_ACCESS: `${teamsNameSpace}REMOVE_ACCESS`,
  REMOVE_ACCESS_FAIL: `${teamsNameSpace}REMOVE_ACCESS_FAIL`,
  ACCEPT_ACCESS: `${teamsNameSpace}ACCEPT_ACCESS`,
  ACCEPT_ACCESS_FAIL: `${teamsNameSpace}ACCEPT_ACCESS_FAIL`,
  INVITE_ACCESS: `${teamsNameSpace}INVITE_ACCESS`,
  INVITE_ACCESS_FAIL: `${teamsNameSpace}INVITE_ACCESS_FAIL`,
  REJECT_ACCESS: `${teamsNameSpace}REJECT_ACCESS`,
  REJECT_ACCESS_FAIL: `${teamsNameSpace}REJECT_ACCESS_FAIL`,
  UPDATE_ACCESS: `${teamsNameSpace}UPDATE_ACCESS`,
  UPDATE_ACCESS_FAIL: `${teamsNameSpace}UPDATE_ACCESS_FAIL`,
  CLEAR_ALL_MODALS: `${teamsNameSpace}CLEAR_ALL_MODALS`,
  CLEAR_ERROR: `${teamsNameSpace}CLEAR_ERROR`,
  GET_TEAMS_MEMBER: `${teamsNameSpace}GET_TEAMS_MEMBER`,
  GET_TEAMS_MEMBER_FAIL: `${teamsNameSpace}GET_TEAMS_MEMBER_FAIL`,
  SET_TEAM: `${teamsNameSpace}SET_TEAM`
};

export const publicListing = {
  SET_LOADING: `${publicListingsNameSpace}SET_LOADING`,
  CLEAR_ALL_MODALS: `${publicListingsNameSpace}CLEAR_ALL_MODALS`,
  TOGGLE_MODAL: `${publicListingsNameSpace}TOGGLE_MODAL`,
  GET_LISTINGS: `${publicListingsNameSpace}GET_LISTINGS`,
  GET_LISTINGS_FAIL: `${publicListingsNameSpace}GET_LISTINGS_FAIL`,
  REMOVE_FAVORITES: `${publicListingsNameSpace}REMOVE_FAVORITES`,
  ADD_FAVORITES: `${publicListingsNameSpace}ADD_FAVORITES`,
  ADD_FAVORITES_FAIL: `${publicListingsNameSpace}ADD_FAVORITES_FAIL`
};
