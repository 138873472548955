import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isToday } from "date-fns";

import Tabs from "./components/tabs";
import Filter from "../saved/filter";
import Profiles from "../saved/profiles";
import Organizations from "../saved/organizations";
import Events from "../saved/events";
import Jobs from "../saved/jobs";
import FollowingModal from "./components/followingModal";
import QuestionForm from "./components/questionForm";
import QuestionFormSuccess from "./components/questionFormSuccess";
import FollowCommunitiesForm from "./components/followCommunitiesForm";
import { ReactComponent as EmptyIcon } from "../../media/icons/new-empty-listing.svg";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../media/icons/edit-pen-white.svg";
import { actions as homeActions } from "../../actions/home";
import { OverlayPreloader } from "../common/preloader";
import PageNames from "../pageNames";
import { useDocTitle } from "../../helpers/hooks";
import { actions as actionProfile } from "../../actions/account/profile";
import { modalsName } from "../../constants/account";
import { communitiesTypes } from "../../constants/searchResult";
import { Notification } from "../common/notification";
import styles from "./feed.module.css";

const Feed = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);
  const wrapperRef = useRef(null);
  const [activeCommunitiesModal, setActiveCommunitiesModal] = useState(false);
  const { communities: communitiesList } = useSelector(state => state.main);
  const [filter, setFilter] = useState({ value: "", label: "" });
  const [search, setSearch] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const getMyFeed = useCallback((command, search, page) => dispatch(homeActions.getMyFeed(command, search, page)), [dispatch]); // eslint-disable-line
  const [page, setPage] = useState(1);
  const { professionTitle } = useSelector(state => state.search);

  const { myFeed, feedPagination, preloaders, followings, error } = useSelector(state => state.home);
  const { profile, modals } = useSelector(state => state.account);
  const addToFavorite = useCallback(listingId => dispatch(homeActions.addToFavorite(listingId)), [dispatch]);
  const removeAllError = useCallback(() => dispatch(homeActions.removeAllError()), [dispatch]);
  const removeFavorite = useCallback(listingId => dispatch(homeActions.removeFavorite(listingId)), [dispatch]);
  const getFollowing = useCallback(() => dispatch(homeActions.getFollowing()), [dispatch]);
  const removeFollowing = useCallback(id => dispatch(homeActions.removeFollowing(id)), [dispatch]);
  const toggleActivityOfModal = useCallback((modal, isActive) => dispatch(actionProfile.toggleActivityOfModal(modal, isActive)), [
    dispatch
  ]);

  const clearAllModals = useCallback(() => dispatch(actionProfile.clearAllModals()), [dispatch]);
  const lastScrollTop = useRef(0);

  const all = { value: "All", label: "all" };
  const activeOptions = professionTitle.map(item => ({
    value: item,
    label: item.toLowerCase() === "people" ? "profiles" : item.toLowerCase()
  }));
  const profiles = activeOptions.find(item => item.label.includes("profile"));
  const organizations = activeOptions.find(item => item.label.includes("organization"));
  const jobs = activeOptions.find(item => item.label.includes("job"));
  const events = activeOptions.find(item => item.label.includes("event"));

  const filterOptions = [all, profiles, organizations, jobs, events];

  const isShowQuestionForm = useMemo(() => {
    return isToday(new Date(profile.added));
  }, [profile.added]);

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const handleHideModal = () => {
    setIsShowModal(false);
  };

  const handleChangeTab = (value, isScroll) => {
    if (value.label !== filter.label) {
      setFilter(value);

      getMyFeed(value.label, search);
      if (page !== 1) {
        setPage(1);
      }
    }

    if (isScroll && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const count = useMemo(() => {
    if ("profiles" in myFeed.count) {
      return myFeed.count;
    }

    return 0;
  }, [myFeed.count, filter.label]);

  const handleChange = event => {
    setSearch(event.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleReset = () => {
    setSearch("");
    getMyFeed(filter.label, "");
    setIsEdited(false);
  };

  const handleCloseFormModal = () => {
    clearAllModals();
  };

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }

    getMyFeed();
    getFollowing();
  }, []);

  useEffect(() => {
    if (isShowQuestionForm) {
      toggleActivityOfModal(modalsName.questionForm, true);
    }
  }, [isShowQuestionForm, profile.added]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isEdited) {
        getMyFeed(filter.label, search);
      }
    }, 750);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  useDocTitle("dashboard_myfeed_metatitle");

  const fetchData = () => {
    if (filter.value !== all.value) {
      if (loading) {
        return;
      }

      document.body.style.overflow = "hidden";
      setLoading(true);

      getMyFeed(filter.label, search, page + 1).then(() => {
        setPage(prev => prev + 1);
        setLoading(false);
        document.body.style.overflow = "";
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (page < feedPagination.total_pages) {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (entries[0].isIntersecting && scrollTop > lastScrollTop.current) {
          fetchData();
        }

        lastScrollTop.current = scrollTop;
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [filter.value, page, feedPagination.total_pages]);

  const isShowContent = useMemo(() => {
    return (
      filter.value !== all.value ||
      search ||
      myFeed?.list?.profiles?.length ||
      myFeed?.list?.organizations?.length ||
      myFeed?.list?.events?.length ||
      myFeed?.list?.jobs?.length
    );
  }, [
    myFeed?.list?.profiles?.length,
    myFeed?.list?.organizations?.length,
    myFeed?.list?.events?.length,
    myFeed?.list?.jobs?.length,
    filter.value
  ]);

  const handleOpenCommunities = () => {
    setActiveCommunitiesModal(true);
  };

  const handleCloseCommunities = () => {
    setActiveCommunitiesModal(false);
  };

  const allCommunityValue = useMemo(() => {
    const globalCommunity = communitiesList.find(item => item.heritage_group_title.toLowerCase() === communitiesTypes.global);
    if (globalCommunity?.children?.[0]) {
      return globalCommunity.children[0];
    }

    return {};
  }, [communitiesList?.length]);

  return (
    <>
      {modals.questionForm && <QuestionForm closeModal={handleCloseFormModal} />}
      {modals.questionFormSuccess && <QuestionFormSuccess clearAllModals={clearAllModals} onConfirm={handleOpenCommunities} />}
      {activeCommunitiesModal && (
        <FollowCommunitiesForm
          closeModal={handleCloseCommunities}
          communities={communitiesList.filter(item => item.heritage_group_title.toLowerCase() !== communitiesTypes.global)}
          allCommunityValue={allCommunityValue}
        />
      )}

      {error.favorites && (
        <Notification error close={removeAllError} time={5000}>
          Oooops. Something went wrong
        </Notification>
      )}

      <div className={styles.wrapper} ref={wrapperRef}>
        {preloaders.feed && <OverlayPreloader />}
        <PageNames />
        {isShowModal && (
          <FollowingModal
            preloader={preloaders.removeFollowing}
            followings={followings}
            removeFollowing={removeFollowing}
            onClose={handleHideModal}
          />
        )}
        <Tabs />
        {isShowContent ? (
          <>
            <div className={styles.searchContent}>
              <SearchIcon className={styles.searchIcon} />
              <input type="text" placeholder="Search" className={styles.search} value={search} onChange={handleChange} />
              {search ? (
                <button type="button" className={styles.reset} onClick={handleReset}>
                  Reset
                </button>
              ) : null}
            </div>

            <Filter filter={filter} setFilter={handleChangeTab} filterOptions={filterOptions.filter(item => item)} />
          </>
        ) : null}

        <div className={styles.flex}>
          {followings.length ? (
            <div className={styles.following}>
              <div className={styles.followingHeading}>
                <h6 className={styles.followingTitle}>Following ({followings.length})</h6>
                <button type="button" className={styles.followingEdit} onClick={handleShowModal}>
                  <PencilIcon />
                </button>
              </div>

              <div className={styles.followingList}>
                {followings.map(item => {
                  return (
                    <button key={item.name} type="button" className={styles.followingItem}>
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}

          {isShowContent ? (
            <div className={styles.content}>
              {(filter.value === profiles?.value || filter.value === all.value) && (
                <div className={styles.container}>
                  {count.profiles || myFeed.list.profiles?.length ? (
                    <div className={styles.heading}>
                      <span>People</span>
                      {filter.value !== profiles?.value && (
                        <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(profiles)}>
                          View All
                        </button>
                      )}
                    </div>
                  ) : null}

                  <Profiles
                    isExpand
                    onClick={() => handleChangeTab(profiles)}
                    isActive={filter.value === profiles?.value}
                    count={myFeed?.count?.profiles}
                    list={myFeed.list.profiles}
                    removeFavorite={removeFavorite}
                    addToFavorite={addToFavorite}
                  />

                  {filter.value === profiles?.value && !myFeed?.list?.profiles?.length ? (
                    <div className={styles.emptyContentImage}>
                      <EmptyIcon className={styles.icon} />
                    </div>
                  ) : null}
                </div>
              )}
              {(filter.value === organizations?.value || filter.value === all.value) && (
                <div className={styles.container}>
                  {count.organizations || myFeed.list.organizations?.length ? (
                    <div className={styles.heading}>
                      <span>Organizations</span>
                      {filter.value !== organizations?.value && (
                        <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(organizations, wrapperRef.current)}>
                          View All
                        </button>
                      )}
                    </div>
                  ) : null}

                  <Organizations
                    isExpand
                    onClick={() => handleChangeTab(organizations)}
                    isActive={filter.value === organizations?.value}
                    count={myFeed?.count?.organizations}
                    list={myFeed.list.organizations}
                    removeFavorite={removeFavorite}
                    addToFavorite={addToFavorite}
                    isOrganizations
                  />

                  {filter.value === organizations?.value && !myFeed?.list?.organizations?.length ? (
                    <div className={styles.emptyContentImage}>
                      <EmptyIcon className={styles.icon} />
                    </div>
                  ) : null}
                </div>
              )}
              {(filter.value === events?.value || filter.value === all.value) && (
                <div className={styles.container}>
                  {count.events || myFeed.list.events?.length ? (
                    <div className={styles.heading}>
                      <span>Events</span>
                      {filter.value !== events?.value && (
                        <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(events, true)}>
                          View All
                        </button>
                      )}
                    </div>
                  ) : null}
                  <Events
                    isExpand
                    onClick={() => handleChangeTab(events)}
                    isActive={filter.value === events?.value}
                    count={myFeed?.count?.events}
                    list={myFeed.list.events}
                    removeFavorite={removeFavorite}
                    addToFavorite={addToFavorite}
                  />

                  {filter.value === events?.value && !myFeed?.list?.events?.length ? (
                    <div className={styles.emptyContentImage}>
                      <EmptyIcon className={styles.icon} />
                    </div>
                  ) : null}
                </div>
              )}
              {(filter.value === jobs?.value || filter.value === all.value) && (
                <div className={styles.container}>
                  {count.jobs || myFeed.list.jobs?.length ? (
                    <div className={styles.heading}>
                      <span>Jobs</span>
                      {filter.value !== jobs?.value && (
                        <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(jobs, true)}>
                          View All
                        </button>
                      )}
                    </div>
                  ) : null}
                  <Jobs
                    isExpand
                    onClick={() => handleChangeTab(jobs)}
                    isActive={filter.value === jobs?.value}
                    organizations
                    count={myFeed?.count?.jobs}
                    list={myFeed.list.jobs}
                    removeFavorite={removeFavorite}
                    addToFavorite={addToFavorite}
                  />

                  {filter.value === jobs?.value && !myFeed?.list?.jobs?.length ? (
                    <div className={styles.emptyContentImage}>
                      <EmptyIcon className={styles.icon} />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.emptyContent}>
              <div className={styles.emptyContentImage}>
                <EmptyIcon className={styles.icon} />
              </div>
              <h1 className={styles.emptyTitle}>Your Feed is Looking a Little Empty</h1>
              <p className={styles.emptyDescription}>
                Follow people, communities, or jobs that connect you to your interests and heritage.
              </p>
            </div>
          )}
        </div>
      </div>
      <div ref={loaderRef} style={{ height: "50px" }} />
    </>
  );
};

export default Feed;
