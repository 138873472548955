import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Select from "../select";
import DropdownActions from "../dropdownActions";
import { customStyles as selectStyles } from "../../../constants/select";
import { track } from "../../../helpers/track";
import { eventName } from "../../../constants/track";
import styles from "./eventsAudiences.module.css";
import { useValidationError } from "../../../helpers/hooks";

const EventsAudiences = ({ validation, selectedEvents, submitChanges, showRequiredText, directoriesProfessions }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;
  const isRequiredText = validation.required && !selectedEvents?.length && showRequiredText;
  const [mainErrors, setMainErrors] = useState({});

  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const handleSort = array => {
    const selectedEventsIds = array.map(item => String(item.heritage_id));

    return directoriesProfessions.heritages
      .filter(item => selectedEventsIds.includes(String(item.heritage_id)))
      .map(item => ({
        title: item.title,
        heritage_id: item.heritage_id,
        prkey: item.prkey
      }))
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
  };

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(handleSort(selectedEvents));
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedEvents[index] ? selectedEvents[index] : item;
      });

      setSelectedList(handleSort(newList));
    }
  }, [selectedEvents.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ communities: updatedList }, "communities", "save_community");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newSelected = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newSelected);
      onSubmit(newSelected);
    } else {
      const newSelected = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newSelected);
      onSubmit(newSelected);
    }
  };

  const normalizeDirectoriesProfessionsHeritages = useMemo(() => {
    const selectedEventsIds = [...selectedEvents, ...selectedList].map(item => String(item.heritage_id));

    const heritages = directoriesProfessions.heritages
      .filter(item => !selectedEventsIds.includes(String(item.heritage_id)))
      .map(item => ({
        title: item.title,
        id: item.heritage_id,
        group_id: item.heritage_id
      }));

    return [...heritages, { title: "Suggest new community", id: "Custom" }];
  }, [directoriesProfessions.heritages, selectedList.length]);

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([
          ...selectedList,
          {
            audience_group: "",
            heritage_id: "",
            isNew: true
          }
        ]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedEvents.length;
  const title = `${validation?.custom_name || `Events Audience${selectedLength > 1 ? "s" : ""}`}${
    selectedLength ? ` (${selectedLength})` : ""
  }`;

  return (
    <Dropdown
      description={validation.custom_description}
      isRequired={isRequired}
      title={title}
      isRequiredText={isRequiredText}
      handleMouseMove={handleMouseMove}
    >
      <div>
        <div>
          {selectedList.map((item, index) => {
            return (
              <EventsAudiencesItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.percentage}-events-audience-${item.radius}-index-${index}`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                handleRemoveEmpty={handleRemove}
                selected={item}
                index={index}
                isRequired={isRequired}
                isNotRequired={isNotRequired}
                refButton={refButton}
                directoriesProfessions={directoriesProfessions}
                heritagesOptions={normalizeDirectoriesProfessionsHeritages}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleAddEmpty} buttonText="Add Audience" />
    </Dropdown>
  );
};

// todo cleanup after release
const EventsAudiencesItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  isRequired,
  handleRemoveEmpty,
  handleRemove,
  refButton,
  directoriesProfessions,
  heritagesOptions,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const { user } = useSelector(state => state.auth);
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const normalizeCommunities = useMemo(() => {
    const heritages = directoriesProfessions.heritages.map(item => ({
      title: item.title,
      id: item.heritage_id,
      group_id: item.heritage_id
    }));
    const directories = directoriesProfessions.directories.map(item => ({
      title: item.name,
      id: item.listings_dir_id,
      group_id: item.listings_dir_id
    }));
    const professions = directoriesProfessions.professions.map(item => ({
      title: item.title,
      id: item.profession_id,
      group_id: item.profession_id
    }));

    return { directories, heritages, professions };
  }, [directoriesProfessions.directories, directoriesProfessions.heritages, directoriesProfessions.professions]);

  const validate = values => {
    const { heritage_id } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!heritage_id) {
      errors.heritage_id = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selected,
      heritage_id: selected.heritage_id,
      audience_group: "heritages",
      isEdited: false
    },
    onSubmit: values => {
      const { heritage_id, audience_group, prkey } = values;
      const audienceGroupObject =
        heritage_id && normalizeCommunities[audience_group]?.length
          ? normalizeCommunities[audience_group].find(item => item.id === heritage_id)
          : {};

      return onSubmit({ heritage_id: audienceGroupObject.group_id, prkey }, isNew, index);
    },
    validate
  });

  const { values, errors, handleSubmit, setFieldValue, setFieldTouched } = formik;

  const handleSelectChange = newValue => {
    if (newValue.id === "Custom") {
      if (window && window.Intercom) {
        window.Intercom("show");
        window.Intercom("onShow", () => {
          track(eventName.viewContent, { em: user?.email, fn: user?.name_f, ln: user?.name_f });
        });
      }
    } else {
      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }

      if (!isEdited) {
        setIsEdited(true);
      }

      setFieldValue("heritage_id", newValue.id);

      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onHandleSubmit = () => {
    setFieldTouched("heritage_id");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if ((values.heritage_id || values.audience_group) && values.isEdited && !Object.keys(errors).length) {
        handleSubmit();
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if ((values.heritage_id || values.audience_group) && isEdited && values.isEdited && !Object.keys(errors).length) {
            handleSubmit();
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { eventsAudiences: "Error" } : "isRemove", "eventsAudiences");

  return (
    <div
      className={styles.item}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      ref={containerRef}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.flex}>
        <div className={styles.flexItem}>
          <Select
            error={errors.heritage_id}
            isInvalid={errors.heritage_id}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            inputId="heritage_id"
            name="heritage_id"
            options={heritagesOptions}
            onBlur={() => handleBlur("heritage_id")}
            noOptionsMessage={() => "Please select profession"}
            handleChange={handleSelectChange}
            styles={selectStyles}
            required={isRequired}
            value={
              values.heritage_id && normalizeCommunities[values.audience_group]?.length
                ? normalizeCommunities[values.audience_group].find(item => item.id === values.heritage_id)
                : []
            }
            placeholder={t("dashboard_listings_edit_community_placeholder")}
            classNameWrap={styles.selectWrap}
          />
        </div>

        <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
      </div>
      <RemoveButton className={styles.removeButton} onClick={onRemove} />
    </div>
  );
};

export default EventsAudiences;
