import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";

import { Button } from "../../common/button";
import Input from "../../common/input";
import styles from "./setNewPass.module.css";
import eyeIcon from "../../../media/icons/eye-paswd.svg";
import { actions } from "../../../actions/auth";
import { OverlayPreloader } from "../../common/preloader";
import { useDocTitle } from "../../../helpers/hooks";
import { ReactComponent as MainLogo } from "../../../media/icons/mainLogo.svg";

const SetNewPass = () => {
  const [isShown, setShow] = useState({ password2: null, password: null });
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setNewPassword = useCallback((routerHistory, passwordData) => dispatch(actions.setNewPassword(routerHistory, passwordData)), [
    dispatch
  ]);

  const {
    preloaders: { setNewPassword: loading },
    code,
    errorResetPassword
  } = useSelector(state => state.auth);

  useDocTitle(t("dashboard_forgotpassword_metatitle"));

  const validate = values => {
    const { password, password2 } = values;
    const errors = {};
    const requiredText = t("dashboard_form_validations_password_required");

    if (!password2) {
      errors.password2 = requiredText;
    } else if (password !== password2) {
      errors.password2 = t("dashboard_form_validations_password_match");
    }

    if (!password) {
      errors.password = requiredText;
    } else if (password.length < 7) {
      errors.password = t("dashboard_form_validations_password_short");
    } else if (!password.match(/[A-Z]/)) {
      errors.password = t("dashboard_form_validations_password_uppercase");
    } else if (!password.match(/[a-z]/)) {
      errors.password = t("dashboard_form_validations_password_lowercase");
    } else if (!password.match(/\d/)) {
      errors.password = t("dashboard_form_validations_password_number");
    } else if (!password.match(/[!@#$%^&]/)) {
      errors.password = t("dashboard_form_validations_password_specialcharacter");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password2: "",
      password: ""
    },
    validate,
    onSubmit: values => {
      setNewPassword(history, {
        password: values.password,
        password2: values.password2,
        code
      });
    }
  });

  const { errors, touched, values, setErrors } = formik;

  const handleBlur = inputName => {
    formik.setFieldTouched(inputName, true);
  };

  useEffect(() => {
    if (errorResetPassword) {
      setErrors({ password: " ", password2: t(errorResetPassword) });
    }
  }, [errorResetPassword, setErrors]); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      {loading && <OverlayPreloader />}
      <MainLogo className={styles.mainLogo} />
      <h2 className={styles.title}>{t("dashboard_login_password")}</h2>
      <p className={styles.subtitle}>{t("dashboard_password_reset_newpassword")}</p>

      <p className={styles.description}>{t("dashboard_password_reset_criteria")}</p>
      <form className={styles.authForm} onSubmit={formik.handleSubmit}>
        <div className="relative">
          <Input
            className={styles.input}
            classNameWrap={styles.inputWrap}
            error={errors.password}
            id="new-pass"
            isInvalid={errors.password && touched.password}
            name="password"
            label={t("dashboard_password_reset_newpassword")}
            onBlur={e => handleBlur(e.target.name)}
            onChange={formik.handleChange}
            required
            type={isShown.password ? "text" : "password"}
            value={values.password}
          />

          <Button onClick={() => setShow({ ...isShown, password: !isShown.password })} className={cn("button--clear", styles.showPassBtn)}>
            <img className={styles.icon} src={eyeIcon} alt="" />
            <span className={styles.text}>{t("dashboard_password_reset_show")}</span>
          </Button>
        </div>

        <div className="relative">
          <Input
            className={styles.input}
            classNameWrap={styles.inputWrap}
            error={errors.password2}
            id="confirm-pass"
            isInvalid={errors.password2 && touched.password2}
            name="password2"
            label={t("dashboard_password_reset_confirmpassword")}
            onBlur={e => handleBlur(e.target.name)}
            onChange={formik.handleChange}
            required
            type={isShown.password2 ? "text" : "password"}
            value={values.password2}
          />
          <Button
            onClick={() => setShow({ ...isShown, password2: !isShown.password2 })}
            className={cn("button--clear", styles.showPassBtn)}
          >
            <img className={styles.icon} src={eyeIcon} alt="" />
            <span className={styles.text}>{t("dashboard_password_reset_show")}</span>
          </Button>
        </div>

        <Button className={styles.submit} type="submit">
          {t("dashboard_password_reset_button")}
        </Button>
      </form>
      <hr className={styles.hr} />
      <Link to="/login" className={styles.link}>
        {t("dashboard_password_reset_back")}
      </Link>
    </div>
  );
};

export default SetNewPass;
