import React, { useCallback } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../../../media/icons/edit-listing-icon.svg";
import { Button } from "../../../../common/button";
import { actions as mainActions } from "../../../../../actions/main";
import styles from "./heading.module.css";

export const Heading = ({ onClose }) => {
  const dispatch = useDispatch();
  const setViewAllNotification = useCallback(() => dispatch(mainActions.setViewAllNotification()), [dispatch]);
  const { notifications, unread_notifications: unreadNotifications } = useSelector(state => state.account.profile);
  const isShowMarkAssRead = Number(unreadNotifications) > 0 && notifications.length;

  return (
    <div className={styles.popupHeading}>
      <div>
        <p className={styles.name}>Notifications</p>
        {isShowMarkAssRead && (
          <Button onClick={setViewAllNotification} className={cn(styles.subtitle, "button--clear")}>
            Mark All as Read
          </Button>
        )}
      </div>
      <Link to="/manage-notification" className={styles.edit} onClick={onClose}>
        <EditIcon />
      </Link>
    </div>
  );
};
