import React from "react";

import ListingContent from "../listingContent";
import ProfileListing from "../profileListing";

const Profiles = ({ isExpand, onClick, isActive, list, addToFavorite, removeFavorite, count }) => {
  return (
    <>
      {list?.length ? (
        <>
          {isExpand ? (
            <>
              {list?.length ? <ProfileListing removeFavorite={removeFavorite} addToFavorite={addToFavorite} isExpand list={list} /> : null}
            </>
          ) : (
            <ListingContent title="Profiles" count={count || ""} onClick={onClick} isActive={isActive}>
              {list?.length ? <ProfileListing list={list} addToFavorite={addToFavorite} removeFavorite={removeFavorite} /> : null}
            </ListingContent>
          )}
        </>
      ) : null}
    </>
  );
};

export default Profiles;
