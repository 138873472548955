import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { ReactComponent as EmptyIcon } from "../../../../media/icons/search-empty.svg";
import { actions as questionsActions } from "../../../../actions/questions";
import { QUESTION_TYPES } from "../../../../constants/questionsAnswers";
import { OverlayPreloader } from "../../../common/preloader";
import { TabNav } from "../../../common/tabs";
import { QuestionBox } from "../questionBox";
import { Pagination } from "../../../common/pagination";
import styles from "./tabsContent.module.css";

export const TabsContent = ({ questionsToRender, listingsCount, isQaExist, isAnswersExist, children }) => {
  const dispatch = useDispatch();
  const { preloader, pagination } = useSelector(state => state.questions);
  const saveQuestion = questionId => dispatch(questionsActions.saveQuestion(questionId));
  const unSaveQuestion = questionId => dispatch(questionsActions.unSaveQuestion(questionId));
  const { profile } = useSelector(state => state.account);

  const navigationTabs = useMemo(() => {
    const navigations = [];

    if (isQaExist && isAnswersExist) {
      navigations.push({
        text: "All",
        param: "all"
      });
    }

    if (isQaExist || !listingsCount) {
      navigations.push({
        text: "My Questions",
        param: "my-questions"
      });
    }

    if (listingsCount) {
      navigations.push({
        text: "My Answers",
        param: "my-answers"
      });
    }

    return navigations;
  }, [isQaExist, listingsCount, isAnswersExist]);

  const history = useHistory();
  const { location } = history;
  const searchParams = queryString.parse(location.search);

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = pagination.lastPageNum || 0;

    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/question-answer", search: queryString.stringify({ ...searchParams, page: i }), number: i });
    }

    return navLinks;
  };

  const isShowPagination = useMemo(() => {
    if (searchParams.tab === "my-questions") {
      return (
        (questionsToRender.qa.length && questionsToRender.qa.length >= pagination.perPage && pagination.lastPageNum !== 0) ||
        Number(searchParams.page) >= 1
      );
    }
    if (searchParams.tab === "all") {
      return (
        (questionsToRender.all?.length && questionsToRender.all?.length >= pagination.perPage && pagination.lastPageNum !== 0) ||
        Number(searchParams.page) >= 1
      );
    }
    return (
      (questionsToRender.answers.length && questionsToRender.answers.length >= pagination.perPage && pagination.lastPageNum !== 0) ||
      Number(searchParams.page) >= 1
    );
  }, [questionsToRender.qa, questionsToRender.answers, questionsToRender.all]);

  const renderQuestions = (array = [], questionType) => {
    if (!array.length) {
      return (
        <div className={styles.empty}>
          <EmptyIcon />
          <p className={styles.text}>Your questions and answers will appear here</p>
        </div>
      );
    }

    return array.map(
      ({ added, city, question_answer, question_id: questionId, saved, state, summary, title, answers, user_id: userId }) => {
        return (
          <QuestionBox
            added={added}
            answer={question_answer}
            answerTimes={answers}
            city={city}
            classNames={styles.box}
            key={questionId}
            questionId={questionId}
            saved={Boolean(Number(saved))}
            saveQuestion={saveQuestion}
            state={state}
            summary={summary}
            tab={questionType || String(userId) === String(profile.user_id) ? QUESTION_TYPES.QUESTIONS : QUESTION_TYPES.MY_ANSWER}
            textClass={styles.boxTitle}
            title={title}
            unSaveQuestion={unSaveQuestion}
          />
        );
      }
    );
  };

  const questionTab =
    isQaExist || !listingsCount
      ? [<React.Fragment key="1">{renderQuestions(questionsToRender.qa, QUESTION_TYPES.QUESTIONS)}</React.Fragment>]
      : null;

  const allQuestionAndAnswers =
    isQaExist || !listingsCount ? [<React.Fragment key="3">{renderQuestions(questionsToRender.all)}</React.Fragment>] : null;

  return (
    <div className={styles.content}>
      {children}
      {preloader && <OverlayPreloader />}
      <TabNav
        page="question-answer"
        nav={navigationTabs}
        content={[
          allQuestionAndAnswers,
          questionTab,
          listingsCount
            ? [<React.Fragment key="2">{renderQuestions(questionsToRender.answers, QUESTION_TYPES.MY_ANSWER)}</React.Fragment>]
            : null
        ].filter(item => Boolean(item))}
      />
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          links={makePaginationLinks()}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
        />
      )}
    </div>
  );
};
