import React from "react";

import PublicHeader from "../publicHeader";
import Footer from "../footer";

const PublicLayout = ({ children }) => {
  return (
    <>
      <PublicHeader />
      {children}
      <Footer />
    </>
  );
};

export default PublicLayout;
