import React from "react";
import cn from "classnames";

import spinner from "../../../media/icons/spinner.gif";
import styles from "./preloader.module.css";

export const Preloader = ({ className }) => {
  return <img src={spinner} alt="loading" className={cn(className, styles.preloader)} />;
};

export const OverlayPreloader = ({ spinnerClassName, overlayClassName }) => {
  return (
    <React.Fragment>
      <div className={cn("sectionOverlay", overlayClassName)} />
      <img src={spinner} alt="loading" className={cn(spinnerClassName, styles.preloader)} />
    </React.Fragment>
  );
};

export const OverlayPreloaderAbsolute = ({ spinnerClassName, overlayClassName }) => {
  return (
    <React.Fragment>
      <div className={cn(styles.sectionOverlayAbsolute, overlayClassName)} />
      <img src={spinner} alt="loading" className={cn(spinnerClassName, styles.preloaderAbsolute)} />
    </React.Fragment>
  );
};
