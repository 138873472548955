import { payPerLeadDetail } from "../constants/actionTypes";

const defaultModals = {
  addCard: false,
  payment: false,
  success: false,
  editCard: false,
  confirm: false
};

const defaultState = {
  error: null,
  lead: [],
  loading: true,
  practicesLoading: true,
  success: {
    hideLead: false,
    buyLead: false
  },
  coupon: {
    preloader: false,
    code: "",
    price: "",
    discountType: "",
    discountUsd: "",
    discount: "",
    couponError: false
  },
  modals: {
    ...defaultModals
  },
  practices: []
};

export default function(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case payPerLeadDetail.SET_LOADING:
      return {
        ...state,
        loading: true
      };

    case payPerLeadDetail.RESET_SUCCESS: {
      return {
        ...state,
        success: { ...state.success, [payload]: false }
      };
    }

    case payPerLeadDetail.RESET_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    case payPerLeadDetail.GET_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false
      };

    case payPerLeadDetail.GET_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case payPerLeadDetail.BUY_LEAD:
      return {
        ...state,
        loading: false,
        success: { ...state.success, buyLead: "Lead Purchased" },
        modals: {
          ...defaultModals,
          success: true
        }
      };

    case payPerLeadDetail.BUY_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case payPerLeadDetail.HIDE_LEAD:
      return {
        ...state,
        loading: false,
        success: { ...state.success, hideLead: "Lead Hidden" }
      };

    case payPerLeadDetail.HIDE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case payPerLeadDetail.CLEAR_LEAD:
      return {
        ...state,
        lead: []
      };

    case payPerLeadDetail.TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        }
      };

    case payPerLeadDetail.CHECK_LOADING_LOADING:
      return {
        ...state,
        coupon: {
          preloader: true,
          code: "",
          price: "",
          discountType: "",
          discountUsd: "",
          discount: "",
          couponError: false
        }
      };

    case payPerLeadDetail.CHECK_COUPON_CLEAR:
      return {
        ...state,
        coupon: {
          preloader: false,
          code: "",
          price: "",
          discountType: "",
          discountUsd: "",
          discount: "",
          couponError: false
        }
      };

    case payPerLeadDetail.CHECK_COUPON:
      return {
        ...state,
        coupon: {
          preloader: false,
          code: payload.code,
          price: payload.price,
          discountType: payload.discountType,
          discountUsd: payload.discountUsd,
          discount: payload.discount,
          couponError: false
        }
      };

    case payPerLeadDetail.CHECK_COUPON_FAIL:
      return {
        ...state,
        coupon: {
          preloader: false,
          couponError: true
        }
      };

    case payPerLeadDetail.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultModals
        }
      };

    case payPerLeadDetail.GET_PRACTICES:
      return {
        ...state,
        practices: payload,
        practicesLoading: false
      };

    case payPerLeadDetail.GET_PRACTICES_FAIL:
      return {
        ...state,
        practicesLoading: false
      };

    case payPerLeadDetail.RESET_ALL_SUCCESS:
      return {
        ...state,
        success: { ...defaultState.success }
      };
    default:
      return {
        ...state
      };
  }
}
