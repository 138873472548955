import isBefore from "date-fns/isBefore";

import { validationNames, statuses, imageDelivery } from "../constants/listings";
import { statusesConstant, statusesConstantKeys } from "../constants/referralRequestDetail";
import { WEBSITE } from "../constants/regex";
import { mainColors } from "../constants/colors";
import visaIcon from "../media/icons/visa-logo.svg";
import americanExprIcon from "../media/icons/american-express.svg";
import masterCardBlack from "../media/icons/mastercard-black.svg";
import discoverIcon from "../media/icons/discover-logo-black.svg";

export const getStateName = (stateList, stateCode) => {
  return stateList?.find(state => state.state === stateCode)?.title;
};

export const disableScroll = () => {
  document.body.classList.add("disableScroll");
};

export const enableScroll = () => {
  document.body.classList.remove("disableScroll");
};

export const formatDuration = duration => {
  if (duration === "0") {
    return "-";
  }

  const d = new Date(duration * 1000);
  const m = d.getUTCMinutes();
  const s = d.getUTCSeconds();
  return m ? `${m}m ${s}s` : `${s}s`;
};

export const getPracticeById = (practiceList, practiceId, returnFull = false) => {
  const area = practiceList?.find(({ id }) => String(id) === String(practiceId));
  return returnFull ? area : area?.title;
};

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export const priceFormatterCurrency = currency =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD"
  });

export const getIsStateSelectionValid = (item, itemCountryFieldName, itemStateFieldName) => {
  const state = item[itemStateFieldName];
  const country = item[itemCountryFieldName];
  const countryOfState = state.includes("-") ? state.split("-")[0] : "US";
  return !(state !== "" && country !== countryOfState);
};

export const socialsToObject = (socials, isNew) => {
  const accumulator = {};
  return socials.reduce((obj, item) => {
    let reducedObject = {};

    if (isNew) {
      reducedObject = Object.assign(obj, {
        [item.social_name]: { social_name: item.social_name, social_url: item.social_url, isNew: true }
      });
    } else {
      reducedObject = Object.assign(obj, { [item.social_name]: { social_name: item.social_name, social_url: item.social_url } });
    }

    return reducedObject;
  }, accumulator);
};

export const socialsToArray = socials => {
  const socialsArray = [];
  const entries = Object.entries(socials);
  entries.forEach(entry => socialsArray.push(entry[1]));
  return socialsArray;
};

// eslint-disable-next-line
export const detectDuplicateEntry = (matchParam, arrayCurrent, editedItem) => {
  return arrayCurrent.some(item => item[matchParam] === editedItem[matchParam]);
};

export const detectDuplicateEntryPractices = (matchParam, arrayCurrent, editedItem) => {
  return arrayCurrent.some(item => String(item[matchParam]) === String(editedItem));
};

export const detectDuplicateAllFields = (arrayCurrent, newItem, index) => {
  const newItemKeys = Object.keys(newItem);

  const findDuplicate = arrayCurrent.filter((item, indexItem) => {
    return newItemKeys.every(newItemKey => {
      return item[newItemKey] === newItem[newItemKey] && indexItem !== index;
    });
  });

  return Boolean(findDuplicate?.length);
};

export const normalizeFollowupQuestionOptions = (answer, isValue) => {
  const { answer_options, lead_followup_id } = answer;
  const normalizeAnswerOptions = typeof answer_options === "string" ? JSON.parse(answer_options) : answer_options;
  const { options: answerOptions } = normalizeAnswerOptions;

  if (!Array.isArray(answerOptions) && typeof answerOptions === "object") {
    const optionsArray = [];
    Object.keys(answerOptions).forEach((option, index) => {
      return optionsArray.push({
        answer: isValue ? option : index.toString(),
        label: answerOptions[option],
        lead_followup_id
      });
    });
    return optionsArray;
  }
  if (Array.isArray(answerOptions)) {
    return [
      ...answerOptions.map((option, index) => {
        return { answer: isValue ? option : index.toString(), label: option, lead_followup_id };
      })
    ];
  }
  return answerOptions;
};

export const paginate = (source, itemsPerPage = 25, currentPage) => {
  const offset = currentPage * itemsPerPage;
  return source.slice(offset).slice(0, itemsPerPage);
};

export const getFormattedDateTimeZone = str => {
  const currentDate = new Date();
  const timeZone = currentDate.getTimezoneOffset() / 60;

  const date = new Date(str);
  return new Date(date.setHours(date.getHours() + -timeZone));
};

export const replacingSpaceUnderscore = string => {
  return string.replace(/ /g, "_").toLowerCase();
};

export const getQueryParams = str => {
  return str
    .slice(1)
    .split("&")
    .reduce((total, current) => {
      const array = current.split("=");
      return {
        ...total,
        [array[0]]: array[1]
      };
    }, {});
};

export const getNameWebsiteFromUrl = (url = "") => {
  return url.replace(WEBSITE, "").split("/")[0];
};

export const filterBySearchValue = (questionsArr, searchValue) => {
  return questionsArr?.filter(question => {
    const questionTitle = question.title.toLowerCase();
    const questionSummary = question.summary.toLowerCase();
    return questionSummary.indexOf(searchValue.toLowerCase()) !== -1 || questionTitle.indexOf(searchValue.toLowerCase()) !== -1;
  });
};

export const filterBookmarked = (isSaved, array) => (!isSaved ? array : array.filter(({ saved }) => String(saved) === "1"));

export const getMessageByType = (messages, payload) => {
  const messageByType = messages.filter(({ email_conversation_id }) => email_conversation_id === payload);

  const index = messages.findIndex(({ email_conversation_id }) => email_conversation_id === payload);
  const newMessages = [...messages];
  newMessages.splice(index, 1);

  return { messageByType, newMessages };
};

export const getValidationData = (validations, name) => {
  if (!validations.length) return {};

  return validations.find(({ title }) => title.toLowerCase() === name.toLowerCase()) || {};
};

export const getFillingFields = ({ validations, memberData }) => {
  const {
    cover_image_url: defaultImage,
    attorney,
    practices,
    languages,
    description,
    website,
    title,
    lawfirms,
    licenses,
    educations,
    associations,
    socials,
    primary_image_url: socialPhoto,
    intro_video: introVideo,
    parent_company: parentCompany,
    tags,
    event_tickets: eventTickets,
    event_schedules: eventSchedules,
    jobs,
    communities,
    bookmeetings
  } = memberData;
  if (!validations.length) {
    return false;
  }

  // const isEmptyDescription = description.includes("<p></p>") && description?.length === 8 ? true : !description?.length;
  const isEmptyBookMeetings = getValidationData(validations, validationNames.calendar).required && !bookmeetings.length;
  const isEmptyCommunities = getValidationData(validations, validationNames.communities).required && !communities.length;
  const isEmptyJobs = getValidationData(validations, validationNames.job).required && !jobs.length;
  const isEmptyTags = getValidationData(validations, validationNames.tags).required && !tags.length;
  const isEventsTickets = getValidationData(validations, validationNames.events).required && !eventTickets.length && !eventSchedules.length;
  const isEmptyVideo = getValidationData(validations, validationNames.video).required && !introVideo.length;
  const isEmptyParentCompany = getValidationData(validations, validationNames.parentOrganization).required && !parentCompany?.length;
  const isEmptyPictures = getValidationData(validations, validationNames.pictures).required && !defaultImage;
  const isEmptyName = getValidationData(validations, validationNames.name).required && !attorney?.length;
  const isEmptyPractices = getValidationData(validations, validationNames.specialties).required && !practices?.length;
  const isEmptyLanguages = getValidationData(validations, validationNames.language).required && !languages?.length;
  const isEmptyBio = getValidationData(validations, validationNames.bio).required && !description.length;
  const isEmptyBusiness = getValidationData(validations, validationNames.business).required && !title?.length;
  const isEmptyWebsite = getValidationData(validations, validationNames.website).required && !website?.length;
  const isEmptySocations = getValidationData(validations, validationNames.locations).required && !lawfirms?.length;
  const isEmptyLicenses = getValidationData(validations, validationNames.licenses).required && !licenses?.length;
  const isEmptyEducations = getValidationData(validations, validationNames.educations).required && !educations?.length;
  const isEmptyAssociations = getValidationData(validations, validationNames.associations).required && !associations?.length;
  const isEmptySocialMedia = getValidationData(validations, validationNames.socialMedia).required && !socials?.length;
  const isEmptySocialPhoto = getValidationData(validations, validationNames.coverImage).required && !socialPhoto;

  return (
    isEmptyPictures ||
    isEventsTickets ||
    isEmptyParentCompany ||
    isEmptyVideo ||
    isEmptyName ||
    isEmptyPractices ||
    isEmptyLanguages ||
    isEmptyBio ||
    isEmptyBusiness ||
    isEmptyWebsite ||
    isEmptySocations ||
    isEmptyCommunities ||
    isEmptyLicenses ||
    isEmptyEducations ||
    isEmptyAssociations ||
    isEmptySocialMedia ||
    isEmptyTags ||
    isEmptyJobs ||
    isEmptyBookMeetings ||
    isEmptySocialPhoto
  );
};

export const getActiveStatuses = statusActive => {
  switch (statusActive) {
    case statusesConstantKeys.missingInformation:
      return statusesConstant.missingInformation;
    case statusesConstantKeys.matches:
      return statusesConstant.matches;
    case statusesConstantKeys.match:
      return statusesConstant.match;
    case statusesConstantKeys.notMatch:
      return statusesConstant.notMatch;
    case statusesConstantKeys.reviewed:
      return statusesConstant.reviewed;
    case statusesConstantKeys.rejected:
      return statusesConstant.rejected;
    case statusesConstantKeys.pendingReview:
      return statusesConstant.pendingReview;
    case statusesConstantKeys.requestReceived:
      return statusesConstant.requestReceived;
    default:
      return "";
  }
};

export const getActiveListings = listings => {
  return listings.filter(item => item.status === statuses.active);
};

export const getDraftListings = listings => {
  return listings.filter(item => item.status === statuses.draft);
};

export const getPendingListings = listings => {
  return listings.filter(item => item.status === statuses.pending);
};

export const getDataIntercom = listings => {
  const activeListings = getActiveListings(listings);

  if (!window.intercomSettings) {
    window.intercomSettings = {};
  }

  if (activeListings.length) {
    activeListings.slice(0, 5).forEach((item, index) => {
      const number = index + 1;
      const directoryUrl = `Current Directory ${number} URL`;
      const directoryName = `Current Directory ${number} Name`;

      window.intercomSettings[directoryUrl] = item.listing_url;
      window.intercomSettings[directoryName] = item.membership;
    });
  }

  window.intercomSettings["Active Listings"] = activeListings.length;
  window.intercomSettings["Draft Listings"] = getDraftListings(listings).length;
  window.intercomSettings["Pending Listings"] = getPendingListings(listings).length;
};

export const getResetPasswordError = status => {
  if (status === 400) {
    return "dashboard_forgotpassword_error_400";
  }

  if (status === 402) {
    return "dashboard_forgotpassword_error_402";
  }

  if (status === 403) {
    return "dashboard_forgotpassword_error_403";
  }

  return "";
};

export const concatSimilarArray = array => {
  return array.reduce((accumulator, current) => {
    const activeIndex = accumulator.findIndex(item => item.title === current.title);
    if (activeIndex !== -1) {
      accumulator[0].leads = Number(accumulator[0].leads) + Number(current.leads);
      return accumulator;
    }
    return [...accumulator, current];
  }, []);
};

export const formatPhoneNumber = phone => {
  const cleaned = `${phone}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3,4})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return null;
};

export const renderBrandIcon = brand => {
  switch (brand) {
    case "Visa":
      return visaIcon;
    case "American Express":
      return americanExprIcon;
    case "MasterCard":
      return masterCardBlack;
    case "Discover":
      return discoverIcon;
    case "Unknown":
      return undefined;
    default:
      return undefined;
  }
};

export const getInitialsListing = (itemToEdit = { attorney: "", membership: "" }) => {
  const [firstAttorney, secondAttorney] = itemToEdit?.attorney?.split(" ");
  const [firstMembership, secondMembership] = itemToEdit?.membership ? itemToEdit?.membership?.split(" ") : ["", ""];
  let initials = "";

  if (firstAttorney) {
    initials += firstAttorney[0];
  }

  if (secondAttorney) {
    initials += secondAttorney[0];
  }

  if (!firstAttorney && !secondAttorney && firstMembership && secondMembership) {
    initials += firstMembership[0] + secondMembership[0];
  }

  return initials;
};

const compare = (a, b) => {
  return isBefore(new Date(a.sent), new Date(b.sent)) ? 1 : -1;
};

export const sortMessagesByDate = arrMessages => {
  return arrMessages?.sort(compare);
};

export const optionsToArray = options => {
  if (!options) {
    return [];
  }
  // restructure listing filter object to array to fit react select
  return Object.entries(options).reduce((accumulator, current) => {
    return accumulator.concat(current[1]);
  }, []);
};

export const isHaveNumber = string => {
  return /\d/.test(string);
};

export const normalizeSubscriptions = (subscriptions, cards) => {
  const normalizeCards = cards.reduce((accumulator, current) => {
    accumulator[current.id] = { brand: current.brand, last4: current.last4 };
    return accumulator;
  }, []);

  return subscriptions.map(subscription => {
    return { ...subscription, ...normalizeCards[subscription.card_token] };
  });
};

export const getListOfYears = from => {
  const now = new Date().getUTCFullYear();

  return Array(now - (now - from || 1970))
    .fill("")
    .map((v, idx) => now - idx);
};

export const getColorsItem = array => {
  let number = -1;

  const getColorNumber = () => {
    if (number >= mainColors.length - 1) {
      number = -1;
    }

    number++;
    return number;
  };

  return array
    .map(item => {
      if (!item.image) {
        return { ...item, colorNumber: getColorNumber() };
      }
      return item;
    })
    .map(item => {
      if (String(item.colorNumber)) {
        return { ...item, background: mainColors[item.colorNumber].background, color: mainColors[item.colorNumber].color };
      }
      return item;
    });
};

export const getCookie = name => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(`${name}=`) === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return "";
};

export const getCountDaysInMonth = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  return lastDayOfMonth.getDate();
};

export const createDaysArrayForCurrentMonth = () => {
  return Array.from({ length: getCountDaysInMonth() }, (_, index) => index);
};

export const isLastDayOfMonth = (date, day) => {
  const currentDate = new Date(date);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const nextMonthFirstDay = new Date(currentYear, currentMonth + 1, 1);

  const lastDayOfMonth = new Date(nextMonthFirstDay - 1);

  return day === lastDayOfMonth.getDate();
};

export const getOccurrenceOfMonth = date => {
  const currentDate = new Date(date);
  const targetWeekday = new Date(date).getDay();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  let occurrence = 0;

  for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
    const currentDay = new Date(year, month, day);
    const currentWeekday = currentDay.getDay();

    if (currentWeekday === targetWeekday) {
      occurrence++;
      if (currentDay.getDate() === currentDate.getDate()) {
        return occurrence;
      }
    }
  }

  return -1;
};

export const createImageDeliveryUrl = imageUrl => `${imageDelivery}${imageUrl}`;

export const compareMsg = (a, b) => {
  return isBefore(new Date(a.sent), new Date(b.sent)) ? 1 : -1;
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

export const getCoverCroppedImg = (imageSrc, crop, rotation, flip = { horizontal: false, vertical: false }) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const rotRad = getRadianAngle(rotation);

      const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

      canvas.width = bBoxWidth;
      canvas.height = bBoxHeight;

      ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
      ctx.rotate(rotRad);
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      ctx.translate(-image.width / 2, -image.height / 2);

      ctx.drawImage(image, 0, 0);

      const data = ctx.getImageData(crop.x, crop.y, crop.width, crop.height);

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.putImageData(data, 0, 0);

      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    };
    image.onerror = () => reject(new Error("Failed to load image"));
  });
};

export const getCroppedImg = (imageSrc, crop, rotation = 0, flip = { horizontal: false, vertical: false }) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const rotRad = getRadianAngle(rotation);
      const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

      canvas.width = bBoxWidth;
      canvas.height = bBoxHeight;

      ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
      ctx.rotate(rotRad);
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      ctx.translate(-image.width / 2, -image.height / 2);
      ctx.drawImage(image, 0, 0);

      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = crop.width;
      croppedCanvas.height = crop.height;
      const croppedCtx = croppedCanvas.getContext("2d");

      croppedCtx.drawImage(canvas, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

      croppedCanvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    };

    image.onerror = () => reject(new Error("Failed to load image"));
  });
};

export const sortBySponsorAndDate = items => {
  return items.sort((a, b) => {
    if (a.is_premium !== b.is_premium) {
      return a.is_premium ? -1 : 1;
    }
    return new Date(b.added) - new Date(a.added);
  });
};

export const normalizeMembershipInsurances = (insurances, insuranceGroups) => {
  return insurances?.reduce((total, insurance) => {
    const network = insuranceGroups.networks.find(item => String(insurance.network_id) === String(item.insurance_networks_id));
    const company = insuranceGroups.companies.find(item => String(insurance.company_id) === String(item.insurance_company_id));
    const plan = insuranceGroups.plans.find(item => String(insurance.plan_id) === String(item.insurance_plan_id));

    total.push({
      plan,
      network,
      company,
      other_plan: insurance.other_plan,
      other_company: insurance.other_company,
      other_network: insurance.other_network
    });

    return total;
  }, []);
};
