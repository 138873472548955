import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import Keycloak from "keycloak-js";

import { actions as authActions } from "./actions/auth";
import { actions as mainActions } from "./actions/main";
import { actions as signUpActions } from "./actions/signup";
import RegistrationContainer from "./containers/registration";
import ProfessionCommunities from "./containers/professionCommunities";
import PrivateRoutesContainer from "./containers/privateRoutesContainer";
import ForgotPassword from "./components/passwordReset/forgotPassword";
import Forbidden from "./components/forbidden";
import VerificationCode from "./components/passwordReset/verificationCode";
import SetNewPass from "./components/passwordReset/setNewPass";
import ResetPasswordSuccess from "./components/passwordReset/resetPasswordSuccess";
import Communities from "./containers/communities";
import Pricing from "./containers/pricing";
import SearchResult from "./containers/searchResult";
import Landing from "./containers/landing";
import PublicListing from "./containers/publicListing";
import PublicOrganizations from "./containers/publicOrganizations";
import PublicProfile from "./containers/publicProfile";
import Login from "./containers/login";
import Logout from "./containers/logout";
import { useIntercom, useKeycloakAuth } from "./helpers/hooks";
import { LeadAlreadyPurchased } from "./containers/leadAlreadyPurchased";
import { LeadExpired } from "./containers/leadExpired";
import { getQueryParams } from "./helpers";
import { Notification } from "./components/common/notification";
import { actions as profileActions } from "./actions/account/profile";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
});

const Routes = () => {
  const { profile, isLoggedIn, loginMethods, preloaders, errors, listings, footerInfo, socialLinks } = useSelector(state => ({
    profile: state.account.profile,
    isLoggedIn: state.main.isLoggedIn,
    loginMethods: state.auth.loginMethods,
    preloaders: state.auth.preloaders,
    errors: state.auth.errors,
    listings: state.listing.memberships,
    footerInfo: state.main.footerInfo,
    socialLinks: state.main.socialLinks
  }));

  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const logoutAction = useCallback(() => dispatch(mainActions.logout()), [dispatch]);
  const setLoggedIn = useCallback(isLoggedInValue => dispatch(authActions.setLoggedIn(isLoggedInValue)), [dispatch]);
  const login = useCallback(credentials => dispatch(authActions.login(credentials)), [dispatch]);
  const getFooterInfo = useCallback(() => dispatch(mainActions.getFooterInfo()), [dispatch]);
  const getPublicProfessions = useCallback(profession => dispatch(signUpActions.getPublicProfessions(profession)), [dispatch]);
  const getHeritages = useCallback(profession => dispatch(signUpActions.getCountries(profession)), [dispatch]);
  const { professionsList, countriesList } = useSelector(state => state.signUp);

  const currentProfession = useMemo(() => {
    return location.pathname
      .replace("-communities", "")
      .replace("/", "")
      .toLowerCase();
  }, [location.pathname]);

  const professionalPathNames = useMemo(() => {
    return countriesList?.length && professionsList?.length
      ? [...countriesList, ...professionsList].map(({ title }) => `/${title.toLowerCase()}-communities`)
      : [];
  }, [countriesList?.length, professionsList?.length]);

  const isCommunities = useMemo(() => location.pathname.includes("-communities"), [location.pathname]);
  const isCommunitiesDefault = useMemo(() => location.pathname.includes("communities"), [location.pathname]);
  const isPublicListingPage = useMemo(() => {
    return (
      location.pathname.includes("public-listing") ||
      location.pathname.includes("public-organizations") ||
      location.pathname.includes("public-profile")
    );
  }, [location.pathname]);
  const queryParams = useMemo(() => getQueryParams(location.search), [location.search]);
  const isGlobalPages = useMemo(() => {
    return ["/pricing", "/communities", "/search-result", "/landing"].includes(location.pathname);
  }, [location.pathname]);

  const isTokenExpired = useMemo(() => {
    return keycloak.token ? keycloak.isTokenExpired() : false;
  }, [keycloak.token, isLoggedIn]);

  const logout = () => {
    keycloak.logout({ redirectUri: `${window.location.origin}/login` });
    logoutAction();
  };

  useIntercom({ isLoggedIn, listings, profile });

  const { initializedKeycloak } = useKeycloakAuth({ keycloakInstance: keycloak, setLoggedIn });

  useEffect(() => {
    if (isCommunitiesDefault) {
      getPublicProfessions(currentProfession);
      getHeritages(currentProfession);
    }
  }, [location.pathname, getPublicProfessions, getHeritages]); // eslint-disable-line

  useEffect(() => {
    if (!profile?.user_id && isLoggedIn && !profile?.email && (isGlobalPages || isPublicListingPage)) {
      getProfile();
    }

    if (
      !isLoggedIn &&
      initializedKeycloak &&
      !isCommunitiesDefault &&
      !isGlobalPages &&
      !isPublicListingPage &&
      location.pathname !== "/login"
    ) {
      history.push("/login");
    }
  }, [isLoggedIn, getProfile, profile?.email, initializedKeycloak]); // eslint-disable-line

  useEffect(() => {
    if (profile?.user_id && isLoggedIn) {
      getFooterInfo();
    }
  }, [profile?.user_id, getFooterInfo]);

  useEffect(() => {
    if (queryParams?.isAuthenticationError) {
      setShowError(true);

      const urlParams = new URLSearchParams(location.search);
      const currentUrl = window.location.href;
      const newUrl = `${currentUrl.split("?")[0]}?${urlParams.toString()}`.replace("?isAuthenticationError=true", "");

      urlParams.delete("isAuthenticationError");

      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [location.search]); // eslint-disable-line

  useEffect(() => {
    const requestInterceptors = axios.interceptors.request.use(
      async config => {
        try {
          await keycloak.updateToken(60);
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${keycloak.token}`;
        } catch (error) {
          console.error("Failed to update keycloak token", error);
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptors);
    };
  }, [isTokenExpired]);

  return (
    <>
      {showError && (
        <Notification error close={() => setShowError(false)}>
          User authentication error. Please contact support.
        </Notification>
      )}

      <Switch>
        <Route
          exact
          path="/login"
          render={props => (
            <Login
              errors={errors}
              isLoggedIn={isLoggedIn}
              login={login}
              loginMethods={loginMethods}
              preloaders={preloaders}
              user={profile}
              keycloak={keycloak}
              {...props}
            />
          )}
        />
        <Route exact path="/logout" render={() => <Logout handleLogout={logout} />} />

        <Route exact path="/login/forgot-password" render={props => <ForgotPassword {...props} />} />
        <Route exact path="/login/verification-code" render={props => <VerificationCode {...props} />} />
        <Route exact path="/login/set-new-password" render={() => <SetNewPass />} />
        <Route exact path="/login/reset-password-success" render={() => <ResetPasswordSuccess />} />
        <Route exact path="/create-new-account" render={() => <RegistrationContainer />} />
        <Route exact path="/forbidden" render={props => <Forbidden {...props} />} />
        {professionalPathNames?.length ? <Route exact path={professionalPathNames} render={() => <ProfessionCommunities />} /> : null}

        {(!countriesList?.length || !professionsList?.length) && isCommunities ? (
          <Route exact path={`/${currentProfession}-communities`} render={() => <ProfessionCommunities />} />
        ) : null}

        <Route exact path="/lead-purchased-already" render={() => <LeadAlreadyPurchased />} />
        <Route exact path="/lead-expired" render={() => <LeadExpired />} />
        <Route exact path="/communities" render={() => <Communities />} />
        <Route exact path="/pricing" render={() => <Pricing />} />
        <Route exact path="/landing" render={() => <Landing />} />
        <Route exact path="/search-result" render={() => <SearchResult />} />

        <Route
          exact
          path="/public-listing/:id?"
          render={props => (
            <PublicListing keycloak={keycloak} socialLinks={socialLinks} footerInfo={footerInfo} listingId={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/public-organizations/:id?"
          render={props => (
            <PublicOrganizations keycloak={keycloak} socialLinks={socialLinks} footerInfo={footerInfo} listingId={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/public-profile/:id?"
          render={props => (
            <PublicProfile keycloak={keycloak} socialLinks={socialLinks} footerInfo={footerInfo} listingId={props.match.params.id} />
          )}
        />

        <PrivateRoutesContainer keycloak={keycloak} isLoggedIn={isLoggedIn} />
      </Switch>
    </>
  );
};

export default Routes;
