import { home } from "../constants/actionTypes";
import { sortBySponsorAndDate } from "../helpers";

const defaultState = {
  dashboardData: {
    listing_messages: [],
    leads: [],
    calls: [],
    notifications: [],
    qa: [],
    cases: [],
    listings: []
  },
  error: {
    listingMessages: null,
    dashboardData: null,
    favorites: false
  },
  preloaders: {
    dashboard: false,
    listingAssistant: false,
    saved: false,
    getFollowing: false,
    removeFollowing: false
  },
  successListingAssistant: false,
  addNewListingData: {},
  savedData: {
    list: {},
    count: {}
  },
  myFeed: {
    list: {},
    count: {}
  },
  feedPagination: {},
  followings: []
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case home.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [action.payload.section]: action.payload.isSectionLoading }
      };

    case home.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        preloaders: {
          dashboard: false
        }
      };

    case home.GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        preloaders: {
          dashboard: false
        }
      };

    case home.GET_ADD_NEW_LISTING_DATA: {
      return {
        ...state,
        addNewListingData: action.payload
      };
    }

    case home.ADD_NEW_LISTING: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false }
      };
    }

    case home.ADD_NEW_LISTING_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false }
      };
    }

    case home.GET_SAVED: {
      const events = sortBySponsorAndDate(action.payload.list.events);
      const profiles = sortBySponsorAndDate(action.payload.list.profiles);
      const jobs = sortBySponsorAndDate(action.payload.list.jobs);
      const organizations = sortBySponsorAndDate(action.payload.list.organizations);

      return {
        ...state,
        preloaders: { ...state.preloaders, saved: false },
        savedData: {
          list: { profiles, jobs, organizations, events },
          count: action.payload.count
        }
      };
    }
    case home.GET_SAVED_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, saved: false }
      };
    }

    case home.GET_MY_FEED: {
      const currentSection =
        action.payload.section !== "all" && action.payload.pagination.current_page !== 1
          ? { [action.payload.command]: [...state.myFeed.list[action.payload.command], ...action.payload.list[action.payload.command]] }
          : {};

      return {
        ...state,
        preloaders: { ...state.preloaders, feed: false },
        myFeed: {
          list: {
            ...state.myFeed.list,
            ...action.payload.list,
            ...currentSection
          },
          count: action.payload.count
        },
        feedPagination: action.payload.pagination
      };
    }

    case home.GET_MY_FEED_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, feed: false }
      };
    }

    case home.GET_FOLLOWING: {
      return {
        ...state,
        preloaders: { ...state.preloaders, getFollowing: false },
        followings: action.payload
      };
    }

    case home.GET_FOLLOWING_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, getFollowing: false }
      };
    }

    case home.REMOVE_FOLLOWING: {
      return {
        ...state,
        preloaders: { ...state.preloaders, removeFollowing: false },
        followings: state.followings.filter(item => String(item.user_following_id) !== String(action.payload))
      };
    }

    case home.POST_FOLLOW_COMMUNITIES: {
      return {
        ...state,
        followCommunities: action.payload
      };
    }

    case home.POST_FOLLOW_COMMUNITIES_FAIL: {
      return {
        ...state,
        error: { ...state, message: action.payload.message }
      };
    }

    case home.ADD_FAVORITES_FAIL: {
      return {
        ...state,
        error: { ...state, favorites: true }
      };
    }

    case home.REMOVE_ALL_ERROR: {
      return {
        ...state,
        error: { ...state.error }
      };
    }

    case home.REMOVE_FOLLOWING_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, removeFollowing: false },
        error: { ...state, favorites: true }
      };
    }

    default:
      return {
        ...state
      };
  }
}
