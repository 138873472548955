export const ACTIVE_BLOCK_NAMES = {
  MESSAGES: "messages",
  CONVERSATION: "conversation",
  DETAIL: "detail",
  DETAIL_DESKTOP: "detailDesktop"
};

export const TABS = {
  ALL: "all",
  ARCHIVED: "archived",
  UNREAD: "unread"
};

export const SORT = {
  desc: "desc",
  asc: "asc"
};

export const listingTypes = {
  events: "Events",
  jobs: "Jobs",
  organizations: "Organizations"
};
