import React from "react";
import { NavLink, Link } from "react-router-dom";
import cn from "classnames";

import { ReactComponent as ArrowIcon } from "../../../media/icons/arrow-right.svg";
import styles from "./breadcrumbs.module.css";

const Breadcrumbs = ({ listingName }) => {
  return (
    <div className={styles.main}>
      <Link className={cn(styles.link, styles.activeLink)} to="/listings">
        All Listings
      </Link>

      <ArrowIcon className={styles.icon} />

      <NavLink className={styles.link} activeClassName={styles.activeLink} to="/my-events">
        Event listings
      </NavLink>

      <NavLink className={styles.link} activeClassName={styles.activeLink} to="/my-organizations">
        Organization Listings
      </NavLink>

      <NavLink className={styles.link} activeClassName={styles.activeLink} to="/my-jobs">
        Job Listings
      </NavLink>

      {listingName && <ArrowIcon className={styles.icon} />}

      <span className={styles.listingName}>{listingName}</span>
    </div>
  );
};

export default Breadcrumbs;
