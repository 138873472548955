import React, { useState } from "react";
import { Link } from "react-router-dom";

import Checkbox from "../../common/checkbox";
import Select from "../../common/selectInput";
import styles from "./sidebar.module.css";

const Sidebar = ({ featuredPractices }) => {
  const [freeConsultation, setFreeConsultation] = useState(false);
  return (
    <aside className={styles.aside}>
      <h3 className={styles.title}>Popular Practice Areas</h3>
      <div className={styles.marks}>
        {featuredPractices?.map(item => {
          return (
            <Link to={`/search-result?practice_id=${item.id}`} className={styles.marksItem} key={`${item.title}-practices`}>
              {item.title}
            </Link>
          );
        })}
      </div>

      <div className={styles.filterContent}>
        <h3 className={styles.title}>Free consultation</h3>

        <Checkbox
          checked={freeConsultation}
          className={styles.checkbox}
          id="consultation"
          name="consultation"
          onChange={() => setFreeConsultation(prev => !prev)}
          isNewStyles
        >
          Free consultation
        </Checkbox>
      </div>

      <div className={styles.filterContent}>
        <h3 className={styles.title}>Filter by languages</h3>

        <Select inputId="languages" name="country" required classNameWrap={styles.select} placeholder="Languages" />
      </div>
    </aside>
  );
};

export default Sidebar;
