import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { useFormik } from "formik";

import { ReactComponent as FacebookIcon } from "../../../media/icons/facebookActive.svg";
import { ReactComponent as LinkedinIcon } from "../../../media/icons/linkedinActive.svg";
import { ReactComponent as TwitterIcon } from "../../../media/icons/twitterActive.svg";
import { ReactComponent as InstagramIcon } from "../../../media/icons/instagramActive.svg";
import { ReactComponent as DropdownIcon } from "../../../media/icons/dropdown-arrow.svg";
import Select from "../select";
import { customStyles as selectStyles } from "../../../constants/select";
import { statuses } from "../../../constants/listingDetail";
import Checkbox from "../../common/checkbox";
import styles from "./productActions.module.css";

const PublishedDirectories = ({
  directoriesAvailability,
  handleAutoSaveListing,
  publishStatus,
  paused,
  pauseListing,
  unPauseListing,
  listingId
}) => {
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const [pauseValue, setPauseValue] = useState({ id: "", title: "" });
  const isNeverPublished = useMemo(() => publishStatus === statuses.neverPublished, [publishStatus]);
  const isActiveStatus = useMemo(() => publishStatus === statuses.active, [publishStatus]);
  const unpublished = useMemo(() => ({ id: "1", title: "Unpublished" }), []);
  const published = useMemo(() => ({ id: "0", title: "Published" }), []);
  const options = [unpublished, published];

  const toggleDropDown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  const handleChange = value => {
    setPauseValue(value);

    if (String(value.id) === "1") {
      pauseListing(listingId, false);
    } else {
      unPauseListing(listingId, false);
    }
  };

  const onSubmit = (value, index) => {
    const newDirectories = directoriesAvailability.map((dir, i) => (i === index ? { ...dir, ...value } : dir));
    const params = newDirectories.map(item => ({
      referrals: item.referrals,
      publish: item.publish,
      newsletter: item.newsletter,
      dir_id: item.dir_id
    }));

    handleAutoSaveListing({ directories_availability: params }, "directories_availability", "save_directory_availability");
  };

  useEffect(() => {
    if (!pauseValue.id) {
      if (String(paused) === "1") {
        setPauseValue(unpublished);
      } else {
        setPauseValue(published);
      }
    }
  }, [paused]);

  return (
    <div className={styles.publishDirectories}>
      {isNeverPublished ? (
        <div className={styles.statusContent}>
          <span className={styles.status}>Status:</span>
          <div className={styles.neverPublish}>
            <span className={styles.dotRed} />
            Never Published Before
          </div>
        </div>
      ) : (
        <>
          <div className={styles.statusContent}>
            <span className={styles.status}>Status:</span>
            <Select
              getOptionLabel={option => option.title}
              getOptionValue={option => option.title}
              inputId="status"
              name="status"
              value={pauseValue}
              options={options}
              styles={selectStyles}
              required
              classNameWrap={cn(styles.selectWrap, String(paused) === "0" ? styles.selectWrapActive : "")}
              handleChange={handleChange}
            />
          </div>

          {String(paused) !== "1" && directoriesAvailability.length ? (
            <div className={styles.container}>
              <button
                type="button"
                className={cn(styles.publishOn, isActiveDropdown ? styles.publishOnActive : "")}
                onClick={toggleDropDown}
              >
                Published on <DropdownIcon />
              </button>

              {isActiveStatus && (
                <>
                  <div className={styles.heading}>
                    <span className={styles.headingItem}>Websites</span>
                    <span className={styles.headingItem}>Publish</span>
                    <span className={styles.headingItem}>Referrals</span>
                    <span className={styles.headingItem}>Newsletter</span>
                    <span className={styles.headingItem}>Social Media</span>
                  </div>
                  <div className={cn(styles.publishList, isActiveDropdown ? styles.publishListActive : "")}>
                    {directoriesAvailability.map((item, index) => (
                      <PublishItem
                        key={`${item.directory_name}-${item.directory_url}`}
                        {...item}
                        onSubmit={value => onSubmit(value, index)}
                        index={index}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

const PublishItem = ({ directory_name: name, directory_url: url, publish, referrals, newsletter, socials, onSubmit, index }) => {
  const [isActive, setIsActive] = useState(false);

  const formik = useFormik({
    initialValues: {
      publish: String(publish),
      referrals: String(referrals),
      newsletter: String(newsletter)
    },
    onSubmit: values => {
      onSubmit(values);
    }
  });

  const { values, setFieldValue, handleSubmit } = formik;
  const onChange = e => {
    setFieldValue(e.target.name, e.target.value === "0" ? "1" : "0");
    handleSubmit();
  };

  const toggleDropdown = () => {
    setIsActive(prev => !prev);
  };

  const activePublishButtonClassName = isActive ? styles.publishItemHeadingButtonActive : "";
  const activePublishContent = isActive ? styles.publishItemContentActive : "";

  return (
    <div className={styles.publishItemContainer}>
      <div className={cn(styles.publishItemHeading, activePublishButtonClassName)}>
        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.publishItemButton}>
          {name}
        </a>
        <button type="button" className={styles.dropdownAction} onClick={toggleDropdown}>
          <DropdownIcon />
        </button>
      </div>

      <div className={cn(styles.publishItemContent, activePublishContent)}>
        <div className={styles.publishItemCheckBoxContent}>
          <Checkbox
            name="publish"
            id={`publish-${index}`}
            htmlFor={`publish-${index}`}
            isNewStyles
            big
            onChange={onChange}
            checked={values.publish === "1"}
            value={values.publish}
            className={styles.publishItemAvailability}
          >
            <span className={styles.publishItemLabel}>Publish:</span>
          </Checkbox>
        </div>

        <div className={styles.publishItemCheckBoxContent}>
          <Checkbox
            isNewStyles
            big
            name="referrals"
            id={`referrals-${index}`}
            htmlFor={`referrals-${index}`}
            onChange={onChange}
            checked={values.referrals === "1"}
            value={values.referrals}
            className={styles.publishItemAvailability}
          >
            <span className={styles.publishItemLabel}>Referrals:</span>
          </Checkbox>
        </div>

        <div className={styles.publishItemCheckBoxContent}>
          <Checkbox
            isNewStyles
            big
            name="newsletter"
            id={`newsletter-${index}`}
            htmlFor={`newsletter-${index}`}
            onChange={onChange}
            checked={values.newsletter === "1"}
            value={values.newsletter}
            className={styles.publishItemAvailability}
          >
            <span className={styles.publishItemLabel}>Newsletter:</span>
          </Checkbox>
        </div>

        <span className={styles.publishItemLabel}>Social Media:</span>

        <div className={styles.publishItemSocials}>
          {socials.facebook ? (
            <a href={socials.facebook} className={styles.social} target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          ) : null}
          {socials.twitter ? (
            <a href={socials.twitter} className={styles.social} target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
          ) : null}
          {socials.linkedin ? (
            <a href={socials.linkedin} className={styles.social} target="_blank" rel="noopener noreferrer">
              <LinkedinIcon />
            </a>
          ) : null}
          {socials.instagram ? (
            <a href={socials.instagram} className={styles.social} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PublishedDirectories;
