export const socialName = {
  facebook: "facebook",
  twitter: "twitter",
  linkedin: "linkedin",
  google: "google",
  instagram: "instagram",
  rss: "rss",
  facebookGroup: "facebook_group",
  linkedinGroup: "linkedin_group"
};

export const heritageUrlTypes = {
  localhost: "localhost",
  staging: "staging",
  qa: "qa",
  production: "production"
};

export const heritageUrls = {
  localhost: "http://localhost:8001",
  staging: "https://staging.heritageweb.com",
  qa: "https://qa.heritageweb.com",
  production: "https://heritageweb.com"
};
