import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import { useFormik } from "formik";

import { OverlayPreloader } from "../../../common/preloader";
import { normalizeFollowupQuestionOptions } from "../../../../helpers";
import { actions as referralRequestDetailActions } from "../../../../actions/referralRequestDetail";
import { RenderQuestions } from "../renderQuestions";
import styles from "./questions.module.css";

const Questions = ({ leadId }) => {
  const { followups: questions, preloader } = useSelector(state => state.referralRequestDetail);
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const noAnsweredQuestion = questions.filter(item => item.answer.length === 0 || (item.answer.length === 1 && item.answer[0] === ""));
  const dispatch = useDispatch();
  const updateFollowupQuestions = useCallback(answers => dispatch(referralRequestDetailActions.updateFollowupQuestions(answers)), [
    dispatch
  ]);
  const handleSendReferralsData = useCallback(answers => dispatch(referralRequestDetailActions.handleSendReferralsData(answers)), [
    dispatch
  ]);

  const formAnswers = questionList => {
    return questionList.map(question => {
      let label = "Select";
      const answerOptions = normalizeFollowupQuestionOptions(question);
      const date = new Date();

      const answerDate = question.answer_type === "date" && question.answer ? new Date(question.answer) : new Date();

      if (question.answer) {
        label = answerOptions.find(({ answer }) => answer === question.answer)?.label || "Select";
      }

      switch (question.answer_type) {
        case "checkboxes": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: Array.isArray(question.answer) ? question.answer[0] : question.answer || "",
            label: Array.isArray(question.answer) ? question.answer[0] : label,
            answer_options: { options: answerOptions }
          };
        }
        case "select": {
          const answerOptionsList = normalizeFollowupQuestionOptions(question, true);

          return {
            lead_followup_id: question.lead_followup_id,
            answer: Array.isArray(question.answer) ? question.answer[0] : question.answer || "",
            label: Array.isArray(question.answer) ? question.answer[0] : label,
            answer_options: { options: answerOptionsList },
            isSelect: true
          };
        }

        case "date": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: answerDate || date
          };
        }

        case "text": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: Array.isArray(question.answer) && question.answer?.length ? question.answer[0] : ""
          };
        }

        default: {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: question.answer || ""
          };
        }
      }
    });
  };

  const validate = values => {
    const errors = {};
    const requiredText = "Required";

    values.forEach((answer, index) => {
      const question = noAnsweredQuestion[index];

      if (!!question?.is_required && !answer?.answer) {
        errors[index] = requiredText;
      }
    });

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formAnswers(questions),
    validate,
    onSubmit: values => {
      const valuesToSend = values.map(({ answer, lead_followup_id }) => {
        if (answer instanceof Date) {
          return {
            answer: answer && format(answer, "yyyy-MM-dd"),
            lead_followup_id: parseInt(lead_followup_id, 10)
          };
        }
        return { answer, lead_followup_id: parseInt(lead_followup_id, 10) };
      });

      const normalizeAnswers = valuesToSend
        .map(item => ({ [item.lead_followup_id]: item.answer }))
        .reduce((total, current) => {
          return { ...total, ...current };
        }, {});

      updateFollowupQuestions({ lead_id: leadId, followups: normalizeAnswers });
    }
  });

  const { errors, touched, values, setFieldTouched, setFieldValue } = formik;

  const normalizeQuestions = questions.map(item => {
    return {
      ...item,
      answer_options: typeof item.answer_options === "string" ? JSON.parse(item.answer_options) : item.answer_options
    };
  });

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const handleChange = (name, value) => {
    setFieldValue(name, value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };
  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleSubmit = () => {
    const normalizedQuestions = values.reduce((acc, item) => {
      if (item.answer instanceof Date) {
        return {
          ...acc,
          [item.lead_followup_id]: format(item.answer, "yyyy-MM-dd")
        };
      }

      if (item.isSelect) {
        const answerItem = item.answer_options.options.find(option => [option.answer, option.label].includes(item.answer));
        return {
          ...acc,
          [item.lead_followup_id]: answerItem?.answer
        };
      }

      return {
        ...acc,
        [item.lead_followup_id]: item.answer
      };
    }, {});

    handleSendReferralsData({
      lead_id: leadId,
      followups: normalizedQuestions,
      command: "save_follow_up_question"
    });
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  return (
    <>
      {preloader && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      {normalizeQuestions.length ? (
        <div
          className={styles.modalWrp}
          ref={containerRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
        >
          <h2 className={styles.title}>Follow up Questions</h2>
          <RenderQuestions
            handleBlur={handleBlur}
            formik={formik}
            questions={normalizeQuestions}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            values={values}
          />
        </div>
      ) : null}
    </>
  );
};

export default Questions;
