import React, { useMemo } from "react";
import queryString from "query-string";

import { Pagination } from "../../common/pagination";
import styles from "./pagination.module.css";

const PaginationContent = ({ setPerPageLead, searchParams, history, pagination, leadsLength }) => {
  const handlePerPageLead = perPage => {
    setPerPageLead(perPage);

    history.push(`/paid-leads?page=0&tab=${searchParams.tab}`);
  };

  const paginationLinks = useMemo(
    () =>
      Array.from({ length: pagination.lastPageNum + 1 || 0 }, (_, i) => ({
        pathname: "/paid-leads",
        search: queryString.stringify({ ...searchParams, page: i }),
        number: i
      })),
    [pagination.lastPageNum, searchParams]
  );

  const isShowPagination = useMemo(() => {
    return (pagination.lastPageNum !== 0 && !!leadsLength && leadsLength >= pagination.perPage) || Number(searchParams.page) >= 1;
  }, [pagination.lastPageNum, leadsLength, searchParams.page]);

  return (
    <>
      {isShowPagination && (
        <Pagination
          perPage={pagination.perPage}
          handlePerPage={handlePerPageLead}
          links={paginationLinks}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
          className={styles.pagination}
        />
      )}
    </>
  );
};

export default PaginationContent;
