import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import ModalControl from "../../../modalControl";
import styles from "./removePhone.module.css";

const RemovePhone = ({ closeModal, clearAllModals, phone, preloaders, removePhoneNumber }) => {
  const { t } = useTranslation();

  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      {preloaders.verifications && <OverlayPreloader />}

      <section>
        <div className={styles.pointer}>ACCOUNT SETTINGS</div>
        <div className={styles.popupHeading}>{t("dashboard_account_phone_remove_title")}</div>
      </section>

      <p className={styles.description}>{t("dashboard_account_phone_remove_msg")}</p>

      <span className={styles.phoneText}>{t("dashboard_common_phone")}</span>
      <span className={styles.phoneValue}>{phone}</span>

      <ModalControl onConfirm={removePhoneNumber} isHideExit>
        {t("dashboard_account_phone_remove_button")}
      </ModalControl>
    </Modal>
  );
};

export default RemovePhone;
