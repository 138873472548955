import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as MainLogo } from "../../../media/icons/mainLogo.svg";
import styles from "./logo.module.css";

export const Logo = () => {
  return (
    <Link to="/" className={styles.logoLink}>
      <MainLogo className={styles.mainLogo} />
    </Link>
  );
};
