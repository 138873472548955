export const communities = {
  culture: "culture",
  languages: "languages",
  religion: "religion",
  country: "country",
  all: "all"
};

export const communitiesTypes = {
  culture: "by culture",
  languages: "by language",
  religion: "by religion",
  region: "from region",
  global: "global"
};
