import React from "react";
import { useTranslation } from "react-i18next";

import { OverlayPreloader } from "../../common/preloader";
import { Notification } from "../../common/notification";
import Input from "../../common/input";
import styles from "./mainUserInfo.module.css";

const MainUserInfo = ({ preloaders, success, touched, handleChange, handleBlur, values, errors }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      {(preloaders.contacts || preloaders.verifications) && <OverlayPreloader />}
      {success.contacts && <Notification success>{t("dashboard_common_saved")}</Notification>}

      <Input
        classNameWrap={styles.inputContainer}
        className={styles.input}
        error={errors.name_f}
        isInvalid={errors.name_f && touched.name_f}
        id="name_f"
        label={t("dashboard_account_firstname")}
        onBlur={e => handleBlur(e.target.name)}
        onChange={handleChange}
        name="name_f"
        type="text"
        value={values.name_f}
      />

      <Input
        classNameWrap={styles.inputContainer}
        className={styles.input}
        error={errors.name_l}
        isInvalid={errors.name_l && touched.name_l}
        id="name_l"
        label={t("dashboard_account_lastname")}
        onBlur={e => handleBlur(e.target.name)}
        onChange={handleChange}
        name="name_l"
        type="text"
        value={values.name_l}
      />
    </div>
  );
};

export default MainUserInfo;
