import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Button } from "../common/button";
import sideImage from "../../media/images/registration-welcome.png";
import eyeIcon from "../../media/icons/eye-paswd.svg";
import { Header } from "./components/header";
import { OverlayPreloader } from "../common/preloader";
import { Notification } from "../common/notification";
import Input from "../common/input";
import { email as emailRegex } from "../../constants/regex";
import "./registerUser.css";
import styles from "./registration.module.css";

// todo I think that you can remove this component. Ask Nima first
const Registration = ({ history, error, loading, signUp, success, title }) => {
  const [isShown, setShow] = useState({ confirm_pass: null, amember_pass: null });
  const [notification, setNotification] = useState(error);
  const { t } = useTranslation();

  const validate = values => {
    const { amember_email, amember_fname, amember_lname, amember_pass, confirm_pass } = values;
    const errors = {};
    const requiredText = t("dashboard_form_validations_password_required");

    const validatePass = pass => {
      if (!values[pass]) {
        errors[pass] = requiredText;
        return;
      }

      if (values[pass].length < 7) {
        errors[pass] = t("dashboard_form_validations_password_short");
      } else if (!values[pass].match(/[A-Z]/)) {
        errors[pass] = t("dashboard_form_validations_password_uppercase");
      } else if (!values[pass].match(/[a-z]/)) {
        errors[pass] = t("dashboard_form_validations_password_lowercase");
      } else if (!values[pass].match(/\d/)) {
        errors[pass] = t("dashboard_form_validations_password_number");
      } else if (!values[pass].match(/[!@#$%^&]/)) {
        errors[pass] = t("dashboard_form_validations_password_specialcharacter");
      }
    };

    if (!amember_email) {
      errors.amember_email = requiredText;
    } else if (!amember_email.match(emailRegex)) {
      errors.amember_email = t("dashboard_form_validations_invalid_email");
    }
    if (!amember_fname) {
      errors.amember_fname = requiredText;
    }
    if (!amember_lname) {
      errors.amember_lname = requiredText;
    }
    if (amember_pass !== confirm_pass) {
      errors.confirm_pass = t("dashboard_form_validations_password_match");
    }
    if (!confirm_pass) {
      errors.confirm_pass = requiredText;
    }

    validatePass("amember_pass");

    return errors;
  };

  useEffect(() => {
    document.title = t("dashboard_newaccount_metatitle");
    if (success) {
      history.push("/");
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setNotification(error);
      setTimeout(() => setNotification(null), 10000);
    }
  }, [error]); //eslint-disable-line

  const formik = useFormik({
    initialValues: {
      amember_email: "",
      amember_fname: "",
      amember_lname: "",
      amember_pass: "",
      confirm_pass: ""
    },
    validate,
    onSubmit: values => {
      signUp(values);
    }
  });
  const { errors, touched, values } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    formik.setFieldTouched(e.target.name, true);
  };

  return (
    <>
      <Header login />
      {notification && (
        <Notification error time={10000}>
          {notification}
        </Notification>
      )}
      <div className={cn("wrapper", styles.wrapperCreateAccount)}>
        <div className="register">
          <div className="row">
            <div className="d-none d-xl-block col-xl-6">
              <aside className="register__aside register__aside--welcome">
                <img className={styles.mb20} src={sideImage} alt="" />
                <p className="h2 register__caption register__caption--welcome">{title || t("dashboard_newaccount_caption")}</p>
              </aside>
            </div>
            <div className="col-12 col-xl-6">
              <div className="card register__card register__card--welcome">
                {loading && <OverlayPreloader overlayClassName="sectionOverlay" />}
                <p className="h2 register__title">{t("dashboard_newaccount_h1")}</p>
                <form className={styles.authFormWrapper} onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Input
                        classNameWrap={styles.newAccInput}
                        error={errors.amember_fname}
                        id="amember_fname"
                        isInvalid={errors.amember_fname && touched.amember_fname}
                        name="amember_fname"
                        onBlur={handleBlur}
                        label={t("dashboard_newaccount_firstname")}
                        onChange={handleChange}
                        required
                        value={values.amember_fname}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        classNameWrap={styles.newAccInput}
                        error={errors.amember_lname}
                        id="amember_lname"
                        isInvalid={errors.amember_lname && touched.amember_lname}
                        name="amember_lname"
                        onBlur={handleBlur}
                        label={t("dashboard_newaccount_lastname")}
                        onChange={handleChange}
                        required
                        value={values.amember_lname}
                      />
                    </div>
                  </div>

                  <Input
                    classNameWrap={styles.newAccInput}
                    error={errors.amember_email}
                    id="amember_email"
                    isInvalid={errors.amember_email && touched.amember_email}
                    name="amember_email"
                    onBlur={handleBlur}
                    label={t("dashboard_newaccount_email")}
                    onChange={handleChange}
                    required
                    value={values.amember_email}
                  />

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="relative">
                        <Input
                          classNameWrap={styles.newAccInput}
                          error={errors.amember_pass}
                          id="amember_pass"
                          isInvalid={errors.amember_pass && touched.amember_pass}
                          name="amember_pass"
                          onBlur={handleBlur}
                          label={t("dashboard_newaccount_password")}
                          onChange={handleChange}
                          required
                          type={isShown.amember_pass ? "text" : "password"}
                          value={values.amember_pass}
                        />

                        <Button
                          onClick={() => {
                            setShow({ ...isShown, amember_pass: !isShown.amember_pass });
                          }}
                          className={cn("button--clear", styles.button)}
                        >
                          <img src={eyeIcon} alt="" />
                        </Button>
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="relative">
                        <Input
                          classNameWrap={styles.newAccInput}
                          error={errors.confirm_pass}
                          id="confirm_pass"
                          isInvalid={errors.confirm_pass && touched.confirm_pass}
                          name="confirm_pass"
                          onBlur={handleBlur}
                          label={t("dashboard_newaccount_passwordconfirm")}
                          onChange={handleChange}
                          required
                          type={isShown.confirm_pass ? "text" : "password"}
                          value={values.confirm_pass}
                        />

                        <Button
                          onClick={() => {
                            setShow({ ...isShown, confirm_pass: !isShown.confirm_pass });
                          }}
                          className={cn("button--clear", styles.button)}
                        >
                          <img src={eyeIcon} alt="" />
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className={styles.mb30}>{t("dashboard_newaccount_passwordstrength")}</p>

                  <Button type="submit" disabled={loading} className={cn("register__button", loading ? "button--outline" : null)}>
                    {t("dashboard_newaccount_button")}
                  </Button>
                </form>
                <Link className="login-link" to="/login">
                  {t("dashboard_login_button")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
