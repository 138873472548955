import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import { WEBSITE } from "../constants/regex";
import { filterBySearchValue, filterBookmarked, getDataIntercom } from "./index";
import { QUESTION_TYPES } from "../constants/question";
import { actions as listingActions } from "../actions/listing";
import { actions as paymentActions } from "../actions/payment";
import { actions as teamsActions } from "../actions/teams";
import { indexEdit } from "../constants/listings";

export const useDocTitle = (title, keepOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(title);
  }, [t, title]);

  useEffect(() => {
    return () => {
      if (!keepOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [keepOnUnmount]);
};

export const useNotification = (error, success) => {
  const [notes, setNotes] = useState({ success: null, error: null });

  useEffect(() => {
    if (Object.values(success).some(value => !!value)) {
      setNotes({ success: true });
      setTimeout(() => setNotes({ success: null }), 7000);
    }

    if (error) {
      setNotes({ error });
      setTimeout(() => setNotes({ error: null }), 7000);
    }
  }, [success, error]);

  return notes;
};

// question-answer page
export const useFiltrationQAndAPage = (activeFilters, searchValue, bookmarked, setQuestionsToRender) => {
  const { qa, answers, allQuestionAndAnswers } = useSelector(state => state.questions);

  useEffect(() => {
    if (!activeFilters.isFirstRender) {
      const { practices, categories, professions } = activeFilters;

      const filterByWebsiteUrl = item => categories.includes(item.website_url.replace(WEBSITE, "").split("/")[0]);
      const filterByPractices = ({ practice1, practice2, practice3 }) => {
        const toArrayPractices = [practice1, practice2, practice3];
        return practices.some(item => toArrayPractices.indexOf(item) >= 0);
      };
      const filterByProfessions = ({ profession_id: professionId }) => professions.includes(professionId);
      const filterByAllQuestions = item => filterByProfessions(item) && filterByWebsiteUrl(item);
      const filterByAllAnswers = item => filterByPractices(item) && filterByWebsiteUrl(item);

      let allAnswerAndQuestionsList = [];
      let questionsList = [];
      let answersList = [];

      if (practices.length && categories.length && professions.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue).filter(filterByAllQuestions);
      } else if (practices.length && categories.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length && categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length && practices.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (practices.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
        questionsList = filterBySearchValue(qa, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByWebsiteUrl);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByWebsiteUrl);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else {
        questionsList = filterBySearchValue(qa, searchValue);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      }

      setQuestionsToRender({
        qa: filterBookmarked(bookmarked, questionsList),
        answers: filterBookmarked(bookmarked, answersList),
        all: filterBookmarked(bookmarked, allAnswerAndQuestionsList)
      });
    }
    // eslint-disable-next-line
  }, [activeFilters.practices, activeFilters.categories, activeFilters.professions, searchValue]);
};

// question-answer detail page
export const useUpdateQuestionsIds = (id, tab) => {
  const [prevQuestionId, setPrevQuestionId] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(null);
  const { qa, answers } = useSelector(state => state?.questions);
  const answeredQuestions = qa.filter(item => Object.keys(answers).includes(item.question_id));
  const currentQuestionIdx = useMemo(() => qa.findIndex(item => String(item.question_id) === String(id)), [qa, qa.length, id]);

  const getPrevNextQuestionsId = useCallback(
    questionsArr => {
      setPrevQuestionId(questionsArr?.[currentQuestionIdx - 1]?.question_id);
      setNextQuestionId(questionsArr?.[currentQuestionIdx + 1]?.question_id);
    },
    [currentQuestionIdx]
  );

  useEffect(() => {
    if (!tab || tab === QUESTION_TYPES.QUESTIONS) {
      getPrevNextQuestionsId(qa);
    }
    if (tab === QUESTION_TYPES.MY_ANSWER) {
      getPrevNextQuestionsId(answeredQuestions);
    }
  }, [qa, tab, id]);

  return { prevQuestionId, nextQuestionId };
};

export const useIntercom = ({ isLoggedIn, listings, profile }) => {
  useEffect(() => {
    if (window.Intercom) {
      if (profile?.user_id && profile.name_f) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "lm1evs0k",
          user_id: profile.user_id,
          user_hash: profile.intercom_hash,
          name: `${profile.name_f} ${profile.name_l}`,
          email: profile.email,
          created_at: profile.added
        });
      }

      if (isLoggedIn === true) {
        getDataIntercom(listings);
      }

      window.Intercom("update");
    }
  }, [isLoggedIn, profile?.user_id]); // eslint-disable-line
};

export const useKeyDownEscape = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Escape") {
        callBack();
      }
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useOnclickOutside = (ref, handler, reset) => {
  useEffect(() => {
    const listener = event => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      if (!reset) handler();
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const useKeyDownEnter = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Enter") {
        callBack();
      }
    },
    [callBack] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useChangePage = callBack => {
  const currentPage = useRef("");
  const location = useLocation();

  useEffect(() => {
    if (Boolean(currentPage.current) && currentPage.current !== location.pathname) {
      callBack();
    }

    if (currentPage.current !== location.pathname) {
      currentPage.current = location.pathname;
    }
  }, [location.pathname, currentPage]); // eslint-disable-line
};

export const useDebounce = (callBack, isSendHide) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callBack(searchTerm);
      if (isSendHide) {
        if (searchTerm && isHide) {
          setIsHide(false);
        }

        if (!isHide && !searchTerm) {
          setIsHide(true);
        }
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]); // eslint-disable-line

  return { searchTerm, setSearchTerm, isHide };
};

export const useTrack = (eventNameTrack, parameters = {}) => {
  const { account } = useSelector(state => state);
  const { profile } = account;

  useEffect(() => {
    if (window.fbq && profile) {
      window.fbq("track", eventNameTrack, { em: profile.email, fn: profile.name_f, ln: profile.name_f, ...parameters });
    }
  }, [profile]); // eslint-disable-line
};

export const useValidationError = (errors, errorName) => {
  const dispatch = useDispatch();
  const { validationErrors } = useSelector(state => state.listing);
  const setValidationError = useCallback(error => dispatch(listingActions.setValidationError(error)), [dispatch]);

  useEffect(() => {
    if (errors !== "isRemove" && Object.values(errors).length && errors[errorName] !== validationErrors[errorName]) {
      setValidationError(errors);
    }

    if (errors === "isRemove" && validationErrors[errorName]) {
      const { [errorName]: value, ...rest } = validationErrors;

      setValidationError(rest);
    }
  }, [errors]); // eslint-disable-line
};

export const useKeycloakAuth = ({ keycloakInstance, setLoggedIn }) => {
  const initialized = useRef(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [initializedKeycloak, setInitializedKeycloak] = useState(false);

  useEffect(() => {
    if (initialized.current) return;

    const initKeycloak = async () => {
      try {
        const isAuthenticated = await keycloakInstance.init({
          checkLoginIframe: false,
          onLoad: "check-sso",
          pkceMethod: "S256"
        });

        setInitializedKeycloak(true);

        setAuthenticated(isAuthenticated);
        if (isAuthenticated) {
          setLoggedIn(true);
          axios.defaults.headers.common.Authorization = `Bearer ${keycloakInstance.token}`;
        }
      } catch (error) {
        console.error("Failed to initialize Keycloak:", error);
      }
    };

    initKeycloak();
    initialized.current = true;
  }, [keycloakInstance]);

  return { keycloakInstance, authenticated, initializedKeycloak };
};

export const useMembership = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { modals } = useSelector(state => state.listing);
  const deletePrimaryImage = useCallback(listingId => dispatch(listingActions.deletePrimaryImage(listingId, history)), [dispatch]);
  const getMemberDataNew = useCallback(listingId => dispatch(listingActions.getMemberDataNew(listingId, history)), [dispatch]);
  const deleteAdditionalImages = useCallback(
    (listingId, imageId) => dispatch(listingActions.deleteAdditionalImages(listingId, imageId, history)),
    [dispatch]
  );
  const deleteCoverImage = useCallback((listingId, imageId) => dispatch(listingActions.deleteCoverImage(listingId, imageId, history)), [
    dispatch
  ]);

  const getValidations = useCallback(listingId => dispatch(listingActions.getValidations(listingId, history)), [dispatch, history]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);

  const submitChanges = useCallback(
    (memberData, section, isCloseModals, isReload) =>
      dispatch(listingActions.submitChanges(memberData, section, history, isCloseModals, isReload)),
    [dispatch, history]
  );

  const autoSaveListing = useCallback(
    (memberData, section, command, cancelTokens) =>
      dispatch(listingActions.autoSaveListing(memberData, section, history, command, cancelTokens)),
    [dispatch, history]
  );

  const submitImages = useCallback(
    (memberData, section, allMemberData) => dispatch(listingActions.submitImages(memberData, section, history, allMemberData)),
    [dispatch, history]
  );

  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const cleanUpgradeOptions = useCallback(() => dispatch(listingActions.cleanUpgradeOptions()), [dispatch]);

  const getDirectoriesProfessions = useCallback(() => dispatch(listingActions.getDirectoriesProfessions()), [dispatch]);
  const pauseMembership = useCallback(id => dispatch(listingActions.pauseListing(id, false, history, true, false)), [dispatch]);
  const unpauseMembership = useCallback(id => dispatch(listingActions.unPauseListing(id, true, false, history, false)), [dispatch]);
  const getUpgradeOptions = useCallback((id, coupon) => dispatch(listingActions.getUpgradeOptions(id, coupon)), [dispatch]);

  const getPendingPayment = useCallback(id => dispatch(listingActions.getPendingPayment(id)), [dispatch]);
  const getRenewOptions = useCallback((id, coupon) => dispatch(listingActions.getRenewOptions(id, coupon)), [dispatch]);

  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);
  const publishListing = useCallback(id => dispatch(listingActions.publishListing(id, false, history)), [dispatch]);
  const clearSuccess = useCallback(() => dispatch(listingActions.clearSuccess()), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);
  const resetDraft = useCallback(listingId => dispatch(listingActions.resetDraft(listingId, history)), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);

  const getOptions = useCallback(() => {
    if (modals.payPending) {
      return getPendingPayment;
    }
    if (modals.upgrade) {
      return getUpgradeOptions;
    }
    return getRenewOptions;
  }, [modals.upgrade, modals.payPending]);

  const removeListingItem = (collection, identifier, currentId, isIndex, memberInputData) => {
    const newCollection = [...memberInputData[collection]];
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    let filterCollection = [];

    if (isIndex) {
      filterCollection = newCollection.filter((item, index) => {
        return String(index) !== String(currentId);
      });
    } else {
      filterCollection = newCollection.filter(item => {
        return item[identifier] !== currentId;
      });
    }

    return submitChanges({
      ...res,
      [collection]: filterCollection
    });
  };

  return {
    deletePrimaryImage: listingId => deletePrimaryImage(listingId),
    getMemberDataNew,
    deleteAdditionalImages,
    deleteCoverImage,
    getValidations,
    setMembershipClear,
    cleanUpgradeOptions,
    publishListing,
    resetDraft,
    getDirectoriesProfessions,
    pauseMembership,
    unpauseMembership,
    addCard,
    updateCard,
    submitImages,
    submitChanges,
    autoSaveListing,
    clearSuccess,
    clearAllModals,
    setCurrentTeam,
    getOptions,
    removeListingItem
  };
};

export const useMembershipDocumentTitle = ({ listingType, attorney }) => {
  const { t } = useTranslation();
  const { isJobListing, isEvents, isProfiles, isOrganizationsPage } = useMemo(() => {
    return {
      isJobListing: listingType === "Jobs",
      isEvents: listingType === "Events",
      isProfiles: listingType === "Profiles",
      isOrganizationsPage: listingType === "Organizations"
    };
  }, [listingType]);

  useEffect(() => {
    const getDocumentTitleText = () => {
      if (isEvents) {
        if (attorney) {
          return `${attorney} ${t("dashbaord_events_edit_metatitle")}`;
        }
        return `Add New Event ${t("dashbaord_organizations_edit_metatitle")}`;
      }

      if (isJobListing) {
        if (attorney) {
          return `${attorney} ${t("dashbaord_jobs_edit_metatitle")}`;
        }
        return `Add New Job ${t("dashbaord_jobs_edit_metatitle")}`;
      }

      if (isOrganizationsPage) {
        if (attorney) {
          return `${attorney} ${t("dashbaord_organizations_edit_metatitle")}`;
        }
        return `Add New Organization ${t("dashbaord_organizations_edit_metatitle")}`;
      }

      if (isProfiles) {
        return t("dashboard_profile_edit_metatitle");
      }

      return t("dashboard_listings_metatitle");
    };

    document.title = getDocumentTitleText();
  }, [isEvents, isOrganizationsPage, isProfiles, isJobListing, attorney]);
};

export const useMembershipImageUpload = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteCoverImage = useCallback((listingId, imageId) => dispatch(listingActions.deleteCoverImage(listingId, imageId, history)), [
    dispatch
  ]);

  const [showUploadImageNotificationError, setShowUploadImageNotificationError] = useState(false);
  const [uploadImageNotificationErrorText, setUploadImageNotificationErrorText] = useState("");

  const uploadImagesError = (isShow, isSizeError, isWidthTooSmall) => {
    setShowUploadImageNotificationError(isShow);

    if (isSizeError) {
      setUploadImageNotificationErrorText("It seems the file is too large. Please upload an image under 5 MB");
    } else if (isWidthTooSmall) {
      setUploadImageNotificationErrorText("The image is too small. Minimum width: 1800px");
    } else {
      setUploadImageNotificationErrorText("");
    }
  };

  const handleDeleteCoverImage = (listingId, imageId) => {
    return deleteCoverImage(listingId, imageId);
  };

  return {
    uploadImagesError,
    showUploadImageNotificationError,
    uploadImageNotificationErrorText,
    handleDeleteCoverImage
  };
};

export const useMembershipModal = ({ setMemberData, memberData }) => {
  const dispatch = useDispatch();

  const [indexToEdit, setIndexToEdit] = useState(indexEdit);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));
  const activeNotificationModal = (modal, data) => dispatch(listingActions.activeNotificationModal(modal, data));

  const openModal = (modalName, editTarget) => {
    if (editTarget) {
      setIndexToEdit({ ...indexToEdit, [modalName]: typeof editTarget !== "string" ? editTarget : editTarget.toString() });
    } else {
      setIndexToEdit(indexEdit);
    }
    toggleActivityOfModal(modalName, true);
  };

  const closeModal = modalName => {
    setIndexToEdit({ ...indexToEdit, [modalName]: null });
    setMemberData(memberData);
    toggleActivityOfModal(modalName, false);
  };

  const openAddNotificationsModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const handleActiveNotificationModal = (modalName, data) => {
    activeNotificationModal(modalName, data);
  };

  return { openModal, closeModal, openAddNotificationsModal, handleActiveNotificationModal };
};
