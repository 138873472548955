import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { actions as referralRequestDetailActions } from "../../actions/referralRequestDetail";
import { actions as payPerLeadActions } from "../../actions/payPerLead";
import { actions as listingActions } from "../../actions/listing";
import { actions as referralRequestsActions } from "../../actions/referralRequests";

export const useLoadReferrals = ({
  setListActiveNetwork,
  setListActivePractice,
  listActiveNetwork,
  listActivePractice,
  filtrationByDate,
  activeSort,
  perPage: perPageValue
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [referralsList, setReferralsList] = useState([]);

  const { location } = history;
  const { updateReferralRequest, cases, preloaders: preloadersReferrals } = useSelector(state => state.referralRequests);

  const setLeadsClear = useCallback(() => dispatch(payPerLeadActions.setLeadsClear()), [dispatch]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);
  const searchParams = queryString.parse(location.search);
  const getCases = useCallback(data => dispatch(referralRequestsActions.getCases(data)), [dispatch]);
  const setClearReferrals = useCallback(() => dispatch(referralRequestsActions.setClearReferrals()), [dispatch]);
  const { t } = useTranslation();
  const isReferrals = history.location.pathname?.includes("referrals");

  useEffect(() => {
    if (searchParams && searchParams.tab) {
      setListActiveNetwork([]);
      setListActivePractice([]);
    }
  }, [searchParams.tab]); // eslint-disable-line

  useEffect(() => {
    document.title = t("dashboard_referrals_all_meta_title");

    return () => {
      setLeadsClear();
      setClearReferrals();
      setMembershipClear();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (cases) {
      setReferralsList(cases);
    }
  }, [cases]); // eslint-disable-line

  useEffect(() => {
    if (isReferrals && !preloadersReferrals.preloaderCase) {
      getCases({
        dirId: listActiveNetwork,
        practiceId: listActivePractice,
        type: searchParams.tab,
        page: searchParams.page,
        sort: activeSort?.value,
        perPage: perPageValue,
        isSelectedFilter: Boolean(listActiveNetwork?.length || listActivePractice?.length || activeSort?.value || Number(searchParams.page))
      });
    }
  }, [listActivePractice, listActiveNetwork, searchParams.page, searchParams.tab, filtrationByDate?.value]); // eslint-disable-line

  useEffect(() => {
    if (isReferrals) {
      document.title = t("dashboard_referrals_all_meta_title");
    }
  }, [updateReferralRequest, searchParams.tab, searchParams.page]); // eslint-disable-line

  return { referralsList };
};

export const useReferralsState = () => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const [activeSort, setActiveSort] = useState("");
  const [searchReferrals, setSearchReferrals] = useState("");
  const [perPage, setPerPage] = useState("");
  const [listActivePractice, setListActivePractice] = useState([]);
  const [listActiveNetwork, setListActiveNetwork] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);

  const { filters, loading } = useSelector(state => state.payPerLead);
  const { practices } = filters;
  const { pagination, preloaders: preloadersReferrals } = useSelector(state => state.referralRequests);

  return {
    activeSort,
    searchReferrals,
    perPage,
    listActivePractice,
    listActiveNetwork,
    activeFilter,
    searchParams,
    setActiveSort,
    setSearchReferrals,
    setPerPage,
    setListActivePractice,
    setListActiveNetwork,
    setActiveFilter,
    filters,
    loading,
    pagination,
    practices,
    preloadersReferrals
  };
};

export const useSortOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: "desc", label: t("dashboard_ppl_sortdropdown_recent") },
      { value: "asc", label: t("dashboard_ppl_sortdropdown_oldest") }
    ],
    [t]
  );
};

export const useReferralsActions = ({
  activeSort,
  listActiveNetwork,
  listActivePractice,
  searchParams,
  searchReferrals,
  setActiveSort,
  setSearchReferrals
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getCases = useCallback(data => dispatch(referralRequestsActions.getCases(data)), [dispatch]);

  const handleRemoveReferrals = useCallback(
    leadId => dispatch(referralRequestDetailActions.handleRemove(leadId, history, true, searchParams)),
    [dispatch, history, searchParams]
  );

  const handleFiltrationSortReferrals = useCallback(
    sort => {
      setActiveSort(sort);
      getCases({
        sort: sort.value,
        dirId: listActiveNetwork,
        practiceId: listActivePractice,
        type: searchParams.tab,
        page: searchParams.page,
        search: searchReferrals
      });
    },
    [getCases, listActiveNetwork, listActivePractice, searchParams.tab, searchParams.page, searchReferrals, setActiveSort]
  );

  const handleFiltrationSearchReferrals = useCallback(
    searchValue => {
      setSearchReferrals(searchValue);
      getCases({
        sort: activeSort?.value,
        dirId: listActiveNetwork,
        practiceId: listActivePractice,
        type: searchParams.tab,
        page: searchParams.page,
        search: searchValue,
        isSelectedFilter: true
      });
    },
    [getCases, activeSort, listActiveNetwork, listActivePractice, searchParams.tab, searchParams.page, setSearchReferrals]
  );

  return {
    getCases,
    handleRemoveReferrals,
    handleFiltrationSortReferrals,
    handleFiltrationSearchReferrals
  };
};
