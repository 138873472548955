import axios from "axios";

import { home } from "../constants/actionTypes";
// eslint-disable-next-line import/no-cycle
import { actions as mainActions } from "./main";
import { track, trackDataLayer } from "../helpers/track";
import { eventName, eventNameDataLayer } from "../constants/track";

export const actions = {
  getDashboardInfo() {
    return dispatch => {
      dispatch(this.setLoading("dashboard", true));

      axios
        .get("/members/json-api/index/home")
        .then(res => {
          dispatch({
            type: home.GET_DASHBOARD_DATA_SUCCESS,
            payload: res.data
          });
        })
        .catch(err =>
          dispatch({
            type: home.GET_DASHBOARD_DATA_FAIL,
            payload: err
          })
        );
    };
  },

  getAddNewListingData(isModal) {
    return dispatch => {
      axios({ method: "get", responseType: "json", url: "/members/json-api/index/lists" })
        .then(response => {
          const priority = isModal ? ["Agents", "Doctors", "Lawyers"] : ["Accountants", "Agents", "Doctors", "Lawyers"];
          const professionsSort = response.data.professions
            .filter(item => !item.title.includes("Profile"))
            .sort((a, b) => (priority.includes(a.title) ? (priority.includes(b.title) ? (a.title < b.title ? -1 : 0) : -1) : 1));

          dispatch({
            type: home.GET_ADD_NEW_LISTING_DATA,
            payload: {
              ...response?.data,
              professions: professionsSort
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_ADD_NEW_LISTING_DATA_FAIL
          });
        });
    };
  },

  addNewListing(data, history, listingCreatePage, listingType) {
    return (dispatch, getState) => {
      const {
        account: { profile }
      } = getState();

      dispatch(this.setLoading("listingAssistant", true));

      const isEvensOrJobsPage =
        ["/my-events", "/my-jobs", "/my-organizations"].some(item => history.location.pathname.includes(item)) ||
        ["Events", "Jobs", "Organizations"].some(item => listingType.includes(item));
      const pattern = /jobs|events|organizations/;
      const listingName = history.location.pathname.match(pattern) || listingType.toLowerCase().match(pattern);

      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}` : "/members/json-api/signup/copy-or-create-listing";

      axios({
        method: "post",
        responseType: "json",
        url,
        data,
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          dispatch({
            type: home.ADD_NEW_LISTING,
            payload: res.data
          });

          dispatch(mainActions.toggleNewListing(false));

          const pathname = isEvensOrJobsPage ? `/my-${listingName}` : "/listings";

          const listingId = isEvensOrJobsPage ? res.data.data.listing_id : res.data.listing_id;

          history.push(`${pathname}/${listingId}`);

          track(eventName.addToCart, { em: profile.email, fn: profile.name_f, ln: profile.name_f });
          trackDataLayer(eventNameDataLayer.listingCreated, { listing_status: "in review", listing_create_page: listingCreatePage });
        })
        .catch(() => {
          dispatch({
            type: home.ADD_NEW_LISTING_FAIL
          });
        });
    };
  },

  getSaved(command = "latest") {
    return dispatch => {
      dispatch(this.setLoading("saved", true));

      axios({ method: "get", url: `/members/api-v2/bookmarks?command=${command}&expand=lawfirms,practices,parent_company` })
        .then(response => {
          const { records } = response.data.data;
          let payload;
          if (command === "latest") {
            payload = records;
          } else {
            // Initialize all keys to empty arrays
            payload = {
              profiles: [],
              organizations: [],
              events: [],
              jobs: []
            };
            // Only update the key relevant to the command
            payload[command] = records;
          }

          dispatch({
            type: home.GET_SAVED,
            payload: {
              list: payload,
              count: response.data.data.counting
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_SAVED_FAIL
          });
        });
    };
  },

  getMyFeed(command = "all", search, page) {
    return dispatch => {
      dispatch(this.setLoading("feed", true));

      return axios({
        method: "get",
        url: `/members/api-v2/my-feed?command=${command}&expand=lawfirms,practices,parent_company,event_tickets${
          page ? `&page=${page}` : ""
        }${search ? `&filters[0][field]=name&filters[0][value]=${search}` : ""}`
      })
        .then(response => {
          dispatch({
            type: home.GET_MY_FEED,
            payload: {
              list: response.data.data.records,
              count: response.data.data.counting,
              pagination: response.data.pagination,
              command
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_MY_FEED_FAIL
          });
        });
    };
  },

  getFollowing() {
    return dispatch => {
      dispatch(this.setLoading("feed", true));

      axios({ method: "get", url: "/members/api-v2/user-following" })
        .then(response => {
          dispatch({
            type: home.GET_FOLLOWING,
            payload: response.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_FOLLOWING_FAIL
          });
        });
    };
  },

  followCommunities(data) {
    return dispatch => {
      axios({
        method: "post",
        responseType: "json",
        url: "/members/api-v2/user-following/communities",
        data,
        headers: { "Content-Type": "application/json" }
      })
        .then(response => {
          dispatch({
            type: home.POST_FOLLOW_COMMUNITIES,
            payload: response.data.data
          });
        })
        .catch(err => {
          dispatch({
            type: home.POST_FOLLOW_COMMUNITIES_FAIL,
            payload: { message: err?.message || "Something went wrong" }
          });
        });
    };
  },

  removeFollowing(id) {
    return dispatch => {
      dispatch(this.setLoading("removeFollowing", true));

      axios({ method: "DELETE", url: `/members/api-v2/user-following?id=${id}` })
        .then(() => {
          dispatch({
            type: home.REMOVE_FOLLOWING,
            payload: id
          });
        })
        .catch(() => {
          dispatch({
            type: home.REMOVE_FOLLOWING_FAIL
          });
        });
    };
  },

  addToFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      return axios({
        method: "post",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(res => {
          dispatch({
            type: home.ADD_FAVORITES,
            payload: listingId
          });

          return res.data.success;
        })
        .catch(error => {
          dispatch({
            type: home.ADD_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  removeFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      return axios({
        method: "delete",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(res => {
          dispatch({
            type: home.REMOVE_FAVORITES,
            payload: listingId
          });

          return res.data.success;
        })
        .catch(error => {
          dispatch({
            type: home.REMOVE_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: home.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  removeAllError() {
    return {
      type: home.REMOVE_ALL_ERROR
    };
  }
};
