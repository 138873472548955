import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";

import styles from "../search/search.module.css";
import Input from "../../common/input";

const CityStateZipInput = ({
  cityStateZipList,
  setCityStateZipValue,
  cityStateZipValue,
  getCityStateZip,
  setActiveCityStateZip,
  placeholder = "Country / State / City",
  className
}) => {
  const [isActiveCityStateZipDropdown, setIsActiveCityStateZipDropdown] = useState(false);
  const [isEditCityStateZipValue, setIsEditCityStateZipValue] = useState(false);
  const cityStateZipListRef = useRef();
  const cityStateZipInputRef = useRef();

  const handleChangeCityStateZipValue = event => {
    setCityStateZipValue(event.target.value);

    if (!isEditCityStateZipValue) {
      setIsEditCityStateZipValue(true);
    }
  };

  const handleClickActiveCityStateZip = current => () => {
    const inputValue = current.city && current.state_code ? `${current.city}, ${current.state_code}` : current.state || current.country;
    setActiveCityStateZip(current);
    setCityStateZipValue(inputValue);
    setIsActiveCityStateZipDropdown(false);
  };

  const handleInputClick = () => {
    setIsActiveCityStateZipDropdown(prev => !prev);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isEditCityStateZipValue) {
        getCityStateZip(cityStateZipValue);
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [cityStateZipValue]); // eslint-disable-line

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        cityStateZipListRef.current &&
        !cityStateZipListRef.current.contains(event.target) &&
        !cityStateZipInputRef.current.contains(event.target)
      ) {
        setIsActiveCityStateZipDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const renderCityStateZipList = cityStateZipList.map(item => {
    return (
      <button
        type="button"
        className={styles.cityStateZipListItem}
        key={`${item.country}-${item.state}`}
        onClick={handleClickActiveCityStateZip(item)}
      >
        <span className={styles.cityStateZipListItemCountry}>{item.country}</span>
        <span className={styles.cityStateZipListItemState}>
          {item.city} {item.state}
        </span>
      </button>
    );
  });

  return (
    <div className={styles.cityStateZipContent}>
      <Input
        classNameWrap={styles.cityStateZipWrap}
        className={cn(styles.cityStateZip, className)}
        placeholder={placeholder}
        onChange={handleChangeCityStateZipValue}
        value={cityStateZipValue}
        inputRef={cityStateZipInputRef}
        onClick={handleInputClick}
      />
      {cityStateZipList.length && isActiveCityStateZipDropdown ? (
        <div className={styles.cityStateZipList} ref={cityStateZipListRef}>
          {renderCityStateZipList}
        </div>
      ) : null}
    </div>
  );
};

export default CityStateZipInput;
