import React, { useCallback, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Directories from "../addFreeListingDirectories";
import { actions as homeActions } from "../../actions/home";
import styles from "./addFreeListing.module.css";

export const AddFreeListing = ({ isModal, listingModalListingType }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const isMyEventsPage = history.location.pathname.includes("/my-events") || listingModalListingType.includes("/my-events");
  const isMyJobsPage = history.location.pathname.includes("/my-jobs") || listingModalListingType.includes("/my-jobs");
  const isMyOrganizationsPage =
    history.location.pathname.includes("/my-organizations") || listingModalListingType.includes("/my-organizations");
  const selectRef = useRef();

  const { addNewListingData } = useSelector(state => state.home);
  const addNewListing = useCallback(
    (data, listingType) => dispatch(homeActions.addNewListing(data, history, window.location.href, listingType)),
    [dispatch]
  ); // eslint-disable-line

  const handleSubmit = values => {
    const data = {
      category_id: values.category.category_id,
      listing_id: values.listing.listing_id,
      attorney: ""
    };

    addNewListing(data, values.profession.title);
  };

  const formik = useFormik({
    initialValues: {
      category: { category_id: "", profession_id: "", title: "Select" },
      listing: { listing_id: "", membership: "Select" },
      profession: { profession_id: "", title: "Select" },
      submitted: false
    },
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleChangeProfessional = newValue => {
    let professionIds = [newValue.profession_id].map(item => String(item));

    if (newValue.title.includes("Organizations")) {
      professionIds = addNewListingData.professions
        .filter(item => item.title.includes("Organizations"))
        .map(item => String(item.profession_id));
    }

    const newCategories = addNewListingData.categories.filter(item => professionIds.includes(String(item.profession_id)));

    if (selectRef.current) {
      selectRef.current.focus();
      if (formik.values.profession.title !== newValue.title) {
        formik.setFieldValue("category", { category_id: "", profession_id: "", title: "Select" });
      }
    }

    formik.setFieldValue("profession", newValue);

    if (["Jobs", "Events", "Organizations"].includes(newValue.title) && newCategories.length) {
      handleChangeCategory(newCategories[0]);
    }

    handleSubmitListing();
  };

  const handleChangeCategory = newValue => {
    formik.setFieldValue("category", newValue);
  };

  useEffect(() => {
    if (isMyJobsPage || isMyEventsPage || isMyOrganizationsPage) {
      let findCurrentProfession = "";
      if (isMyJobsPage) {
        findCurrentProfession = addNewListingData.professions.find(item => item.title === "Jobs");
      } else if (isMyEventsPage) {
        findCurrentProfession = addNewListingData.professions.find(item => item.title === "Events");
      } else {
        findCurrentProfession = addNewListingData.professions.find(item => item.title.includes("Organizations"));
      }

      handleChangeProfessional(findCurrentProfession);
    }
  }, [isMyJobsPage, isMyEventsPage, isMyOrganizationsPage]);

  useEffect(() => {
    if (
      !formik.values.submitted &&
      (isMyJobsPage || isMyEventsPage || isMyOrganizationsPage) &&
      formik.values.profession.title &&
      formik.values.category.title
    ) {
      formik.handleSubmit();
      formik.setFieldValue("submitted", true);
    }
  }, [formik.values.category.title]);

  const handleSubmitListing = () => {
    if (!isMyOrganizationsPage && !isMyJobsPage && !isMyEventsPage) {
      formik.handleSubmit();
    }
  };

  return (
    <>
      {isMyJobsPage || isMyEventsPage || isMyOrganizationsPage ? null : (
        <>
          <div className={styles.content}>
            <h2 className={styles.title}>Add new listing</h2>
            <h3 className={styles.description}>Select listing type</h3>

            <Directories
              activeProfessional={formik.values.profession}
              professions={addNewListingData.professions.filter(item => item.title.toLowerCase() !== "people")}
              handleChangeProfessional={handleChangeProfessional}
              isViewMore
              isModal={isModal}
            />
          </div>
        </>
      )}
    </>
  );
};
