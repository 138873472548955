import React from "react";

import cn from "classnames";

import styles from "./schedule.module.css";

const Schedule = ({ bookmeetings = [] }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Schedule</h3>
      <div>
        {bookmeetings.map(item => {
          return (
            <div className={styles.tile} key={item.name}>
              <div className={styles.tileHeading}>
                <h6 className={styles.tileTitle}>{item.name}</h6>
                <button type="button" className={cn(styles.tileButton, styles.tileButtonDesktop)}>
                  Schedule 15 Minutes for Free
                </button>
              </div>
              <button type="button" className={cn(styles.tileButton, styles.tileButtonMobile)}>
                Schedule 15 Minutes for Free
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Schedule;
