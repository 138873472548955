import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Modal } from "../../common/modal";
import { ReactComponent as ArrowIcon } from "../../../media/icons/arrow-right.svg";
import testBanner from "../../../media/images/eventsTestBanner.jpg";
import styles from "./allPhotos.module.css";

const SampleNextArrow = props => {
  const { onClick } = props;

  return (
    <button type="button" className={cn(styles.arrow, styles.arrowNext)} onClick={onClick}>
      <ArrowIcon />
    </button>
  );
};

const SamplePrevArrow = props => {
  const { onClick } = props;
  return (
    <button type="button" className={cn(styles.arrow, styles.arrowPrev)} onClick={onClick}>
      <ArrowIcon />
    </button>
  );
};

const settings = {
  dots: false,
  arrow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

const AllPhotos = ({ closeModal }) => {
  return (
    <Modal closeModal={closeModal} classNameMain={styles.modal} clearAllModals={closeModal}>
      <section>
        <div className={styles.title}>All Photos</div>

        <Slider {...settings} className={styles.slider}>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
          <div>
            <img src={testBanner} alt="cover" />
          </div>
        </Slider>
      </section>
    </Modal>
  );
};

export default AllPhotos;
