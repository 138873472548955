import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { ReactComponent as ArrowIcon } from "../../../media/icons/arrow-right.svg";
import styles from "./breadcrumbs.module.css";

const Breadcrumbs = () => {
  return (
    <div className={styles.content}>
      <NavLink className={styles.item} to="/paid-leads">
        My Leads <ArrowIcon className={styles.icon} />
      </NavLink>
      <span className={cn(styles.item, styles.itemActive)}>Lead Details</span>
    </div>
  );
};

export default Breadcrumbs;
