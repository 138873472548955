import React, { useMemo } from "react";

import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import styles from "./company.module.css";

const Company = ({ currentJob, parentCompany }) => {
  const {
    location_city: city,
    location_state: state,
    location_country: country,
    company_desc: description,
    company_website: website
  } = currentJob;

  const companyName = useMemo(() => {
    if (parentCompany?.length) {
      return parentCompany[0]?.name;
    }

    return "";
  }, [parentCompany]);

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{companyName}</h3>
      <div className={styles.heading}>
        {website && (
          <a href={`mailto::${website}`} className={styles.website} rel="noopener noreferrer">
            {website}
          </a>
        )}
        <span className={styles.address}>
          <LocationIcon />
          {city ? `${city}, ` : ""}
          {state ? `${state}, ` : ""}
          {country ? `${country}, ` : ""}
        </span>
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};

export default Company;
