import React, { useCallback, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import EventsAside from "../publicListing/eventsAside";
import EmptyState from "../publicListing/emptyState";
import Heading from "./heading";
import Gallery from "./gallery";
import About from "./about";
import Schedule from "./schedule";
import Location from "./location";
import Tags from "./tags";
import Video from "./video";
import Languages from "./languages";
import Socials from "./socials";
import Licenses from "./licenses";
import Insurances from "./insurances";
import { actions } from "../../actions/publicListings";
import { OverlayPreloader } from "../common/preloader";
import styles from "./publicOrganizations.module.css";
import { actions as profileActions } from "../../actions/account/profile";
import Layout from "../layout";
import PublicLayout from "../publicLayout";

const PublicOrganization = ({ footerInfo, socialLinks, keycloak, listingId }) => {
  const dispatch = useDispatch();
  const getListing = useCallback(id => dispatch(actions.getListing(id)), [dispatch]);
  const addToFavorite = useCallback(id => dispatch(actions.addToFavorite(id)), [dispatch]);
  const removeFavorite = useCallback(id => dispatch(actions.removeFavorite(id)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const { preloaders, listing } = useSelector(state => state.publicListing);
  const { account } = useSelector(state => state);
  const { profile } = account;

  const {
    description,
    bookmeetings,
    insurances,
    lawfirms: locations,
    practices,
    languages,
    licenses,
    socials,
    attorney,
    website,
    listings_image_name: logo,
    bookmarked: isFavorite,
    listing_id,
    parentcompany: parentCompany,
    intro_video: introVideo
  } = listing;

  const videoId = useMemo(() => {
    if (introVideo) {
      const { searchParams } = new URL(introVideo);
      return introVideo && searchParams ? searchParams.get("v") : "";
    }

    return "";
  }, []);

  useEffect(() => {
    getListing(listingId);
  }, [listingId]);

  useEffect(() => {
    if (!profile.user_id) {
      getProfile();
    }
  }, [profile.user_id]);

  const MainContainer = profile?.user_id ? Layout : PublicLayout;

  return (
    <>
      {preloaders.page && <OverlayPreloader />}

      <MainContainer footerInfo={footerInfo} socialLinks={socialLinks} keycloak={keycloak}>
        <div className={styles.main}>
          <div className={styles.container}>
            {!listing.listing_id ? (
              <EmptyState />
            ) : (
              <>
                <div className={styles.content}>
                  <Heading
                    isGalleryExist
                    name={attorney}
                    website={website}
                    logo={logo}
                    addToFavorite={() => addToFavorite(listing_id)}
                    removeFavorite={() => removeFavorite(listing_id)}
                    isShowFavorites={profile.user_id}
                    isFavorite={isFavorite}
                  />
                  <Gallery />
                  <About description={description} />
                  {bookmeetings.length ? <Schedule bookmeetings={bookmeetings} /> : null}

                  {locations.length ? <Location parentCompany={parentCompany} address={locations[0]} /> : null}

                  {practices.length ? <Tags title="Industries" tags={practices.map(item => item._practice)} /> : null}

                  {languages.length ? <Languages languages={languages} /> : null}

                  {videoId && <Video videoId={videoId} />}

                  {licenses.length ? <Licenses licenses={licenses} /> : null}

                  {socials.length ? <Socials socials={socials} /> : null}

                  {insurances.length ? <Insurances insurances={insurances} /> : null}
                </div>
                <EventsAside />
              </>
            )}
          </div>
        </div>
      </MainContainer>
    </>
  );
};

export default PublicOrganization;
