import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { Button } from "../../../common/button";
import { ReactComponent as FavoriteIcon } from "../../../../media/icons/unsave.svg";
import { ReactComponent as AnswersIcon } from "../../../../media/icons/answers.svg";
import locationIcon from "../../../../media/icons/location.svg";
import calendarIcon from "../../../../media/icons/calendar.svg";
import styles from "./questionBox.module.css";

export const QuestionBox = ({ questionId, added, answerTimes, city, state, title, saved, saveQuestion, summary, tab, unSaveQuestion }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <Link to={`/question-answer/${questionId}?tab=${tab}`} className={styles.title}>
        {title}
      </Link>
      <p className={styles.description}>{summary}</p>

      <div className={styles.container}>
        <div className={styles.answerFlex}>
          {answerTimes ? (
            <Link to={`/question-answer/${questionId}?tab=${tab}`} className={styles.titleLink}>
              <AnswersIcon />({answerTimes}){" "}
              {answerTimes === 1 ? <>{t("dashboard_qa_all_answer")}</> : <>{t("dashboard_qa_all_answers")}</>}
            </Link>
          ) : null}
        </div>

        <div className={styles.info}>
          <div className={styles.city}>
            <img className={styles.infoIcon} src={locationIcon} alt="" />
            {city ? `${city}, ` : ""} {state}
          </div>

          <div className={styles.date}>
            <img className={styles.infoIcon} src={calendarIcon} alt="" />
            {added && format(new Date(added.split(" ").join("T")), "MMM do yyyy")}
          </div>

          {saved ? (
            <Button className={cn(styles.iconButtonFilled, "button--clear")} onClick={() => unSaveQuestion(questionId)}>
              <div className={styles.iconFilled} />
              <FavoriteIcon />
            </Button>
          ) : (
            <Button className={cn(styles.iconButton, "button--clear")} onClick={() => saveQuestion(questionId)}>
              <div className={styles.iconFill} />
              <FavoriteIcon />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
