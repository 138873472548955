export const questionsNamespace = "QUESTIONS_";
export const referralRequestDetailNameSpace = "REFERRAL_REQUEST_DETAIL_";
export const referralRequestsNamespace = "REFERRAL_REQUESTS_";
export const notificationNamespace = "NOTIFICATION_";
export const performanceNamespace = "PERFORMANCE_";
export const accountNamespace = "ACCOUNT_";
export const callHistoryNamespace = "CALL_HISTORY_";
export const payPerLeadDetailNamespace = "PAY_PER_LEAD_DETAIL_";
export const payPerLeadNamespace = "PAY_PER_LEAD_";
export const listingNamespace = "LISTING_";
export const paymentNamespace = "PAYMENT_";
export const inboxNamespace = "INBOX_";
export const homeNamespace = "HOME_";
export const signUpNamespace = "SIGN_UP_";
export const authNamespace = "AUTH_";
export const mainNamespace = "MAIN_";
export const landingNamespace = "LANDING_";
export const messagesNamespace = "MESSAGES_";
export const publicListingsNameSpace = "PUBLIC_LISTINGS_";
export const teamsNameSpace = "TEAMS_";
export const searchResultNameSpace = "SEARCH_RESULT_";
export const searchNameSpace = "SEARCH_";
