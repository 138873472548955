import React from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./layout.module.css";
import MainSidebar from "../mainSidebar";
import Header from "../header";
import Footer from "../newFooter";

const Layout = ({ keycloak, isShowFooter, footerInfo, socialLinks, children }) => {
  const history = useHistory();
  const isMessages = history.location.pathname === "/inbox";
  const messagePageClassName = isMessages ? styles.messagePage : "";

  return (
    <>
      <div className={cn(styles.page, messagePageClassName)}>
        <MainSidebar keycloak={keycloak} />
        <div className={styles.content}>
          <Header keycloak={keycloak} />

          {children}
        </div>
      </div>

      {!isShowFooter && <Footer footerInfo={footerInfo} socialLinks={socialLinks} />}
    </>
  );
};

export default Layout;
