import axios from "axios";

import { publicListing } from "../constants/actionTypes";

export const actions = {
  getListing(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api/public/listing?listing_id=${listingId}`
      })
        .then(response => {
          dispatch({
            type: publicListing.GET_LISTINGS,
            payload: response.data
          });
        })
        .catch(error => {
          dispatch({
            type: publicListing.GET_LISTINGS_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  addToFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(() => {
          dispatch({
            type: publicListing.ADD_FAVORITES
          });
        })
        .catch(error => {
          dispatch({
            type: publicListing.ADD_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  removeFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "delete",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(() => {
          dispatch({
            type: publicListing.REMOVE_FAVORITES
          });
        })
        .catch(error => {
          dispatch({
            type: publicListing.ADD_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  toggleModal(modal, isActive) {
    return {
      type: publicListing.TOGGLE_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  clearAllModals() {
    return {
      type: publicListing.CLEAR_ALL_MODALS
    };
  },

  setLoading(section, isLoading) {
    return {
      type: publicListing.SET_LOADING,
      payload: { section, isLoading }
    };
  }
};
