import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useHistory } from "react-router-dom";
import PhotoModal from "../cropImage";
import Confirm from "../../listings/components/modals/confirm";
import { Upgrade } from "../../listings/components/modals/upgrade";
import { Payment } from "../../listings/components/modals/payment";
import { modals as modalsConstant, validationNames } from "../../../constants/listings";
import { DowngradeSuccess } from "../../listings/components/modals/downgradeSuccess";
import { Downgrade } from "../../listings/components/modals/downgrade";
import { PaymentSuccess } from "../../listings/components/modals/paymentSuccess";
import { Photo } from "./photo";
import { getValidationData } from "../../../helpers";
import { Phone } from "./phone";
import { UnpauseSuccess } from "../../listings/components/modals/unpauseSuccess";
import { Unpause } from "../../listings/components/modals/unpause";
import { DeleteSuccess } from "../../listings/components/modals/deleteSuccess";
import { Delete } from "../../listings/components/modals/delete";
import { Pause } from "../../listings/components/modals/pause";
import { PauseSuccess } from "../../listings/components/modals/pauseSuccess";
import { DowngradePeriodSuccess } from "../../listings/components/modals/downgradePeriodSuccess";
import { DowngradePeriod } from "../../listings/components/modals/downgradePeriod";
import { actions as listingActions } from "../../../actions/listing";
import { actions as paymentActions } from "../../../actions/payment";
import { Notifications } from "./notifications";
import { Modal } from "../../common/modal";
import { AddCard } from "../../common/payment/addCard";
import EditCard from "../../common/payment/editCard";
import { apiKey } from "../../../constants/stripe";

const stripeToken = loadStripe(apiKey);

const Modals = ({
  modals,
  handleSubmitImage,
  closeModal,
  openModal,
  memberData,
  uploadImagesError,
  clearAllModals,
  isPendingPayment,
  isExpired,
  updateCard,
  addCard,
  countries,
  setMembershipClear,
  images,
  socialPhoto,
  handleSubmitListing,
  handleBlur,
  memberInputData,
  submitChanges,
  unpauseMembership,
  pauseMembership,
  getUpgradeOptions
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deleteMembership = useCallback(() => dispatch(listingActions.deleteMembership(memberInputData, false, history, true)), [dispatch]);
  const uploadSocialImage = useCallback(imageData => dispatch(listingActions.uploadSocialImage(imageData)), [dispatch]);
  const uploadImage = useCallback(imageData => dispatch(listingActions.uploadImage(imageData)), [dispatch]);
  const deleteImage = useCallback((listingId, imageId) => dispatch(listingActions.deleteImage(listingId, imageId)), [dispatch]);
  const clearPaymentState = useCallback(() => dispatch(paymentActions.clearState()), [dispatch]);
  const updateDefaultSource = useCallback(cardId => dispatch(paymentActions.updateDefaultSource(cardId)), [dispatch]);
  const downgradeMembershipHow = useCallback(ids => dispatch(listingActions.downgradeMembershipHow(ids, true)), [dispatch]);
  const downgradeMembership = useCallback(ids => dispatch(listingActions.downgradeMembership(ids, true, false, history)), [dispatch]);
  const unDowngradeMembership = useCallback(ids => dispatch(listingActions.unDowngradeMembership(ids, true, false, history)), [dispatch]);
  const getPaymentMethods = useCallback(() => dispatch(paymentActions.getPaymentMethods()), [dispatch]);
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const cleanUpgradeOptions = useCallback(() => dispatch(listingActions.cleanUpgradeOptions()), [dispatch]);
  const getRenewOptions = useCallback((id, coupon) => dispatch(listingActions.getRenewOptions(id, coupon)), [dispatch]);
  const clearSavedPaymentData = useCallback(() => dispatch(listingActions.clearSavedPaymentData()), [dispatch]);
  const setSavedPaymentData = useCallback(() => dispatch(listingActions.setSavedPaymentData()), [dispatch]);
  const updateActiveUpgradeOptions = useCallback(activeOption => dispatch(listingActions.updateActiveUpgradeOptions(activeOption)), [
    dispatch
  ]);
  const chargeForMembership = useCallback(
    (paymentData, paymentType) => dispatch(paymentActions.chargeForMembership(paymentData, paymentType, false, history)),
    [dispatch]
  );
  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const clearTokenError = useCallback(() => () => dispatch(paymentActions.clearTokenError()), [dispatch]);

  const handleRemoveMembership = () => {
    deleteMembership();
  };

  const {
    preloaders,
    photoError,
    success,
    upgradeOptions,
    activeUpgradeOptions,
    showUpgradeOptionsError,
    validations,
    savedPaymentData,
    notificationModalData
  } = useSelector(state => state.listing);

  const {
    defaultSource,
    preloaders: paymentPreloaders,
    paymentMethods,
    selectedCard,
    success: paymentSuccess,
    error: { stripeTokenError: errorToken, addCard: errorAddCard }
  } = useSelector(state => state.payment);

  return (
    <>
      <Elements stripe={stripeToken}>
        <>
          {modals.addCard && (
            <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.addCard)} clearAllModals={clearAllModals}>
              <AddCard
                errorAddCard={errorAddCard}
                addCard={addCard}
                clearTokenError={clearTokenError}
                countries={countries}
                closeModal={() => closeModal(modalsConstant.addCard)}
                errorToken={errorToken}
                isFirst={!paymentMethods?.length}
                setErrorToken={setErrorToken}
                paymentPreloaders={paymentPreloaders}
                paymentSuccess={paymentSuccess}
              />
            </Modal>
          )}

          {selectedCard && modals.editCard && (
            <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.editCard)} clearAllModals={clearAllModals}>
              <EditCard
                closeModal={() => closeModal(modalsConstant.editCard)}
                countries={countries}
                currentCardDetails={selectedCard}
                paymentPreloaders={paymentPreloaders}
                paymentSuccess={paymentSuccess}
                updateCard={updateCard}
              />
            </Modal>
          )}
        </>
      </Elements>

      {modals.imageCrope && (
        <PhotoModal
          handleSubmitImage={handleSubmitImage}
          closeModal={() => closeModal("imageCrope")}
          clearAllModals={() => closeModal("imageCrope")}
          listingId={memberData.listing_id}
          loading={preloaders.photo}
          setShowUploadImageNotificationError={uploadImagesError}
        />
      )}

      {(modals.upgrade || modals.payPending || modals.renew) && (
        <Upgrade
          cleanUpgradeOptions={cleanUpgradeOptions}
          itemToEdit={memberData}
          modals={modals}
          preloaders={preloaders}
          getUpgradeOptions={getUpgradeOptions}
          getRenewOptions={getRenewOptions}
          activeUpgradeOptions={activeUpgradeOptions}
          updateActiveUpgradeOptions={updateActiveUpgradeOptions}
          showUpgradeOptionsError={showUpgradeOptionsError}
          setItemToEdit={() => {}}
          upgradeOptions={upgradeOptions}
          clearAllModals={clearAllModals}
          closeModal={closeModal}
          openModal={openModal}
          savedPaymentData={savedPaymentData}
          clearSavedPaymentData={clearSavedPaymentData}
          setSavedPaymentData={setSavedPaymentData}
        />
      )}

      {modals.payment && (
        <Payment
          addCard={addCard}
          countries={countries}
          chargeForMembership={chargeForMembership}
          deleteCard={deleteCard}
          defaultSource={defaultSource}
          getPaymentMethods={getPaymentMethods}
          itemToEdit={memberData}
          modals={modals}
          paymentMethods={paymentMethods}
          paymentPreloaders={paymentPreloaders}
          activeUpgradeOptions={activeUpgradeOptions}
          selectedCard={selectedCard}
          selectCard={selectCard}
          updateDefaultSource={updateDefaultSource}
          updateCard={updateCard}
          clearAllModals={clearAllModals}
          openModal={openModal}
          savedPaymentData={savedPaymentData}
          clearSavedPaymentData={clearSavedPaymentData}
          isPendingPayment={isPendingPayment}
          isExpired={isExpired}
          closeModal={() => closeModal(modalsConstant.payment)}
        />
      )}

      {modals.confirm && (
        <Confirm
          openModal={openModal}
          closeModal={() => closeModal(modalsConstant.confirm)}
          clearAllModals={clearAllModals}
          activeUpgradeOptions={activeUpgradeOptions}
          modals={modals}
          chargeForMembership={chargeForMembership}
          itemToEdit={memberData}
          selectedCard={selectedCard}
          defaultSource={defaultSource}
          paymentPreloaders={paymentPreloaders}
          clearSavedPaymentData={clearSavedPaymentData}
        />
      )}

      {modals.downgradeSuccess && <DowngradeSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

      {modals.downgrade && (
        <Downgrade
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          itemToEdit={memberData}
          preloadersDowngradeMembership={preloaders.downgrade}
          downgradeMembership={downgradeMembership}
        />
      )}

      {modals.paymentSuccess && (
        <PaymentSuccess
          closeModal={() => closeModal(modalsConstant.paymentSuccess)}
          clearPaymentState={clearPaymentState}
          modals={modals}
          activeUpgradeOptions={activeUpgradeOptions}
          setMembershipClear={setMembershipClear}
          updateActiveUpgradeOptions={updateActiveUpgradeOptions}
          clearAllModals={clearAllModals}
          clearSavedPaymentData={clearSavedPaymentData}
        />
      )}

      {(modals.photo || modals.socialPhoto) && (
        <Photo
          closeModal={clearAllModals}
          deleteImage={deleteImage}
          id={memberData.listing_id}
          images={images}
          loading={preloaders.photo}
          photoError={photoError}
          uploadImage={uploadImage}
          uploadSocialImage={uploadSocialImage}
          clearAllModals={clearAllModals}
          validation={getValidationData(validations, validationNames.pictures)}
          validationSocialPhoto={getValidationData(validations, validationNames.coverImage)}
          isSocialPhoto={modals.socialPhoto}
          socialPhoto={socialPhoto}
          handleSubmitListing={handleSubmitListing}
          listingsImageName={memberData.listings_image_name}
        />
      )}

      {modals.phone && (
        <Phone
          closeModal={() => closeModal(modalsConstant.phone)}
          handleBlur={handleBlur}
          loading={preloaders.modal}
          memberInputData={memberInputData}
          submitChanges={submitChanges}
          success={success}
          clearAllModals={clearAllModals}
        />
      )}

      {modals.unpauseSuccess && <UnpauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

      {modals.unpause && (
        <Unpause
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          itemToEdit={memberInputData}
          handleUnpause={unpauseMembership}
          preloadersUnpauseMembership={preloaders.unpause}
        />
      )}

      {modals.deleteSuccess && <DeleteSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

      {modals.delete && (
        <Delete
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          itemToEdit={memberInputData}
          handleDelete={() => handleRemoveMembership(memberInputData)}
          preloadersDeleteMembership={preloaders.deleteMembership}
          handlePause={() => openModal(modalsConstant.pause)}
        />
      )}

      {modals.pause && (
        <Pause
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          itemToEdit={memberInputData}
          handlePause={pauseMembership}
          preloadersPauseMembership={preloaders.pause}
        />
      )}
      {modals.pauseSuccess && <PauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

      {modals.downgradePeriodSuccess && <DowngradePeriodSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
      {modals.downgradePeriod && (
        <DowngradePeriod
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          itemToEdit={memberInputData}
          preloaders={preloaders.downgrade}
          unDowngradeMembership={unDowngradeMembership}
          downgradeMembershipHow={downgradeMembershipHow}
        />
      )}

      <Notifications
        modals={modals}
        clearAllModals={clearAllModals}
        memberData={memberData}
        notificationModalData={notificationModalData}
      />
    </>
  );
};

export default Modals;
