import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../common/modal";
import Checkbox from "../../../common/checkbox";
import ModalControl from "../../../modalControl";
import styles from "./viewAccess.module.css";

const ViewAccess = ({ clearAllModals, onClose, active }) => {
  const { t } = useTranslation();

  const options = [
    { value: "listings", text: t("dashboard_teams_invite_option_1") },
    { value: "inbox", text: t("dashboard_teams_invite_option_2") },
    { value: "referrals", text: t("dashboard_teams_invite_option_3") },
    { value: "qa", text: t("dashboard_teams_invite_option_4") },
    { value: "payments", text: t("dashboard_teams_invite_option_5") }
  ];

  const { name_f, name_l, listings, inbox, referrals, qa, payments, email } = active;
  const activePermission = { listings, inbox, referrals, qa, payments };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      <span className={styles.label}>{t("dashboard_teams_myteam_title")}</span>
      <h3 className={styles.title}>{t("dashboard_teams_myaccess_title")}</h3>

      <form>
        <span className={styles.labelInput}>{t("dashboard_teams_invite_fieldinput_name")}</span>
        <div className={styles.modalInput}>{`${name_f} ${name_l}`}</div>
        <span className={styles.labelInput}>{t("dashboard_teams_invite_fieldtitle_email")}</span>
        <div className={styles.modalInput}>{email}</div>
      </form>

      <div className={styles.permission}>
        <h5 className={styles.permissionTitle}>Permissions</h5>

        <div>
          {options.map(item => {
            return (
              <Checkbox
                key={item.value}
                isNewStyles
                unactive
                big
                checked={activePermission[item.value]}
                className={styles.checkbox}
                id={item.value}
                name={item.value}
              >
                <span className={styles.checkboxName}>{item.text}</span>
              </Checkbox>
            );
          })}
        </div>
      </div>

      <ModalControl onExit={onClose} isHideExitConfirm isClose />
    </Modal>
  );
};

export default ViewAccess;
