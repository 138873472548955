import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { actions as mainActions } from "../../actions/main";
import { DetailNavigation } from "./popups/detailNavigation";
import { Notification } from "./notification";
import { Hamburger } from "./hamburger";
import { Avatar } from "./avatar";
// import MainSearch from "../mainSearch";
import styles from "./header.module.css";

const Header = ({ keycloak }) => {
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const dispatch = useDispatch();
  // communities
  const { isActiveMobileMenu } = useSelector(state => state.main);
  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);
  const getCommunities = useCallback(() => dispatch(mainActions.getCommunities()), [dispatch]);

  const isMessages = location.pathname === "/inbox";
  const isListingDetailPage = ["/my-jobs", "/my-events", "/my-organizations", "/profile"].some(item => location.pathname.includes(item));

  const handleOpenNewListing = () => {
    toggleMobileMenu(!isActiveMobileMenu);
  };

  useEffect(() => {
    setPopup(false);
  }, [location.search]);

  useEffect(() => {
    getCommunities();
  }, []);

  const togglePopup = name => {
    if (name === popup) {
      setPopup(false);
    } else {
      setPopup(name);
    }
  };

  const headerBackgroundMessages = isMessages ? styles.headerMessages : "";

  return (
    <div className={cn(styles.headerBackground, headerBackgroundMessages)}>
      <header className={cn(styles.headerGlobal, isListingDetailPage ? styles.header : "")}>
        <Hamburger toggleDropdown={handleOpenNewListing} activeMenu={isActiveMobileMenu} />

        <div className={styles.headerActions}>
          <Notification onCloseHandler={() => togglePopup("notification")} popup={popup} />
          <div className="relative">
            <Avatar togglePopup={togglePopup} />
            {popup === "links" && <DetailNavigation onCloseHandler={() => togglePopup("links")} keycloak={keycloak} />}
          </div>
        </div>
        {/*
        <MainSearch className={styles.mainSearch} communities={communities} />
        */}
      </header>
    </div>
  );
};

export default Header;
