import axios from "axios";

import { searchResult } from "../constants/actionTypes";

export const actions = {
  getSearchResult(data) {
    return dispatch => {
      dispatch(this.setLoading("page", true));
      const { country_code, practice_id } = data;
      const practiceId = practice_id ? [practice_id] : null;
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/public/search",
        data: { country_code, practice_id: practiceId }
      })
        .then(result => {
          const normalizePractices = Object.keys(result.data.featuredPractices).map(item => {
            return { id: item, ...result.data.featuredPractices[item] };
          });

          dispatch({
            type: searchResult.GET_SEARCH_RESULT,
            payload: { ...result.data, featuredPractices: normalizePractices }
          });
        })
        .catch(error => {
          dispatch({
            type: searchResult.GET_SEARCH_RESULT_FAIL,
            payload: error.response
          });
        });
    };
  },

  setLoading(section, isLoading) {
    return {
      type: searchResult.SET_LOADING,
      payload: { section, isLoading }
    };
  }
};
