import React from "react";
import { Notification } from "../../common/notification";

export const Notifications = ({ notification, error, resetAllSuccess }) => {
  return (
    <>
      {notification && (
        <Notification success time="6000" close={resetAllSuccess}>
          {notification}
        </Notification>
      )}

      {error && (
        <Notification error time="6000" close={resetAllSuccess}>
          {error}
        </Notification>
      )}
    </>
  );
};
