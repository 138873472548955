import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";

import { OverlayPreloader } from "../../common/preloader";
import { actions } from "../../../actions/auth";
import logo from "../../../media/icons/logo.svg";
import { Button } from "../../common/button";
import styles from "./forgotPassword.module.css";
import Input from "../../common/input";
import { email as emailRegex } from "../../../constants/regex";
import { useDocTitle } from "../../../helpers/hooks";

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();
  const resetPassword = useCallback(email => dispatch(actions.resetPassword(email, history)), [dispatch, history]);
  const {
    errors,
    preloaders: { resetPassword: loading }
  } = useSelector(state => state.auth);
  const { t } = useTranslation();

  useDocTitle(t("dashboard_forgotpassword_metatitle"));

  const validate = ({ email }) => {
    const validationErrors = {};
    if (!email) {
      validationErrors.email = t("dashboard_common_required");
    } else if (!email.match(emailRegex)) {
      validationErrors.email = t("dashboard_form_validations_invalid_email");
    }
    return validationErrors;
  };

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: () => {
      resetPassword(formik.values.email, history);
    },
    validate,
    validateOnBlur: true
  });
  const { touched, values, handleSubmit } = formik;

  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };
  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (errors) {
      formik.setFieldError("email", errors.resetPassword);
    }
  }, [errors]); //eslint-disable-line

  return (
    <div className={styles.wrapper}>
      {loading && <OverlayPreloader />}
      <div className="login-banner">
        <img className="login-banner__logo" src={logo} alt="" />
        <h1 className={cn("login-banner__title", styles.titleIndent)}>{t("dashboard_forgotpassword_h1")}</h1>
        <p className="login-banner__content ">{t("dashboard_forgotpassword_text")}</p>
      </div>

      <form className={styles.authForm} onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.mb30}
          error={formik.errors.email}
          id="email"
          isInvalid={formik.errors.email && touched.email}
          name="email"
          onBlur={e => handleBlur(e.target.name)}
          onChange={handleChange}
          label={t("dashboard_login_email")}
          required
          value={values.email}
        />

        <Button className="button--blue" type="submit">
          {t("dashboard_forgotpassword_button")}
        </Button>
        <hr className={styles.hr} />
        <Link to="/login" className={styles.link}>
          {t("dashboard_forgotpassword_back")}
        </Link>
      </form>
    </div>
  );
};

export default ForgotPassword;
