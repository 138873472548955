import React from "react";
import { useTranslation } from "react-i18next";

import ModalControl from "../../../modalControl";
import { ReactComponent as CameraIcon } from "../../../../media/icons/photo-listing.svg";
import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import styles from "./accept.module.css";

const Accept = ({ clearAllModals, onClose, active, onSubmit, loading, rejectAccess }) => {
  const { t } = useTranslation();

  const { team_member_id, name_f, name_l } = active;

  const handleSubmit = () => {
    onSubmit({ team_member_id });
  };

  const handleReject = () => {
    rejectAccess({ team_member_id });
    onClose();
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}

      <span className={styles.label}>{t("dashboard_teams_myteam_title")}</span>
      <h3 className={styles.title}>{t("dashboard_teams_review_desc")}</h3>

      <div className={styles.user}>
        <div className={styles.userPhoto}>
          <CameraIcon />
        </div>
        <div className={styles.invited}>
          Invited by
          <span> {`${name_f} ${name_l}`}</span>
        </div>
      </div>

      <ModalControl onExit={handleReject} onConfirm={handleSubmit} isExitReject>
        Accept
      </ModalControl>
    </Modal>
  );
};

export default Accept;
