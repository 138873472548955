import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLoadReferrals, useReferralsState, useReferralsActions, useSortOptions } from "./hooks";
import { OverlayPreloader } from "../common/preloader";
import { Search } from "../payPerLead/search";
import Tabs from "../payPerLead/tabs";
import List from "../payPerLead/list";
import Pagination from "./components/pagination";
import SelectInput from "../common/selectInput";
import MainTabs from "../payPerLead/mainTabs";
import styles from "../payPerLead/payPerLead.module.css";

const Referrals = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    setListActiveNetwork,
    listActivePractice,
    activeSort,
    perPage,
    setListActivePractice,
    listActiveNetwork,
    practices,
    searchParams,
    searchReferrals,
    setActiveSort,
    setSearchReferrals,
    setPerPage,
    pagination,
    preloadersReferrals,
    loading,
    setActiveFilter,
    activeFilter
  } = useReferralsState();

  const options = useSortOptions();
  const { getCases, handleRemoveReferrals, handleFiltrationSortReferrals, handleFiltrationSearchReferrals } = useReferralsActions({
    activeSort,
    listActiveNetwork,
    listActivePractice,
    searchParams,
    searchReferrals,
    setActiveSort,
    setSearchReferrals
  });

  const { referralsList } = useLoadReferrals({
    setListActiveNetwork,
    setListActivePractice,
    listActiveNetwork,
    listActivePractice,
    activeSort,
    perPage
  });

  const renderMainContent = () => (
    <div>
      <List referralsList={referralsList} isReferrals practices={practices} handleRemoveReferrals={handleRemoveReferrals} />
      <Pagination
        setPerPage={setPerPage}
        getCases={getCases}
        activeSort={activeSort}
        listActiveNetwork={listActiveNetwork}
        listActivePractice={listActivePractice}
        searchParams={searchParams}
        searchReferrals={searchReferrals}
        history={history}
        pagination={pagination}
        referralsLength={referralsList.length}
      />
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.mainTitle}>My referral requests</h1>
      {(loading || preloadersReferrals.preloaderCase) && <OverlayPreloader />}

      <MainTabs />

      <Search handleFiltrationSearch={handleFiltrationSearchReferrals} search={searchReferrals} isReferrals>
        <SelectInput
          classNameWrap={styles.selectWrap}
          className={styles.selectLatest}
          name="latest"
          placeholder="Most Recent"
          defaultValue={{ value: "", label: t("dashboard_ppl_sortdropdown_default") }}
          onChange={handleFiltrationSortReferrals}
          options={options}
          value={activeSort}
        />
      </Search>

      <div className={styles.container}>
        <div className={styles.tabNavContent}>
          <div>
            <Tabs
              setListActivePractice={setListActivePractice}
              listActivePractice={listActivePractice}
              setListActiveNetwork={setListActiveNetwork}
              listActiveNetwork={listActiveNetwork}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
            />
            {renderMainContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
