import React from "react";
import { Redirect } from "react-router-dom";

import { OverlayPreloader } from "../../components/common/preloader";
import PrivateRoutes from "../privateRoutes";

const PrivateRoutesContainer = ({ isLoggedIn, keycloak }) => {
  if (isLoggedIn === null) {
    return <OverlayPreloader />;
  }

  return isLoggedIn ? <PrivateRoutes keycloak={keycloak} /> : <Redirect to="/login" />;
};

export default PrivateRoutesContainer;
